.register-page {
  .register-container {
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: start;
    align-content: start;
  }
  
  .btn-login {
    margin-top: 2rem;
  }
  
  .form-right {
    margin-top: 10%;
  }
       
  
  /* Create a custom radio button */
  .G-zr .G-radio .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #fff;
    border: 2px solid #000;
    border-radius: 50%;
  }
  
  /* On mouse-over, add a grey background color */
  .G-zr .G-radio .conta:hover input~.checkmark {
    background-color: #ccc;
  }
  
  /* When the radio button is checked, add a blue background */
  .G-zr .G-radio .conta input:checked~.checkmark {
    background-color: #358539;
    border: 2px solid #358539;
  }
  
  /* Create the indicator (the dot/circle - hidden when not checked) */
  .G-zr .G-radio .checkmark:after {
    content: '';
    position: absolute;
    display: none;
  }
  
  /* Show the indicator (dot/circle) when checked */
  .G-zr .G-radio .conta input:checked~.checkmark:after {
    display: block;
  }
  
  .alert {
    padding: 15px;
    margin-top: 20px;
    border: 1px solid transparent;
    border-radius: 4px;
  }
  
  .checkbox-new {}
  
  .checkbox-new input[type='checkbox'] {
    accent-color: #3C923F;
    transform: scale(1.5);
  }
  
  .checkbox-new label {
    margin: 0 1rem 0 0.4rem;
  }
  
  .G-zr .G-radio .conta .checkmark:hover {
    background: white;
  }
  
  /* Style the indicator (dot/circle) */
  .G-zr .G-radio .conta .checkmark:after {
    top: 4.5px;
    left: 4.5px;
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background: white;
  }
  
  .G-zr .ptype input[type='checkbox']+label {
    display: block;
    margin: 0.2em;
    cursor: pointer;
    padding: 0.2em;
  }
  
  .G-zr .ptype input[type='checkbox'] {
    display: none;
  }
  
     
  
  /* RegistrationPage.css */
  .registration-page {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .header-banner { 
    padding: 50px 0;
    text-align: center;
    width: 97%;
    background-size: cover; /* Ensure the image covers the entire area */
    background-position: center; 
    height: 19rem;
    margin-top: 18px;
    border-radius: 12px;
  }
  
  .banner-content h1 {
    font-size: 2.5em;
    color: white;
  }
  
  .banner-content p {
    font-size: 1.2em;
    color: white;
  }
  
  .registration-form {
    background-color: white;
    padding: 40px;
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.04);
    width: 400px;
    margin-top: -142px;
  }
  
  .registration-form h2 {
    margin-bottom: 20px;
    text-align: center;
    font-weight: 600;
    font-size: 18px;
    color: #2D3748;
  }
  
  .form-group {
    margin-bottom: 14px;
  }
  
  .form-group input,
  .form-group select {
    width: 100%;
    padding: 10px;
    font-size: 1em;
    border: 1px solid #A0AEC0;
    border-radius: 8px;
    outline: none;
    color: #A0AEC0;
  }
  
  .signup-btn {
    background-color: #3C923F80; /* 50% opacity */
    color: white;
    border: none;
    padding: 10px 20px;
    font-size: 12px;
    cursor: pointer;
    border-radius: 12px;
    width: 100%;
    max-width: 22rem;
    transition: background-color 0.3s ease;
  }
  
  .signup-btn:hover {
    background-color: #3C923F;
  }
  
  
   
  
  .signin-link {
    text-align: center;
    margin-top: 20px;
  }
  
  .signin-link a {
    color: #4CAF50;
    text-decoration: none;
  }
  
  .signin-link a:hover {
    text-decoration: underline;
  }
  .footer1 {
    text-align: left;
    padding: 10px;
    bottom: 0;
    width: 100%;
    margin-left: 10%;
  }
}
  