@import url("https://fonts.googleapis.com/css?family=Raleway:300,400,500,600,700&display=swap");
@import url("https://fonts.googleapis.com/css?family=Montserrat:300,400,500,600,700&display=swap");

.montserrat {
  font-family: "Montserrat", sans-serif;
}

:root {
  --fs-10: 10px;
  --fs-12: 12px;
  --fs-13: 13px;
  --fs-14: 14px;
  --fs-15: 15px;
  --green: #358539;
  --lt-green: #19be20;
  --brown: #362f2c;
  --gray: #f1f1f1;
  --lit-gray: #a7a7a7;
}

a {
  text-decoration: none !important;
  color: #28642b;
}

.container {
  width: 100%;
  max-width: 1200px;
}

.sec-clr {
  color: #362f2c !important;
}

.mar-0 {
  margin: 0 !important;
}

.ml-6,
.mx-6 {
  margin-left: 4rem !important;
}

.mr-6,
.mx-6 {
  margin-right: 4rem !important;
}

.pl-6,
.px-6 {
  padding-left: 4rem !important;
}

.pr-6,
.px-6 {
  padding-right: 4rem !important;
}

.pad-lt-0 {
  padding-left: 0 !important;
}

.text-black {
  color: #000000;
}

.G-zr{
  font-family: Roboto;
}

.G-zr .site-top-hight {
  margin-top: 22%;
}

.G-zr .h-135 {
  height: 135%;
}

.G-zr .w-48 {
  width: 48.5% !important;
}

.fs-10 {
  font-size: var(--fs-10) !important;
}

.lt-green {
  color: var(--lt-green) !important;
}

.lt-gray-bg {
  background: #f5f5f5;
}

.txt-gray {
  color: var(--lit-gray);
}

body.bg-gray,
.bg-gray {
  background: var(--gray);
}

body .bg-Dgray {
  background: #f3f3f3;
}

body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  background: #fff;
  font-family: "Raleway", sans-serif;
}

.G-zr .navbar-brand img {
  width: 150px;
  height: 50px;
  object-fit: cover;
}

.G-zr .navbar {
  height: 70px;
}

.G-zr .navbar-expand-lg .navbar-nav .nav-item.active .nav-link {
  border: 1px solid #fff;
  border-radius: 4px;
}

.G-zr .navbar-expand-lg .navbar-nav .nav-item.user-nav .nav-link {
  font-weight: 400;
}

.G-zr .navbar-expand-lg .navbar-nav .nav-link {
  font-size: 14px;
  font-weight: 600;
  padding: 6px 12px;
  color: #fff;
  text-transform: uppercase;
}

.G-zr .video-bg {
  background-image: url("/images/banner-bg.png");
  width: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 660px;
  min-height: 660px;
}

.G-zr .G-left h1 {
  color: #358539;
  font-size: 35px;
  font-family: "SpaceGrotesk-Medium";
}

.G-zr .G-left h2 {
  font-size: 15px;
  line-height: 30px;
  font-weight: 400;
}

.G-zr .hiw-sec {
  display: flex;
  justify-content: center;
  flex-flow: column;
  align-items: center;
}

.G-zr .hiw-sec h4 {
  font-size: 30px;
  font-weight: 800;
}

.G-zr .hiw-sec .nav-pills .nav-link {
  color: #358539;
  background-color: #ffffff;
  border-radius: 0;
  font-size: 21px;
  border: 1px solid rgba(59, 147, 63, 0);
  font-weight: 600;
  padding: 3px;
  width: 133px;
  text-align: center;
  text-transform: uppercase;
}

.G-zr .hiw-sec .nav-pills .nav-link.active {
  color: #358539;
  border: 1px solid #358539;
}

.G-zr .hiw-sec .list-works {
  padding: 0 45px;
  background: url("/images/dot-line.svg");
  width: 100%;
  background-position: center 130px;
  background-repeat: no-repeat;
}

.G-zr .hiw-sec .list-works .card-list {
  padding: 10px 25px;
}

.G-zr .hiw-sec .list-works .card-list h5 {
  font-size: 18px;
  font-weight: 600;
  background: white;
  margin: 0;
  height: 60px;
}

.G-zr .hiw-sec .list-works .card-list p {
  font-size: 15px;
  font-weight: 400;
  padding: 0 30px;
}

.G-zr .hiw-sec .list-works.snd .card-list p {
  padding: 0px;
}

.G-zr .hiw-sec .list-works .card-list img {
  width: 131px;
  height: 131px;
  object-fit: cover;
}

.btn-green {
  border: 1px solid #358539;
  background: #358539;
  padding: 10px 25px;
  font-size: 15px;
  color: #fff;
  transition: all 0.3s ease-in-out;
}
.btn-red {
  border: 1px solid #e40c0c;
  background: #e40c0c;
  padding: 10px 25px;
  font-size: 15px;
  color: #fff;
  transition: all 0.3s ease-in-out;
}
.G-zr .snd-banner:after {
  content: "";
  position: absolute;
  width: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: #362f2ccc;
  z-index: 0;
}

.G-zr .snd-banner {
  background: url("/images/snd-banner.png");
  width: 100%;
  height: 360px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

.G-zr .snd-banner .enquty h4 {
  font-size: 25px;
  text-transform: uppercase;
  font-family: "SpaceGrotesk-Regular";
}

.G-zr .snd-banner .enquty p {
  font-size: 15px;
  color: #d6d6d6;
  padding: 15px 0;
}

.G-zr .snd-banner .enquty {
  z-index: 999;
  color: white;
  height: 360px;
  position: relative;
  display: flex;
  flex-flow: column;
  justify-content: center;
  width: 60%;
  align-items: center;
  align-content: center;
  margin: auto;
}

.G-zr .fot-sec figure img[alt="Fot-logo"] {
  width: 188px;
  height: 66px;
}

.G-zr .fot-sec figure {
  padding: 0 40px;
}

.G-zr .fot-sec .contact span.af:after {
  position: absolute;
  content: "";
  background: #dedede;
  width: 1px;
  height: 25px;
  top: 0;
  right: 0;
}

.G-zr .fot-sec .contact span {
  font-size: 15px;
  font-weight: 500;
  position: relative;
}

.G-zr .fot-sec span {
  padding: 0 30px;
}

.G-zr .fot-sec span a {
  font-size: 15px;
  font-weight: 500;
  color: #358539;
  cursor: pointer;
}

.G-zr footer h6 {
  font-size: 14px;
}

.G-zr footer {
  background: #362f2c;
  padding: 12px;
  color: #fff;
  text-align: center;
}

/*CUSTOM SCROLLBAR*/
.act-cen {
  height: 180px !important;
}

.act-cen .ac-scrollbar {
  overflow-y: scroll;
  padding-right: 10px;
  width: 100%;
}

.act-cen .ac-scrollbar::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: white;
  width: 5px;
}

.act-cen .ac-scrollbar::-webkit-scrollbar {
  width: 5px;
  background-color: transparent;
  background-color: white;
}

.act-cen .ac-scrollbar::-webkit-scrollbar-thumb {
  background-color: #358539;
  border-radius: 50px;
}

/*CUSTOM SCROLLBAR END*/

.G-zr .G-main {
  height: 600px;
  align-items: center;
}

.G-zr .G-left {
  width: 610px;
  padding-left: 60px;
}

.G-zr .G-right {
  padding: 20%;
}

.G-zr .G-right img {
  cursor: pointer;
}

.G-zr .search-form .btn-green {
  padding: 13px 25px;
}

.G-zr .search-form .input-group-prepend {
  position: relative;
  margin-right: 0px;
}

.G-zr .search-form .input-group-text.af figure:after {
  background: #5e5e5e21;
  content: "";
  width: 2px;
  position: absolute;
  height: 25px;
  top: -2px;
  right: -11px;
}

.G-zr .search-form .input-group-text {
  border: none;
  background-color: #ffffff;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
}

.G-zr .search-form .input-group {
  border: 1px solid #358539;
  border-radius: 6px;
  margin-right: 10px;
  height: 50px;
}

.G-zr .search-form .input-group input.b-in {
  font-size: 12px;
  padding-left: 3px;
  border-radius: 6px;
  font-weight: 500;
}

.G-zr .search-form .input-group input.a-in {
  width: 165px;
  font-family: "Raleway", sans-serif !important;
}

.G-zr .search-form .input-group input {
  border: none;
  padding: 0;
  height: 48px;
}

.G-zr .search-form .input-group input:focus {
  border: none;
  outline: none;
  box-shadow: none;
}

.G-zr .search-form .input-group input.a-in::placeholder {
  color: #358539;
  font-style: italic;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.5px;
  font-family: "Raleway", sans-serif !important;
}

.modal-pop .modal-header button {
  font-size: 26px;
  font-weight: 100;
  color: #04561a;
  float: none;
  opacity: 1;
  font-family: fantasy;
  margin: 0;
  line-height: 0;
  padding: 0;
}

.modal-pop .modal-header {
  right: 19px;
  z-index: 99;
  background: #e2e2e2;
  color: black;
  border-radius: 100px;
  width: 15px;
  height: 15px;
  display: flex;
  justify-content: center;
  top: 12px;
  align-items: center;
}

.modal-pop .modal-dialog {
  max-width: 1300px;
  height: 500px;
  margin: 46px auto;
}

/* login */
.G-zr .form-right .log-logo img {
  width: 188px;
  height: 66px;
  object-fit: cover;
}

.G-zr .log-logo span h4 {
  position: relative;
  font-size: 18px;
  font-weight: 500;
  color: #358539;
  letter-spacing: 1px;
}

.G-zr .log-logo span h4::after {
  content: "";
  position: absolute;
  background: #358539;
  width: 30px;
  height: 2px;
  margin: auto;
  left: 0;
  right: 0;
  bottom: 0;
  top: 35px;
  border-radius: 100px;
}

.G-zr .G-inpu::placeholder {
  color: #bbbbbb;
  font-size: 16px;
  font-weight: 400;
}

.G-zr .G-label {
  color: rgb(0, 0, 0);
  font-size: 16px;
  font-weight: 400;
}

.G-zr .G-input:focus,
.G-zr .G-input:hover,
.G-zr .G-input:active {
  box-shadow: none;
  outline: 0;
  background: #f5fdf5;
  border-bottom: 2px solid #358539 !important;
  transition: all 0.6s cubic-bezier(0, 0, 0.2, 1);
}

.G-zr .G-input {
  height: 50px;
  border-bottom: 1px solid #000 !important;
}

.G-zr .form-right {
  margin: 80px auto 30px;
  max-width: 400px;
}

.G-zr .cpy-rt {
  color: #c5c5c5;
  font-weight: 600;
  font-size: 12px;
}

.G-zr .L-curve {
  top: 0px;
}

.rtCrvCntnr {
  height: 900px;
  min-height: 900px;
  max-height: 900px;
  width: 100%;
  object-fit: cover;
  object-position: top;
  border-top-right-radius: 500px;
  border-bottom-right-radius: 500px;
  overflow: hidden;
}

.G-zr .L-curve .curve-D {
  bottom: -150px;
  left: -36px;
  background: #fff;
  border-radius: 100%;
  width: 530px;
  height: 530px;
  display: flex;
  flex-flow: column;
  text-align: center;
  align-items: center;
  justify-content: center;
  padding: 0 100px;
}

.G-zr .L-curve .curve-D a {
  color: #358539;
  font-size: 16px;
  font-weight: 500;
}

.G-zr .G-form .recovey {
  line-height: 25px;
}

.G-zr .txt-green {
  color: var(--green) !important;
}

.G-zr .G-form .recovey p {
  font-size: 15px;
  font-weight: 400;
}

.G-zr .L-curve img {
  height: 900px;
  min-height: 900px;
  max-height: 900px;
  width: 100%;
  object-fit: cover;
  object-position: top;
  /* border-top-right-radius: 100%; */
  /* border-bottom-right-radius: 100%; */
}

.G-zr .L-curve .curve-D h1 {
  margin-top: -110px;
}

.G-zr .L-curve .curve-D p {
  color: #464646;
  font-size: 15px;
}

/* custom check box */
.G-zr .con-ner {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 16px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.G-zr .con-ner input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.G-zr .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  border-radius: 3px;
  background-color: #fff;
  border: 1px solid #358539;
}

/* On mouse-over, add a grey background color */
.G-zr .con-ner:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.G-zr .con-ner input:checked ~ .checkmark {
  background-color: #358539;
  transition: all 0.6s cubic-bezier(0, 0.7, 0.49, 0.01);
}

/* Create the checkmark/indicator (hidden when not checked) */
.G-zr .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.G-zr .con-ner input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.G-zr .con-ner .checkmark:after {
  left: 7px;
  top: 3px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

/* custom check box end */

.G-zr .Af-log {
  /* border-bottom: 3px solid #358539; */
  border: none;
}

.G-zr .Af-log .navbar-expand-lg .navbar-nav .nav-link:hover {
  color: #358539;
}

.G-zr .navbar-expand-lg .navbar-nav .nav-link.btn {
  border: 1px solid #358539;
}

.G-zr .Af-log .navbar-expand-lg .navbar-nav .nav-link {
  color: #262626;
}

.G-zr .quick-search.quick-search-filter {
  background: #f1f1f1;
}

.G-zr .quick-search {
  background: #ffffff;
}

.G-zr .quick-search .search-form .input-group .btn {
  font-size: 14px;
  color: #358539;
  padding: 0 20px;
  display: flex;
}

.G-zr .quick-search .search-form .input-group .btn:before {
  content: "";
  position: absolute;
  background: #bcbcbc;
  width: 1px;
  height: 25px;
  top: 10px;
  left: 0;
}

.G-zr .quick-search .search-form .input-group .btn:after {
  border: none;
  content: "\f078";
  font-family: "FontAwesome";
  font-weight: 400;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  margin-left: 15px;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
}

.G-zr .quick-search .search-form .input-group .btn:hover,
.G-zr .quick-search .search-form .input-group .btn:focus {
  background: #fff;
  box-shadow: none;
}

.G-zr .quick-search .search-form .input-group {
  flex: 1;
}

.G-zr .quick-search .form-btm h4 {
  font-size: 14px;
  font-weight: 500;
  position: relative;
  display: flex;
  justify-content: space-between;
}

.G-zr .quick-search .form-btm h4 .fa-circle:before {
  font-size: 6px;
  position: absolute;
  top: 6px;
  left: -14px;
}

.G-zr .quick-search .search-form .input-group {
  border-radius: 3px;
}

.G-zr .filter-left .filter-hed span:nth-child(2) {
  font-weight: 500;
}

/* Accordion styles */
.accor.tabs {
  border-radius: 0px;
  overflow: hidden;
}

.accor .tab input[type="radio"] {
  position: absolute;
  opacity: 0;
  z-index: -1;
}

.accor .tab {
  border-radius: 4px;
  width: 100%;
  color: white;
  overflow: hidden;
}

.accor .tab-label {
  display: flex;
  justify-content: space-between;
  padding: 16px;
  background: #fff;
  font-weight: bold;
  cursor: pointer;
  color: black;
  font-weight: 500;
  font-size: 16px;
  margin-bottom: 15px;
  /* Icon */
}

.accor .tab-label:hover {
  background: #fff;
}

.accor .tab-label::after {
  content: "";
  width: 24px;
  height: 24px;
  text-align: center;
  transition: all 0.35s;
  border: 1px solid #000;
  border-radius: 100px;
  background-image: url(/images/icon/add.svg);
  background-size: 13px 19px;
  background-repeat: no-repeat;
  background-position: 4px 1px;
}

.accor .tab-content {
  max-height: 0;
  padding: 0 1em;
  color: #2c3e50;
  background: transparent;
  transition: all 0.35s;
}

.accor .tab-close {
  display: flex;
  justify-content: flex-end;
  padding: 1em;
  font-size: 0.75em;
  background: #2c3e50;
  cursor: pointer;
}

.accor .tab-close:hover {
  background: #fff;
}

.accor input:checked + .tab-label {
  background: #ffffff;
  color: #358539;
  margin: 0;
}

.accor input:checked + .tab-label::after {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
  background-image: url(/images/icon/remove.svg);
  background-size: 13px 19px;
  background-repeat: no-repeat;
  background-position: 4px 1px;
  border: 1px solid #358539;
  border-radius: 100px;
}

.accor input:checked ~ .tab-content {
  max-height: 100vh;
  padding: 1em;
}

.accor input:checked ~ .tab-content ul li:hover {
  color: #358539;
}

.accor input:checked ~ .tab-content ul li {
  line-height: 30px;
  font-weight: 600;
  cursor: pointer;
}

.accor input:checked ~ .tab-content ul {
  list-style-type: none;
}

/* // accordien end */

.G-zr .filter-right .btn-emt .btn:hover,
.G-zr .filter-right .btn-emt .btn:focus {
  background: transparent;
  box-shadow: none;
}

.G-zr .btn-green.tg-gbtn {
  padding: 5px;
  width: 35px;
  height: 30px;
  line-height: 1;
  margin-left: 5px;
  background: #212529;
  border-color: #212529;
}

.G-zr .btn.btn-green:hover {
  color: #ffffff;
  background: #2f7d33;
  border-color: #2f7d33;
}

.G-zr .btn.btn-green:focus {
  box-shadow: none;
  background: #2e7c31;
  border-color: #2e7c31;
}

.G-zr .btn-green.tg-gbtn:hover,
.G-zr .btn-green.tg-gbtn:focus {
  background: #fff;
  color: #212529;
  border-color: #212529;
}

.G-zr .filter-right .btn-emt .btn {
  font-size: 14px;
  color: #358539;
  padding: 0 20px;
  display: flex;
}

.G-zr .filter-right .btn-emt .btn:after {
  border: none;
  content: "\f078";
  font-family: "FontAwesome";
  font-weight: 400;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  margin-left: 15px;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
}

.G-zr .filter-right .product-list .card {
  flex-flow: row;
  margin-bottom: 30px;
  height: 285px;
  min-height: 285px;
  overflow: hidden;
}

.G-zr .filter-right .product-list .card .pro-img.wht figure:after {
  background-image: url(/images/icon/w-like.svg);
}

.G-zr .filter-right .product-list .card .pro-img figure:after,
.G-zr .pro-view-slider .owl-carousel .owl-item div:after {
  position: absolute;
  content: "";
  top: 0;
  right: 0;
  width: 42px;
  height: 42px;
  background: #00000040;
  background-image: url(/images/icon/like.svg);
  background-repeat: no-repeat;
  background-size: 22px;
  background-position: center;
}

/* .G-zr .Dash-right .Farms-card figure:after {
    

    background-image: none;
    content: "\f005";
    font-family: "FontAwesome";
    font-weight: 400;
    font-style: normal;
    font-variant: normal;
    border-radius: 5px;
    font-size: 18px;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    color: #FECB25;
    pointer-events: none;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
} */

.G-zr .pro-view-slider .owl-carousel .owl-item div:after {
  background: #00000059;
  background-repeat: no-repeat;
  opacity: 0;
  background-image: url(/images/icon/like.svg);
  background-size: 22px;
  background-position: center;
}

.G-zr .filter-right .product-list .card .pro-img img {
  width: 350px;
  height: 285px;
  object-fit: cover;
  object-position: center;
}

.G-zr .fs-12 {
  font-size: var(--fs-12);
}

.G-zr .fs-13 {
  font-size: var(--fs-13);
}

.G-zr .fs-14 {
  font-size: var(--fs-14);
}

.G-zr .fs-15 {
  font-size: var(--fs-15);
}

.G-zr .fs-16 {
  font-size: 16px !important;
}

.G-zr .fs-18 {
  font-size: 18px !important;
}

.G-zr .fs-20 {
  font-size: 20px !important;
}

.G-zr .fs-22 {
  font-size: 22px !important;
}

.G-zr .fs-24 {
  font-size: 24px !important;
}

.G-zr .fw-l {
  font-weight: 300;
}

.G-zr .fw-r {
  font-weight: 400;
}

.G-zr .fw-m {
  font-weight: 500;
}

.G-zr .fw-sm {
  font-weight: 600;
}

.G-zr .btn-bwn {
  background: var(--brown);
  padding: 8px 16px;
  cursor: pointer;
  z-index:1;
  position:relative;
}

.G-zr .btn-bwn:hover {
  color: #fff;
  background: #594d47;
  box-shadow: none;
}

.G-zr .btn-bwn:focus {
  box-shadow: none;
}

.G-zr .filter-right .product-list .card .card-body .C-hd span:nth-child(1) {
  font-size: 20px;
  font-weight: 500;
}

.G-zr .filter-right .product-list .card .card-body .card-fot span {
  color: #f85315;
}

.G-zr .filter-right .product-list .card .card-body .ro-wo {
  padding: 5px 0;
}

.G-zr .filter-right .product-list .card .card-body .ro-wo span {
  font-size: 15px;
  font-weight: 500;
}

.G-zr .filter-right .product-list .card .card-body .ro-wo span b {
  font-weight: 500;
}

.G-zr .filter-right .product-list .card .card-body .sub {
  font-weight: 500;
  color: #bcbcbc;
  padding: 4px 0;
  font-size: 13px;
  display: block;
}

.G-zr .filter-right .product-list .card .card-body .C-hd span:nth-child(2) {
  font-weight: 600;
  font-size: 20px;
}

.G-zr .carosal-up-product.sub-list .up-card {
  /*    margin-right: 15px;*/
  margin-bottom: 55px;
}

.G-zr .carosal-up-product .up-card {
  width: 352px;
  /*    box-shadow: 0px 12px 9px 0px #00000008;*/
  transition: all 0.5s ease-in-out;
}

.G-zr .carosal-up-product.search-l .up-card:hover,
.seller-product-vw .up-card:hover {
  box-shadow: 0px 3px 15px 0px #7676762e;
  border-radius: 5px;
  box-shadow: 0px 3px 15px 0px #7676762e;
  transform: scale(1.05);
  border-radius: 5px;
}

.G-zr .carosal-up-product .up-card:hover .sp-img {
  /*    transform: scale(1.05);*/
}

.G-zr .carosal-up-product .up-card .up-card-body h5 {
  font-size: 18px;
}

.G-zr .carosal-up-product .up-card button {
  height: 45px;
}

.G-zr .carosal-up-product .up-card img {
  width: 352px;
  height: 253px;
  object-fit: cover;
  object-position: center;
  border-radius: 5px;
  transition: all 0.5s ease-in-out;
}

.G-zr .carosal-up-product .up-card figure .ovy-lay img {
  width: 25px;
  height: 25px;
  object-fit: cover;
  object-position: center;
}

.G-zr .carosal-up-product .up-card figure .ovy-lay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: #0000007a;
  text-align: center;
  padding: 6px;
  font-size: 15px;
  color: #fbfbfb;
}

.G-zr .carosal-up-product .up-card.red figure:after {
  background-image: url(/images/icon/like.svg);
}

.G-zr .carosal-up-product .up-card figure:after {
  position: absolute;
  content: "";
  top: 0;
  right: 0;
  width: 42px;
  height: 42px;
  background: #00000052;
  background-image: url(/images/icon/w-like.svg);
  background-repeat: no-repeat;
  background-size: 22px;
  background-position: center;
  border-top-right-radius: 5px;
}

.G-zr .G-page {
  display: flex;
  justify-content: flex-end;
  clear: both;
}

.G-zr .G-page .page-item {
  margin-right: 10px;
  font-weight: 600;
  font-size: 18px;
  box-shadow: 0px 3px 6px 0px #0000000d;
  border: none;
  font-family: "Montserrat", sans-serif;
  text-align: center;
}

.G-zr .G-page .page-item .page-link:hover {
  background-color: #358539;
  color: #fff;
}

.G-zr .G-page .page-item .page-link {
  color: #358539;
  border: none;
  width: 45px;
  height: 45px;
  line-height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 2px;
}

.G-zr .G-page .page-item.active .page-link {
  z-index: 1;
  color: #fff;
  background-color: #358539;
  border: none;
}

.G-zr .breadcrumb-item + .breadcrumb-item::before {
  content: ">" !important;
  font-weight: 600;
  color: #358539;
}

.G-zr .product-post h4 {
  font-size: 20px;
  font-weight: 500;
  padding-top: 25px;
}

.G-zr .product-post {
  margin-top: 15% !important;
  width: 100%;
  max-width: 900px;
  border: 1px solid #d2d2d2;
  border-radius: 5px;
  /*    box-shadow: 0px 0px 10px 0px #0000001a;*/
}

.G-zr .product-post .progres-bar .step.active p,
.G-zr .product-post .progres-bar .step.finished p {
  color: #358539;
}

.G-zr .product-post .progres-bar .step p {
  font-size: 14px;
  font-weight: 500;
  color: #bcbcbc;
}

.G-zr .product-post .progres-bar .step.active span,
.G-zr .track-left .progres-bar .step.active span {
  border: 1px solid #19be20;
  border-radius: 100%;
  color: #19be20;
  background: #fff;
}

.G-zr .product-post .progres-bar .step.finished span {
  border: 1px solid #19be20;
  border-radius: 100%;
  color: #fff;
  background: #19be20;
}

.G-zr .product-post .progres-bar {
  flex-flow: row;
}

.G-zr .product-post .progres-bar .step {
  margin: auto 25px;
}

.G-zr .progress-form .pl-f-img img {
  width: 525px;
}

.G-zr .track-left.track-table .progres-bar .step {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30%;
  flex-flow: column;
}

.G-zr .track-left .progres-bar {
  height: 100%;
}

.G-zr .track-left .progres-bar .step {
  margin: 0;
}

.G-zr .track-left .progres-bar .step span {
  margin: 0 !important;
}

.G-zr .product-post .progres-bar .step span,
.G-zr .track-left .progres-bar .step span {
  text-align: center;
  width: 60px;
  display: flex;
  align-items: center;
  margin: auto;
  justify-content: center;
  height: 60px;
  border: 1px solid #bcbcbc;
  border-radius: 100%;
  position: relative;
  background: #fff;
  color: #bcbcbc;
  z-index: 99;
  font-weight: 600;
  font-size: 18px;
}

.G-zr .track-left .progres-bar .step span {
  width: 44px !important;
  height: 44px !important;
  min-width: 44px;
  max-width: 44px;
}

.G-zr .product-post .progres-bar .step-1.active span:after,
.G-zr .product-post .progres-bar .step-2.active span:after,
.G-zr .track-left .progres-bar .step-1.active span:after,
.G-zr .track-left .progres-bar .step-2.active span:after,
.G-zr .track-left .progres-bar .step-3.active span:after,
.G-zr .track-left .progres-bar .step-4.active span:after {
  /*    background-image: linear-gradient(90deg, #358539, #358539 75%, transparent 75%, transparent 100%) !important;*/
  border-width: 1px;
  border: none;
  background-size: 14px 17px;
  background-repeat: space;
}

.G-zr .product-post .progres-bar .step:nth-child(3) span:after {
  display: none;
}

.G-zr .track-left .progres-bar .step:nth-child(3) span:after {
  display: block;
}

.G-zr .track-left .progres-bar .step:nth-child(5) span:after {
  display: none;
}

.G-zr .product-post .progres-bar .step-1 span:after {
  content: "";
  position: absolute;
  background-image: linear-gradient(
    90deg,
    #dfdfdf,
    #b2b2b2 75%,
    transparent 75%,
    transparent 100%
  );
  width: 600px;
  height: 1px;
  left: 60px;
  top: 29px;
  z-index: -1;
  border-width: 1px;
  background-image: linear-gradient(
    90deg,
    #dfdfdf,
    #b2b2b2 75%,
    transparent 75%,
    transparent 100%
  );
  background-size: 14px 10px;
  border: none;
}

.G-zr .track-left {
  box-shadow: 0px 0px 10px 0px #00000017;
}

.G-zr .track-left,
.G-zr .track-right {
  padding: 35px;
}

.G-zr .track-left .progres-bar .step {
  display: flex;
  align-items: flex-start;
  height: 140px;
}

.G-zr .track-left .progres-bar .step span:after {
  content: "";
  position: absolute;
  background: linear-gradient(
    90deg,
    #dfdfdf,
    #b2b2b2 75%,
    transparent 75%,
    transparent 100%
  );
  width: 1px;
  height: 100px;
  left: 20px;
  top: 100%;
  z-index: -1;
  border: none;
  background-size: 4px 10px;
  background-repeat: space;
}

.G-zr .product-post .progres-bar .step.on-progress span,
.G-zr .track-left .progres-bar .step.on-progress span {
  border: 1px solid #19be20;
  color: #19be20;
}

/* .G-zr .product-post .progres-bar .step-1 span{
    background: #358539;
    color: #fff;
} */
.G-zr .txt-area {
  height: 100px !important;
  padding: 20px 25px !important;
}

.G-zr .calender::after {
  position: absolute;
  content: "";
  background-image: url(/images/icon/calendar.svg);
  width: 20px;
  height: 42px;
  background-size: contain;
  top: 0;
  right: 10px;
  background-position: center;
  background-repeat: no-repeat;
}

.G-zr .D-input {
  border: 1px solid #dedede;
  font-size: 15px;
  font-weight: 400;
  height: 45px;
  color: #929292;
  border-radius: 2px;
  padding: 0 25px;
  transition: all 0.5s ease-in-out;
}

.G-zr .D-input::placeholder {
  color: #929292;
  font-size: 15px;
}

.G-zr .D-input:hover,
.G-zr .D-input:focus {
  box-shadow: none;
}

.G-zr .progress-form .sub-pro img {
  width: 75px;
  height: 48px;
  object-position: center;
  object-fit: cover;
  border-radius: 6px;
}

.G-zr .progress-form.form-col p.t-t {
  left: 30px;
}

.G-zr .progress-form p.t-t {
  top: -8px;
  background: #fff;
  left: 15px;
  padding: 0 12px;
  font-size: 12px;
  font-weight: 500;
}

.G-zr .btn-upload {
  height: 50px;
  border: 1px solid #362f2c;
  background: #fff;
  font-size: 15px;
}

.G-zr .s-img img {
  max-width: 355px;
  width: 100%;
  height: 214px;
  object-fit: cover;
  object-position: center;
  border-radius: 6px;
}

.G-zr .progress-form select {
  -webkit-appearance: unset;
}

.G-zr .progress-form .final-state h3 {
  font-size: 25px;
  text-align: center;
}

.G-zr .progress-form .final-state {
  display: flex;
  justify-content: center;
  text-align: center;
}

.G-zr .progress-form .select-wrapper:after {
  width: 0;
  height: 0;
  content: "\f078";
  font-family: "FontAwesome";
  font-weight: 400;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  font-size: 14px;
  margin-left: 15px;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  position: absolute;
  right: 25px;
  top: 12px;
  color: #929292c2;
  pointer-events: none;
}

/* .G-zr .progress-form select:after {
    border: none;
    content: "\f078";
    font-family: "FontAwesome";
    font-weight: 400;
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    margin-left: 15px;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
} */
.G-zr .product-post.final {
  box-shadow: none;
}

.modal-body {
  overflow: hidden;
}

.G-zr .G-modal .modal-body .or {
  color: BCBCBC;
}

.G-zr .G-modal .modal-body .or::after {
  position: absolute;
  content: "";
  background: #bcbcbc;
  width: 47%;
  height: 1px;
  top: 10px;
  right: 0;
}

.G-zr .G-modal .modal-body .or::before {
  position: absolute;
  content: "";
  background: #bcbcbc;
  width: 47%;
  height: 1px;
  top: 10px;
  left: 0;
}

.G-zr .G-modal .modal-header {
  border-bottom: 2px solid #000000;
}

.G-zr .carosal-up-product.sub-list .up-card figure .ovy-lay {
  left: unset;
  width: 45px;
  height: 45px;
  font-size: 15px;
  font-weight: 600;
  display: flex;
  align-items: center;
}

.G-zr .carosal-up-product.sub-list .up-card figure .ovy-lay img {
  width: 16px;
  height: 16px;
  object-fit: contain;
}

.G-zr .G-modal .green-lay {
  background: #35853938;
  padding: 10px 16px;
  color: #358539;
}

/* carosal */
.G-zr .pro-view-slider .content-carousel {
  width: 600px;
  display: block;
  margin: 0 auto;
}

.G-zr .pro-view-slider .owl-carousel {
  width: calc(100% - 100px);
  float: right;
}

.G-zr .pro-view-slider .owl-carousel div {
  /* position: relative;
    width: 100%; */
}

.G-zr .pro-view-slider .owl-carousel .owl-controls .owl-dot {
  background-size: cover;
  margin-top: 10px;
}

.G-zr .pro-view-slider .owl-carousel .owl-dots {
  position: absolute;
  top: 0;
  left: -104px;
  width: 80px;
  height: 325px;
  overflow-x: hidden;
  overflow-y: auto;
}

.G-zr .pro-view-slider .owl-carousel .owl-item img {
  height: 310px;
  object-fit: cover;
  object-position: center;
}

.G-zr .pro-view-slider .owl-carousel.owl-drag .owl-item {
  /* width: 580px!important; */
  object-fit: cover;
}

.G-zr .pro-view-slider .owl-carousel .owl-dot {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 80px;
  height: 65px !important;
  margin-bottom: 15px;
}

/* carosal end */
.G-zr .pro-view-details .pro-view-tit h4 {
  font-size: 22px;
}

.G-zr .pro-view-details .pro-view-tit h4:nth-child(2) {
  font-size: 20px;
}

.G-zr .pro-view-details .sub {
  font-weight: 600;
  color: #bcbcbc;
  font-size: 13px;
}

.G-zr .A-time span {
  background: #fbeee8;
  padding: 12px 20px;
  width: 100%;
  font-weight: 500;
  font-size: 14px;
  color: #f85315;
  font-family: "Montserrat", sans-serif;
}

.G-zr .bid-H .table td .crown:after {
  position: absolute;
  content: "";
  bottom: 6px;
  right: -6px;
  background-image: url(/images/icon/crown.svg);
  width: 22px;
  background-repeat: no-repeat;
  height: 18px;
  background-size: contain;
  background-position: center;
}

.G-zr .bid-H .table td {
  border: none;
  vertical-align: middle;
  height: 60px;
  font-size: 13px;
  font-family: "Montserrat", sans-serif;
}

.G-zr .bid-H .table tr:nth-child(odd) {
  background: #f1f1f1;
}

.G-zr .side-Dash ul {
  list-style-type: none;
  background: #fff;
  box-shadow: 0px 4px 15px 0px #0000001f;
}

.G-zr .side-Dash ul li {
  color: #000000;
  padding: 14px 40px;
  font-size: 15px;
  font-weight: 500;
}

.G-zr .side-Dash ul li:hover,
.G-zr .side-Dash ul li:focus {
  cursor: pointer;
  background: #358539;
  color: #fff;
  transition: all 0.8s cubic-bezier(0.22, 0.61, 0.36, 1);
}

.G-zr .side-Dash ul li.active::after {
  position: absolute;
  content: "";
  background: white;
  width: 3px;
  height: 25px;
  top: 13px;
  left: 25px;
  border-radius: 100px;
}

.G-zr .side-Dash ul li.active {
  background: #358539;
  position: relative;
  color: #fff;
}

.G-zr .Dash-right .table tbody td {
  background: #fff;
}

.G-zr .Dash-right .table thead th {
  vertical-align: top;
  /* text-align: center; */
  border-bottom: 2px solid #E3E5E5;
  border-top: none;
  font-size: 14px;
  font-weight: 500;
  background: #fff;
  padding-bottom: 5px;
  padding-top: 5px;
  color: #919699;
}

.G-zr .Dash-right .table {
  border-collapse: separate;
  border-spacing: 0 5px;
}

.G-zr .Dash-right .table .tbody tr {
  background: #f3f3f3;
}

.G-zr .Dash-right .table tbody {
  background: #f3f3f3;
}

.G-zr .Dash-right .table tbody td {
  vertical-align: middle;
  border-top: none;
  height: 45px;
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  border-bottom: 2px solid #dbdbdb;
}

.G-zr .main-dash .da-sh img {
  /* width: 58px;
    height: 58px; */
  object-fit: contain;
}

.G-zr .main-dash a:hover {
  text-decoration: none;
}

.G-zr .main-dash .da-sh h4 {
  letter-spacing: 1px;
  color: #000;
}

input.form-control.D-input:focus,
textarea.form-control.D-input:focus,
.G-zr .progress-form select:focus {
  border: 1px solid #000;
}

.G-zr .main-dash .da-sh h4::after {
  content: "";
  position: absolute;
  background: #358539;
  right: 0;
  bottom: -5px;
  width: 34px;
  border-radius: 100px;
  height: 2px;
}

.G-zr .main-dash .da-sh .notifi {
  position: absolute;
  top: 10px;
  right: 10px;
  background: #e6521a;
  font-size: 11px;
  width: 66px;
  height: 27px;
  border-radius: 100px;
  text-align: center;
  color: #fff;
  padding: 7px;
}

.G-zr .main-dash .da-sh {
  background: #fff;
  display: flex;
  flex-flow: column;
  align-items: center;
  width: 260px;
  height: 240px;
  justify-content: stretch;
  border-radius: 10px;
  box-shadow: 0px 0px 10px 0px #00000014;
  margin-bottom: 60px;
  border: 1px solid #358539;
}

.G-zr .main-dash .da-sh.bg-none {
  background: transparent;
  box-shadow: none;
}

.G-zr .Dash-right .pro-file img {
  width: 120px;
  height: 120px;
  object-fit: cover;
  margin: auto;
  border-radius: 100px;
}

.G-zr .Dash-right .pro-file .up-load {
  width: 100%;
  text-align: center;
}

.G-zr .custom-control-input:checked ~ .custom-control-label::before {
  border-color: #358539;
  background-color: #358539;
}

.G-zr .custom-switch .custom-control-label::before {
  left: 100%;
  margin-left: 2em;
}

.G-zr .custom-switch .custom-control-label::after {
  left: calc(100% + 25%);
}

.G-zr .custom-switch {
  padding-left: 0.25rem;
}

.G-zr .breadcrumb-item.active {
  color: #000000;
  padding-left: 30px;
}

/* 09/10 s-h */
.G-zr .Dash-right .nav-tabs .nav-item.show .nav-link,
.G-zr .Dash-right .nav-tabs .nav-link.active {
  color: #ffffff;
  background-color: #358539;
  border: 1px solid #358539;
}

.G-zr .Dash-right .nav-tabs {
  border-bottom: 1px solid #358539;
}

.G-zr .Dash-right .nav-tabs .nav-item {
  margin-bottom: 0px;
}

/* .G-zr .Dash-right .nav-tabs .nav-link {
    border: 1px solid #F3F3F3;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    background: #F3F3F3;
    width: 165px;
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    color: #358539;
} */

.G-zr .dash-banner-h {
  height: 331px;
  min-height: 331px;
  object-fit: cover;
}

.G-zr .dash-banner {
  height: 232px;
  min-height: 232px;
  object-fit: cover;
  border-radius: 8px;
}

.G-zr .seller-profile img {
  width: 200px;
  height: 200px;
  object-fit: cover;
  border-radius: 100%;
  border: 8px solid #fff;
}

.G-zr .seller-profile figure {
  top: -100px;
  overflow: hidden;
}

.G-zr .Dash-right .Farms-card .farms-body ul {
  letter-spacing: 6px;
}

.G-zr .seller-profile ul,
.G-zr .Dash-right .Farms-card .farms-body ul,
.G-zr .feed-card .feed-body ul,
.G-zr .feed-card ul,
.G-zr .rating-pop ul {
  list-style-type: none;
}

.G-zr .seller-profile ul li,
.G-zr .feed-card .feed-body ul li {
  margin: 0 5px;
}

.G-zr .rating-pop ul li {
  margin-right: 25px;
}

.G-zr .feed-card ul li {
  margin: 0 3px;
}

.G-zr .seller-profile .vw {
  color: #aca8a8;
}

.G-zr .progress-form.rating .D-input {
  height: 95px !important;
  overflow: hidden;
  resize: none;
}

.G-zr .rating-pop ul li .fa-star:before,
.G-zr .rating-pop ul li .fa-star-half-alt:before,
.G-zr .seller-profile ul li .fa-star:before,
.G-zr .seller-profile ul li .fa-star-half-alt:before,
.G-zr .feed-card .feed-body ul li .fa-star:before,
.G-zr .feed-card .feed-body ul li .fa-star-half-alt:before,
.G-zr .Dash-right .Farms-card .farms-body ul li .fa-star-half-alt:before,
.G-zr .Dash-right .Farms-card .farms-body ul li .fa-star:before {
  color: #fecb25;
  font-size: 20px;
}

.G-zr .Dash-right .Farms-card .farms-body ul li .fa-star-half-alt:before,
.G-zr .Dash-right .Farms-card .farms-body ul li .fa-star:before {
  font-size: 16px;
}

.G-zr .feed-card ul li .fa-star:before,
.G-zr .feed-card ul li .fa-star-half-alt:before {
  color: #fecb25;
  font-size: 14px;
}

.G-zr .seller-profile ul li .far:before,
.G-zr .Dash-right .Farms-card .farms-body ul li .far:before {
  color: #bcbcbc;
}

.G-zr .seller-product-vw .carosal-up-product .up-card {
  margin-right: 60px;
  margin-left: 0px;
  margin-bottom: 55px;
}

.G-zr .fav-bt,
.G-zr .mf-bt {
  /* width: 180px; */
  height: 40px;
  border-radius: 4px;
  border: 1px solid #358539;
  font-size: 15px;
  color: #358539;
  background: #fff;
  transition: 500ms;
}

.G-zr .fav-bt:hover,
.G-zr .mf-bt:hover {
  color: #fff;
  background: #358539;
}

.G-zr .fav-bt:focus,
.G-zr .mf-bt:focus {
  box-shadow: none;
}

.G-zr .m-bt {
  position: absolute;
  bottom: 15px;
  left: 20px;
}

.G-zr .profile-owl .owl-dots {
  display: flex;
  justify-content: center;
  align-items: center;
}

.G-zr .profile-owl .owl-dots button span {
  transition: 400ms;
}

/* .G-zr .seller-product-vw .carosal-up-product.sub-list .up-card:nth-child(3){  margin-right: 0px;}  */
/* 09/10 s-h end */

/* 11/10 */
/* The container */
.G-zr .G-radio .conta {
  display: block;
  position: relative;
  padding-left: 30px;
  margin-right: 30px;
  cursor: pointer;
  line-height: 20px;
  margin-bottom: 0;
  font-weight: 500;
  font-size: 16px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default radio button */
.G-zr .G-radio .conta input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.G-zr .conta:hover .checkmark {
  background: #fff !important;
  border-color: #358539 !important;
}

/* Create a custom radio button */
.G-zr .G-radio .checkmark {
  position: absolute;
  top: 0.5em;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #fff;
  border: 2px solid #000;
  border-radius: 50%;
}

/* On mouse-over, add a grey background color */
.G-zr .G-radio .conta:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the radio button is checked, add a blue background */
.G-zr .G-radio .conta input:checked ~ .checkmark {
  background-color: #358539;
  border: 2px solid #358539;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.G-zr .G-radio .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.G-zr .G-radio .conta input:checked ~ .checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.G-zr .G-radio .conta .checkmark:after {
  top: 3px;
  left: 3px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: white;
}

/* 11/10 end */

.G-zr .feed-card .feed-img img {
  width: 75px;
  height: 75px;
  object-fit: cover;
  border-radius: 100px;
}

.G-zr .feed-card .feed-img {
  width: 50%;
}

.G-zr .feed-card .feed-body {
  width: 50%;
  display: flex;
  flex-flow: column;
  justify-content: start;
}
.G-zr .prd-feed-card.feed-card .feed-img {
  width: 20%;
}
.G-zr .prd-feed-card.feed-card .feed-body {
  width: 80%;
}

.G-zr .feed-card {
  background: #fff;
  border: 1px solid #dedede;
  padding: 23px;
}

.G-zr header {
  z-index: 999;
}

.G-zr .carosal-up-product .owl-carousel .owl-item .ovy-lay img {
  display: unset;
}

.G-zr .carosal-up-product .owl-carousel.owl-drag .owl-item {
  margin-right: 0px !important;
}

.G-zr .bg-Dgray .caro-a img {
  height: 331px;
  min-height: 331px;
  object-fit: cover;
  object-position: center;
  border-radius: 10px;
}

/* .G-zr .bg-Dgray .caro-b:after {
    content: '';
    position: absolute;
    background: url(/images/video-pop.svg) no-repeat;
    left: 0;
    right: 0;
    margin: auto;
    top: 0px;
    z-index: 9999;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-position: center;
    cursor: pointer;
} */

.G-zr .bg-Dgray .caro-b img {
  height: 331px;
  min-height: 331px;
  object-fit: cover;
  object-position: center;
  border-radius: 10px;
}

.G-zr .bg-Dgray .owl-carousel .owl-dots .owl-dot:hover {
  transition: all 1s ease-in;
}

.G-zr .bg-Dgray .owl-carousel .owl-dots .owl-dot:focus {
  outline: 0;
}

.G-zr .bg-Dgray .owl-carousel .owl-dots.disabled {
  display: block;
}

.G-zr .bg-Dgray .owl-theme .owl-dots .owl-dot {
  margin: 0;
}

.G-zr .bg-Dgray .owl-theme .owl-dots .owl-dot.active span,
.G-zr .bg-Dgray .owl-theme .owl-dots .owl-dot:hover span {
  background: #358539;
  width: 20px;
  height: 20px;
  margin: 4px 8px;
}

.G-zr .dash-D {
  padding: 10px 30px;
  padding-right: 120px;
}

.G-zr .dash-D::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  background: #358539;
  width: 9px;
  height: 150px;
  border-radius: 100px;
}

.G-zr .pro-view-details p {
  /* text-overflow: ellipsis;
    overflow: auto;
    height: 75px; */
  text-overflow: ellipsis;
  /* overflow-x: hidden; */
  height: auto;
  max-height: 5em;
  overflow: auto;
  overflow-x: hidden;
  overflow-y: auto;
  word-break: break-all;
  /* border-bottom: 1px solid #358539; */
}

.G-zr .feed-card .feed-img.feed-b img {
  width: 85px;
  height: 85px;
}

.G-zr .check-left address {
  border: 1px solid #ececec;
  line-height: 25px;
}

.G-zr .cart-item img {
  width: 140px;
  height: 100px;
  object-fit: cover;
}

.G-zr .cart-body {
  width: 80%;
}

.G-zr .check-right {
  width: 440px;
}

.G-zr .check-right .progress-form {
  border-radius: 10px;
  box-shadow: 0px 0px 8px 0px #00000017;
  padding-top: 30px !important;
}

.G-zr .place-order h6 {
  padding: 0 22%;
  line-height: 25px;
}

.G-zr .track-bg h4 {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 300px;
  font-size: 25px;
}

.G-zr .track-bg.comutity-bg img {
  height: 350px;
  min-height: 350px;
  object-fit: cover;
  object-position: center;
  width: 100%;
}

.G-zr .track-bg.comutity-bg.sellercomunity-bg {
  background: url("/images/seller-comunity.png") no-repeat;
  background-size: cover;
  background-position: center;
}

.G-zr .track-bg.comutity-bg:after {
  content: "";
  background: #0000001a;
  top: 0;
  bottom: 0;
  width: 100%;
  max-width: 100%;
  height: 100%;
  position: absolute;
  z-index: 0;
}

.G-zr .track-bg.comutity-bg h4 {
  height: 450px;
  min-height: 450px;
}

.G-zr .track-bg {
  /* background: url("/images/tracking-bg.png")no-repeat; */
  background-size: cover;
  background-position: center;
  height: 330px;
  min-height: 330px;
  width: 75%;
  margin-top: 0;
  overflow: hidden;
}

.G-zr .chk-product img {
  height: 103px;
  min-height: 103px;
  width: 113px;
  object-fit: cover;
  max-width: 113px;
}

.G-zr .trk-ststus {
  top: 237px;
  z-index: 9;
}

.G-zr .noti-badge .badge {
  position: absolute;
  background: #e6521a;
  border-radius: 100px;
  color: #fff;
  padding: 3px 7px;
  font-weight: 400;
  font-size: 12px;
  top: -2px;
  right: 8px;
}

.G-zr .comunity-tabs ul .nav-link.active,
.G-zr .comunity-tabs ul .nav-link:hover {
  color: #358539 !important;
  background-color: #fff;
  border: 5px solid #358539;
  border-bottom: 0;
  border-top-left-radius: 9px;
  border-top-right-radius: 9px;
}

.G-zr .comunity-tabs ul .nav-link {
  color: #fff;
  font-size: 18px;
  font-weight: 500;
  width: 202px;
  padding: 14px;
  text-align: center;
}

.G-zr .comunity-tabs ul.nav-tabs {
  border: none;
  background: #358539;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  height: 60px;
}

.G-zr .comunity-tabs {
  margin-top: -60px;
  z-index: 9;
  position: relative;
}

.G-zr .w-90p {
  width: 90%;
}

.G-zr .w-10p {
  width: 10%;
}

.G-zr .w-10 {
  width: 10px;
}

.G-zr .w-20 {
  width: 20px;
}

.G-zr .drop-dn {
  width: 40px;
  height: 40px;
  display: flex;
  border-radius: 100px;
  background: #ebebeb;
  box-shadow: 0px 0px 8px 0px #0000003b;
  justify-content: center;
  align-items: center;
}

.G-zr .grid-img img {
  width: 184px;
  height: 184px;
  object-fit: cover;
  margin-bottom: 35px;
}

.G-zr .discuss-sec .card h6 {
  font-size: 10px;
}

.G-zr .discuss-sec .card h4 {
  margin: 0;
}

.G-zr .discuss-sec .card .grp-img img {
  width: 44px;
  height: 44px;
  border-radius: 100px;
  border: 4px solid #fff;
  margin-left: -15px;
}

.G-zr .discuss-sec .card .active-noti {
  background: #19be20;
  border: 2px solid #19be20;
  border-radius: 100px;
  padding: 3px 12px;
  font-size: 10px;
  font-weight: 500;
  color: #fff;
  margin-left: 10px;
}

.G-zr .discuss-sec .card p {
  line-height: 24px;
}

.G-zr .discuss-sec .card {
  border: none;
  box-shadow: 0px 3px 10px 3px #0000000d;
  border-radius: 10px;
}

.G-zr .discus-tab .w-10 {
  width: 10%;
}

.G-zr .discus-tab .tab-span {
  background: #fff;
  padding: 25px 20px;
  float: left;
  width: 100%;
  border-radius: 4px;
  margin-bottom: 15px;
}

.G-zr .discus-tab img[alt="user-profile"] {
  width: 46px;
  height: 46px;
  object-fit: cover;
  object-position: center;
}

.G-zr .discus-tab .w-90 {
  width: 90%;
}

.G-zr .user-bdy h6:nth-child(1) {
  border: 1px solid #dedede;
  padding: 10px 22px;
  border-radius: 100px;
}

.G-zr .user-bdy h6 {
  /* margin-right: 35px !important; */
  font-weight: 400;
}

.G-zr .Community-div {
  background: var(--gray);
}

.G-zr .comunity-sch span {
  background: #358539;
  border-top-right-radius: 4px !important;
  border-bottom-right-radius: 4px !important;
  border: none;
}

.G-zr .comunity-sch input:focus {
  box-shadow: none;
}

.G-zr .comunity-sch input {
  border: 1px solid #358539;
  height: 60px;
}

.G-zr .comunity-sch input::placeholder {
  font-size: 15px;
  font-style: italic;
  color: #358539;
  font-weight: 500;
}

.G-zr .track-left.track-table .progres-bar {
  height: auto;
}

.G-zr .track-left .progres-bar .step:nth-child(3) {
  height: 190px;
}

.G-zr .track-left.track-table .progres-bar .step:nth-child(3) {
  height: auto;
}

.G-zr .track-left .progres-bar .step.step-3 span:after {
  height: 153px;
  background-size: 5px 20px;
}

.G-zr .track-left.track-table .progres-bar .step span:after {
  width: 110px;
  height: 1px;
  left: 43px;
  top: 20px;
  background-image: linear-gradient(
    90deg,
    #dfdfdf,
    #b2b2b2 75%,
    transparent 75%,
    transparent 100%
  );
  background-size: 14px 10px;
  border: none;
}

.G-zr .table-pro img {
  width: 40px;
  height: 40px;
  border-radius: 5px;
  object-fit: cover;
}

.G-zr .btn-white {
  border: 1px solid #358539;
  border-radius: 4px;
}

/*AS*/
.bg-white-head {
  margin-top: 45px;
}

.wl-table-img {
  width: 50px;
  border-radius: 2px;
}

.items-select {
  margin-left: 15px;
  width: 100px;
}

.feedback-bt button {
  width: max-content;
}

.fp-text {
  font-size: 15px;
  line-height: 24px;
}

.fp-box {
  margin-top: 100px;
}

.fp-form {
  margin-top: 45px;
}

.py-figure {
  padding-bottom: 35px;
}

.pl-upd-box label {
  width: 100%;
  height: 150px;
  border: 1px dashed #929292;
  background: #f8f8f8;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pl-upd-box span {
  width: 160px;
  font-size: 20px;
}

.pl-upd-box label i {
  color: #929292;
}

.pl-upd-box label h3 {
  font-size: 15px;
  font-weight: 500;
  color: #929292;
  margin: 0;
}

.pl-upd-box input {
  display: none;
}

.p-title {
  background: #f9f9f9;
  border-radius: 5px;
  padding: 15px;
  margin-bottom: 35px;
}

.p-title h3 {
  font-size: 16px;
  font-weight: 500;
  margin: 0;
}

.pl-edit a {
  text-decoration: none;
  font-weight: 500;
}

.pl-edit a span {
  padding-right: 10px;
}

.ap-loc-box {
  background: #f8f8f8;
  border-radius: 5px;
  padding: 15px;
}

.processor-list {
  padding: 15px 0;
}

.processor-list .con-ner {
  padding: 10px 35px 0 45px;
  margin-bottom: 5px;
}

.processor-list .checkmark {
  top: 24px;
  left: 10px;
}

.pv-pro-background {
  background: #f2fff3 !important;
}

.pl-bt button {
  font-size: 16px;
  height: 50px;
  width: 150px;
}

.up-card .up-card-body .sl-f_name {
  font-style: italic;
  font-size: 16px;
  font-weight: 500;
  margin: 0;
  color: #000;
}

.up-card .up-card-body .sl-price {
  font-size: 20px;
  font-weight: 600;
  color: #358539;
  margin: 0;
}

.up-card .up-card-body .sl-desc {
  font-size: 15px;
  font-weight: 300;
  line-height: 22px;
  margin: 0;
  color: #000;
}

.up-card .up-card-body .sl-card-top {
  padding-bottom: 15px;
  padding-top: 10px;
}

.up-card .sp-tags {
  position: absolute;
  bottom: 10px;
  left: 10px;
  max-width: 170px;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.up-card .prod-tag {
  color: #fff;
  background: #000000a6;
  padding: 5px 24px;
  border-radius: 20px;
  font-size: 16px;
  font-weight: 500;
  font-style: italic;
}

.up-card .cut-icon {
  background: #000000a6;
  padding: 5px 24px;
  border-radius: 20px;
  max-width: 2em;
  max-height: 2em;
}

.up-card .cut-text {
  width: 180px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.up-card .cut-text span {
  width: max-content;
  background: #000000a6;
  border-radius: 25px;
  font-size: 13px;
  font-weight: 500;
  font-style: italic;
  padding: 6px 15px;
  color: #fff;
  position: absolute;
  left: -200px;
  transition: all 0.5s ease-in-out;
}

.G-zr .carosal-up-product.search-l .up-card:hover .cut-text span,
.seller-product-vw .up-card:hover .cut-text span {
  left: 5px !important;
  bottom: 0;
  margin: auto;
  height: 31px;
}

.search-fil p {
  margin: 0;
  font-size: 16px;
  font-weight: 500;
  padding-right: 20px;
  color: #000;
}

.search-fil .fil-ss {
  background: #f6f6f6;
  color: #929292;
  border: 1px solid #cecece;
  border-radius: 16px;
  padding: 5px 15px;
  font-size: 15px;
  margin-right: 15px;
  cursor: pointer;
}

.search-fil .fil-ss.active {
  background: #362f2c;
  color: #fff;
  border: 1px solid #362f2c;
  border-radius: 16px;
  padding: 5px 15px;
  font-size: 15px;
}

/*SORT*/
.search-fil .search-sort {
  width: 460px;
  font-size: 14px;
}

.search-fil .search-sort p {
  margin: 0;
  font-size: 16px;
  font-weight: 500;
  padding-right: 18px;
  color: #000;
}

.search-fil .search-sort span {
  font-weight: 600;
}

.search-fil .search-sort a {
  color: #000;
  border-bottom: 3px solid #fff;
  text-decoration: none;
  margin-right: 20px;
}

.search-fil .search-sort a:last-child {
  margin-right: 0px;
}

.search-fil .search-sort a.active {
  color: #358539;
  border-bottom: 3px solid #358539;
}

.search-fil .search-sort a:hover {
  color: #358539;
  cursor: pointer;
  border-bottom: 3px solid #358539;
}

.G-zr .quick-search .search-form .input-group {
  border: 1px solid #e5e5e5;
}

/*SORT END*/
.b_pro-proc {
  /* background: #f3f3f3; */
  margin-bottom: 35px;
}

.b_pro-proc h3 {
  font-size: 18px;
  font-weight: 600;
  margin: 0;
}

.b_pro-proc a {
  padding: 8px 20px !important;
  /* height: 35px; */
  background: #362f2c;
  color: #fff;
}

/*AS END*/

/* sh */
.G-zr .navbar-expand-lg .navbar-nav .nav-item .dropdown-menu .dropdown-item {
  font-size: 12px;
  font-family: "Montserrat", sans-serif;
}

.G-zr
  .navbar-expand-lg
  .navbar-nav
  .nav-item
  .dropdown-menu
  .dropdown-item:hover {
  color: #ffffff;
  background-color: #358539;
  transition: all 0.3s ease-in;
}

.G-zr
  .navbar-expand-lg
  .navbar-nav
  .nav-item
  .dropdown-menu
  .dropdown-item
  svg {
  margin-right: 10px;
  width: 20px;
  height: 20px;
}

.G-zr .navbar-expand-lg .navbar-nav .nav-item .dropdown-menu {
  right: 0;
  left: unset;
  padding: 0;
  top: 64px;
  line-height: 35px;
  border: none;
  box-shadow: 0px 0px 9px 0px #00000012;
}

.G-zr .Dash-right .Farms-card figure {
  height: 145px;
}

.G-zr .Dash-right .Farms-card img {
  height: 145px;
  object-fit: cover;
  object-position: center;
}

.G-zr .Dash-right .Farms-card .farms-body {
  margin-top: 67px;
}

.G-zr .Dash-right .Farms-card {
  height: 330px;
  min-height: 330px;
  width: 248px;
  max-width: 248px;
  box-shadow: 0px 0px 15px 0px #0000001a;
  margin: 5px;
  border-radius: 5px;
  overflow: hidden;
  margin-right: 50px;
  margin-bottom: 40px;
}

.G-zr .Dash-right .Farms-card .farms-user {
  top: 93px;
  left: 0;
  right: 0;
  text-align: center;
}

.G-zr .Dash-right .Farms-card .farms-user img {
  width: 110px;
  height: 110px;
  margin: auto !important;
  object-fit: cover;
  border: 5px solid #ffe;
  border-radius: 50%;
}

.G-zr .approved-user {
  margin: 5px auto;
}

.G-zr .approved-user img {
  width: 35px;
  height: 35;
  object-fit: cover;
  object-position: center;
  border-radius: 100px;
}

.G-zr .main-dash.seller-D .dash-D {
  padding-right: 0;
}

.G-zr.top-h {
  margin-top: 6em;
}

.G-zr .main-dash.seller-D .da-sh,
.G-zr .main-dash.buyer-D .da-sh {
  width: 230px;
  height: auto;
  padding: 45px 0;
}

.G-zr .btn-gradien {
  background: linear-gradient(45deg, #41933b, #1ab97a);
  border-radius: 100px;
  padding: 8px 24px;
  color: #fff;
  font-size: 12px;
  font-weight: 500;
}

.G-zr .dot-S::before {
  content: "";
  background: #358539;
  left: -21px;
  top: 5px;
  width: 9px;
  height: 9px;
  border-radius: 100px;
  position: absolute;
  box-shadow: 0px 0px 1px 3px #35853959;
}

/* 1/11 */
.flaticon-close-button:before {
  font-size: 16px;
}

/* 2/11 */
.G-zr .h-flower {
  height: 354px;
  border-radius: 4px;
  object-fit: cover;
  object-position: center;
}

.G-zr .comunity-members img {
  object-fit: cover;
  object-position: center;
  height: 175px;
  min-height: 175px;
  width: 100%;
}

.G-zr .comunity-members figure {
  border-radius: 5px;
  border: 1px solid #dbdbdb;
}

.G-zr .member .comunity-members {
  margin-right: 0;
}

.G-zr .comunity-members {
  width: 168px;
  min-width: 168px;
  overflow: hidden;
  margin-bottom: 25px;
  margin-right: 37px;
}

.G-zr .overlay-post {
  position: absolute;
  bottom: 5px;
  right: 5px;
  border-radius: 100px;
  background: #fff;
  color: green;
  font-weight: 500;
  font-size: 10px;
  padding: 6px 14px;
}

.G-zr .overlay-post.gallery.no-file {
  background: #e1e1e1;
  color: #acacac;
}

.G-zr .overlay-post.gallery {
  position: absolute;
  bottom: -2px;
  right: -2px;
  border-radius: 0;
  background: #0000001f;
  color: #cbcbcb;
  font-size: 12px;
  font-weight: 600;
  padding: 8px 14px;
}

/* 04/11 */
/* message */
.G-zr .msg-head img {
  min-width: 44px;
  width: 44px;
  object-fit: cover;
  object-position: center;
}

.G-zr .msg-head p {
  color: #e4e4e4;
}

.G-zr .msg-head .w-8p {
  width: 60px;
}

.G-zr .msg-head .w-92p {
  width: 92%;
}

.G-zr .msg-head .dropdown {
  cursor: pointer;
}

.G-zr .msg-head {
  background: #362f2c;
  padding: 10px 20px;
}

.G-zr .msg-body {
  min-height: 250px;
  height: auto;
  background: #fff;
  border-top: 10px solid #f1f1f1;
  border-bottom: 10px solid #f1f1f1;
  padding: 30px 20px 20px 20px;
  overflow: hidden;
  background-image: url("/images/msg-bg.png");
  background-size: cover;
  background-repeat: no-repeat;
}

.G-zr .msg-body .reciver-msg h6,
.G-zr .msg-body .sender-msg h6 {
  background: #e8e8e8;
  border-radius: 40px;
  width: auto;
  min-width: auto;
  max-width: 50%;
  border-top-left-radius: 0;
  padding: 10px 20px 10px 30px;
  margin-right: 20px !important;
  word-break: break-word;
  line-height: 23px;
}

.G-zr .msg-body .reciver-msg p,
.G-zr .msg-body .sender-msg p {
  color: #bcbcbc;
  white-space: nowrap;
}

.G-zr .msg-body .reciver-msg,
.G-zr .msg-body .sender-msg {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  margin-bottom: 15px;
}

.G-zr .msg-body .sender-msg {
  margin-right: 0;
  flex-flow: row-reverse;
  width: 100%;
}

.G-zr .msg-body .sender-msg h6 {
  margin-right: 0 !important;
  margin-left: 20px !important;
  background: #57a15a;
  color: #fff;
  border-bottom-right-radius: 0;
  border-top-left-radius: 40px;
}

.G-zr .msg-body .date-divider:after,
.G-zr .msg-body .date-divider:before {
  content: "";
  position: absolute;
  width: 150px;
  height: 1px;
  top: 8px;
  background: #e1dede;
}

.G-zr .msg-body .date-divider:after {
  right: 23%;
}

.G-zr .msg-body .date-divider:before {
  left: 23%;
}

.G-zr .msg-fot input:focus {
  box-shadow: none;
}

.G-zr .msg-fot .input-group object {
  padding: 10px 25px;
}

.G-zr .msg-fot input {
  height: 50px;
  border: none;
  position: relative;
  font-style: italic;
  font-size: 14px;
}

.G-zr .msg-fot .input-group:after,
.G-zr .msg-fot .input-group:before {
  content: "";
  position: absolute;
  background: #b0d4b2;
  width: 1px;
  height: 30px;
  top: 9px;
  right: 70px;
  z-index: 9;
}

.G-zr .msg-fot .input-group:before {
  /* left: 70px; */
}

.G-zr .msg-fot .input-group {
  background: #fff;
  border: 1px solid #358539;
  height: 50px;
  overflow: hidden;
  border-radius: 2px;
}

.G-zr .msg-list figure:after {
  content: "";
  position: absolute;
  width: 10px;
  height: 10px;
  background: #bcbcbc;
  top: 0;
  right: 20px;
  border-radius: 100px;
  box-shadow: 0px 0px 0px 1px #fff;
}

.G-zr .msg-list figure {
  width: 20%;
  position: relative;
}

.G-zr .msg-list img {
  width: 44px;
  min-width: 44px;
  height: 44px;
  border-radius: 100px;
}

.G-zr .msg-list .list-bdy .unread {
  background: #19be20;
  border-radius: 100px;
  padding: 3px 3px;
  text-align: center;
  color: #fff;
  width: 35px;
  margin-left: auto;
}

.G-zr .msg-list .list-bdy span:nth-child(1) p {
  width: 100px;
  overflow: hidden;
}

.G-zr .msg-list .list-bdy {
  width: 80%;
}

.G-zr .msg-list .B-L.active figure:after {
  background: #57a15a;
}

.G-zr .msg-list .B-L {
  border: 2px solid #57a15a;
}

.G-zr .msg-list .P-L {
  border: 2px solid #1306d1;
}

.G-zr .msg-list .B-L.S-L {
  border: 2px solid #e66c25;
}

.G-zr .msg-list .B-L.active,
.G-zr .msg-list .B-L:hover {
  background: #ccc;
}

.G-zr .msg-list .B-L {
  cursor: pointer;
  background: white;
  padding: 10px 5px;
  border-radius: 5px;
  margin-bottom: 10px;
  height: 70px;
  min-height: 70px;
}

.G-zr .msg-list {
  background: #eeeeee;
  padding: 10px;
  /*
    min-height: 423px;
    overflow: auto;
    height: 423px;
*/
}

.G-zr .msg-list .nav-tabs .nav-link {
  max-width: 170px;
  width: 100%;
  background: transparent;
  border: none;
  border-radius: 5px;
  border-bottom: 5px solid #d9d9d9;
}

.G-zr .msg-list .nav-tabs .nav-link.active {
  font-size: 16px;
  border: none;
  background: transparent;
  color: #358539;
  border-bottom: 5px solid #3b943f;
  border-radius: 5px;
}

.G-zr .msg-list .nav-tabs {
  margin-bottom: 20px;
  border: none;
}

.G-zr .msg-list .sel-msg-tabs .sm-tab-msg {
  overflow: auto;
  height: 400px;
}

.G-zr .msg-list .sel-msg-tabs .sm-tab-msg.stm {
  overflow: auto;
  height: 350px;
}

.sel-msg-tabs .sm-tab-msg {
  padding-right: 10px;
  width: 100%;
}

.sel-msg-tabs .sm-tab-msg::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: white;
  width: 5px;
}

.sel-msg-tabs .sm-tab-msg::-webkit-scrollbar {
  width: 5px;
  background-color: transparent;
  background-color: white;
}

.sel-msg-tabs .sm-tab-msg::-webkit-scrollbar-thumb {
  background-color: #358539;
  border-radius: 50px;
}

.con-ner.sel-msg-bc {
  padding-left: 15px;
  font-size: 15px;
  letter-spacing: 0.5px;
}

.con-ner.sel-msg-bc span {
  right: 15px;
  left: inherit;
}

.con-ner.sl-bc-msg {
  padding-left: 0;
}

.con-ner.sl-bc-msg .checkmark {
  right: 15px;
  left: inherit;
  top: 20px;
}

.sl-bc-msg {
  background: #fff;
}

.con-ner.sl-bc-msg .B-L {
  background: transparent;
}

.sl-bc-msg-bg {
  background: #f2fff3 !important;
}

.msg-list .bb-btn button {
  background: #362f2c;
  color: #fff;
  border-radius: 4px;
  width: 100%;
  height: 50px;
  font-size: 16px;
}

/* message end */
.G-zr .anoun ul li {
  position: relative;
}

.G-zr .anoun ul li:after {
  position: absolute;
  content: "";
  background: #358539;
  width: 6px;
  height: 6px;
  left: -18px;
  top: 6px;
  border-radius: 100px;
  box-shadow: 0px 0px 0px 4px #35853969;
}

.G-zr .anoun ul {
  list-style-type: none;
}

.G-zr .anoun {
  background: #fff;
  box-shadow: 0px 2px 6px 0px #00000014;
}

.G-zr .anoun-hd {
  border-bottom: 1px solid #e5e5e5;
  height: 50px;
}

.G-zr .start-dis {
  border: 1px dashed #bbbbbb;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 26px;
  cursor: pointer;
}

.G-zr .Dash-right .msg-body {
  background: #eee;
  border-top: 10px solid #fff;
  background-image: url(/images/msg-bg.png);
  border-bottom: 10px solid #fff;
}

.G-zr .Dash-right .msg-head .w-92p {
  width: 88%;
}

.G-zr .Dash-right .msg-body .reciver-msg h6,
.G-zr .Dash-right .msg-body .sender-msg h6 {
  background: #ffffff;
}

.G-zr .Dash-right .msg-body .sender-msg h6 {
  background: #57a15a;
}

.G-zr .Dash-right .msg-body .date-divider:after {
  right: 15%;
}

.G-zr .Dash-right .msg-body .date-divider:before {
  left: 15%;
}

.G-zr .Dash-right .msg-list-head .comunity-sch .input-group input {
  border: none;
  height: 62px;
}

.G-zr .Dash-right .msg-list-head .comunity-sch .input-group input::placeholder {
  font-size: 13px;
}

.G-zr
  .Dash-right
  .msg-list-head
  .comunity-sch
  .input-group-prepend
  span.input-group-text {
  background: none !important;
}

.G-zr .Dash-right .msg-list-head .comunity-sch {
  box-shadow: 0px 2px 6px 0px #0000002e;
  height: 62px;
  margin-bottom: 10px;
}

.G-zr #style-7::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
  border-radius: 10px;
}

.G-zr #style-7::-webkit-scrollbar {
  width: 6px;
  background-color: #f5f5f5;
}

.G-zr #style-7::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-image: -webkit-gradient(
    linear,
    left bottom,
    left top,
    color-stop(0.44, #57a15a),
    color-stop(0.72, #57a15a),
    color-stop(0.86, #57b15a)
  );
}

/* 07/11/19 */
.G-zr .bdy-flx {
  flex: 1 1 50%;
}

.G-zr .filter-right .progress-form select {
  padding-left: 10px;
  padding-right: 35px;
  font-weight: 600;
}

.G-zr .filter-right .progress-form .select-wrapper:after {
  color: #358539;
}

.G-zr .comunity-card span {
  padding: 15px 25px;
  width: 375px;
  height: 345px;
  text-align: center;
  box-shadow: 0px 2px 12px 0px #00000026;
  border-radius: 10px;
}

.G-zr .comunity-card span em {
  text-overflow: ellipsis;
  overflow: hidden;
  height: 65px;
  white-space: pre-wrap;
  display: block;
}

.G-zr .comunity-card span .span-fot {
  background: #e8f8e9;
  padding: 11px 10px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.G-zr .sch-cmunity .comunity-card span {
  width: 330px;
}

.G-zr .Dash-right .quick-search .search-form .input-group {
  width: 79%;
}

.G-zr .Dash-right .quick-search {
  background: #ffffff;
  box-shadow: 0px 3px 10px 0px #00000017;
  border-radius: 6px;
}

.G-zr .comunity-card .add-user {
  width: 44px;
  height: 44px;
  box-shadow: 0px 4px 8px 2px #0000001f;
  border-radius: 100px;
  position: absolute;
  right: 15px;
  top: 15px;
}

.G-zr .place-odr span {
  flex: 1 1 50%;
}

.G-zr .order-bg {
  display: flex;
  justify-content: center;
  flex-flow: column;
  padding-left: 50px;
  padding-right: 9%;
  color: #fff;
}

.G-zr .order-bg h4 {
  font-size: 32px;
}

.G-zr .order-bg p {
  padding-top: 10px;
  padding-bottom: 15px;
}

.G-zr .order-bg .btn {
  width: 190px;
}

.G-zr .order-right-bg {
  background-image: url("/images/brown-bg.png") !important;
}

.G-zr .order-bg,
.G-zr .order-right-bg {
  background-image: url("/images/green-bg.png");
  background-size: cover;
  background-position: center;
  height: 560px;
}

.G-zr .place-odr {
  margin-top: 78px;
}

.G-zr .order-right-bg figure img {
  height: 368px;
  object-fit: cover;
}

.G-zr .order-right-bg figure:after {
  content: "";
  background: #0000001a;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 0;
  left: 0;
}

.G-zr .new-discustion .discustion-left textarea {
  color: #929292;
  border: 1px solid #cccccc;
  font-size: 15px;
}

.G-zr .new-discustion .discustion-left {
  width: 64%;
  background: #fff;
  padding: 2em 4em 2em 4em;
}

.G-zr .new-discustion .discustion-right {
  width: 34%;
  background: #fff;
  padding: 4em 4em 2em 2em;
}

.G-zr .new-discustion {
  display: flex;
  justify-content: space-between;
}

.G-zr .comunity-tabs .dropdown-menu.show .dropdown-item:hover {
  color: #362f2c;
  background: #e3e3e3;
}

.G-zr .comunity-tabs .dropdown-menu.show .dropdown-item {
  padding: 0.5rem 1rem;
}

.G-zr .comunity-tabs .dropdown-menu.show {
  left: auto !important;
  transform: none !important;
  right: 0;
  border: 1px solid #358539;
  border-radius: 0px;
  padding: 0;
  top: 43 !important;
}

.G-zr .user-bdy h6.active:nth-child(1) {
  border: 1px solid #dafbdb;
  padding: 10px 22px;
  border-radius: 100px;
  background: #f5fff5;
  color: #358539;
  font-weight: 500;
}

.G-zr .user-bdy p {
  height: 40px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.G-zr .user-comments img {
  width: 40px;
  height: 40px;
  border-radius: 100px;
  margin-right: 15px;
}

.G-zr .user-comments .D-input,
.G-zr .user-comments .btn-green {
  border-radius: 100px;
}

.G-zr .user-comments .D-input::placeholder {
  color: #000;
}

.G-zr .user-comments {
  border-top: 1px solid #e5e5e5;
  margin-top: 20px;
  display: flex;
  margin-left: 30px;
}

.G-zr .cmt-list img {
  width: 31px;
  height: 31px;
  object-fit: cover;
  border-radius: 100px;
}

.G-zr .list-bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.G-zr .cmt-list {
  padding-top: 25px;
  margin-left: 30px;
  padding-bottom: 25px;
  border-bottom: 7px solid #f1f1f1;
}

.G-zr .list-bottom h6 {
  color: #929292;
}

.G-zr .A-B::after,
.G-zr .A-B::before {
  content: "";
  position: absolute;
  background: #d2d2d2;
  width: 42%;
  height: 1px;
  right: 0;
  top: 10px;
}

.G-zr .A-B::before {
  left: 0;
  right: unset;
}

.G-zr .photos-fils img {
  width: 76px;
  height: 70px;
  border-radius: 6px;
}

.G-zr .mesage .A-B::before,
.G-zr .mesage .A-B::after {
  width: 33%;
  top: 5px;
}

.G-zr .inner-tabs ul li a.active:after,
.G-zr .inner-tabs ul li a:hover:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 3px;
  border-radius: 100px;
  background: #358539;
  color: #358539;
  bottom: -6px;
  left: 0;
  right: 0;
}

.G-zr .inner-tabs ul li a.active,
.G-zr .inner-tabs ul li a:hover {
  color: #358539;
}

.G-zr .inner-tabs ul li a {
  text-decoration: none;
  position: relative;
  font-size: 16px;
  font-weight: 500;
  color: black;
  margin-right: 30px;
}

.G-zr #Path_3443,
#Path_3446,
#Path_3445,
#Path_3448,
#Path_3447,
#Path_3450,
#Path_3441 {
  opacity: 1;
  animation: fade 2s infinite;
}

#Group_9238,
#Group_9239,
#cow {
}

@keyframes fade {
  0%,
  100% {
    opacity: 0;
    fill: rgba(59, 147, 63, 0.66);
  }

  50% {
    opacity: 1;
    fill: rgba(139, 235, 143, 0.66);
  }
}

/*INVOICE*/
.Grzr-mod-inv {
  max-width: 950px;
  margin: 0 auto;
}

.invoice-mod {
  padding: 60px 50px;
}

.im-cust-info,
.im-sell-info {
  max-width: 300px;
}

.im-sell-info {
  text-align: right;
  float: right;
}

.im-cust-info h2,
.im-sell-info h2 {
  font-family: "Raleway";
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 1px;
}

.im-cust-info h3,
.im-sell-info h3 {
  font-family: "Raleway";
  font-size: 18px;
  font-weight: 500;
  font-style: italic;
  letter-spacing: 1px;
}

.im-cust-info p,
.im-sell-info p {
  font-family: "Montserrat";
  font-size: 15px;
  font-style: italic;
  margin-bottom: 10px;
}

.im-invoice-status {
  margin-top: 25px;
  margin-bottom: 35px;
}

.im-invoice-status h3 {
  background: #f3fff4;
  color: #358539;
  font-size: 16px;
  font-weight: 500;
  text-transform: uppercase;
  padding: 20px 0;
  margin: 0;
  text-align: center;
  letter-spacing: 2px;
}

.invoice-mod .im-trans h3 {
  font-size: 18px;
  font-weight: 500;
}

.invoice-mod .im-lc {
  float: right;
  text-align: right;
}

.invoice-mod .im-trans p {
  font-size: 15px;
  font-weight: 400;
  font-family: "Montserrat";
  margin-bottom: 10px;
}

.invoice-mod .im-gen h3 {
  font-size: 18px;
  font-weight: 500;
}

.invoice-mod .im-gen p {
  font-size: 16px;
  font-weight: 500;
  font-style: italic;
  margin-bottom: 0px;
}

.im-generate,
.im-transaction {
  margin-top: 25px;
}

.im-date {
  color: #929292 !important;
  font-weight: 400 !important;
}

.im-btn {
  margin-top: 45px;
}

/*INVOICE END*/

/* calaender after */
.G-zr .progress-form .select-wrapper.orde-r:after {
  color: #000;
  font-weight: 600;
}

.G-zr .progress-form .select-wrapper.calender:after {
  width: 25px;
  height: 25px;
  content: "";
  font-weight: 400;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  font-size: 14px;
  margin-left: 15px;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  position: absolute;
  right: 10px;
  background-image: url(/images/calendar.svg);
  top: 12px;
  color: #929292c2;
  pointer-events: none;
}

/* 25/11/19 */

.G-zr .F-select {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 720px;
  margin: auto;
  padding-top: 10px;
}

.G-zr .F-select span {
  width: 168px;
  height: 65px;
  cursor: pointer;
  border-radius: 10px;
  overflow: hidden;
}

.G-zr .F-select figure::before {
  background: #362f2c;
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  left: 0px;
  opacity: 0.4;
}

.G-zr .F-select figure h5 {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  z-index: 99;
  height: 100%;
  color: #fff;
  text-transform: uppercase;
  font-size: 20px;
  font-weight: 500;
}

.G-zr .F-select figure .cat-overlay {
  background: #362f2c;
  position: absolute;
  width: 100%;
  height: 100%;
  left: -180px;
  opacity: 0.5;
  transition: all 0.5s ease-in-out;
  margin: auto;
}

.G-zr .F-select figure:hover .cat-overlay,
.G-zr .F-select figure.active .cat-overlay {
  opacity: 1;
  left: 0px;
  top: 0;
  bottom: 0;
  margin: auto;
}

/* 18/12/19 */
.G-zr .main-dash .da-sh .ac-scrollbar h4::after {
  display: none;
}

.G-zr .main-dash .da-sh span.ent {
  background: #ffece5;
  border: 1px solid #fedbce;
  font-size: 11px;
  font-weight: 500;
  color: #e6521a;
  padding: 8px 15px;
  border-radius: 100px;
}

.G-zr .Dash-right .setup-calender h4,
.G-zr .Dash-right .agenta-calender h4 {
  font-size: 18px;
  font-weight: 400;
}

.G-zr .Dash-right .agenta-calender .table tbody td:nth-child(1) {
  width: 85px;
}

.G-zr .Dash-right .agenta-calender .table tbody td svg.feather-check {
  opacity: 0.2;
}

.G-zr .Dash-right .agenta-calender .table tbody td.active svg.feather-check,
.G-zr .Dash-right .agenta-calender .table tbody td.active {
  opacity: 1;
  stroke: #358539;
  background: #eeffee;
}

.G-zr .Dash-right .agenta-calender .table tbody td {
  padding: 0;
  background: #f6f6f6;
  border-left: 3px solid #fff;
  border-right: 3px solid #fff;
  border-top: 2px solid #fff;
  border-bottom: 3px solid #fff;
}

.G-zr .Dash-right .agenta-calender .table tbody td object {
  width: 100%;
  height: 85px;
  padding: 8px;
  border: 1px solid #f3f3f3;
}

.G-zr .Dash-right .agenta-calender .table {
  border-spacing: 0px 0px;
}

.G-zr .Dash-right .agenta-calender .table thead th:nth-child(1) {
  background: white;
}

.G-zr .Dash-right .agenta-calender .table thead th {
  width: 106px;
  background: #362f2c;
  padding: 6px 4px;
  border-left: 3px solid #fff;
  border-right: 3px solid #fff;
  color: #fff;
  font-weight: 400;
  font-size: 15px;
  border-bottom: none;
}

.G-zr .feather-info:hover + .noti {
  position: absolute;
  top: 35px;
  bottom: 0;
  right: 0px;
  width: 200px;
  height: 35px;
  display: block !important;
  background: #000;
  padding: 9px 15px;
  color: #fff;
  border-radius: 6px;
  font-size: 14px;
  font-weight: 500;
  z-index: 99;
}

.G-zr .F-select span {
  width: 168px;
  height: 65px;
  cursor: pointer;
  border-radius: 10px;
  overflow: hidden;
}

.G-zr .feather-info {
  fill: #343a40;
  position: relative;
  cursor: pointer;
  stroke: white;
}

.G-zr .F-select {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 720px;
  margin: auto;
  flex-wrap: wrap;
  padding-top: 10px;
}

/* 04/2020 */
.G-zr .Af-log.normal {
  background: #ffffff;
  z-index: 99999;
  transition: all 0.5s cubic-bezier(0.4, 0, 1, 1);
}

.G-zr .Af-log.normal .navbar-expand-lg .navbar-nav .nav-link {
  color: #131313;
}

.G-zr .Af-log.normal .navbar-expand-lg .navbar-nav .nav-link.btn {
  border: 1px solid #358539;
}

.G-zr .Af-log.normal .navbar {
  height: 80px;
}

.G-zr .Af-log.normal,
.G-zr .Af-log.normal.inner-Header {
  box-shadow: 0px 1px 0px 1px #358539;
}

.G-zr .search-form .scd-fx .btn {
  padding: 5px;
  margin: 2px 5px;
  width: 180px;
  height: 46px;
  line-height: 1;
}

.G-zr .imgWid {
  height: 6em !important;
  width: 6em !important;
  margin: 0.3em !important;
}

.G-zr .pro-view-slider .owl-carousel div.fst {
  width: 20%;
  margin: 0;
}

.G-zr .pro-view-slider .owl-carousel div.mainImgwid {
  width: 80%;
}

.modal-pop .modal-dialog.video {
  transform: translate(-0px, 35px);
}

.video-body video {
  height: 500px;
}

.pop-vdo {
  display: none;
}

.G-zr .msg-list .list-bdy span:nth-child(1) p {
  width: 100px;
  overflow: hidden;
}

/* 09/01/20 */
.toggle,
[id^="drop"] {
  display: none;
}

/* 09/01/20 */
.toggle,
[id^="drop"] {
  display: none;
}

.modal-rechange {
  display: flex;
  justify-content: center;
  padding: 35px 70px;
}

.modal-hdrecng {
  align-items: center;
  border-bottom: 2px solid #358539;
  margin-bottom: 20px;
  background: #358539;
  color: #fff;
  border-radius: 0;
}

.G-zr .msg-list .list-bdy span:nth-child(1) h5 {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100px;
}

/* 07/03/2020 */
.processor-hd h4 {
  width: 100%;
  word-break: break-word;
}

#select_proc .processor-list {
  padding: 15px 0;
  height: 500px;
  overflow: auto;
}

.fp-img-box object {
  max-width: 100%;
}

.cutSheetBuilderPopup .MuiAppBar-positionFixed {
  top: 80px;
  left: auto;
  right: 0;
  position: fixed;
}

.MuiDialog-root.cutSheetBuilderPopup.fullScreenPopup {
  position: fixed;
  z-index: 1300;
  inset: 0px;
  height: calc(100vh - 144px) !important;
  top: unset !important;
  bottom: 0 !important;
  /* padding-top: 82px !important; */
}

.react-form-builder .react-form-builder-toolbar {
  width: 260px !important;
  margin-top: unset !important;
}

.G-zr header.Af-log {
  z-index: 999999999;
}

.G-zr .modal {
  background-color: #00000052;
  z-index: 9999999999;
}

@media (max-width: 1199px) {
  .p-5 {
    padding: 15px !important;
  }

  .G-zr .snd-banner .enquty {
    padding: 15px;
    width: 100%;
  }
}

@media (max-width: 1024px) {
  .G-zr .Dash-right .msg-head .w-92p {
    width: 85%;
  }

  .G-zr .msg-list figure {
    width: 25%;
  }

  .G-zr .msg-list .list-bdy {
    width: 70%;
    text-align: left;
    align-items: flex-start !important;
  }

  .G-zr .pro-view-slider .content-carousel {
    width: auto;
  }

  .G-zr .pro-view-slider .owl-carousel div:nth-child(1) {
    width: 15%;
  }

  .search-form .d-flex {
    flex-flow: row;
    align-items: center;
  }

  .G-zr .search-form .input-group {
    margin-right: 0 !important;
  }

  .search-fil .search-sort {
    width: 440px;
    font-size: 14px;
  }

  .search-fil p {
    font-size: 15px;
  }

  .search-fil .fil-ss {
    padding: 2px 12px;
    font-size: 14px;
  }

  .G-zr .imgWid {
    height: 4em !important;
    width: 4em !important;
  }
}

@media (max-width: 991px) {
  .G-zr .Af-log .icon-bar {
    background-color: #131313 !important;
  }

  .G-zr .Af-log.normal .icon-bar {
    background-color: #131313 !important;
  }

  .G-zr .banner-sec.video-bg {
    margin-top: 9em;
  }

  .msg-res {
    flex-direction: column-reverse;
  }

  .G-zr .msg-list figure {
    width: 44px;
    position: relative;
  }

  .pop-vdo video {
    height: 300px;
  }

  .G-zr .Af-log.normal .navbar {
    height: 60px;
    padding: 1px;
  }

  .G-zr .Af-log {
    height: 62px;
  }

  .G-zr .msg-list figure:after {
    right: 0;
  }

  /* .G-zr .G-main {
        height: 600px;
        margin-top: 5em;
    } */
  .pop-vdo {
    display: block;
  }

  .d-nn {
    display: none;
  }

  .G-zr .G-right {
    padding: 0;
  }

  .G-zr .G-main {
    flex-flow: column;
  }

  .G-zr .search-form .input-group {
    margin-right: 10px !important;
  }

  .G-zr .btn-green {
    padding: 10px 20px;
  }

  .filter-right.search-fil {
    display: block;
    margin: auto;
  }

  .filter-right.search-fil .col {
    display: flex;
    align-items: center;
    justify-content: left !important;
    padding: 15px 20px;
    border: 1px solid #ececec;
    margin: 5px auto;
    width: 100%;
    overflow: auto;
    white-space: nowrap;
  }

  .search-form .d-flex {
    flex-flow: row;
    align-items: center;
  }

  .G-zr .L-curve .curve-D {
    bottom: 0;
    left: 0;
    border-radius: 0;
    width: 100%;
    top: 16em;
    padding: 0 35px;
  }

  .G-zr .L-curve img {
    display: none;
  }

  .G-zr .banner-sec.video-bg {
    background-image: url("/images/resbg.png");
    height: auto;
    min-height: auto;
  }

  .G-zr .G-left {
    width: 100%;
    padding-left: 30px;
    padding-right: 30px;
  }

  .G-zr .Af-log #navbarSupportedContent {
    position: relative;
  }

  .G-zr .Af-log #navbarSupportedContent ul.onres li {
    width: 100%;
    justify-content: end;
    flex-direction: column-reverse !important;
    align-items: start !important;
  }

  .G-zr
    .Af-log
    #navbarSupportedContent
    ul.onres
    li.mbnone.nav-item.d-flex.flex-row-reverse.align-items-center.user-nav.mobile-drodn-none {
    display: none !important;
  }

  .G-zr .Af-log #navbarSupportedContent ul.onres.add {
    right: 0% !important;
  }

  .G-zr .Af-log #navbarSupportedContent ul.onres {
    background: #262626;
    align-items: flex-start;
    width: 40%;
    margin-left: 0 !important;
    margin-top: 12px;
    position: fixed;
    right: -40%;
    height: calc(100vh - 62px);
    top: 62px;
    overflow-y: auto;
    padding: 5px 25px;
    transition: all 0.7s ease-in-out;
    margin: 0;
  }

  .G-zr .Af-log .navbar-expand-lg .navbar-nav .nav-link {
    color: #fff !important;
  }

  .G-zr .Af-log.normal .navbar-expand-lg .navbar-nav .nav-link,
  .G-zr .Af-log .navbar-expand-lg .navbar-nav .nav-link {
    padding: 10px 0;
  }

  .G-zr .Af-log.normal .navbar-expand-lg .navbar-nav .nav-link.btn,
  .G-zr .Af-log .navbar-expand-lg .navbar-nav .nav-link.btn {
    margin-top: 1em;
    border: 1px solid #358539;
  }

  .G-zr .msg-list .list-bdy {
    width: 85%;
  }
}

@media (max-width: 850px) {
  .G-zr .side-Dash ul li {
    padding: 14px 15px;
  }

  .G-zr .side-Dash ul li.active:after {
    left: 6px;
  }
}

@media (max-width: 800px) {
  .G-zr .feed-card {
    flex-flow: column;
    padding: 23px !important;
  }

  .G-zr .feed-card .feed-body,
  .G-zr .feed-card .feed-img,
  .G-zr .prd-feed-card.feed-card .feed-img {
    width: 100%;
  }
}

@media (max-width: 767px) {
  .search-form .sech-pag {
    width: 100%;
    max-width: 100%;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
  }
  .G-zr .msg-list {
    min-height: 168px;
    height: 168px;
    margin-bottom: 14px;
  }

  .G-zr .hiw-sec .list-works {
    flex-direction: column;
    padding: 20px 0 !important;
  }

  .tab-content {
    padding: 10px 15px;
  }

  .G-zr .main-dash.seller-D .ac-scrollbar .dot-S {
    max-width: 75%;
  }

  .G-zr .main-dash.seller-D {
    padding: 0 !important;
  }

  .pro-file {
    flex-direction: column;
    padding: 25px;
  }

  .G-zr .G-left h1 {
    font-size: 32px;
  }

  .G-zr .hiw-sec .nav-pills .nav-link {
    font-size: 15px;
    width: auto;
    padding: 5px;
  }

  .G-zr .G-left {
    width: 90%;
  }

  .G-zr .hiw-sec .list-works .card-list {
    padding: 0px 10px 10px 100px;
    position: relative;
    text-align: left !important;
    min-height: 85px;
  }

  .list-works .card-list figure {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    border: 1px solid #ccc;
    margin: auto !important;
    padding: 5px !important;
    height: 75px;
    border-radius: 50%;
    width: 75px;
    text-align: center;
  }

  .G-zr .hiw-sec .list-works .card-list img {
    width: 55px;
    height: 55px;
    object-fit: contain;
  }

  .G-zr .hiw-sec .list-works {
    padding: 0 20px;
    background: none;
  }

  .G-zr .hiw-sec .list-works .card-list p {
    font-size: 14px;
    padding: 0;
  }

  .G-zr .hiw-sec .list-works .card-list h5 {
    height: 30px;
    font-size: 16px;
  }

  .G-zr .msg-body .reciver-msg h6,
  .G-zr .msg-body .sender-msg h6 {
    max-width: 70%;
  }

  .G-zr .main-dash.seller-D {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .G-zr .error-page svg {
    width: 100%;
  }

  .G-zr .F-select {
    margin: auto 35px;
  }

  .G-zr .F-select span {
    width: 145px;
    margin: 15px auto;
    height: 52px;
  }

  .G-zr .navbar {
    height: auto;
    padding: 0 15px !important;
  }

  .G-zr .L-curve .curve-D {
    top: 0;
    height: auto;
    position: relative !important;
  }

  .G-zr .L-curve .curve-D h1 {
    margin-top: 0;
  }

  /* table responsive mobile */
  /* Force table to not be like tables anymore */
  #no-more-tables table,
  #no-more-tables thead,
  #no-more-tables tbody,
  #no-more-tables th,
  #no-more-tables td,
  #no-more-tables tr {
    display: block;
  }

  /* Hide table headers (but not display: none;, for accessibility) */
  #no-more-tables thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }

  #no-more-tables tr {
    border: 1px solid #ccc;
  }

  #no-more-tables td {
    border: none;
    position: relative;
    padding-left: 54%;
    white-space: normal;
    text-align: left;
    height: auto;
  }

  #no-more-tables td:before {
    position: absolute;
    top: 0;
    left: 6px;
    width: 45%;
    white-space: nowrap;
    text-align: left;
    font-weight: 700;
    content: attr(data-title);
    color: #212529;
    bottom: 0;
    margin: auto;
    height: 22px;
  }
}

@media (max-width: 600px) {
  .loader {
    width: 100px !important;
    height: 100px !important;
    border: 5px solid #f3f3f3;
  }
  .G-zr .product-post .progres-bar .step-1 span:after {
    width: 100% !important;
  }
  .G-zr .prd-feed-card.feed-card .feed-body {
    width: 100%;
  }
  .G-zr .Dash-right .Farms-card {
    width: 100%;
    max-width: 100%;
  }
  .G-zr .progress-form .select-wrapper:after {
    right: 40px;
  }
  .progress-form .col.pr-0,
  .progress-form .coll.pr-0 {
    padding-right: 15px !important;
  }
  #calenderComponent .model-height {
    width: 100% !important;
    height: auto;
  }
  .cm-det {
    padding: 30px 0;
    margin-top: 45px;
  }
  .cp_dash-nav {
    flex-direction: column;
    box-shadow: none;
  }
  .cp-lt-mn,
  .G-zr .track-bg {
    width: 100%;
    min-width: 100%;
  }
  .G-zr .main-dash.seller-D .ac-scrollbar .dot-S {
    max-width: 50%;
  }

  .G-zr .hiw-sec h4 {
    font-size: 26px;
  }

  .fot-sec .contact span.af:after {
    /* display: none; */
  }

  .G-zr .G-left {
    width: 100%;
    padding: 0 30px;
  }

  .G-zr .banner-sec .G-left {
    padding: 0;
  }

  footer .fot-sec div.contact,
  footer .fot-sec div.fot-list {
    flex-direction: initial;
    align-items: center;
    flex-wrap: wrap;
    margin: 0 -10px 5px;
  }

  footer .fot-sec div.fot-list a {
    width: 100%;
    text-align: center;
  }

  .fot-sec div.fot-list a:hover {
    color: #358539;
  }

  .fot-sec span {
    padding: 5px 10px !important;
    display: inline-block;
  }

  .actvOrdrsWrpr .srchBox {
    width: 100%;
    margin-bottom: 20px;
  }
}

@media (max-width: 767px) {
  .G-zr .w-10p.w345 {
    width: 40%;
  }

  .G-zr .Dash-right .nav-tabs .nav-item,
  .G-zr .Dash-right .table tbody td.w-25 {
    width: 100% !important;
  }

  .G-zr .progress-form.form-col .row,
  .G-zr .progress-form.form-col .b_pro-proc {
    flex-flow: column;
  }

  .G-zr .breadcrumb-item.active {
    padding-left: 0px;
  }

  /* toggle-menu start */
  #logo {
    display: block;
    padding: 0;
    width: 100%;
    text-align: center;
    float: none;
  }

  nav {
    margin: 0;
  }

  /* Hide the navigation menu by default */
  /* Also hide the  */
  .toggle + a,
  .side-Dash {
    display: none;
  }

  /* Stylinf the toggle lable */
  .toggle {
    display: block;
    background-color: #362f2c;
    padding: 14px 20px;
    color: #fff;
    font-size: 17px;
    text-decoration: none;
    border: none;
  }

  .toggle:hover {
    background-color: #000000;
  }

  /* Display Dropdown when clicked on Parent Lable */
  [id^="drop"]:checked + .side-Dash {
    display: block;
  }

  /* toggle menu */

  .G-zr .approved-user .w-10p {
    width: 20%;
  }

  .G-zr .main-dash.buyer-D .da-sh,
  .G-zr .main-dash.seller-D .da-sh {
    padding: 15px 0;
  }

  .G-zr .main-dash.seller-D .ac-scrollbar .dot-S p {
    margin-bottom: 15px !important;
  }

  .G-zr .main-dash.seller-D .ac-scrollbar .dot-S {
    max-width: 100%;
  }

  .G-zr .msg-body .reciver-msg h6,
  .G-zr .msg-body .sender-msg h6 {
    max-width: 85%;
    font-size: 14px;
  }

  .modal-body .ap-loc-box {
    align-items: start !important;
  }

  .w-sm-100 {
    width: 100% !important;
  }

  .ap-loc-box {
    height: 110px;
    flex-flow: column;
    align-items: start !important;
    justify-content: space-between !important;
  }

  .G-zr .quick-search .search-form .input-group {
    width: 100%;
    max-width: 100%;
    flex: 1 1 100%;
    margin-bottom: 15px;
  }

  .G-zr .search-form .input-group {
    margin-right: 0px !important;
    margin-bottom: 10px;
  }

  .search-form .d-flex {
    flex-flow: column;
    align-items: flex-start;
  }

  .quickSearch.search-form .d-flex {
    flex-flow: row wrap !important;
    align-items: flex-start;
  }

  .search-form .d-flex.scd-fx {
    flex-flow: row;
    align-items: flex-start;
    width: 100%;
  }

  .G-zr .search-form .scd-fx .btn {
    width: 50%;
  }

  .G-zr .responsive-view {
    display: block !important;
  }

  .G-zr .responsive-none {
    display: none;
  }

  .G-zr .feed-card .feed-body span:nth-child(1) {
    margin-bottom: 15px;
  }

  .G-zr .feed-card .feed-body span h5 {
    line-height: 25px;
  }

  .G-zr .feed-card .feed-body span {
    flex-flow: column;
    align-items: start !important;
  }

  .G-zr .main-dash.seller-D {
    padding-left: 0rem !important;
    padding-right: 0rem !important;
  }

  .G-zr .place-order h6 {
    padding: 0 10%;
  }

  .G-zr .search-form .input-group input.a-in::placeholder {
    font-size: 12px;
  }

  .G-zr .L-curve .curve-D h1 {
    margin-top: 0;
    font-size: 26px;
    padding: 0;
  }

  .rtCrvCntnr {
    height: unset;
    min-height: unset;
    max-height: unset;
    width: 100%;
    object-fit: cover;
    object-position: top;
    border-top-right-radius: unset;
    border-bottom-right-radius: unset;
    overflow: unset;
  }
}
@media (max-width: 600px) {
  .react-tabs {
    overflow: auto !important;
  }
  .btm-text {
    margin-top: 20px;
    margin-left: 20px;
  }
  .calenpop .modal-dialog {
    width: 98% !important;
    max-width: 98% !important;
  }
  span.G-radio.d-flex.justify-content-start.align-items-center {
    flex-direction: column;
    align-items: flex-start !important;
  }
  .progress-form .col .mb-5 {
    margin-bottom: 1rem !important;
  }
  .progress-form .col {
    max-width: 100%;
    width: 100%;
    min-width: 100%;
  }
  .G-zr .product-post {
    margin-top: 35% !important;
    width: 100%;
    max-width: 100%;
    border: 1px solid #d2d2d2;
    border-radius: 5px;
  }
  .G-zr .txt-green {
    padding-top: 10px !important;
  }
  .G-zr .btn-green {
    padding: 10px 10px;
  }
  .pl-5,
  .px-5 {
    padding-left: 1.5rem !important;
    padding-right: 1.5em !important;
  }
  .G-zr .seller-profile figure {
    top: -25px;
    overflow: hidden;
    position: relative !important;
  }
  .seller-profile a {
    display: inline-grid;
  }
  .pxs-0 {
    padding: 0 !important;
    text-align: center;
  }
  .sn-msg {
    padding-left: 0;
  }
  .row.filter-right.pb-3.pr-5 {
    padding: 0 !important;
  }
  .G-zr .seller-product-vw .carosal-up-product .up-card {
    margin-right: auto !important;
    margin-left: 0;
    margin-bottom: 40px;
  }
  .fb-wrapper .feed-card .feed-img {
    margin: auto;
  }
  .G-zr .feed-card {
    margin: 15px auto !important;
  }
  .G-zr .track-left .progres-bar .step.active p {
    font-size: 13px;
  }
  .G-zr .track-left,
  .G-zr .track-right {
    padding: 20px;
  }
  .G-zr .action-list .btn-gradien {
    padding: 4px 15px;
    color: #fff;
    font-size: 12px;
    font-weight: 500;
    white-space: nowrap;
  }
  .fc-toolbar h2 {
    font-size: 32px !important;
  }
  .calenpop .modal-dialog {
    width: 100%;
    max-width: 100%;
    margin: 30px auto;
  }
  .calender-res,
  .fc-view-container {
    overflow: auto;
  }
  .fc-view-container .fc-view {
    width: 600px;
  }
  .calender-res .table-responsive {
    width: 600px;
  }
  .G-zr .Dash-right .agenta-calender .table tbody td:first-child {
    width: 125px;
  }
  .G-zr .Dash-right .agenta-calender .table tbody td object {
    width: auto;
  }
}

@media (max-width: 475px) {
  .prof-il .pr-0 {
    padding-right: 15px !important;
  }
  .G-zr .progress-form.prof-il .select-wrapper:after {
    right: 40px;
  }
  .G-zr .G-main {
    height: auto;
  }

  .G-zr .msg-body .reciver-msg,
  .G-zr .msg-body .sender-msg {
    flex-flow: column;
  }

  .G-zr .msg-body .sender-msg {
    align-items: flex-end;
  }

  .G-zr .msg-body .reciver-msg {
    align-items: flex-start;
  }
}

@media (max-width: 425px) {
  .G-zr .banner-sec.video-bg {
    margin-top: 5em;
  }

  .processor-list .con-ner {
    padding: 0px 0px 0 0px;
  }

  .G-zr .progress-form.form-col .b_pro-proc h3 {
    padding: 15px 0;
  }

  .G-zr .msg-body .reciver-msg h6,
  .G-zr .msg-body .sender-msg h6 {
    max-width: 100%;
    font-size: 14px;
    margin: 0 !important;
  }

  .G-zr .G-main {
    margin-top: 3em;
  }

  .pop-vdo video {
    height: 200px;
  }

  .col.search-fil {
    overflow: auto;
    padding: 16px;
    background: #eeeeee;
    margin: auto 12px;
  }

  .G-zr .G-modal .modal-header .modal-title.fs-18 {
    font-size: 16px !important;
  }

  .modal-body.px-5.pb-5,
  .G-zr .G-modal .modal-header {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .G-zr .btn-bwn.up-bwn {
    padding: 8px 6px !important;
    width: 100%;
  }

  .ro-wo {
    flex-direction: column;
    align-items: start !important;
  }

  .G-zr .btn-green {
    padding: 10px 20px;
    font-size: 14px;
    margin: 5px auto;
  }

  .G-zr .pro-view-slider .owl-carousel {
    flex-direction: column;
  }

  .G-zr .pro-view-slider .owl-carousel div:nth-child(1),
  .G-zr .pro-view-slider .owl-carousel div:nth-child(2) {
    width: 100%;
    text-align: center;
  }

  .G-zr .abt-sll {
    flex-direction: column;
    align-items: center;
  }

  .G-zr .Af-log #navbarSupportedContent ul.onres {
    width: 60%;
    right: -60%;
  }

  .G-zr .quick-search .search-form .input-group {
    width: 100%;
    margin-bottom: 15px;
  }

  .G-zr .L-curve img {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .G-zr .L-curve .curve-D h1 {
    margin-top: 0;
    font-size: 26px;
  }
}

@media (max-width: 375px) {
  .text-sm-center ul {
    text-align: center !important;
  }

  .ap-loc-box .w-75 {
    width: 100% !important;
    margin-right: 0 !important;
  }
}

/* responsive end */
/* calender-view */

/* .Dash-right tr:nth-child(2)>td>.fc-day-grid-event {
    background-color: #3b8793 !important;
}

.Dash-right tr:nth-child(3)>td>.fc-day-grid-event {
    background-color: rgb(59, 90, 147) !important;
}

.Dash-right tr:nth-child(4)>td>.fc-day-grid-event {
    background-color: rgb(60, 59, 147) !important;
}

.Dash-right tr>td>.fc-day-grid-event {
    border: 1px solid transparent;
    margin: 1px 6px;
}

.Dash-right tr:first-child>td>.fc-day-grid-event {
    background: #358539 !important;
    border-radius: 4px;
} */

.fc-day-grid-event .fc-content {
  padding: 2px 5px;
  font-size: 12px;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  color: white;
  cursor: pointer;
}

.table-scrool {
  height: 300px;
  overflow: auto;
}

.G-zr .comunity-members h5 {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

@media (max-width: 600px) {
  .fc-day-grid-event .fc-content {
    white-space: unset !important;
  }

  #calenderComponent .fa-close-cls {
    margin: 0 !important;
    font-size: 26px !important;
  }
}

@media (max-width: 500px) {
  .G-zr .w-10p.w345 {
    width: 40%;
  }

  .G-zr .Dash-right .nav-tabs .nav-item,
  .G-zr .Dash-right .table tbody td.w-25 {
    width: 100% !important;
  }

  .G-zr .progress-form.form-col .row,
  .G-zr .progress-form.form-col .b_pro-proc {
    flex-flow: column;
  }

  .G-zr .breadcrumb-item.active {
    padding-left: 0px;
  }

  /* toggle-menu start */
  #logo {
    display: block;
    padding: 0;
    width: 100%;
    text-align: center;
    float: none;
  }

  nav {
    margin: 0;
  }

  /* Hide the navigation menu by default */
  /* Also hide the  */
  .toggle + a,
  .side-Dash {
    display: none;
  }

  /* Stylinf the toggle lable */
  .toggle {
    display: block;
    background-color: #362f2c;
    padding: 14px 20px;
    color: #fff;
    font-size: 17px;
    text-decoration: none;
    border: none;
  }

  .toggle:hover {
    background-color: #000000;
  }

  /* Display Dropdown when clicked on Parent Lable */
  [id^="drop"]:checked + .side-Dash {
    display: block;
  }

  /* toggle menu */

  .G-zr .approved-user .w-10p {
    width: 20%;
  }

  .G-zr .main-dash.buyer-D .da-sh,
  .G-zr .main-dash.seller-D .da-sh {
    padding: 15px 0;
  }

  .G-zr .main-dash.seller-D .da-sh {
    width: 100%;
  }

  .G-zr .main-dash.seller-D .ac-scrollbar .dot-S p {
    margin-bottom: 15px !important;
  }

  .G-zr .main-dash.seller-D .ac-scrollbar .dot-S {
    max-width: 100%;
  }

  .G-zr .ac-scrollbar .d-flex {
    flex-direction: column;
  }

  .G-zr .msg-body .reciver-msg h6,
  .G-zr .msg-body .sender-msg h6 {
    max-width: 85%;
    font-size: 14px;
  }

  .modal-body .ap-loc-box {
    align-items: start !important;
  }

  .w-sm-100 {
    width: 100% !important;
  }

  .ap-loc-box {
    height: 110px;
    flex-flow: column;
    align-items: start !important;
    justify-content: space-between !important;
  }

  .G-zr .quick-search .search-form .input-group {
    width: 100%;
    margin-bottom: 15px;
  }

  .G-zr .search-form .input-group {
    margin-right: 0px !important;
    margin-bottom: 10px;
  }

  .search-form .d-flex {
    flex-flow: column;
    align-items: flex-start;
  }

  .quickSearch.search-form .d-flex {
    flex-flow: row wrap !important;
    align-items: flex-start;
  }

  .search-form .d-flex.scd-fx {
    flex-flow: row;
    align-items: flex-start;
    width: 100%;
  }

  .G-zr .search-form .scd-fx .btn {
    width: 50%;
  }

  .G-zr .responsive-view {
    display: block !important;
  }

  .G-zr .responsive-none {
    display: none;
  }

  .G-zr .feed-card .feed-body span:nth-child(1) {
    margin-bottom: 15px;
  }

  .G-zr .feed-card .feed-body span h5 {
    line-height: 25px;
  }

  .G-zr .feed-card .feed-body span {
    flex-flow: column;
    align-items: start !important;
  }

  .G-zr .main-dash.seller-D {
    padding-left: 0rem !important;
    padding-right: 0rem !important;
  }

  .G-zr .place-order h6 {
    padding: 0 10%;
  }

  .G-zr .search-form .input-group input.a-in::placeholder {
    font-size: 12px;
  }
}

.loader {
  border: 16px solid #f3f3f3;
  /* Light grey */
  border-top: 16px solid #358539;
  /* Blue */
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
  margin: 20px auto;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.cp-spinner {
  width: 48px;
  height: 48px;
  display: inline-block;
  box-sizing: border-box;
  position: relative;
}

.cp-balls {
  animation: cp-balls-animate 1s linear infinite;
}

.cp-balls:before {
  border-radius: 50%;
  content: " ";
  width: 24px;
  height: 24px;
  display: inline-block;
  box-sizing: border-box;
  background-color: #0fd6ff;
  position: absolute;
  top: 0;
  left: 0;
  animation: cp-balls-animate-before 1s ease-in-out infinite;
}

.cp-balls:after {
  border-radius: 50%;
  content: " ";
  width: 24px;
  height: 24px;
  display: inline-block;
  box-sizing: border-box;
  background-color: #eb68a1;
  position: absolute;
  bottom: 0;
  right: 0;
  animation: cp-balls-animate-after 1s ease-in-out infinite;
}

.validation-error {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  margin: 0;
  z-index: 999999999 !important;
}

@keyframes cp-balls-animate {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes cp-balls-animate-before {
  0% {
    transform: translate(-5px, -5px);
  }

  50% {
    transform: translate(0, 0);
  }

  100% {
    transform: translate(-5px, -5px);
  }
}

@keyframes cp-balls-animate-after {
  0% {
    transform: translate(5px, 5px);
  }

  50% {
    transform: translate(0, 0);
  }

  100% {
    transform: translate(5px, 5px);
  }
}

.cp-spinner {
  width: 32px;
  height: 32px;
  display: inline-block;
  box-sizing: border-box;
  position: relative;
}

.cp-bubble {
  border-radius: 50%;
  width: 13px;
  height: 13px;
  display: inline-block;
  box-sizing: border-box;
  margin: 0 30px;
  background: #58bd55;
  animation: cp-bubble-animate 1s linear infinite;
}

.cp-bubble:before {
  border-radius: 50%;
  content: " ";
  width: 12px;
  height: 12px;
  display: inline-block;
  box-sizing: border-box;
  background-color: #58bd55;
  position: absolute;
  left: -30px;
  animation: cp-bubble-animate-before 1s ease-in-out infinite;
}

.cp-bubble:after {
  border-radius: 50%;
  content: " ";
  width: 12px;
  height: 12px;
  display: inline-block;
  box-sizing: border-box;
  background-color: #58bd55;
  position: absolute;
  right: -30px;
  animation: cp-bubble-animate-after 1s ease-in-out infinite;
}

@keyframes cp-bubble-animate {
  0% {
    opacity: 0.5;
    transform: scale(1) translateX(0);
  }

  25% {
    opacity: 1;
    transform: scale(1) translateX(0px);
  }

  50% {
    opacity: 1;
    transform: scale(1) translateX(0px);
  }

  100% {
    opacity: 0.5;
    transform: scale(1) translateX(0);
  }
}

@keyframes cp-bubble-animate-before {
  0% {
    opacity: 0.5;
    transform: scale(1);
  }

  25% {
    transform: scale(1.1);
  }

  100%,
  50% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes cp-bubble-animate-after {
  0%,
  50% {
    opacity: 0.5;
    transform: scale(1);
  }

  50% {
    transform: scale(1.1);
  }

  100%,
  75% {
    opacity: 1;
    transform: scale(1);
  }
}

.modal .modal-header {
  margin-bottom: 0;
}

.G-zr .G-modal .modal-body {
  max-height: calc(90vh - 61px);
  overflow: auto;
}

@media (max-width: 1199px) {
  .G-zr.top-h .px-5.pb-5 {
    padding: 0 15px !important;
  }

  .G-zr .breadcrumb-item.active {
    padding-left: 0;
  }

  .G-zr .dash-hd .breadcrumb-item a {
    padding: 5px 5px 5px 0;
  }

  .G-zr .Dash-right .nav-tabs {
    border-bottom: 1px solid #358539;
    display: flex;
    white-space: nowrap;
    flex-wrap: nowrap;
    overflow: auto;
  }

  .G-zr .Dash-right .nav-tabs .nav-item {
    width: auto !important;
  }
}

@media (max-width: 850px) {
  .G-zr .search-form .scd-fx .btn {
    font-size: 14px;
    font-weight: 500;
    margin: 20px 10px;
  }
  .search-form .sech-pag {
    width: 50%;
    max-width: 50%;
    -ms-flex: 0 0 50%;
    flex: 0 0 45%;
  }
}

@media (max-width: 768px) and (max-width: 850px) {
  .G-zr .search-form .scd-fx .btn {
    font-size: 14px;
    font-weight: 500;
    margin: 20px 10px;
  }
  .search-form .sech-pag {
    width: 50%;
    max-width: 50%;
    -ms-flex: 0 0 50%;
    flex: 0 0 45%;
  }
}

@media (max-width: 767px) {
  .search-form .sech-pag {
    width: 100%;
    max-width: 100%;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
  }
  /* .sn-msg {
        padding-left: 120px; 
    } */

  .mrtp-11 {
    margin-top: -11px;
  }

  .pxs-0 {
    padding: 0 !important;
  }

  .G-zr .dash-banner-h {
    height: 180px;
    min-height: 180px;
  }
  .mtp-82 {
    margin-top: 62px;
  }
  .G-zr .main-dash.seller-D .da-sh {
    width: 48%;
    margin: 0 auto 20px;
    height: 185px;
  }

  /* .G-zr .main-dash.seller-D .da-sh:last-child {
        width:100%;
    } */

  .G-zr .bg-dash {
    padding: 0 !important;
  }

  .G-zr .Dash-right .Farms-card {
    width: 48%;
    max-width: 48%;
    margin: 5px auto 20px;
  }
  .G-zr.top-h {
    margin-top: 75px;
  }
  .G-zr .form-right {
    margin: 0 auto 20px;
  }

  .pt-xs-70 {
    padding-top: 75px;
  }

  .pt-xs-30 {
    padding-top: 30px;
  }
  .lead {
    font-size: 15px;
    font-weight: 300;
    margin: 20px 0;
  }
}

@media (min-width: 768px) {
  .G-zr .hiw-sec .list-works .card-list.pcrd-list {
    width: 33.3%;
  }

  .G-zr .hiw-sec .list-works .card-list.pcrd-list img {
    object-fit: contain;
  }
}

@media (min-width: 768px) and (max-width: 1199px) {
  .G-zr .hiw-sec .list-works {
    background: none;
    padding: 0;
  }

  .G-zr .hiw-sec .list-works .card-list {
    padding: 10px;
  }
}

@media (min-width: 600px) {
  .G-zr .search-form .scd-fx .btn {
    margin: 0px 10px;
    width: 100%;
  }
}

@media (max-width: 600px) {
  .cstmPopTable #no-more-tables thead tr {
    position: relative;
    top: unset;
    left: unset;
  }

  .cstmPopTable #no-more-tables table,
  .cstmPopTable #no-more-tables thead,
  .cstmPopTable #no-more-tables tbody,
  .cstmPopTable #no-more-tables tr {
    display: table;
  }

  .cstmPopTable #no-more-tables th,
  .cstmPopTable #no-more-tables td {
    display: table-cell;
    padding-left: 8px;
    padding-right: 8px;
  }
}

@media (max-width: 425px) {
  .G-zr .F-select figure h5 {
    font-size: 15px !important;
  }
  .G-zr .search-form .scd-fx .btn {
    width: 100%;
    margin: 3px 10px;
  }
  .G-zr .quick-search.quick-search-filter {
    padding: 10px 0 !important;
  }
}

@media (max-width: 375px) {
  .G-zr .F-select span {
    width: 120px;
    margin: 6px auto;
    height: 45px;
  }
}
