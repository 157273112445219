.btn-center{
    margin-left: 160px;
    margin-top:5px;
}

.btn-size{
    max-width: 235px;
}

.btm-text
{
    margin-top:20px;
    margin-left: 120px;
}

#calenderComponent .fa-close-cls
{ 
    cursor: pointer;
    margin:1em
    /* margin-left: 560px;     */
}
#calenderComponent{
    height:auto;
    width:1000px;
    margin: auto;
}
#calenderComponent .model-height
{
    /*height: auto;*/
    width:1000px;
}
.calender-margin
{
    margin-top:40px;
    margin-left: 120px;
}
/* .react-calendar__navigation__label
{
    background-color: aquamarine !important;
} */


@media (max-width: 600px){
    #calenderComponent .model-height {
        width: 100%;
        height: auto;
    }
    .fc-toolbar {
        display: flex;
        justify-content: space-between;
        align-items: baseline;
        flex-flow: column;
    }
}