/* @import '~@fullcalendar/core/main.css'; */
/* @import '~@fullcalendar/daygrid/main.css'; */
.slotNo {
  box-shadow: 0px 0px 3px 0px grey;
  margin: 10px;
  cursor: pointer;
}
.yellow {
  background: yellow;
}

.blue {
  background: #167982;
}

.green {
  background: green;
}

.text-white {
  color: white;
}

.btnred {
  background: red;
  background-color: red;
  color: red;
}
.calenderPop {
  border: none;
  padding: 2em;
  background: white;
  z-index: 9999999;
  left: 0;
  position: absolute;
  margin: -5em 0em 0em -8em;
  top: 0;
  width: 150px;
  box-shadow: 1px 1px 7px 3px lightgrey;
  height: 150px;
  border-radius: 1em;
  transform: translate(-22px, 62px);
}
.cursor-pointer {
  cursor: pointer;
}
