.G-zr .msg-head {
    background: #362F2C;
    padding: 10px 20px;
}
.G-zr .Dash-right .msg-head .w-92p {
    width: 88%;
}
.G-zr .msg-head .w-92p {
    width: 92%;
}
.G-zr .msg-head img {
    min-width: 44px;
    width: 44px;
    object-fit: cover;
    object-position: center;
}
.G-zr .fw-m {
    font-weight: 500;
}

.G-zr .fs-16 {
    font-size: 16px !important;
}
.G-zr .fs-15 {
    font-size: 12px
     /* !important; */
}
.G-zr .Dash-right .msg-body {
    background: #eee;
    border-top: 10px solid #fff;
    background-image: url(/images/msg-bg.png);
    border-bottom: 10px solid #fff;
}

.G-zr .msg-body {
    min-height: 250px;
    height: 423px;
    background: #fff;
    border-top: 10px solid #f1f1f1;
    border-bottom: 10px solid #f1f1f1;
    padding: 30px 20px 20px 20px;
    overflow: auto;
    background-image: url(/images/msg-bg.png);
    background-size: cover;
    background-repeat: no-repeat;

}

.G-zr .Dash-right .msg-body .reciver-msg h6, .G-zr .Dash-right .msg-body .sender-msg h6 {
    background: #ffffff;
}

.G-zr .Dash-right .msg-body .sender-msg h6 {
    background: #57A15A;
}
.G-zr .msg-fot .input-group {
    background: #fff;
    border: 1px solid #358539;
    height: 50px;
    overflow: hidden;
    border-radius: 2px;
}
.G-zr .msg-fot input {
    height: 50px;
    border: none;
    position: relative;
    font-style: italic;
    font-size: 14px;
}
.G-zr .Dash-right .msg-list-head .comunity-sch {
    box-shadow: 0px 2px 6px 0px #0000002e;
    height: 62px;
    margin-bottom: 10px;
}
.G-zr .Dash-right .msg-list-head .comunity-sch .input-group input {
    border: none;
    height: 62px;
}
.G-zr .Dash-right .msg-list-head .comunity-sch .input-group-prepend span.input-group-text {
    background: none !important;
}

.G-zr .msg-list {
    background: #EEEEEE;
    padding: 10px;
    min-height: 423px;
    overflow: auto;
    height: 423px;
}

.G-zr .msg-list .B-L {
    cursor: pointer;
    background: white;
    padding: 10px 5px;
    border-radius: 5px;
    margin-bottom: 10px;
    height: 70px;
    min-height: 70px;
}
.G-zr .msg-list figure {
    width: 20%;
    position: relative;
}
.G-zr .msg-list img {
    width: 44px;
    min-width: 44px;
    height: 44px;
    border-radius: 100px;
}
.G-zr .msg-list .list-bdy {
    width: 80%;
}