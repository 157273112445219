.btn-center{
    margin-left: 160px;
    margin-top:5px;
}

.btn-size{
    max-width: 235px;
}

.btm-text
{
    margin-top:20px;
    margin-left: 120px;
}

.fa-close-cls
{ 
    cursor: pointer;
    margin-left: 560px;    
}

.model-height
{
    height: auto;
}
.calender-margin
{
    margin-top:40px;
    margin-left: 120px;
}
/* .react-calendar__navigation__label
{
    background-color: aquamarine !important;
} */

.popup-page{
    padding: 0.5rem;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
    align-content: center;
    background-color: white;
    border: 1px solid #E3E8EE;
}

.popup-page-x{
    cursor: pointer;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: end;
    align-items: center;
    align-content: center;
}

.popup-page-x:hover{
    cursor: pointer;
    font-weight: 700;
}

.popup-page-x-item{
    width: 20px;
    height: 20px;
    font-size: 12px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
    border-radius: 50px;
    background: #E5E5E5;
}

.popup-page-container-no-boder{
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    border: none;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
}

.popup-page-container-item{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 1rem;
}

.popup-page-container-item-column{
    display: flex;
    flex-direction: column;
    justify-content: start;
    width: 200px;
}

.popup-page-container-item-column-wide{
    display: flex;
    flex-direction: column;
    justify-content: start;
    width: 500px;
}

.popup-page-container-input{
    display: flex;
    flex-direction: column;
}

.popup-page-container-input-line{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    align-content: start;
    margin: 0.5rem 0;
}

.popup-page-container-input-line-wide{
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    align-content: start;
    margin: 0.5rem 0;
}

.popup-page-input-line{
    box-shadow: 0px 1px 2px 0px #37415114;
    padding: 8px;
    border-radius: 4px;
    border: 1px;
    gap: 10px;
    border: 1px solid #E3E8EE;
    width: 50px;
}
  
.popup-page-label-line{
    font-size: 12px;
    font-weight: 600;
    line-height: 16px;
    letter-spacing: 0px;
    text-align: left;
    color: #697386;
    margin-bottom: 0;
    margin-right: 0.5rem;
}

.popup-page-input-line-wide{
    box-shadow: 0px 1px 2px 0px #37415114;
    padding: 8px;
    border-radius: 4px;
    border: 1px;
    gap: 10px;
    border: 1px solid #E3E8EE;
    width: 80px;
    margin-right: 0.5rem;
}
  
.popup-page-label-line-wide{
    font-size: 12px;
    font-weight: 600;
    line-height: 16px;
    letter-spacing: 0px;
    text-align: left;
    color: #697386;
    margin-bottom: 0;
    margin-right: 0.5rem;
    width: 80px;
}

.switch-wrap{
    width:80px;
    height:36px;
    padding:0 8px !important;
}

.popup-page-input{
    box-shadow: 0px 1px 2px 0px #37415114;
    padding: 8px;
    border-radius: 4px;
    border: 1px;
    gap: 10px;
    border: 1px solid #E3E8EE;
}

.popup-page-input-checkbox{
    box-shadow: 0px 1px 2px 0px #37415114;
    padding: 8px;
    border-radius: 4px;
    border: 1px;
    gap: 10px;
    border: 1px solid #E3E8EE;
    margin-bottom: 1rem;
    width: 70px;
}
  
.popup-page-label{
    font-size: 12px;
    font-weight: 600;
    line-height: 16px;
    letter-spacing: 0px;
    text-align: left;
    color: #697386;
    margin-bottom: 0;
}

.popup-page-title-border{
    padding: 0.5rem 1rem;
    border: 1px solid #E3E8EE;
    font-size: 14px;
    border-radius: 8px;
    width: 150px;
    text-align: center;
}

.popup-page-title-fill{
    padding: 0.5rem 1rem;
    font-size: 14px;
    border-radius: 8px;
    width: 150px;
    text-align: center;
    background-color: #525252;
    color: white;
}

.popup-page-title{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: start;
    padding: 1rem;
}

.popup-multiple-checkbox{
    width: 100%;
    max-width:300px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: start;
    align-items: flex-start;
    align-content: center;
    border: 1px solid #E3E8EE;
    padding: 1rem;
    border-radius: 8px;
    background: white;
    position: absolute;
    margin-top: 35px;
    z-index: 999;
}

.popup-multiple-checkbox label{
    width: 100px;
    font-size:14px;
    font-family: Roboto;
    color: var(--gray-500, #697386);
}

.popup-multiple-checkbox input[type=checkbox]{
    margin-right:5px;
}

