@import url(https://fonts.googleapis.com/css?family=Raleway:300,400,500,600,700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Montserrat:300,400,500,600,700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Raleway:300,400,500,600,700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Raleway:300,400,500,600,700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Raleway:300,400,500,600,700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Montserrat:300,400,500,600,700&display=swap);
.montserrat {
  font-family: "Montserrat", sans-serif;
}

:root {
  --fs-10: 10px;
  --fs-12: 12px;
  --fs-13: 13px;
  --fs-14: 14px;
  --fs-15: 15px;
  --green: #358539;
  --lt-green: #19be20;
  --brown: #362f2c;
  --gray: #f1f1f1;
  --lit-gray: #a7a7a7;
}

a {
  text-decoration: none !important;
  color: #28642b;
}

.container {
  width: 100%;
  max-width: 1200px;
}

.sec-clr {
  color: #362f2c !important;
}

.mar-0 {
  margin: 0 !important;
}

.ml-6,
.mx-6 {
  margin-left: 4rem !important;
}

.mr-6,
.mx-6 {
  margin-right: 4rem !important;
}

.pl-6,
.px-6 {
  padding-left: 4rem !important;
}

.pr-6,
.px-6 {
  padding-right: 4rem !important;
}

.pad-lt-0 {
  padding-left: 0 !important;
}

.text-black {
  color: #000000;
}

.G-zr{
  font-family: Roboto;
}

.G-zr .site-top-hight {
  margin-top: 22%;
}

.G-zr .h-135 {
  height: 135%;
}

.G-zr .w-48 {
  width: 48.5% !important;
}

.fs-10 {
  font-size: var(--fs-10) !important;
}

.lt-green {
  color: var(--lt-green) !important;
}

.lt-gray-bg {
  background: #f5f5f5;
}

.txt-gray {
  color: var(--lit-gray);
}

body.bg-gray,
.bg-gray {
  background: var(--gray);
}

body .bg-Dgray {
  background: #f3f3f3;
}

body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  background: #fff;
  font-family: "Raleway", sans-serif;
}

.G-zr .navbar-brand img {
  width: 150px;
  height: 50px;
  object-fit: cover;
}

.G-zr .navbar {
  height: 70px;
}

.G-zr .navbar-expand-lg .navbar-nav .nav-item.active .nav-link {
  border: 1px solid #fff;
  border-radius: 4px;
}

.G-zr .navbar-expand-lg .navbar-nav .nav-item.user-nav .nav-link {
  font-weight: 400;
}

.G-zr .navbar-expand-lg .navbar-nav .nav-link {
  font-size: 14px;
  font-weight: 600;
  padding: 6px 12px;
  color: #fff;
  text-transform: uppercase;
}

.G-zr .video-bg {
  background-image: url("/images/banner-bg.png");
  width: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 660px;
  min-height: 660px;
}

.G-zr .G-left h1 {
  color: #358539;
  font-size: 35px;
  font-family: "SpaceGrotesk-Medium";
}

.G-zr .G-left h2 {
  font-size: 15px;
  line-height: 30px;
  font-weight: 400;
}

.G-zr .hiw-sec {
  display: flex;
  justify-content: center;
  flex-flow: column;
  align-items: center;
}

.G-zr .hiw-sec h4 {
  font-size: 30px;
  font-weight: 800;
}

.G-zr .hiw-sec .nav-pills .nav-link {
  color: #358539;
  background-color: #ffffff;
  border-radius: 0;
  font-size: 21px;
  border: 1px solid rgba(59, 147, 63, 0);
  font-weight: 600;
  padding: 3px;
  width: 133px;
  text-align: center;
  text-transform: uppercase;
}

.G-zr .hiw-sec .nav-pills .nav-link.active {
  color: #358539;
  border: 1px solid #358539;
}

.G-zr .hiw-sec .list-works {
  padding: 0 45px;
  background: url("/images/dot-line.svg");
  width: 100%;
  background-position: center 130px;
  background-repeat: no-repeat;
}

.G-zr .hiw-sec .list-works .card-list {
  padding: 10px 25px;
}

.G-zr .hiw-sec .list-works .card-list h5 {
  font-size: 18px;
  font-weight: 600;
  background: white;
  margin: 0;
  height: 60px;
}

.G-zr .hiw-sec .list-works .card-list p {
  font-size: 15px;
  font-weight: 400;
  padding: 0 30px;
}

.G-zr .hiw-sec .list-works.snd .card-list p {
  padding: 0px;
}

.G-zr .hiw-sec .list-works .card-list img {
  width: 131px;
  height: 131px;
  object-fit: cover;
}

.btn-green {
  border: 1px solid #358539;
  background: #358539;
  padding: 10px 25px;
  font-size: 15px;
  color: #fff;
  transition: all 0.3s ease-in-out;
}
.btn-red {
  border: 1px solid #e40c0c;
  background: #e40c0c;
  padding: 10px 25px;
  font-size: 15px;
  color: #fff;
  transition: all 0.3s ease-in-out;
}
.G-zr .snd-banner:after {
  content: "";
  position: absolute;
  width: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: #362f2ccc;
  z-index: 0;
}

.G-zr .snd-banner {
  background: url("/images/snd-banner.png");
  width: 100%;
  height: 360px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

.G-zr .snd-banner .enquty h4 {
  font-size: 25px;
  text-transform: uppercase;
  font-family: "SpaceGrotesk-Regular";
}

.G-zr .snd-banner .enquty p {
  font-size: 15px;
  color: #d6d6d6;
  padding: 15px 0;
}

.G-zr .snd-banner .enquty {
  z-index: 999;
  color: white;
  height: 360px;
  position: relative;
  display: flex;
  flex-flow: column;
  justify-content: center;
  width: 60%;
  align-items: center;
  align-content: center;
  margin: auto;
}

.G-zr .fot-sec figure img[alt="Fot-logo"] {
  width: 188px;
  height: 66px;
}

.G-zr .fot-sec figure {
  padding: 0 40px;
}

.G-zr .fot-sec .contact span.af:after {
  position: absolute;
  content: "";
  background: #dedede;
  width: 1px;
  height: 25px;
  top: 0;
  right: 0;
}

.G-zr .fot-sec .contact span {
  font-size: 15px;
  font-weight: 500;
  position: relative;
}

.G-zr .fot-sec span {
  padding: 0 30px;
}

.G-zr .fot-sec span a {
  font-size: 15px;
  font-weight: 500;
  color: #358539;
  cursor: pointer;
}

.G-zr footer h6 {
  font-size: 14px;
}

.G-zr footer {
  background: #362f2c;
  padding: 12px;
  color: #fff;
  text-align: center;
}

/*CUSTOM SCROLLBAR*/
.act-cen {
  height: 180px !important;
}

.act-cen .ac-scrollbar {
  overflow-y: scroll;
  padding-right: 10px;
  width: 100%;
}

.act-cen .ac-scrollbar::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: white;
  width: 5px;
}

.act-cen .ac-scrollbar::-webkit-scrollbar {
  width: 5px;
  background-color: transparent;
  background-color: white;
}

.act-cen .ac-scrollbar::-webkit-scrollbar-thumb {
  background-color: #358539;
  border-radius: 50px;
}

/*CUSTOM SCROLLBAR END*/

.G-zr .G-main {
  height: 600px;
  align-items: center;
}

.G-zr .G-left {
  width: 610px;
  padding-left: 60px;
}

.G-zr .G-right {
  padding: 20%;
}

.G-zr .G-right img {
  cursor: pointer;
}

.G-zr .search-form .btn-green {
  padding: 13px 25px;
}

.G-zr .search-form .input-group-prepend {
  position: relative;
  margin-right: 0px;
}

.G-zr .search-form .input-group-text.af figure:after {
  background: #5e5e5e21;
  content: "";
  width: 2px;
  position: absolute;
  height: 25px;
  top: -2px;
  right: -11px;
}

.G-zr .search-form .input-group-text {
  border: none;
  background-color: #ffffff;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
}

.G-zr .search-form .input-group {
  border: 1px solid #358539;
  border-radius: 6px;
  margin-right: 10px;
  height: 50px;
}

.G-zr .search-form .input-group input.b-in {
  font-size: 12px;
  padding-left: 3px;
  border-radius: 6px;
  font-weight: 500;
}

.G-zr .search-form .input-group input.a-in {
  width: 165px;
  font-family: "Raleway", sans-serif !important;
}

.G-zr .search-form .input-group input {
  border: none;
  padding: 0;
  height: 48px;
}

.G-zr .search-form .input-group input:focus {
  border: none;
  outline: none;
  box-shadow: none;
}

.G-zr .search-form .input-group input.a-in::placeholder {
  color: #358539;
  font-style: italic;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.5px;
  font-family: "Raleway", sans-serif !important;
}

.modal-pop .modal-header button {
  font-size: 26px;
  font-weight: 100;
  color: #04561a;
  float: none;
  opacity: 1;
  font-family: fantasy;
  margin: 0;
  line-height: 0;
  padding: 0;
}

.modal-pop .modal-header {
  right: 19px;
  z-index: 99;
  background: #e2e2e2;
  color: black;
  border-radius: 100px;
  width: 15px;
  height: 15px;
  display: flex;
  justify-content: center;
  top: 12px;
  align-items: center;
}

.modal-pop .modal-dialog {
  max-width: 1300px;
  height: 500px;
  margin: 46px auto;
}

/* login */
.G-zr .form-right .log-logo img {
  width: 188px;
  height: 66px;
  object-fit: cover;
}

.G-zr .log-logo span h4 {
  position: relative;
  font-size: 18px;
  font-weight: 500;
  color: #358539;
  letter-spacing: 1px;
}

.G-zr .log-logo span h4::after {
  content: "";
  position: absolute;
  background: #358539;
  width: 30px;
  height: 2px;
  margin: auto;
  left: 0;
  right: 0;
  bottom: 0;
  top: 35px;
  border-radius: 100px;
}

.G-zr .G-inpu::placeholder {
  color: #bbbbbb;
  font-size: 16px;
  font-weight: 400;
}

.G-zr .G-label {
  color: rgb(0, 0, 0);
  font-size: 16px;
  font-weight: 400;
}

.G-zr .G-input:focus,
.G-zr .G-input:hover,
.G-zr .G-input:active {
  box-shadow: none;
  outline: 0;
  background: #f5fdf5;
  border-bottom: 2px solid #358539 !important;
  transition: all 0.6s cubic-bezier(0, 0, 0.2, 1);
}

.G-zr .G-input {
  height: 50px;
  border-bottom: 1px solid #000 !important;
}

.G-zr .form-right {
  margin: 80px auto 30px;
  max-width: 400px;
}

.G-zr .cpy-rt {
  color: #c5c5c5;
  font-weight: 600;
  font-size: 12px;
}

.G-zr .L-curve {
  top: 0px;
}

.rtCrvCntnr {
  height: 900px;
  min-height: 900px;
  max-height: 900px;
  width: 100%;
  object-fit: cover;
  object-position: top;
  border-top-right-radius: 500px;
  border-bottom-right-radius: 500px;
  overflow: hidden;
}

.G-zr .L-curve .curve-D {
  bottom: -150px;
  left: -36px;
  background: #fff;
  border-radius: 100%;
  width: 530px;
  height: 530px;
  display: flex;
  flex-flow: column;
  text-align: center;
  align-items: center;
  justify-content: center;
  padding: 0 100px;
}

.G-zr .L-curve .curve-D a {
  color: #358539;
  font-size: 16px;
  font-weight: 500;
}

.G-zr .G-form .recovey {
  line-height: 25px;
}

.G-zr .txt-green {
  color: var(--green) !important;
}

.G-zr .G-form .recovey p {
  font-size: 15px;
  font-weight: 400;
}

.G-zr .L-curve img {
  height: 900px;
  min-height: 900px;
  max-height: 900px;
  width: 100%;
  object-fit: cover;
  object-position: top;
  /* border-top-right-radius: 100%; */
  /* border-bottom-right-radius: 100%; */
}

.G-zr .L-curve .curve-D h1 {
  margin-top: -110px;
}

.G-zr .L-curve .curve-D p {
  color: #464646;
  font-size: 15px;
}

/* custom check box */
.G-zr .con-ner {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 16px;
  -webkit-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.G-zr .con-ner input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.G-zr .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  border-radius: 3px;
  background-color: #fff;
  border: 1px solid #358539;
}

/* On mouse-over, add a grey background color */
.G-zr .con-ner:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.G-zr .con-ner input:checked ~ .checkmark {
  background-color: #358539;
  transition: all 0.6s cubic-bezier(0, 0.7, 0.49, 0.01);
}

/* Create the checkmark/indicator (hidden when not checked) */
.G-zr .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.G-zr .con-ner input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.G-zr .con-ner .checkmark:after {
  left: 7px;
  top: 3px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

/* custom check box end */

.G-zr .Af-log {
  /* border-bottom: 3px solid #358539; */
  border: none;
}

.G-zr .Af-log .navbar-expand-lg .navbar-nav .nav-link:hover {
  color: #358539;
}

.G-zr .navbar-expand-lg .navbar-nav .nav-link.btn {
  border: 1px solid #358539;
}

.G-zr .Af-log .navbar-expand-lg .navbar-nav .nav-link {
  color: #262626;
}

.G-zr .quick-search.quick-search-filter {
  background: #f1f1f1;
}

.G-zr .quick-search {
  background: #ffffff;
}

.G-zr .quick-search .search-form .input-group .btn {
  font-size: 14px;
  color: #358539;
  padding: 0 20px;
  display: flex;
}

.G-zr .quick-search .search-form .input-group .btn:before {
  content: "";
  position: absolute;
  background: #bcbcbc;
  width: 1px;
  height: 25px;
  top: 10px;
  left: 0;
}

.G-zr .quick-search .search-form .input-group .btn:after {
  border: none;
  content: "\f078";
  font-family: "FontAwesome";
  font-weight: 400;
  display: inline-block;
  font-style: normal;
  font-feature-settings: normal;
  font-variant: normal;
  margin-left: 15px;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
}

.G-zr .quick-search .search-form .input-group .btn:hover,
.G-zr .quick-search .search-form .input-group .btn:focus {
  background: #fff;
  box-shadow: none;
}

.G-zr .quick-search .search-form .input-group {
  flex: 1 1;
}

.G-zr .quick-search .form-btm h4 {
  font-size: 14px;
  font-weight: 500;
  position: relative;
  display: flex;
  justify-content: space-between;
}

.G-zr .quick-search .form-btm h4 .fa-circle:before {
  font-size: 6px;
  position: absolute;
  top: 6px;
  left: -14px;
}

.G-zr .quick-search .search-form .input-group {
  border-radius: 3px;
}

.G-zr .filter-left .filter-hed span:nth-child(2) {
  font-weight: 500;
}

/* Accordion styles */
.accor.tabs {
  border-radius: 0px;
  overflow: hidden;
}

.accor .tab input[type="radio"] {
  position: absolute;
  opacity: 0;
  z-index: -1;
}

.accor .tab {
  border-radius: 4px;
  width: 100%;
  color: white;
  overflow: hidden;
}

.accor .tab-label {
  display: flex;
  justify-content: space-between;
  padding: 16px;
  background: #fff;
  font-weight: bold;
  cursor: pointer;
  color: black;
  font-weight: 500;
  font-size: 16px;
  margin-bottom: 15px;
  /* Icon */
}

.accor .tab-label:hover {
  background: #fff;
}

.accor .tab-label::after {
  content: "";
  width: 24px;
  height: 24px;
  text-align: center;
  transition: all 0.35s;
  border: 1px solid #000;
  border-radius: 100px;
  background-image: url(/images/icon/add.svg);
  background-size: 13px 19px;
  background-repeat: no-repeat;
  background-position: 4px 1px;
}

.accor .tab-content {
  max-height: 0;
  padding: 0 1em;
  color: #2c3e50;
  background: transparent;
  transition: all 0.35s;
}

.accor .tab-close {
  display: flex;
  justify-content: flex-end;
  padding: 1em;
  font-size: 0.75em;
  background: #2c3e50;
  cursor: pointer;
}

.accor .tab-close:hover {
  background: #fff;
}

.accor input:checked + .tab-label {
  background: #ffffff;
  color: #358539;
  margin: 0;
}

.accor input:checked + .tab-label::after {
  transform: rotate(180deg);
  background-image: url(/images/icon/remove.svg);
  background-size: 13px 19px;
  background-repeat: no-repeat;
  background-position: 4px 1px;
  border: 1px solid #358539;
  border-radius: 100px;
}

.accor input:checked ~ .tab-content {
  max-height: 100vh;
  padding: 1em;
}

.accor input:checked ~ .tab-content ul li:hover {
  color: #358539;
}

.accor input:checked ~ .tab-content ul li {
  line-height: 30px;
  font-weight: 600;
  cursor: pointer;
}

.accor input:checked ~ .tab-content ul {
  list-style-type: none;
}

/* // accordien end */

.G-zr .filter-right .btn-emt .btn:hover,
.G-zr .filter-right .btn-emt .btn:focus {
  background: transparent;
  box-shadow: none;
}

.G-zr .btn-green.tg-gbtn {
  padding: 5px;
  width: 35px;
  height: 30px;
  line-height: 1;
  margin-left: 5px;
  background: #212529;
  border-color: #212529;
}

.G-zr .btn.btn-green:hover {
  color: #ffffff;
  background: #2f7d33;
  border-color: #2f7d33;
}

.G-zr .btn.btn-green:focus {
  box-shadow: none;
  background: #2e7c31;
  border-color: #2e7c31;
}

.G-zr .btn-green.tg-gbtn:hover,
.G-zr .btn-green.tg-gbtn:focus {
  background: #fff;
  color: #212529;
  border-color: #212529;
}

.G-zr .filter-right .btn-emt .btn {
  font-size: 14px;
  color: #358539;
  padding: 0 20px;
  display: flex;
}

.G-zr .filter-right .btn-emt .btn:after {
  border: none;
  content: "\f078";
  font-family: "FontAwesome";
  font-weight: 400;
  display: inline-block;
  font-style: normal;
  font-feature-settings: normal;
  font-variant: normal;
  margin-left: 15px;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
}

.G-zr .filter-right .product-list .card {
  flex-flow: row;
  margin-bottom: 30px;
  height: 285px;
  min-height: 285px;
  overflow: hidden;
}

.G-zr .filter-right .product-list .card .pro-img.wht figure:after {
  background-image: url(/images/icon/w-like.svg);
}

.G-zr .filter-right .product-list .card .pro-img figure:after,
.G-zr .pro-view-slider .owl-carousel .owl-item div:after {
  position: absolute;
  content: "";
  top: 0;
  right: 0;
  width: 42px;
  height: 42px;
  background: #00000040;
  background-image: url(/images/icon/like.svg);
  background-repeat: no-repeat;
  background-size: 22px;
  background-position: center;
}

/* .G-zr .Dash-right .Farms-card figure:after {
    

    background-image: none;
    content: "\f005";
    font-family: "FontAwesome";
    font-weight: 400;
    font-style: normal;
    font-variant: normal;
    border-radius: 5px;
    font-size: 18px;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    color: #FECB25;
    pointer-events: none;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
} */

.G-zr .pro-view-slider .owl-carousel .owl-item div:after {
  background: #00000059;
  background-repeat: no-repeat;
  opacity: 0;
  background-image: url(/images/icon/like.svg);
  background-size: 22px;
  background-position: center;
}

.G-zr .filter-right .product-list .card .pro-img img {
  width: 350px;
  height: 285px;
  object-fit: cover;
  object-position: center;
}

.G-zr .fs-12 {
  font-size: var(--fs-12);
}

.G-zr .fs-13 {
  font-size: var(--fs-13);
}

.G-zr .fs-14 {
  font-size: var(--fs-14);
}

.G-zr .fs-15 {
  font-size: var(--fs-15);
}

.G-zr .fs-16 {
  font-size: 16px !important;
}

.G-zr .fs-18 {
  font-size: 18px !important;
}

.G-zr .fs-20 {
  font-size: 20px !important;
}

.G-zr .fs-22 {
  font-size: 22px !important;
}

.G-zr .fs-24 {
  font-size: 24px !important;
}

.G-zr .fw-l {
  font-weight: 300;
}

.G-zr .fw-r {
  font-weight: 400;
}

.G-zr .fw-m {
  font-weight: 500;
}

.G-zr .fw-sm {
  font-weight: 600;
}

.G-zr .btn-bwn {
  background: var(--brown);
  padding: 8px 16px;
  cursor: pointer;
  z-index:1;
  position:relative;
}

.G-zr .btn-bwn:hover {
  color: #fff;
  background: #594d47;
  box-shadow: none;
}

.G-zr .btn-bwn:focus {
  box-shadow: none;
}

.G-zr .filter-right .product-list .card .card-body .C-hd span:nth-child(1) {
  font-size: 20px;
  font-weight: 500;
}

.G-zr .filter-right .product-list .card .card-body .card-fot span {
  color: #f85315;
}

.G-zr .filter-right .product-list .card .card-body .ro-wo {
  padding: 5px 0;
}

.G-zr .filter-right .product-list .card .card-body .ro-wo span {
  font-size: 15px;
  font-weight: 500;
}

.G-zr .filter-right .product-list .card .card-body .ro-wo span b {
  font-weight: 500;
}

.G-zr .filter-right .product-list .card .card-body .sub {
  font-weight: 500;
  color: #bcbcbc;
  padding: 4px 0;
  font-size: 13px;
  display: block;
}

.G-zr .filter-right .product-list .card .card-body .C-hd span:nth-child(2) {
  font-weight: 600;
  font-size: 20px;
}

.G-zr .carosal-up-product.sub-list .up-card {
  /*    margin-right: 15px;*/
  margin-bottom: 55px;
}

.G-zr .carosal-up-product .up-card {
  width: 352px;
  /*    box-shadow: 0px 12px 9px 0px #00000008;*/
  transition: all 0.5s ease-in-out;
}

.G-zr .carosal-up-product.search-l .up-card:hover,
.seller-product-vw .up-card:hover {
  box-shadow: 0px 3px 15px 0px #7676762e;
  border-radius: 5px;
  box-shadow: 0px 3px 15px 0px #7676762e;
  transform: scale(1.05);
  border-radius: 5px;
}

.G-zr .carosal-up-product .up-card:hover .sp-img {
  /*    transform: scale(1.05);*/
}

.G-zr .carosal-up-product .up-card .up-card-body h5 {
  font-size: 18px;
}

.G-zr .carosal-up-product .up-card button {
  height: 45px;
}

.G-zr .carosal-up-product .up-card img {
  width: 352px;
  height: 253px;
  object-fit: cover;
  object-position: center;
  border-radius: 5px;
  transition: all 0.5s ease-in-out;
}

.G-zr .carosal-up-product .up-card figure .ovy-lay img {
  width: 25px;
  height: 25px;
  object-fit: cover;
  object-position: center;
}

.G-zr .carosal-up-product .up-card figure .ovy-lay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: #0000007a;
  text-align: center;
  padding: 6px;
  font-size: 15px;
  color: #fbfbfb;
}

.G-zr .carosal-up-product .up-card.red figure:after {
  background-image: url(/images/icon/like.svg);
}

.G-zr .carosal-up-product .up-card figure:after {
  position: absolute;
  content: "";
  top: 0;
  right: 0;
  width: 42px;
  height: 42px;
  background: #00000052;
  background-image: url(/images/icon/w-like.svg);
  background-repeat: no-repeat;
  background-size: 22px;
  background-position: center;
  border-top-right-radius: 5px;
}

.G-zr .G-page {
  display: flex;
  justify-content: flex-end;
  clear: both;
}

.G-zr .G-page .page-item {
  margin-right: 10px;
  font-weight: 600;
  font-size: 18px;
  box-shadow: 0px 3px 6px 0px #0000000d;
  border: none;
  font-family: "Montserrat", sans-serif;
  text-align: center;
}

.G-zr .G-page .page-item .page-link:hover {
  background-color: #358539;
  color: #fff;
}

.G-zr .G-page .page-item .page-link {
  color: #358539;
  border: none;
  width: 45px;
  height: 45px;
  line-height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 2px;
}

.G-zr .G-page .page-item.active .page-link {
  z-index: 1;
  color: #fff;
  background-color: #358539;
  border: none;
}

.G-zr .breadcrumb-item + .breadcrumb-item::before {
  content: ">" !important;
  font-weight: 600;
  color: #358539;
}

.G-zr .product-post h4 {
  font-size: 20px;
  font-weight: 500;
  padding-top: 25px;
}

.G-zr .product-post {
  margin-top: 15% !important;
  width: 100%;
  max-width: 900px;
  border: 1px solid #d2d2d2;
  border-radius: 5px;
  /*    box-shadow: 0px 0px 10px 0px #0000001a;*/
}

.G-zr .product-post .progres-bar .step.active p,
.G-zr .product-post .progres-bar .step.finished p {
  color: #358539;
}

.G-zr .product-post .progres-bar .step p {
  font-size: 14px;
  font-weight: 500;
  color: #bcbcbc;
}

.G-zr .product-post .progres-bar .step.active span,
.G-zr .track-left .progres-bar .step.active span {
  border: 1px solid #19be20;
  border-radius: 100%;
  color: #19be20;
  background: #fff;
}

.G-zr .product-post .progres-bar .step.finished span {
  border: 1px solid #19be20;
  border-radius: 100%;
  color: #fff;
  background: #19be20;
}

.G-zr .product-post .progres-bar {
  flex-flow: row;
}

.G-zr .product-post .progres-bar .step {
  margin: auto 25px;
}

.G-zr .progress-form .pl-f-img img {
  width: 525px;
}

.G-zr .track-left.track-table .progres-bar .step {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30%;
  flex-flow: column;
}

.G-zr .track-left .progres-bar {
  height: 100%;
}

.G-zr .track-left .progres-bar .step {
  margin: 0;
}

.G-zr .track-left .progres-bar .step span {
  margin: 0 !important;
}

.G-zr .product-post .progres-bar .step span,
.G-zr .track-left .progres-bar .step span {
  text-align: center;
  width: 60px;
  display: flex;
  align-items: center;
  margin: auto;
  justify-content: center;
  height: 60px;
  border: 1px solid #bcbcbc;
  border-radius: 100%;
  position: relative;
  background: #fff;
  color: #bcbcbc;
  z-index: 99;
  font-weight: 600;
  font-size: 18px;
}

.G-zr .track-left .progres-bar .step span {
  width: 44px !important;
  height: 44px !important;
  min-width: 44px;
  max-width: 44px;
}

.G-zr .product-post .progres-bar .step-1.active span:after,
.G-zr .product-post .progres-bar .step-2.active span:after,
.G-zr .track-left .progres-bar .step-1.active span:after,
.G-zr .track-left .progres-bar .step-2.active span:after,
.G-zr .track-left .progres-bar .step-3.active span:after,
.G-zr .track-left .progres-bar .step-4.active span:after {
  /*    background-image: linear-gradient(90deg, #358539, #358539 75%, transparent 75%, transparent 100%) !important;*/
  border-width: 1px;
  border: none;
  background-size: 14px 17px;
  background-repeat: space;
}

.G-zr .product-post .progres-bar .step:nth-child(3) span:after {
  display: none;
}

.G-zr .track-left .progres-bar .step:nth-child(3) span:after {
  display: block;
}

.G-zr .track-left .progres-bar .step:nth-child(5) span:after {
  display: none;
}

.G-zr .product-post .progres-bar .step-1 span:after {
  content: "";
  position: absolute;
  background-image: linear-gradient(
    90deg,
    #dfdfdf,
    #b2b2b2 75%,
    transparent 75%,
    transparent 100%
  );
  width: 600px;
  height: 1px;
  left: 60px;
  top: 29px;
  z-index: -1;
  border-width: 1px;
  background-image: linear-gradient(
    90deg,
    #dfdfdf,
    #b2b2b2 75%,
    transparent 75%,
    transparent 100%
  );
  background-size: 14px 10px;
  border: none;
}

.G-zr .track-left {
  box-shadow: 0px 0px 10px 0px #00000017;
}

.G-zr .track-left,
.G-zr .track-right {
  padding: 35px;
}

.G-zr .track-left .progres-bar .step {
  display: flex;
  align-items: flex-start;
  height: 140px;
}

.G-zr .track-left .progres-bar .step span:after {
  content: "";
  position: absolute;
  background: linear-gradient(
    90deg,
    #dfdfdf,
    #b2b2b2 75%,
    transparent 75%,
    transparent 100%
  );
  width: 1px;
  height: 100px;
  left: 20px;
  top: 100%;
  z-index: -1;
  border: none;
  background-size: 4px 10px;
  background-repeat: space;
}

.G-zr .product-post .progres-bar .step.on-progress span,
.G-zr .track-left .progres-bar .step.on-progress span {
  border: 1px solid #19be20;
  color: #19be20;
}

/* .G-zr .product-post .progres-bar .step-1 span{
    background: #358539;
    color: #fff;
} */
.G-zr .txt-area {
  height: 100px !important;
  padding: 20px 25px !important;
}

.G-zr .calender::after {
  position: absolute;
  content: "";
  background-image: url(/images/icon/calendar.svg);
  width: 20px;
  height: 42px;
  background-size: contain;
  top: 0;
  right: 10px;
  background-position: center;
  background-repeat: no-repeat;
}

.G-zr .D-input {
  border: 1px solid #dedede;
  font-size: 15px;
  font-weight: 400;
  height: 45px;
  color: #929292;
  border-radius: 2px;
  padding: 0 25px;
  transition: all 0.5s ease-in-out;
}

.G-zr .D-input::placeholder {
  color: #929292;
  font-size: 15px;
}

.G-zr .D-input:hover,
.G-zr .D-input:focus {
  box-shadow: none;
}

.G-zr .progress-form .sub-pro img {
  width: 75px;
  height: 48px;
  object-position: center;
  object-fit: cover;
  border-radius: 6px;
}

.G-zr .progress-form.form-col p.t-t {
  left: 30px;
}

.G-zr .progress-form p.t-t {
  top: -8px;
  background: #fff;
  left: 15px;
  padding: 0 12px;
  font-size: 12px;
  font-weight: 500;
}

.G-zr .btn-upload {
  height: 50px;
  border: 1px solid #362f2c;
  background: #fff;
  font-size: 15px;
}

.G-zr .s-img img {
  max-width: 355px;
  width: 100%;
  height: 214px;
  object-fit: cover;
  object-position: center;
  border-radius: 6px;
}

.G-zr .progress-form select {
  -webkit-appearance: unset;
}

.G-zr .progress-form .final-state h3 {
  font-size: 25px;
  text-align: center;
}

.G-zr .progress-form .final-state {
  display: flex;
  justify-content: center;
  text-align: center;
}

.G-zr .progress-form .select-wrapper:after {
  width: 0;
  height: 0;
  content: "\f078";
  font-family: "FontAwesome";
  font-weight: 400;
  display: inline-block;
  font-style: normal;
  font-feature-settings: normal;
  font-variant: normal;
  font-size: 14px;
  margin-left: 15px;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  position: absolute;
  right: 25px;
  top: 12px;
  color: #929292c2;
  pointer-events: none;
}

/* .G-zr .progress-form select:after {
    border: none;
    content: "\f078";
    font-family: "FontAwesome";
    font-weight: 400;
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    margin-left: 15px;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
} */
.G-zr .product-post.final {
  box-shadow: none;
}

.modal-body {
  overflow: hidden;
}

.G-zr .G-modal .modal-body .or {
  color: BCBCBC;
}

.G-zr .G-modal .modal-body .or::after {
  position: absolute;
  content: "";
  background: #bcbcbc;
  width: 47%;
  height: 1px;
  top: 10px;
  right: 0;
}

.G-zr .G-modal .modal-body .or::before {
  position: absolute;
  content: "";
  background: #bcbcbc;
  width: 47%;
  height: 1px;
  top: 10px;
  left: 0;
}

.G-zr .G-modal .modal-header {
  border-bottom: 2px solid #000000;
}

.G-zr .carosal-up-product.sub-list .up-card figure .ovy-lay {
  left: unset;
  width: 45px;
  height: 45px;
  font-size: 15px;
  font-weight: 600;
  display: flex;
  align-items: center;
}

.G-zr .carosal-up-product.sub-list .up-card figure .ovy-lay img {
  width: 16px;
  height: 16px;
  object-fit: contain;
}

.G-zr .G-modal .green-lay {
  background: #35853938;
  padding: 10px 16px;
  color: #358539;
}

/* carosal */
.G-zr .pro-view-slider .content-carousel {
  width: 600px;
  display: block;
  margin: 0 auto;
}

.G-zr .pro-view-slider .owl-carousel {
  width: calc(100% - 100px);
  float: right;
}

.G-zr .pro-view-slider .owl-carousel div {
  /* position: relative;
    width: 100%; */
}

.G-zr .pro-view-slider .owl-carousel .owl-controls .owl-dot {
  background-size: cover;
  margin-top: 10px;
}

.G-zr .pro-view-slider .owl-carousel .owl-dots {
  position: absolute;
  top: 0;
  left: -104px;
  width: 80px;
  height: 325px;
  overflow-x: hidden;
  overflow-y: auto;
}

.G-zr .pro-view-slider .owl-carousel .owl-item img {
  height: 310px;
  object-fit: cover;
  object-position: center;
}

.G-zr .pro-view-slider .owl-carousel.owl-drag .owl-item {
  /* width: 580px!important; */
  object-fit: cover;
}

.G-zr .pro-view-slider .owl-carousel .owl-dot {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 80px;
  height: 65px !important;
  margin-bottom: 15px;
}

/* carosal end */
.G-zr .pro-view-details .pro-view-tit h4 {
  font-size: 22px;
}

.G-zr .pro-view-details .pro-view-tit h4:nth-child(2) {
  font-size: 20px;
}

.G-zr .pro-view-details .sub {
  font-weight: 600;
  color: #bcbcbc;
  font-size: 13px;
}

.G-zr .A-time span {
  background: #fbeee8;
  padding: 12px 20px;
  width: 100%;
  font-weight: 500;
  font-size: 14px;
  color: #f85315;
  font-family: "Montserrat", sans-serif;
}

.G-zr .bid-H .table td .crown:after {
  position: absolute;
  content: "";
  bottom: 6px;
  right: -6px;
  background-image: url(/images/icon/crown.svg);
  width: 22px;
  background-repeat: no-repeat;
  height: 18px;
  background-size: contain;
  background-position: center;
}

.G-zr .bid-H .table td {
  border: none;
  vertical-align: middle;
  height: 60px;
  font-size: 13px;
  font-family: "Montserrat", sans-serif;
}

.G-zr .bid-H .table tr:nth-child(odd) {
  background: #f1f1f1;
}

.G-zr .side-Dash ul {
  list-style-type: none;
  background: #fff;
  box-shadow: 0px 4px 15px 0px #0000001f;
}

.G-zr .side-Dash ul li {
  color: #000000;
  padding: 14px 40px;
  font-size: 15px;
  font-weight: 500;
}

.G-zr .side-Dash ul li:hover,
.G-zr .side-Dash ul li:focus {
  cursor: pointer;
  background: #358539;
  color: #fff;
  transition: all 0.8s cubic-bezier(0.22, 0.61, 0.36, 1);
}

.G-zr .side-Dash ul li.active::after {
  position: absolute;
  content: "";
  background: white;
  width: 3px;
  height: 25px;
  top: 13px;
  left: 25px;
  border-radius: 100px;
}

.G-zr .side-Dash ul li.active {
  background: #358539;
  position: relative;
  color: #fff;
}

.G-zr .Dash-right .table tbody td {
  background: #fff;
}

.G-zr .Dash-right .table thead th {
  vertical-align: top;
  /* text-align: center; */
  border-bottom: 2px solid #E3E5E5;
  border-top: none;
  font-size: 14px;
  font-weight: 500;
  background: #fff;
  padding-bottom: 5px;
  padding-top: 5px;
  color: #919699;
}

.G-zr .Dash-right .table {
  border-collapse: separate;
  border-spacing: 0 5px;
}

.G-zr .Dash-right .table .tbody tr {
  background: #f3f3f3;
}

.G-zr .Dash-right .table tbody {
  background: #f3f3f3;
}

.G-zr .Dash-right .table tbody td {
  vertical-align: middle;
  border-top: none;
  height: 45px;
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  border-bottom: 2px solid #dbdbdb;
}

.G-zr .main-dash .da-sh img {
  /* width: 58px;
    height: 58px; */
  object-fit: contain;
}

.G-zr .main-dash a:hover {
  text-decoration: none;
}

.G-zr .main-dash .da-sh h4 {
  letter-spacing: 1px;
  color: #000;
}

input.form-control.D-input:focus,
textarea.form-control.D-input:focus,
.G-zr .progress-form select:focus {
  border: 1px solid #000;
}

.G-zr .main-dash .da-sh h4::after {
  content: "";
  position: absolute;
  background: #358539;
  right: 0;
  bottom: -5px;
  width: 34px;
  border-radius: 100px;
  height: 2px;
}

.G-zr .main-dash .da-sh .notifi {
  position: absolute;
  top: 10px;
  right: 10px;
  background: #e6521a;
  font-size: 11px;
  width: 66px;
  height: 27px;
  border-radius: 100px;
  text-align: center;
  color: #fff;
  padding: 7px;
}

.G-zr .main-dash .da-sh {
  background: #fff;
  display: flex;
  flex-flow: column;
  align-items: center;
  width: 260px;
  height: 240px;
  justify-content: stretch;
  border-radius: 10px;
  box-shadow: 0px 0px 10px 0px #00000014;
  margin-bottom: 60px;
  border: 1px solid #358539;
}

.G-zr .main-dash .da-sh.bg-none {
  background: transparent;
  box-shadow: none;
}

.G-zr .Dash-right .pro-file img {
  width: 120px;
  height: 120px;
  object-fit: cover;
  margin: auto;
  border-radius: 100px;
}

.G-zr .Dash-right .pro-file .up-load {
  width: 100%;
  text-align: center;
}

.G-zr .custom-control-input:checked ~ .custom-control-label::before {
  border-color: #358539;
  background-color: #358539;
}

.G-zr .custom-switch .custom-control-label::before {
  left: 100%;
  margin-left: 2em;
}

.G-zr .custom-switch .custom-control-label::after {
  left: calc(100% + 25%);
}

.G-zr .custom-switch {
  padding-left: 0.25rem;
}

.G-zr .breadcrumb-item.active {
  color: #000000;
  padding-left: 30px;
}

/* 09/10 s-h */
.G-zr .Dash-right .nav-tabs .nav-item.show .nav-link,
.G-zr .Dash-right .nav-tabs .nav-link.active {
  color: #ffffff;
  background-color: #358539;
  border: 1px solid #358539;
}

.G-zr .Dash-right .nav-tabs {
  border-bottom: 1px solid #358539;
}

.G-zr .Dash-right .nav-tabs .nav-item {
  margin-bottom: 0px;
}

/* .G-zr .Dash-right .nav-tabs .nav-link {
    border: 1px solid #F3F3F3;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    background: #F3F3F3;
    width: 165px;
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    color: #358539;
} */

.G-zr .dash-banner-h {
  height: 331px;
  min-height: 331px;
  object-fit: cover;
}

.G-zr .dash-banner {
  height: 232px;
  min-height: 232px;
  object-fit: cover;
  border-radius: 8px;
}

.G-zr .seller-profile img {
  width: 200px;
  height: 200px;
  object-fit: cover;
  border-radius: 100%;
  border: 8px solid #fff;
}

.G-zr .seller-profile figure {
  top: -100px;
  overflow: hidden;
}

.G-zr .Dash-right .Farms-card .farms-body ul {
  letter-spacing: 6px;
}

.G-zr .seller-profile ul,
.G-zr .Dash-right .Farms-card .farms-body ul,
.G-zr .feed-card .feed-body ul,
.G-zr .feed-card ul,
.G-zr .rating-pop ul {
  list-style-type: none;
}

.G-zr .seller-profile ul li,
.G-zr .feed-card .feed-body ul li {
  margin: 0 5px;
}

.G-zr .rating-pop ul li {
  margin-right: 25px;
}

.G-zr .feed-card ul li {
  margin: 0 3px;
}

.G-zr .seller-profile .vw {
  color: #aca8a8;
}

.G-zr .progress-form.rating .D-input {
  height: 95px !important;
  overflow: hidden;
  resize: none;
}

.G-zr .rating-pop ul li .fa-star:before,
.G-zr .rating-pop ul li .fa-star-half-alt:before,
.G-zr .seller-profile ul li .fa-star:before,
.G-zr .seller-profile ul li .fa-star-half-alt:before,
.G-zr .feed-card .feed-body ul li .fa-star:before,
.G-zr .feed-card .feed-body ul li .fa-star-half-alt:before,
.G-zr .Dash-right .Farms-card .farms-body ul li .fa-star-half-alt:before,
.G-zr .Dash-right .Farms-card .farms-body ul li .fa-star:before {
  color: #fecb25;
  font-size: 20px;
}

.G-zr .Dash-right .Farms-card .farms-body ul li .fa-star-half-alt:before,
.G-zr .Dash-right .Farms-card .farms-body ul li .fa-star:before {
  font-size: 16px;
}

.G-zr .feed-card ul li .fa-star:before,
.G-zr .feed-card ul li .fa-star-half-alt:before {
  color: #fecb25;
  font-size: 14px;
}

.G-zr .seller-profile ul li .far:before,
.G-zr .Dash-right .Farms-card .farms-body ul li .far:before {
  color: #bcbcbc;
}

.G-zr .seller-product-vw .carosal-up-product .up-card {
  margin-right: 60px;
  margin-left: 0px;
  margin-bottom: 55px;
}

.G-zr .fav-bt,
.G-zr .mf-bt {
  /* width: 180px; */
  height: 40px;
  border-radius: 4px;
  border: 1px solid #358539;
  font-size: 15px;
  color: #358539;
  background: #fff;
  transition: 500ms;
}

.G-zr .fav-bt:hover,
.G-zr .mf-bt:hover {
  color: #fff;
  background: #358539;
}

.G-zr .fav-bt:focus,
.G-zr .mf-bt:focus {
  box-shadow: none;
}

.G-zr .m-bt {
  position: absolute;
  bottom: 15px;
  left: 20px;
}

.G-zr .profile-owl .owl-dots {
  display: flex;
  justify-content: center;
  align-items: center;
}

.G-zr .profile-owl .owl-dots button span {
  transition: 400ms;
}

/* .G-zr .seller-product-vw .carosal-up-product.sub-list .up-card:nth-child(3){  margin-right: 0px;}  */
/* 09/10 s-h end */

/* 11/10 */
/* The container */
.G-zr .G-radio .conta {
  display: block;
  position: relative;
  padding-left: 30px;
  margin-right: 30px;
  cursor: pointer;
  line-height: 20px;
  margin-bottom: 0;
  font-weight: 500;
  font-size: 16px;
  -webkit-user-select: none;
  user-select: none;
}

/* Hide the browser's default radio button */
.G-zr .G-radio .conta input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.G-zr .conta:hover .checkmark {
  background: #fff !important;
  border-color: #358539 !important;
}

/* Create a custom radio button */
.G-zr .G-radio .checkmark {
  position: absolute;
  top: 0.5em;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #fff;
  border: 2px solid #000;
  border-radius: 50%;
}

/* On mouse-over, add a grey background color */
.G-zr .G-radio .conta:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the radio button is checked, add a blue background */
.G-zr .G-radio .conta input:checked ~ .checkmark {
  background-color: #358539;
  border: 2px solid #358539;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.G-zr .G-radio .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.G-zr .G-radio .conta input:checked ~ .checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.G-zr .G-radio .conta .checkmark:after {
  top: 3px;
  left: 3px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: white;
}

/* 11/10 end */

.G-zr .feed-card .feed-img img {
  width: 75px;
  height: 75px;
  object-fit: cover;
  border-radius: 100px;
}

.G-zr .feed-card .feed-img {
  width: 50%;
}

.G-zr .feed-card .feed-body {
  width: 50%;
  display: flex;
  flex-flow: column;
  justify-content: start;
}
.G-zr .prd-feed-card.feed-card .feed-img {
  width: 20%;
}
.G-zr .prd-feed-card.feed-card .feed-body {
  width: 80%;
}

.G-zr .feed-card {
  background: #fff;
  border: 1px solid #dedede;
  padding: 23px;
}

.G-zr header {
  z-index: 999;
}

.G-zr .carosal-up-product .owl-carousel .owl-item .ovy-lay img {
  display: unset;
}

.G-zr .carosal-up-product .owl-carousel.owl-drag .owl-item {
  margin-right: 0px !important;
}

.G-zr .bg-Dgray .caro-a img {
  height: 331px;
  min-height: 331px;
  object-fit: cover;
  object-position: center;
  border-radius: 10px;
}

/* .G-zr .bg-Dgray .caro-b:after {
    content: '';
    position: absolute;
    background: url(/images/video-pop.svg) no-repeat;
    left: 0;
    right: 0;
    margin: auto;
    top: 0px;
    z-index: 9999;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-position: center;
    cursor: pointer;
} */

.G-zr .bg-Dgray .caro-b img {
  height: 331px;
  min-height: 331px;
  object-fit: cover;
  object-position: center;
  border-radius: 10px;
}

.G-zr .bg-Dgray .owl-carousel .owl-dots .owl-dot:hover {
  transition: all 1s ease-in;
}

.G-zr .bg-Dgray .owl-carousel .owl-dots .owl-dot:focus {
  outline: 0;
}

.G-zr .bg-Dgray .owl-carousel .owl-dots.disabled {
  display: block;
}

.G-zr .bg-Dgray .owl-theme .owl-dots .owl-dot {
  margin: 0;
}

.G-zr .bg-Dgray .owl-theme .owl-dots .owl-dot.active span,
.G-zr .bg-Dgray .owl-theme .owl-dots .owl-dot:hover span {
  background: #358539;
  width: 20px;
  height: 20px;
  margin: 4px 8px;
}

.G-zr .dash-D {
  padding: 10px 30px;
  padding-right: 120px;
}

.G-zr .dash-D::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  background: #358539;
  width: 9px;
  height: 150px;
  border-radius: 100px;
}

.G-zr .pro-view-details p {
  /* text-overflow: ellipsis;
    overflow: auto;
    height: 75px; */
  text-overflow: ellipsis;
  /* overflow-x: hidden; */
  height: auto;
  max-height: 5em;
  overflow: auto;
  overflow-x: hidden;
  overflow-y: auto;
  word-break: break-all;
  /* border-bottom: 1px solid #358539; */
}

.G-zr .feed-card .feed-img.feed-b img {
  width: 85px;
  height: 85px;
}

.G-zr .check-left address {
  border: 1px solid #ececec;
  line-height: 25px;
}

.G-zr .cart-item img {
  width: 140px;
  height: 100px;
  object-fit: cover;
}

.G-zr .cart-body {
  width: 80%;
}

.G-zr .check-right {
  width: 440px;
}

.G-zr .check-right .progress-form {
  border-radius: 10px;
  box-shadow: 0px 0px 8px 0px #00000017;
  padding-top: 30px !important;
}

.G-zr .place-order h6 {
  padding: 0 22%;
  line-height: 25px;
}

.G-zr .track-bg h4 {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 300px;
  font-size: 25px;
}

.G-zr .track-bg.comutity-bg img {
  height: 350px;
  min-height: 350px;
  object-fit: cover;
  object-position: center;
  width: 100%;
}

.G-zr .track-bg.comutity-bg.sellercomunity-bg {
  background: url("/images/seller-comunity.png") no-repeat;
  background-size: cover;
  background-position: center;
}

.G-zr .track-bg.comutity-bg:after {
  content: "";
  background: #0000001a;
  top: 0;
  bottom: 0;
  width: 100%;
  max-width: 100%;
  height: 100%;
  position: absolute;
  z-index: 0;
}

.G-zr .track-bg.comutity-bg h4 {
  height: 450px;
  min-height: 450px;
}

.G-zr .track-bg {
  /* background: url("/images/tracking-bg.png")no-repeat; */
  background-size: cover;
  background-position: center;
  height: 330px;
  min-height: 330px;
  width: 75%;
  margin-top: 0;
  overflow: hidden;
}

.G-zr .chk-product img {
  height: 103px;
  min-height: 103px;
  width: 113px;
  object-fit: cover;
  max-width: 113px;
}

.G-zr .trk-ststus {
  top: 237px;
  z-index: 9;
}

.G-zr .noti-badge .badge {
  position: absolute;
  background: #e6521a;
  border-radius: 100px;
  color: #fff;
  padding: 3px 7px;
  font-weight: 400;
  font-size: 12px;
  top: -2px;
  right: 8px;
}

.G-zr .comunity-tabs ul .nav-link.active,
.G-zr .comunity-tabs ul .nav-link:hover {
  color: #358539 !important;
  background-color: #fff;
  border: 5px solid #358539;
  border-bottom: 0;
  border-top-left-radius: 9px;
  border-top-right-radius: 9px;
}

.G-zr .comunity-tabs ul .nav-link {
  color: #fff;
  font-size: 18px;
  font-weight: 500;
  width: 202px;
  padding: 14px;
  text-align: center;
}

.G-zr .comunity-tabs ul.nav-tabs {
  border: none;
  background: #358539;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  height: 60px;
}

.G-zr .comunity-tabs {
  margin-top: -60px;
  z-index: 9;
  position: relative;
}

.G-zr .w-90p {
  width: 90%;
}

.G-zr .w-10p {
  width: 10%;
}

.G-zr .w-10 {
  width: 10px;
}

.G-zr .w-20 {
  width: 20px;
}

.G-zr .drop-dn {
  width: 40px;
  height: 40px;
  display: flex;
  border-radius: 100px;
  background: #ebebeb;
  box-shadow: 0px 0px 8px 0px #0000003b;
  justify-content: center;
  align-items: center;
}

.G-zr .grid-img img {
  width: 184px;
  height: 184px;
  object-fit: cover;
  margin-bottom: 35px;
}

.G-zr .discuss-sec .card h6 {
  font-size: 10px;
}

.G-zr .discuss-sec .card h4 {
  margin: 0;
}

.G-zr .discuss-sec .card .grp-img img {
  width: 44px;
  height: 44px;
  border-radius: 100px;
  border: 4px solid #fff;
  margin-left: -15px;
}

.G-zr .discuss-sec .card .active-noti {
  background: #19be20;
  border: 2px solid #19be20;
  border-radius: 100px;
  padding: 3px 12px;
  font-size: 10px;
  font-weight: 500;
  color: #fff;
  margin-left: 10px;
}

.G-zr .discuss-sec .card p {
  line-height: 24px;
}

.G-zr .discuss-sec .card {
  border: none;
  box-shadow: 0px 3px 10px 3px #0000000d;
  border-radius: 10px;
}

.G-zr .discus-tab .w-10 {
  width: 10%;
}

.G-zr .discus-tab .tab-span {
  background: #fff;
  padding: 25px 20px;
  float: left;
  width: 100%;
  border-radius: 4px;
  margin-bottom: 15px;
}

.G-zr .discus-tab img[alt="user-profile"] {
  width: 46px;
  height: 46px;
  object-fit: cover;
  object-position: center;
}

.G-zr .discus-tab .w-90 {
  width: 90%;
}

.G-zr .user-bdy h6:nth-child(1) {
  border: 1px solid #dedede;
  padding: 10px 22px;
  border-radius: 100px;
}

.G-zr .user-bdy h6 {
  /* margin-right: 35px !important; */
  font-weight: 400;
}

.G-zr .Community-div {
  background: var(--gray);
}

.G-zr .comunity-sch span {
  background: #358539;
  border-top-right-radius: 4px !important;
  border-bottom-right-radius: 4px !important;
  border: none;
}

.G-zr .comunity-sch input:focus {
  box-shadow: none;
}

.G-zr .comunity-sch input {
  border: 1px solid #358539;
  height: 60px;
}

.G-zr .comunity-sch input::placeholder {
  font-size: 15px;
  font-style: italic;
  color: #358539;
  font-weight: 500;
}

.G-zr .track-left.track-table .progres-bar {
  height: auto;
}

.G-zr .track-left .progres-bar .step:nth-child(3) {
  height: 190px;
}

.G-zr .track-left.track-table .progres-bar .step:nth-child(3) {
  height: auto;
}

.G-zr .track-left .progres-bar .step.step-3 span:after {
  height: 153px;
  background-size: 5px 20px;
}

.G-zr .track-left.track-table .progres-bar .step span:after {
  width: 110px;
  height: 1px;
  left: 43px;
  top: 20px;
  background-image: linear-gradient(
    90deg,
    #dfdfdf,
    #b2b2b2 75%,
    transparent 75%,
    transparent 100%
  );
  background-size: 14px 10px;
  border: none;
}

.G-zr .table-pro img {
  width: 40px;
  height: 40px;
  border-radius: 5px;
  object-fit: cover;
}

.G-zr .btn-white {
  border: 1px solid #358539;
  border-radius: 4px;
}

/*AS*/
.bg-white-head {
  margin-top: 45px;
}

.wl-table-img {
  width: 50px;
  border-radius: 2px;
}

.items-select {
  margin-left: 15px;
  width: 100px;
}

.feedback-bt button {
  width: -webkit-max-content;
  width: max-content;
}

.fp-text {
  font-size: 15px;
  line-height: 24px;
}

.fp-box {
  margin-top: 100px;
}

.fp-form {
  margin-top: 45px;
}

.py-figure {
  padding-bottom: 35px;
}

.pl-upd-box label {
  width: 100%;
  height: 150px;
  border: 1px dashed #929292;
  background: #f8f8f8;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pl-upd-box span {
  width: 160px;
  font-size: 20px;
}

.pl-upd-box label i {
  color: #929292;
}

.pl-upd-box label h3 {
  font-size: 15px;
  font-weight: 500;
  color: #929292;
  margin: 0;
}

.pl-upd-box input {
  display: none;
}

.p-title {
  background: #f9f9f9;
  border-radius: 5px;
  padding: 15px;
  margin-bottom: 35px;
}

.p-title h3 {
  font-size: 16px;
  font-weight: 500;
  margin: 0;
}

.pl-edit a {
  text-decoration: none;
  font-weight: 500;
}

.pl-edit a span {
  padding-right: 10px;
}

.ap-loc-box {
  background: #f8f8f8;
  border-radius: 5px;
  padding: 15px;
}

.processor-list {
  padding: 15px 0;
}

.processor-list .con-ner {
  padding: 10px 35px 0 45px;
  margin-bottom: 5px;
}

.processor-list .checkmark {
  top: 24px;
  left: 10px;
}

.pv-pro-background {
  background: #f2fff3 !important;
}

.pl-bt button {
  font-size: 16px;
  height: 50px;
  width: 150px;
}

.up-card .up-card-body .sl-f_name {
  font-style: italic;
  font-size: 16px;
  font-weight: 500;
  margin: 0;
  color: #000;
}

.up-card .up-card-body .sl-price {
  font-size: 20px;
  font-weight: 600;
  color: #358539;
  margin: 0;
}

.up-card .up-card-body .sl-desc {
  font-size: 15px;
  font-weight: 300;
  line-height: 22px;
  margin: 0;
  color: #000;
}

.up-card .up-card-body .sl-card-top {
  padding-bottom: 15px;
  padding-top: 10px;
}

.up-card .sp-tags {
  position: absolute;
  bottom: 10px;
  left: 10px;
  max-width: 170px;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.up-card .prod-tag {
  color: #fff;
  background: #000000a6;
  padding: 5px 24px;
  border-radius: 20px;
  font-size: 16px;
  font-weight: 500;
  font-style: italic;
}

.up-card .cut-icon {
  background: #000000a6;
  padding: 5px 24px;
  border-radius: 20px;
  max-width: 2em;
  max-height: 2em;
}

.up-card .cut-text {
  width: 180px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.up-card .cut-text span {
  width: -webkit-max-content;
  width: max-content;
  background: #000000a6;
  border-radius: 25px;
  font-size: 13px;
  font-weight: 500;
  font-style: italic;
  padding: 6px 15px;
  color: #fff;
  position: absolute;
  left: -200px;
  transition: all 0.5s ease-in-out;
}

.G-zr .carosal-up-product.search-l .up-card:hover .cut-text span,
.seller-product-vw .up-card:hover .cut-text span {
  left: 5px !important;
  bottom: 0;
  margin: auto;
  height: 31px;
}

.search-fil p {
  margin: 0;
  font-size: 16px;
  font-weight: 500;
  padding-right: 20px;
  color: #000;
}

.search-fil .fil-ss {
  background: #f6f6f6;
  color: #929292;
  border: 1px solid #cecece;
  border-radius: 16px;
  padding: 5px 15px;
  font-size: 15px;
  margin-right: 15px;
  cursor: pointer;
}

.search-fil .fil-ss.active {
  background: #362f2c;
  color: #fff;
  border: 1px solid #362f2c;
  border-radius: 16px;
  padding: 5px 15px;
  font-size: 15px;
}

/*SORT*/
.search-fil .search-sort {
  width: 460px;
  font-size: 14px;
}

.search-fil .search-sort p {
  margin: 0;
  font-size: 16px;
  font-weight: 500;
  padding-right: 18px;
  color: #000;
}

.search-fil .search-sort span {
  font-weight: 600;
}

.search-fil .search-sort a {
  color: #000;
  border-bottom: 3px solid #fff;
  text-decoration: none;
  margin-right: 20px;
}

.search-fil .search-sort a:last-child {
  margin-right: 0px;
}

.search-fil .search-sort a.active {
  color: #358539;
  border-bottom: 3px solid #358539;
}

.search-fil .search-sort a:hover {
  color: #358539;
  cursor: pointer;
  border-bottom: 3px solid #358539;
}

.G-zr .quick-search .search-form .input-group {
  border: 1px solid #e5e5e5;
}

/*SORT END*/
.b_pro-proc {
  /* background: #f3f3f3; */
  margin-bottom: 35px;
}

.b_pro-proc h3 {
  font-size: 18px;
  font-weight: 600;
  margin: 0;
}

.b_pro-proc a {
  padding: 8px 20px !important;
  /* height: 35px; */
  background: #362f2c;
  color: #fff;
}

/*AS END*/

/* sh */
.G-zr .navbar-expand-lg .navbar-nav .nav-item .dropdown-menu .dropdown-item {
  font-size: 12px;
  font-family: "Montserrat", sans-serif;
}

.G-zr
  .navbar-expand-lg
  .navbar-nav
  .nav-item
  .dropdown-menu
  .dropdown-item:hover {
  color: #ffffff;
  background-color: #358539;
  transition: all 0.3s ease-in;
}

.G-zr
  .navbar-expand-lg
  .navbar-nav
  .nav-item
  .dropdown-menu
  .dropdown-item
  svg {
  margin-right: 10px;
  width: 20px;
  height: 20px;
}

.G-zr .navbar-expand-lg .navbar-nav .nav-item .dropdown-menu {
  right: 0;
  left: unset;
  padding: 0;
  top: 64px;
  line-height: 35px;
  border: none;
  box-shadow: 0px 0px 9px 0px #00000012;
}

.G-zr .Dash-right .Farms-card figure {
  height: 145px;
}

.G-zr .Dash-right .Farms-card img {
  height: 145px;
  object-fit: cover;
  object-position: center;
}

.G-zr .Dash-right .Farms-card .farms-body {
  margin-top: 67px;
}

.G-zr .Dash-right .Farms-card {
  height: 330px;
  min-height: 330px;
  width: 248px;
  max-width: 248px;
  box-shadow: 0px 0px 15px 0px #0000001a;
  margin: 5px;
  border-radius: 5px;
  overflow: hidden;
  margin-right: 50px;
  margin-bottom: 40px;
}

.G-zr .Dash-right .Farms-card .farms-user {
  top: 93px;
  left: 0;
  right: 0;
  text-align: center;
}

.G-zr .Dash-right .Farms-card .farms-user img {
  width: 110px;
  height: 110px;
  margin: auto !important;
  object-fit: cover;
  border: 5px solid #ffe;
  border-radius: 50%;
}

.G-zr .approved-user {
  margin: 5px auto;
}

.G-zr .approved-user img {
  width: 35px;
  height: 35;
  object-fit: cover;
  object-position: center;
  border-radius: 100px;
}

.G-zr .main-dash.seller-D .dash-D {
  padding-right: 0;
}

.G-zr.top-h {
  margin-top: 6em;
}

.G-zr .main-dash.seller-D .da-sh,
.G-zr .main-dash.buyer-D .da-sh {
  width: 230px;
  height: auto;
  padding: 45px 0;
}

.G-zr .btn-gradien {
  background: linear-gradient(45deg, #41933b, #1ab97a);
  border-radius: 100px;
  padding: 8px 24px;
  color: #fff;
  font-size: 12px;
  font-weight: 500;
}

.G-zr .dot-S::before {
  content: "";
  background: #358539;
  left: -21px;
  top: 5px;
  width: 9px;
  height: 9px;
  border-radius: 100px;
  position: absolute;
  box-shadow: 0px 0px 1px 3px #35853959;
}

/* 1/11 */
.flaticon-close-button:before {
  font-size: 16px;
}

/* 2/11 */
.G-zr .h-flower {
  height: 354px;
  border-radius: 4px;
  object-fit: cover;
  object-position: center;
}

.G-zr .comunity-members img {
  object-fit: cover;
  object-position: center;
  height: 175px;
  min-height: 175px;
  width: 100%;
}

.G-zr .comunity-members figure {
  border-radius: 5px;
  border: 1px solid #dbdbdb;
}

.G-zr .member .comunity-members {
  margin-right: 0;
}

.G-zr .comunity-members {
  width: 168px;
  min-width: 168px;
  overflow: hidden;
  margin-bottom: 25px;
  margin-right: 37px;
}

.G-zr .overlay-post {
  position: absolute;
  bottom: 5px;
  right: 5px;
  border-radius: 100px;
  background: #fff;
  color: green;
  font-weight: 500;
  font-size: 10px;
  padding: 6px 14px;
}

.G-zr .overlay-post.gallery.no-file {
  background: #e1e1e1;
  color: #acacac;
}

.G-zr .overlay-post.gallery {
  position: absolute;
  bottom: -2px;
  right: -2px;
  border-radius: 0;
  background: #0000001f;
  color: #cbcbcb;
  font-size: 12px;
  font-weight: 600;
  padding: 8px 14px;
}

/* 04/11 */
/* message */
.G-zr .msg-head img {
  min-width: 44px;
  width: 44px;
  object-fit: cover;
  object-position: center;
}

.G-zr .msg-head p {
  color: #e4e4e4;
}

.G-zr .msg-head .w-8p {
  width: 60px;
}

.G-zr .msg-head .w-92p {
  width: 92%;
}

.G-zr .msg-head .dropdown {
  cursor: pointer;
}

.G-zr .msg-head {
  background: #362f2c;
  padding: 10px 20px;
}

.G-zr .msg-body {
  min-height: 250px;
  height: auto;
  background: #fff;
  border-top: 10px solid #f1f1f1;
  border-bottom: 10px solid #f1f1f1;
  padding: 30px 20px 20px 20px;
  overflow: hidden;
  background-image: url("/images/msg-bg.png");
  background-size: cover;
  background-repeat: no-repeat;
}

.G-zr .msg-body .reciver-msg h6,
.G-zr .msg-body .sender-msg h6 {
  background: #e8e8e8;
  border-radius: 40px;
  width: auto;
  min-width: auto;
  max-width: 50%;
  border-top-left-radius: 0;
  padding: 10px 20px 10px 30px;
  margin-right: 20px !important;
  word-break: break-word;
  line-height: 23px;
}

.G-zr .msg-body .reciver-msg p,
.G-zr .msg-body .sender-msg p {
  color: #bcbcbc;
  white-space: nowrap;
}

.G-zr .msg-body .reciver-msg,
.G-zr .msg-body .sender-msg {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  margin-bottom: 15px;
}

.G-zr .msg-body .sender-msg {
  margin-right: 0;
  flex-flow: row-reverse;
  width: 100%;
}

.G-zr .msg-body .sender-msg h6 {
  margin-right: 0 !important;
  margin-left: 20px !important;
  background: #57a15a;
  color: #fff;
  border-bottom-right-radius: 0;
  border-top-left-radius: 40px;
}

.G-zr .msg-body .date-divider:after,
.G-zr .msg-body .date-divider:before {
  content: "";
  position: absolute;
  width: 150px;
  height: 1px;
  top: 8px;
  background: #e1dede;
}

.G-zr .msg-body .date-divider:after {
  right: 23%;
}

.G-zr .msg-body .date-divider:before {
  left: 23%;
}

.G-zr .msg-fot input:focus {
  box-shadow: none;
}

.G-zr .msg-fot .input-group object {
  padding: 10px 25px;
}

.G-zr .msg-fot input {
  height: 50px;
  border: none;
  position: relative;
  font-style: italic;
  font-size: 14px;
}

.G-zr .msg-fot .input-group:after,
.G-zr .msg-fot .input-group:before {
  content: "";
  position: absolute;
  background: #b0d4b2;
  width: 1px;
  height: 30px;
  top: 9px;
  right: 70px;
  z-index: 9;
}

.G-zr .msg-fot .input-group:before {
  /* left: 70px; */
}

.G-zr .msg-fot .input-group {
  background: #fff;
  border: 1px solid #358539;
  height: 50px;
  overflow: hidden;
  border-radius: 2px;
}

.G-zr .msg-list figure:after {
  content: "";
  position: absolute;
  width: 10px;
  height: 10px;
  background: #bcbcbc;
  top: 0;
  right: 20px;
  border-radius: 100px;
  box-shadow: 0px 0px 0px 1px #fff;
}

.G-zr .msg-list figure {
  width: 20%;
  position: relative;
}

.G-zr .msg-list img {
  width: 44px;
  min-width: 44px;
  height: 44px;
  border-radius: 100px;
}

.G-zr .msg-list .list-bdy .unread {
  background: #19be20;
  border-radius: 100px;
  padding: 3px 3px;
  text-align: center;
  color: #fff;
  width: 35px;
  margin-left: auto;
}

.G-zr .msg-list .list-bdy span:nth-child(1) p {
  width: 100px;
  overflow: hidden;
}

.G-zr .msg-list .list-bdy {
  width: 80%;
}

.G-zr .msg-list .B-L.active figure:after {
  background: #57a15a;
}

.G-zr .msg-list .B-L {
  border: 2px solid #57a15a;
}

.G-zr .msg-list .P-L {
  border: 2px solid #1306d1;
}

.G-zr .msg-list .B-L.S-L {
  border: 2px solid #e66c25;
}

.G-zr .msg-list .B-L.active,
.G-zr .msg-list .B-L:hover {
  background: #ccc;
}

.G-zr .msg-list .B-L {
  cursor: pointer;
  background: white;
  padding: 10px 5px;
  border-radius: 5px;
  margin-bottom: 10px;
  height: 70px;
  min-height: 70px;
}

.G-zr .msg-list {
  background: #eeeeee;
  padding: 10px;
  /*
    min-height: 423px;
    overflow: auto;
    height: 423px;
*/
}

.G-zr .msg-list .nav-tabs .nav-link {
  max-width: 170px;
  width: 100%;
  background: transparent;
  border: none;
  border-radius: 5px;
  border-bottom: 5px solid #d9d9d9;
}

.G-zr .msg-list .nav-tabs .nav-link.active {
  font-size: 16px;
  border: none;
  background: transparent;
  color: #358539;
  border-bottom: 5px solid #3b943f;
  border-radius: 5px;
}

.G-zr .msg-list .nav-tabs {
  margin-bottom: 20px;
  border: none;
}

.G-zr .msg-list .sel-msg-tabs .sm-tab-msg {
  overflow: auto;
  height: 400px;
}

.G-zr .msg-list .sel-msg-tabs .sm-tab-msg.stm {
  overflow: auto;
  height: 350px;
}

.sel-msg-tabs .sm-tab-msg {
  padding-right: 10px;
  width: 100%;
}

.sel-msg-tabs .sm-tab-msg::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: white;
  width: 5px;
}

.sel-msg-tabs .sm-tab-msg::-webkit-scrollbar {
  width: 5px;
  background-color: transparent;
  background-color: white;
}

.sel-msg-tabs .sm-tab-msg::-webkit-scrollbar-thumb {
  background-color: #358539;
  border-radius: 50px;
}

.con-ner.sel-msg-bc {
  padding-left: 15px;
  font-size: 15px;
  letter-spacing: 0.5px;
}

.con-ner.sel-msg-bc span {
  right: 15px;
  left: inherit;
}

.con-ner.sl-bc-msg {
  padding-left: 0;
}

.con-ner.sl-bc-msg .checkmark {
  right: 15px;
  left: inherit;
  top: 20px;
}

.sl-bc-msg {
  background: #fff;
}

.con-ner.sl-bc-msg .B-L {
  background: transparent;
}

.sl-bc-msg-bg {
  background: #f2fff3 !important;
}

.msg-list .bb-btn button {
  background: #362f2c;
  color: #fff;
  border-radius: 4px;
  width: 100%;
  height: 50px;
  font-size: 16px;
}

/* message end */
.G-zr .anoun ul li {
  position: relative;
}

.G-zr .anoun ul li:after {
  position: absolute;
  content: "";
  background: #358539;
  width: 6px;
  height: 6px;
  left: -18px;
  top: 6px;
  border-radius: 100px;
  box-shadow: 0px 0px 0px 4px #35853969;
}

.G-zr .anoun ul {
  list-style-type: none;
}

.G-zr .anoun {
  background: #fff;
  box-shadow: 0px 2px 6px 0px #00000014;
}

.G-zr .anoun-hd {
  border-bottom: 1px solid #e5e5e5;
  height: 50px;
}

.G-zr .start-dis {
  border: 1px dashed #bbbbbb;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 26px;
  cursor: pointer;
}

.G-zr .Dash-right .msg-body {
  background: #eee;
  border-top: 10px solid #fff;
  background-image: url(/images/msg-bg.png);
  border-bottom: 10px solid #fff;
}

.G-zr .Dash-right .msg-head .w-92p {
  width: 88%;
}

.G-zr .Dash-right .msg-body .reciver-msg h6,
.G-zr .Dash-right .msg-body .sender-msg h6 {
  background: #ffffff;
}

.G-zr .Dash-right .msg-body .sender-msg h6 {
  background: #57a15a;
}

.G-zr .Dash-right .msg-body .date-divider:after {
  right: 15%;
}

.G-zr .Dash-right .msg-body .date-divider:before {
  left: 15%;
}

.G-zr .Dash-right .msg-list-head .comunity-sch .input-group input {
  border: none;
  height: 62px;
}

.G-zr .Dash-right .msg-list-head .comunity-sch .input-group input::placeholder {
  font-size: 13px;
}

.G-zr
  .Dash-right
  .msg-list-head
  .comunity-sch
  .input-group-prepend
  span.input-group-text {
  background: none !important;
}

.G-zr .Dash-right .msg-list-head .comunity-sch {
  box-shadow: 0px 2px 6px 0px #0000002e;
  height: 62px;
  margin-bottom: 10px;
}

.G-zr #style-7::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
  border-radius: 10px;
}

.G-zr #style-7::-webkit-scrollbar {
  width: 6px;
  background-color: #f5f5f5;
}

.G-zr #style-7::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-image: -webkit-gradient(
    linear,
    left bottom,
    left top,
    color-stop(0.44, #57a15a),
    color-stop(0.72, #57a15a),
    color-stop(0.86, #57b15a)
  );
}

/* 07/11/19 */
.G-zr .bdy-flx {
  flex: 1 1 50%;
}

.G-zr .filter-right .progress-form select {
  padding-left: 10px;
  padding-right: 35px;
  font-weight: 600;
}

.G-zr .filter-right .progress-form .select-wrapper:after {
  color: #358539;
}

.G-zr .comunity-card span {
  padding: 15px 25px;
  width: 375px;
  height: 345px;
  text-align: center;
  box-shadow: 0px 2px 12px 0px #00000026;
  border-radius: 10px;
}

.G-zr .comunity-card span em {
  text-overflow: ellipsis;
  overflow: hidden;
  height: 65px;
  white-space: pre-wrap;
  display: block;
}

.G-zr .comunity-card span .span-fot {
  background: #e8f8e9;
  padding: 11px 10px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.G-zr .sch-cmunity .comunity-card span {
  width: 330px;
}

.G-zr .Dash-right .quick-search .search-form .input-group {
  width: 79%;
}

.G-zr .Dash-right .quick-search {
  background: #ffffff;
  box-shadow: 0px 3px 10px 0px #00000017;
  border-radius: 6px;
}

.G-zr .comunity-card .add-user {
  width: 44px;
  height: 44px;
  box-shadow: 0px 4px 8px 2px #0000001f;
  border-radius: 100px;
  position: absolute;
  right: 15px;
  top: 15px;
}

.G-zr .place-odr span {
  flex: 1 1 50%;
}

.G-zr .order-bg {
  display: flex;
  justify-content: center;
  flex-flow: column;
  padding-left: 50px;
  padding-right: 9%;
  color: #fff;
}

.G-zr .order-bg h4 {
  font-size: 32px;
}

.G-zr .order-bg p {
  padding-top: 10px;
  padding-bottom: 15px;
}

.G-zr .order-bg .btn {
  width: 190px;
}

.G-zr .order-right-bg {
  background-image: url("/images/brown-bg.png") !important;
}

.G-zr .order-bg,
.G-zr .order-right-bg {
  background-image: url("/images/green-bg.png");
  background-size: cover;
  background-position: center;
  height: 560px;
}

.G-zr .place-odr {
  margin-top: 78px;
}

.G-zr .order-right-bg figure img {
  height: 368px;
  object-fit: cover;
}

.G-zr .order-right-bg figure:after {
  content: "";
  background: #0000001a;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 0;
  left: 0;
}

.G-zr .new-discustion .discustion-left textarea {
  color: #929292;
  border: 1px solid #cccccc;
  font-size: 15px;
}

.G-zr .new-discustion .discustion-left {
  width: 64%;
  background: #fff;
  padding: 2em 4em 2em 4em;
}

.G-zr .new-discustion .discustion-right {
  width: 34%;
  background: #fff;
  padding: 4em 4em 2em 2em;
}

.G-zr .new-discustion {
  display: flex;
  justify-content: space-between;
}

.G-zr .comunity-tabs .dropdown-menu.show .dropdown-item:hover {
  color: #362f2c;
  background: #e3e3e3;
}

.G-zr .comunity-tabs .dropdown-menu.show .dropdown-item {
  padding: 0.5rem 1rem;
}

.G-zr .comunity-tabs .dropdown-menu.show {
  left: auto !important;
  transform: none !important;
  right: 0;
  border: 1px solid #358539;
  border-radius: 0px;
  padding: 0;
  top: 43 !important;
}

.G-zr .user-bdy h6.active:nth-child(1) {
  border: 1px solid #dafbdb;
  padding: 10px 22px;
  border-radius: 100px;
  background: #f5fff5;
  color: #358539;
  font-weight: 500;
}

.G-zr .user-bdy p {
  height: 40px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.G-zr .user-comments img {
  width: 40px;
  height: 40px;
  border-radius: 100px;
  margin-right: 15px;
}

.G-zr .user-comments .D-input,
.G-zr .user-comments .btn-green {
  border-radius: 100px;
}

.G-zr .user-comments .D-input::placeholder {
  color: #000;
}

.G-zr .user-comments {
  border-top: 1px solid #e5e5e5;
  margin-top: 20px;
  display: flex;
  margin-left: 30px;
}

.G-zr .cmt-list img {
  width: 31px;
  height: 31px;
  object-fit: cover;
  border-radius: 100px;
}

.G-zr .list-bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.G-zr .cmt-list {
  padding-top: 25px;
  margin-left: 30px;
  padding-bottom: 25px;
  border-bottom: 7px solid #f1f1f1;
}

.G-zr .list-bottom h6 {
  color: #929292;
}

.G-zr .A-B::after,
.G-zr .A-B::before {
  content: "";
  position: absolute;
  background: #d2d2d2;
  width: 42%;
  height: 1px;
  right: 0;
  top: 10px;
}

.G-zr .A-B::before {
  left: 0;
  right: unset;
}

.G-zr .photos-fils img {
  width: 76px;
  height: 70px;
  border-radius: 6px;
}

.G-zr .mesage .A-B::before,
.G-zr .mesage .A-B::after {
  width: 33%;
  top: 5px;
}

.G-zr .inner-tabs ul li a.active:after,
.G-zr .inner-tabs ul li a:hover:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 3px;
  border-radius: 100px;
  background: #358539;
  color: #358539;
  bottom: -6px;
  left: 0;
  right: 0;
}

.G-zr .inner-tabs ul li a.active,
.G-zr .inner-tabs ul li a:hover {
  color: #358539;
}

.G-zr .inner-tabs ul li a {
  text-decoration: none;
  position: relative;
  font-size: 16px;
  font-weight: 500;
  color: black;
  margin-right: 30px;
}

.G-zr #Path_3443,
#Path_3446,
#Path_3445,
#Path_3448,
#Path_3447,
#Path_3450,
#Path_3441 {
  opacity: 1;
  animation: fade 2s infinite;
}

#Group_9238,
#Group_9239,
#cow {
}

@keyframes fade {
  0%,
  100% {
    opacity: 0;
    fill: rgba(59, 147, 63, 0.66);
  }

  50% {
    opacity: 1;
    fill: rgba(139, 235, 143, 0.66);
  }
}

/*INVOICE*/
.Grzr-mod-inv {
  max-width: 950px;
  margin: 0 auto;
}

.invoice-mod {
  padding: 60px 50px;
}

.im-cust-info,
.im-sell-info {
  max-width: 300px;
}

.im-sell-info {
  text-align: right;
  float: right;
}

.im-cust-info h2,
.im-sell-info h2 {
  font-family: "Raleway";
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 1px;
}

.im-cust-info h3,
.im-sell-info h3 {
  font-family: "Raleway";
  font-size: 18px;
  font-weight: 500;
  font-style: italic;
  letter-spacing: 1px;
}

.im-cust-info p,
.im-sell-info p {
  font-family: "Montserrat";
  font-size: 15px;
  font-style: italic;
  margin-bottom: 10px;
}

.im-invoice-status {
  margin-top: 25px;
  margin-bottom: 35px;
}

.im-invoice-status h3 {
  background: #f3fff4;
  color: #358539;
  font-size: 16px;
  font-weight: 500;
  text-transform: uppercase;
  padding: 20px 0;
  margin: 0;
  text-align: center;
  letter-spacing: 2px;
}

.invoice-mod .im-trans h3 {
  font-size: 18px;
  font-weight: 500;
}

.invoice-mod .im-lc {
  float: right;
  text-align: right;
}

.invoice-mod .im-trans p {
  font-size: 15px;
  font-weight: 400;
  font-family: "Montserrat";
  margin-bottom: 10px;
}

.invoice-mod .im-gen h3 {
  font-size: 18px;
  font-weight: 500;
}

.invoice-mod .im-gen p {
  font-size: 16px;
  font-weight: 500;
  font-style: italic;
  margin-bottom: 0px;
}

.im-generate,
.im-transaction {
  margin-top: 25px;
}

.im-date {
  color: #929292 !important;
  font-weight: 400 !important;
}

.im-btn {
  margin-top: 45px;
}

/*INVOICE END*/

/* calaender after */
.G-zr .progress-form .select-wrapper.orde-r:after {
  color: #000;
  font-weight: 600;
}

.G-zr .progress-form .select-wrapper.calender:after {
  width: 25px;
  height: 25px;
  content: "";
  font-weight: 400;
  display: inline-block;
  font-style: normal;
  font-feature-settings: normal;
  font-variant: normal;
  font-size: 14px;
  margin-left: 15px;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  position: absolute;
  right: 10px;
  background-image: url(/images/calendar.svg);
  top: 12px;
  color: #929292c2;
  pointer-events: none;
}

/* 25/11/19 */

.G-zr .F-select {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 720px;
  margin: auto;
  padding-top: 10px;
}

.G-zr .F-select span {
  width: 168px;
  height: 65px;
  cursor: pointer;
  border-radius: 10px;
  overflow: hidden;
}

.G-zr .F-select figure::before {
  background: #362f2c;
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  left: 0px;
  opacity: 0.4;
}

.G-zr .F-select figure h5 {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  z-index: 99;
  height: 100%;
  color: #fff;
  text-transform: uppercase;
  font-size: 20px;
  font-weight: 500;
}

.G-zr .F-select figure .cat-overlay {
  background: #362f2c;
  position: absolute;
  width: 100%;
  height: 100%;
  left: -180px;
  opacity: 0.5;
  transition: all 0.5s ease-in-out;
  margin: auto;
}

.G-zr .F-select figure:hover .cat-overlay,
.G-zr .F-select figure.active .cat-overlay {
  opacity: 1;
  left: 0px;
  top: 0;
  bottom: 0;
  margin: auto;
}

/* 18/12/19 */
.G-zr .main-dash .da-sh .ac-scrollbar h4::after {
  display: none;
}

.G-zr .main-dash .da-sh span.ent {
  background: #ffece5;
  border: 1px solid #fedbce;
  font-size: 11px;
  font-weight: 500;
  color: #e6521a;
  padding: 8px 15px;
  border-radius: 100px;
}

.G-zr .Dash-right .setup-calender h4,
.G-zr .Dash-right .agenta-calender h4 {
  font-size: 18px;
  font-weight: 400;
}

.G-zr .Dash-right .agenta-calender .table tbody td:nth-child(1) {
  width: 85px;
}

.G-zr .Dash-right .agenta-calender .table tbody td svg.feather-check {
  opacity: 0.2;
}

.G-zr .Dash-right .agenta-calender .table tbody td.active svg.feather-check,
.G-zr .Dash-right .agenta-calender .table tbody td.active {
  opacity: 1;
  stroke: #358539;
  background: #eeffee;
}

.G-zr .Dash-right .agenta-calender .table tbody td {
  padding: 0;
  background: #f6f6f6;
  border-left: 3px solid #fff;
  border-right: 3px solid #fff;
  border-top: 2px solid #fff;
  border-bottom: 3px solid #fff;
}

.G-zr .Dash-right .agenta-calender .table tbody td object {
  width: 100%;
  height: 85px;
  padding: 8px;
  border: 1px solid #f3f3f3;
}

.G-zr .Dash-right .agenta-calender .table {
  border-spacing: 0px 0px;
}

.G-zr .Dash-right .agenta-calender .table thead th:nth-child(1) {
  background: white;
}

.G-zr .Dash-right .agenta-calender .table thead th {
  width: 106px;
  background: #362f2c;
  padding: 6px 4px;
  border-left: 3px solid #fff;
  border-right: 3px solid #fff;
  color: #fff;
  font-weight: 400;
  font-size: 15px;
  border-bottom: none;
}

.G-zr .feather-info:hover + .noti {
  position: absolute;
  top: 35px;
  bottom: 0;
  right: 0px;
  width: 200px;
  height: 35px;
  display: block !important;
  background: #000;
  padding: 9px 15px;
  color: #fff;
  border-radius: 6px;
  font-size: 14px;
  font-weight: 500;
  z-index: 99;
}

.G-zr .F-select span {
  width: 168px;
  height: 65px;
  cursor: pointer;
  border-radius: 10px;
  overflow: hidden;
}

.G-zr .feather-info {
  fill: #343a40;
  position: relative;
  cursor: pointer;
  stroke: white;
}

.G-zr .F-select {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 720px;
  margin: auto;
  flex-wrap: wrap;
  padding-top: 10px;
}

/* 04/2020 */
.G-zr .Af-log.normal {
  background: #ffffff;
  z-index: 99999;
  transition: all 0.5s cubic-bezier(0.4, 0, 1, 1);
}

.G-zr .Af-log.normal .navbar-expand-lg .navbar-nav .nav-link {
  color: #131313;
}

.G-zr .Af-log.normal .navbar-expand-lg .navbar-nav .nav-link.btn {
  border: 1px solid #358539;
}

.G-zr .Af-log.normal .navbar {
  height: 80px;
}

.G-zr .Af-log.normal,
.G-zr .Af-log.normal.inner-Header {
  box-shadow: 0px 1px 0px 1px #358539;
}

.G-zr .search-form .scd-fx .btn {
  padding: 5px;
  margin: 2px 5px;
  width: 180px;
  height: 46px;
  line-height: 1;
}

.G-zr .imgWid {
  height: 6em !important;
  width: 6em !important;
  margin: 0.3em !important;
}

.G-zr .pro-view-slider .owl-carousel div.fst {
  width: 20%;
  margin: 0;
}

.G-zr .pro-view-slider .owl-carousel div.mainImgwid {
  width: 80%;
}

.modal-pop .modal-dialog.video {
  transform: translate(-0px, 35px);
}

.video-body video {
  height: 500px;
}

.pop-vdo {
  display: none;
}

.G-zr .msg-list .list-bdy span:nth-child(1) p {
  width: 100px;
  overflow: hidden;
}

/* 09/01/20 */
.toggle,
[id^="drop"] {
  display: none;
}

/* 09/01/20 */
.toggle,
[id^="drop"] {
  display: none;
}

.modal-rechange {
  display: flex;
  justify-content: center;
  padding: 35px 70px;
}

.modal-hdrecng {
  align-items: center;
  border-bottom: 2px solid #358539;
  margin-bottom: 20px;
  background: #358539;
  color: #fff;
  border-radius: 0;
}

.G-zr .msg-list .list-bdy span:nth-child(1) h5 {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100px;
}

/* 07/03/2020 */
.processor-hd h4 {
  width: 100%;
  word-break: break-word;
}

#select_proc .processor-list {
  padding: 15px 0;
  height: 500px;
  overflow: auto;
}

.fp-img-box object {
  max-width: 100%;
}

.cutSheetBuilderPopup .MuiAppBar-positionFixed {
  top: 80px;
  left: auto;
  right: 0;
  position: fixed;
}

.MuiDialog-root.cutSheetBuilderPopup.fullScreenPopup {
  position: fixed;
  z-index: 1300;
  inset: 0px;
  height: calc(100vh - 144px) !important;
  top: unset !important;
  bottom: 0 !important;
  /* padding-top: 82px !important; */
}

.react-form-builder .react-form-builder-toolbar {
  width: 260px !important;
  margin-top: unset !important;
}

.G-zr header.Af-log {
  z-index: 999999999;
}

.G-zr .modal {
  background-color: #00000052;
  z-index: 9999999999;
}

@media (max-width: 1199px) {
  .p-5 {
    padding: 15px !important;
  }

  .G-zr .snd-banner .enquty {
    padding: 15px;
    width: 100%;
  }
}

@media (max-width: 1024px) {
  .G-zr .Dash-right .msg-head .w-92p {
    width: 85%;
  }

  .G-zr .msg-list figure {
    width: 25%;
  }

  .G-zr .msg-list .list-bdy {
    width: 70%;
    text-align: left;
    align-items: flex-start !important;
  }

  .G-zr .pro-view-slider .content-carousel {
    width: auto;
  }

  .G-zr .pro-view-slider .owl-carousel div:nth-child(1) {
    width: 15%;
  }

  .search-form .d-flex {
    flex-flow: row;
    align-items: center;
  }

  .G-zr .search-form .input-group {
    margin-right: 0 !important;
  }

  .search-fil .search-sort {
    width: 440px;
    font-size: 14px;
  }

  .search-fil p {
    font-size: 15px;
  }

  .search-fil .fil-ss {
    padding: 2px 12px;
    font-size: 14px;
  }

  .G-zr .imgWid {
    height: 4em !important;
    width: 4em !important;
  }
}

@media (max-width: 991px) {
  .G-zr .Af-log .icon-bar {
    background-color: #131313 !important;
  }

  .G-zr .Af-log.normal .icon-bar {
    background-color: #131313 !important;
  }

  .G-zr .banner-sec.video-bg {
    margin-top: 9em;
  }

  .msg-res {
    flex-direction: column-reverse;
  }

  .G-zr .msg-list figure {
    width: 44px;
    position: relative;
  }

  .pop-vdo video {
    height: 300px;
  }

  .G-zr .Af-log.normal .navbar {
    height: 60px;
    padding: 1px;
  }

  .G-zr .Af-log {
    height: 62px;
  }

  .G-zr .msg-list figure:after {
    right: 0;
  }

  /* .G-zr .G-main {
        height: 600px;
        margin-top: 5em;
    } */
  .pop-vdo {
    display: block;
  }

  .d-nn {
    display: none;
  }

  .G-zr .G-right {
    padding: 0;
  }

  .G-zr .G-main {
    flex-flow: column;
  }

  .G-zr .search-form .input-group {
    margin-right: 10px !important;
  }

  .G-zr .btn-green {
    padding: 10px 20px;
  }

  .filter-right.search-fil {
    display: block;
    margin: auto;
  }

  .filter-right.search-fil .col {
    display: flex;
    align-items: center;
    justify-content: left !important;
    padding: 15px 20px;
    border: 1px solid #ececec;
    margin: 5px auto;
    width: 100%;
    overflow: auto;
    white-space: nowrap;
  }

  .search-form .d-flex {
    flex-flow: row;
    align-items: center;
  }

  .G-zr .L-curve .curve-D {
    bottom: 0;
    left: 0;
    border-radius: 0;
    width: 100%;
    top: 16em;
    padding: 0 35px;
  }

  .G-zr .L-curve img {
    display: none;
  }

  .G-zr .banner-sec.video-bg {
    background-image: url("/images/resbg.png");
    height: auto;
    min-height: auto;
  }

  .G-zr .G-left {
    width: 100%;
    padding-left: 30px;
    padding-right: 30px;
  }

  .G-zr .Af-log #navbarSupportedContent {
    position: relative;
  }

  .G-zr .Af-log #navbarSupportedContent ul.onres li {
    width: 100%;
    justify-content: end;
    flex-direction: column-reverse !important;
    align-items: start !important;
  }

  .G-zr
    .Af-log
    #navbarSupportedContent
    ul.onres
    li.mbnone.nav-item.d-flex.flex-row-reverse.align-items-center.user-nav.mobile-drodn-none {
    display: none !important;
  }

  .G-zr .Af-log #navbarSupportedContent ul.onres.add {
    right: 0% !important;
  }

  .G-zr .Af-log #navbarSupportedContent ul.onres {
    background: #262626;
    align-items: flex-start;
    width: 40%;
    margin-left: 0 !important;
    margin-top: 12px;
    position: fixed;
    right: -40%;
    height: calc(100vh - 62px);
    top: 62px;
    overflow-y: auto;
    padding: 5px 25px;
    transition: all 0.7s ease-in-out;
    margin: 0;
  }

  .G-zr .Af-log .navbar-expand-lg .navbar-nav .nav-link {
    color: #fff !important;
  }

  .G-zr .Af-log.normal .navbar-expand-lg .navbar-nav .nav-link,
  .G-zr .Af-log .navbar-expand-lg .navbar-nav .nav-link {
    padding: 10px 0;
  }

  .G-zr .Af-log.normal .navbar-expand-lg .navbar-nav .nav-link.btn,
  .G-zr .Af-log .navbar-expand-lg .navbar-nav .nav-link.btn {
    margin-top: 1em;
    border: 1px solid #358539;
  }

  .G-zr .msg-list .list-bdy {
    width: 85%;
  }
}

@media (max-width: 850px) {
  .G-zr .side-Dash ul li {
    padding: 14px 15px;
  }

  .G-zr .side-Dash ul li.active:after {
    left: 6px;
  }
}

@media (max-width: 800px) {
  .G-zr .feed-card {
    flex-flow: column;
    padding: 23px !important;
  }

  .G-zr .feed-card .feed-body,
  .G-zr .feed-card .feed-img,
  .G-zr .prd-feed-card.feed-card .feed-img {
    width: 100%;
  }
}

@media (max-width: 767px) {
  .search-form .sech-pag {
    width: 100%;
    max-width: 100%;
    flex: 0 0 100%;
  }
  .G-zr .msg-list {
    min-height: 168px;
    height: 168px;
    margin-bottom: 14px;
  }

  .G-zr .hiw-sec .list-works {
    flex-direction: column;
    padding: 20px 0 !important;
  }

  .tab-content {
    padding: 10px 15px;
  }

  .G-zr .main-dash.seller-D .ac-scrollbar .dot-S {
    max-width: 75%;
  }

  .G-zr .main-dash.seller-D {
    padding: 0 !important;
  }

  .pro-file {
    flex-direction: column;
    padding: 25px;
  }

  .G-zr .G-left h1 {
    font-size: 32px;
  }

  .G-zr .hiw-sec .nav-pills .nav-link {
    font-size: 15px;
    width: auto;
    padding: 5px;
  }

  .G-zr .G-left {
    width: 90%;
  }

  .G-zr .hiw-sec .list-works .card-list {
    padding: 0px 10px 10px 100px;
    position: relative;
    text-align: left !important;
    min-height: 85px;
  }

  .list-works .card-list figure {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    border: 1px solid #ccc;
    margin: auto !important;
    padding: 5px !important;
    height: 75px;
    border-radius: 50%;
    width: 75px;
    text-align: center;
  }

  .G-zr .hiw-sec .list-works .card-list img {
    width: 55px;
    height: 55px;
    object-fit: contain;
  }

  .G-zr .hiw-sec .list-works {
    padding: 0 20px;
    background: none;
  }

  .G-zr .hiw-sec .list-works .card-list p {
    font-size: 14px;
    padding: 0;
  }

  .G-zr .hiw-sec .list-works .card-list h5 {
    height: 30px;
    font-size: 16px;
  }

  .G-zr .msg-body .reciver-msg h6,
  .G-zr .msg-body .sender-msg h6 {
    max-width: 70%;
  }

  .G-zr .main-dash.seller-D {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .G-zr .error-page svg {
    width: 100%;
  }

  .G-zr .F-select {
    margin: auto 35px;
  }

  .G-zr .F-select span {
    width: 145px;
    margin: 15px auto;
    height: 52px;
  }

  .G-zr .navbar {
    height: auto;
    padding: 0 15px !important;
  }

  .G-zr .L-curve .curve-D {
    top: 0;
    height: auto;
    position: relative !important;
  }

  .G-zr .L-curve .curve-D h1 {
    margin-top: 0;
  }

  /* table responsive mobile */
  /* Force table to not be like tables anymore */
  #no-more-tables table,
  #no-more-tables thead,
  #no-more-tables tbody,
  #no-more-tables th,
  #no-more-tables td,
  #no-more-tables tr {
    display: block;
  }

  /* Hide table headers (but not display: none;, for accessibility) */
  #no-more-tables thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }

  #no-more-tables tr {
    border: 1px solid #ccc;
  }

  #no-more-tables td {
    border: none;
    position: relative;
    padding-left: 54%;
    white-space: normal;
    text-align: left;
    height: auto;
  }

  #no-more-tables td:before {
    position: absolute;
    top: 0;
    left: 6px;
    width: 45%;
    white-space: nowrap;
    text-align: left;
    font-weight: 700;
    content: attr(data-title);
    color: #212529;
    bottom: 0;
    margin: auto;
    height: 22px;
  }
}

@media (max-width: 600px) {
  .loader {
    width: 100px !important;
    height: 100px !important;
    border: 5px solid #f3f3f3;
  }
  .G-zr .product-post .progres-bar .step-1 span:after {
    width: 100% !important;
  }
  .G-zr .prd-feed-card.feed-card .feed-body {
    width: 100%;
  }
  .G-zr .Dash-right .Farms-card {
    width: 100%;
    max-width: 100%;
  }
  .G-zr .progress-form .select-wrapper:after {
    right: 40px;
  }
  .progress-form .col.pr-0,
  .progress-form .coll.pr-0 {
    padding-right: 15px !important;
  }
  #calenderComponent .model-height {
    width: 100% !important;
    height: auto;
  }
  .cm-det {
    padding: 30px 0;
    margin-top: 45px;
  }
  .cp_dash-nav {
    flex-direction: column;
    box-shadow: none;
  }
  .cp-lt-mn,
  .G-zr .track-bg {
    width: 100%;
    min-width: 100%;
  }
  .G-zr .main-dash.seller-D .ac-scrollbar .dot-S {
    max-width: 50%;
  }

  .G-zr .hiw-sec h4 {
    font-size: 26px;
  }

  .fot-sec .contact span.af:after {
    /* display: none; */
  }

  .G-zr .G-left {
    width: 100%;
    padding: 0 30px;
  }

  .G-zr .banner-sec .G-left {
    padding: 0;
  }

  footer .fot-sec div.contact,
  footer .fot-sec div.fot-list {
    flex-direction: initial;
    align-items: center;
    flex-wrap: wrap;
    margin: 0 -10px 5px;
  }

  footer .fot-sec div.fot-list a {
    width: 100%;
    text-align: center;
  }

  .fot-sec div.fot-list a:hover {
    color: #358539;
  }

  .fot-sec span {
    padding: 5px 10px !important;
    display: inline-block;
  }

  .actvOrdrsWrpr .srchBox {
    width: 100%;
    margin-bottom: 20px;
  }
}

@media (max-width: 767px) {
  .G-zr .w-10p.w345 {
    width: 40%;
  }

  .G-zr .Dash-right .nav-tabs .nav-item,
  .G-zr .Dash-right .table tbody td.w-25 {
    width: 100% !important;
  }

  .G-zr .progress-form.form-col .row,
  .G-zr .progress-form.form-col .b_pro-proc {
    flex-flow: column;
  }

  .G-zr .breadcrumb-item.active {
    padding-left: 0px;
  }

  /* toggle-menu start */
  #logo {
    display: block;
    padding: 0;
    width: 100%;
    text-align: center;
    float: none;
  }

  nav {
    margin: 0;
  }

  /* Hide the navigation menu by default */
  /* Also hide the  */
  .toggle + a,
  .side-Dash {
    display: none;
  }

  /* Stylinf the toggle lable */
  .toggle {
    display: block;
    background-color: #362f2c;
    padding: 14px 20px;
    color: #fff;
    font-size: 17px;
    text-decoration: none;
    border: none;
  }

  .toggle:hover {
    background-color: #000000;
  }

  /* Display Dropdown when clicked on Parent Lable */
  [id^="drop"]:checked + .side-Dash {
    display: block;
  }

  /* toggle menu */

  .G-zr .approved-user .w-10p {
    width: 20%;
  }

  .G-zr .main-dash.buyer-D .da-sh,
  .G-zr .main-dash.seller-D .da-sh {
    padding: 15px 0;
  }

  .G-zr .main-dash.seller-D .ac-scrollbar .dot-S p {
    margin-bottom: 15px !important;
  }

  .G-zr .main-dash.seller-D .ac-scrollbar .dot-S {
    max-width: 100%;
  }

  .G-zr .msg-body .reciver-msg h6,
  .G-zr .msg-body .sender-msg h6 {
    max-width: 85%;
    font-size: 14px;
  }

  .modal-body .ap-loc-box {
    align-items: start !important;
  }

  .w-sm-100 {
    width: 100% !important;
  }

  .ap-loc-box {
    height: 110px;
    flex-flow: column;
    align-items: start !important;
    justify-content: space-between !important;
  }

  .G-zr .quick-search .search-form .input-group {
    width: 100%;
    max-width: 100%;
    flex: 1 1 100%;
    margin-bottom: 15px;
  }

  .G-zr .search-form .input-group {
    margin-right: 0px !important;
    margin-bottom: 10px;
  }

  .search-form .d-flex {
    flex-flow: column;
    align-items: flex-start;
  }

  .quickSearch.search-form .d-flex {
    flex-flow: row wrap !important;
    align-items: flex-start;
  }

  .search-form .d-flex.scd-fx {
    flex-flow: row;
    align-items: flex-start;
    width: 100%;
  }

  .G-zr .search-form .scd-fx .btn {
    width: 50%;
  }

  .G-zr .responsive-view {
    display: block !important;
  }

  .G-zr .responsive-none {
    display: none;
  }

  .G-zr .feed-card .feed-body span:nth-child(1) {
    margin-bottom: 15px;
  }

  .G-zr .feed-card .feed-body span h5 {
    line-height: 25px;
  }

  .G-zr .feed-card .feed-body span {
    flex-flow: column;
    align-items: start !important;
  }

  .G-zr .main-dash.seller-D {
    padding-left: 0rem !important;
    padding-right: 0rem !important;
  }

  .G-zr .place-order h6 {
    padding: 0 10%;
  }

  .G-zr .search-form .input-group input.a-in::placeholder {
    font-size: 12px;
  }

  .G-zr .L-curve .curve-D h1 {
    margin-top: 0;
    font-size: 26px;
    padding: 0;
  }

  .rtCrvCntnr {
    height: unset;
    min-height: unset;
    max-height: unset;
    width: 100%;
    object-fit: cover;
    object-position: top;
    border-top-right-radius: unset;
    border-bottom-right-radius: unset;
    overflow: unset;
  }
}
@media (max-width: 600px) {
  .react-tabs {
    overflow: auto !important;
  }
  .btm-text {
    margin-top: 20px;
    margin-left: 20px;
  }
  .calenpop .modal-dialog {
    width: 98% !important;
    max-width: 98% !important;
  }
  span.G-radio.d-flex.justify-content-start.align-items-center {
    flex-direction: column;
    align-items: flex-start !important;
  }
  .progress-form .col .mb-5 {
    margin-bottom: 1rem !important;
  }
  .progress-form .col {
    max-width: 100%;
    width: 100%;
    min-width: 100%;
  }
  .G-zr .product-post {
    margin-top: 35% !important;
    width: 100%;
    max-width: 100%;
    border: 1px solid #d2d2d2;
    border-radius: 5px;
  }
  .G-zr .txt-green {
    padding-top: 10px !important;
  }
  .G-zr .btn-green {
    padding: 10px 10px;
  }
  .pl-5,
  .px-5 {
    padding-left: 1.5rem !important;
    padding-right: 1.5em !important;
  }
  .G-zr .seller-profile figure {
    top: -25px;
    overflow: hidden;
    position: relative !important;
  }
  .seller-profile a {
    display: inline-grid;
  }
  .pxs-0 {
    padding: 0 !important;
    text-align: center;
  }
  .sn-msg {
    padding-left: 0;
  }
  .row.filter-right.pb-3.pr-5 {
    padding: 0 !important;
  }
  .G-zr .seller-product-vw .carosal-up-product .up-card {
    margin-right: auto !important;
    margin-left: 0;
    margin-bottom: 40px;
  }
  .fb-wrapper .feed-card .feed-img {
    margin: auto;
  }
  .G-zr .feed-card {
    margin: 15px auto !important;
  }
  .G-zr .track-left .progres-bar .step.active p {
    font-size: 13px;
  }
  .G-zr .track-left,
  .G-zr .track-right {
    padding: 20px;
  }
  .G-zr .action-list .btn-gradien {
    padding: 4px 15px;
    color: #fff;
    font-size: 12px;
    font-weight: 500;
    white-space: nowrap;
  }
  .fc-toolbar h2 {
    font-size: 32px !important;
  }
  .calenpop .modal-dialog {
    width: 100%;
    max-width: 100%;
    margin: 30px auto;
  }
  .calender-res,
  .fc-view-container {
    overflow: auto;
  }
  .fc-view-container .fc-view {
    width: 600px;
  }
  .calender-res .table-responsive {
    width: 600px;
  }
  .G-zr .Dash-right .agenta-calender .table tbody td:first-child {
    width: 125px;
  }
  .G-zr .Dash-right .agenta-calender .table tbody td object {
    width: auto;
  }
}

@media (max-width: 475px) {
  .prof-il .pr-0 {
    padding-right: 15px !important;
  }
  .G-zr .progress-form.prof-il .select-wrapper:after {
    right: 40px;
  }
  .G-zr .G-main {
    height: auto;
  }

  .G-zr .msg-body .reciver-msg,
  .G-zr .msg-body .sender-msg {
    flex-flow: column;
  }

  .G-zr .msg-body .sender-msg {
    align-items: flex-end;
  }

  .G-zr .msg-body .reciver-msg {
    align-items: flex-start;
  }
}

@media (max-width: 425px) {
  .G-zr .banner-sec.video-bg {
    margin-top: 5em;
  }

  .processor-list .con-ner {
    padding: 0px 0px 0 0px;
  }

  .G-zr .progress-form.form-col .b_pro-proc h3 {
    padding: 15px 0;
  }

  .G-zr .msg-body .reciver-msg h6,
  .G-zr .msg-body .sender-msg h6 {
    max-width: 100%;
    font-size: 14px;
    margin: 0 !important;
  }

  .G-zr .G-main {
    margin-top: 3em;
  }

  .pop-vdo video {
    height: 200px;
  }

  .col.search-fil {
    overflow: auto;
    padding: 16px;
    background: #eeeeee;
    margin: auto 12px;
  }

  .G-zr .G-modal .modal-header .modal-title.fs-18 {
    font-size: 16px !important;
  }

  .modal-body.px-5.pb-5,
  .G-zr .G-modal .modal-header {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .G-zr .btn-bwn.up-bwn {
    padding: 8px 6px !important;
    width: 100%;
  }

  .ro-wo {
    flex-direction: column;
    align-items: start !important;
  }

  .G-zr .btn-green {
    padding: 10px 20px;
    font-size: 14px;
    margin: 5px auto;
  }

  .G-zr .pro-view-slider .owl-carousel {
    flex-direction: column;
  }

  .G-zr .pro-view-slider .owl-carousel div:nth-child(1),
  .G-zr .pro-view-slider .owl-carousel div:nth-child(2) {
    width: 100%;
    text-align: center;
  }

  .G-zr .abt-sll {
    flex-direction: column;
    align-items: center;
  }

  .G-zr .Af-log #navbarSupportedContent ul.onres {
    width: 60%;
    right: -60%;
  }

  .G-zr .quick-search .search-form .input-group {
    width: 100%;
    margin-bottom: 15px;
  }

  .G-zr .L-curve img {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .G-zr .L-curve .curve-D h1 {
    margin-top: 0;
    font-size: 26px;
  }
}

@media (max-width: 375px) {
  .text-sm-center ul {
    text-align: center !important;
  }

  .ap-loc-box .w-75 {
    width: 100% !important;
    margin-right: 0 !important;
  }
}

/* responsive end */
/* calender-view */

/* .Dash-right tr:nth-child(2)>td>.fc-day-grid-event {
    background-color: #3b8793 !important;
}

.Dash-right tr:nth-child(3)>td>.fc-day-grid-event {
    background-color: rgb(59, 90, 147) !important;
}

.Dash-right tr:nth-child(4)>td>.fc-day-grid-event {
    background-color: rgb(60, 59, 147) !important;
}

.Dash-right tr>td>.fc-day-grid-event {
    border: 1px solid transparent;
    margin: 1px 6px;
}

.Dash-right tr:first-child>td>.fc-day-grid-event {
    background: #358539 !important;
    border-radius: 4px;
} */

.fc-day-grid-event .fc-content {
  padding: 2px 5px;
  font-size: 12px;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  color: white;
  cursor: pointer;
}

.table-scrool {
  height: 300px;
  overflow: auto;
}

.G-zr .comunity-members h5 {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

@media (max-width: 600px) {
  .fc-day-grid-event .fc-content {
    white-space: unset !important;
  }

  #calenderComponent .fa-close-cls {
    margin: 0 !important;
    font-size: 26px !important;
  }
}

@media (max-width: 500px) {
  .G-zr .w-10p.w345 {
    width: 40%;
  }

  .G-zr .Dash-right .nav-tabs .nav-item,
  .G-zr .Dash-right .table tbody td.w-25 {
    width: 100% !important;
  }

  .G-zr .progress-form.form-col .row,
  .G-zr .progress-form.form-col .b_pro-proc {
    flex-flow: column;
  }

  .G-zr .breadcrumb-item.active {
    padding-left: 0px;
  }

  /* toggle-menu start */
  #logo {
    display: block;
    padding: 0;
    width: 100%;
    text-align: center;
    float: none;
  }

  nav {
    margin: 0;
  }

  /* Hide the navigation menu by default */
  /* Also hide the  */
  .toggle + a,
  .side-Dash {
    display: none;
  }

  /* Stylinf the toggle lable */
  .toggle {
    display: block;
    background-color: #362f2c;
    padding: 14px 20px;
    color: #fff;
    font-size: 17px;
    text-decoration: none;
    border: none;
  }

  .toggle:hover {
    background-color: #000000;
  }

  /* Display Dropdown when clicked on Parent Lable */
  [id^="drop"]:checked + .side-Dash {
    display: block;
  }

  /* toggle menu */

  .G-zr .approved-user .w-10p {
    width: 20%;
  }

  .G-zr .main-dash.buyer-D .da-sh,
  .G-zr .main-dash.seller-D .da-sh {
    padding: 15px 0;
  }

  .G-zr .main-dash.seller-D .da-sh {
    width: 100%;
  }

  .G-zr .main-dash.seller-D .ac-scrollbar .dot-S p {
    margin-bottom: 15px !important;
  }

  .G-zr .main-dash.seller-D .ac-scrollbar .dot-S {
    max-width: 100%;
  }

  .G-zr .ac-scrollbar .d-flex {
    flex-direction: column;
  }

  .G-zr .msg-body .reciver-msg h6,
  .G-zr .msg-body .sender-msg h6 {
    max-width: 85%;
    font-size: 14px;
  }

  .modal-body .ap-loc-box {
    align-items: start !important;
  }

  .w-sm-100 {
    width: 100% !important;
  }

  .ap-loc-box {
    height: 110px;
    flex-flow: column;
    align-items: start !important;
    justify-content: space-between !important;
  }

  .G-zr .quick-search .search-form .input-group {
    width: 100%;
    margin-bottom: 15px;
  }

  .G-zr .search-form .input-group {
    margin-right: 0px !important;
    margin-bottom: 10px;
  }

  .search-form .d-flex {
    flex-flow: column;
    align-items: flex-start;
  }

  .quickSearch.search-form .d-flex {
    flex-flow: row wrap !important;
    align-items: flex-start;
  }

  .search-form .d-flex.scd-fx {
    flex-flow: row;
    align-items: flex-start;
    width: 100%;
  }

  .G-zr .search-form .scd-fx .btn {
    width: 50%;
  }

  .G-zr .responsive-view {
    display: block !important;
  }

  .G-zr .responsive-none {
    display: none;
  }

  .G-zr .feed-card .feed-body span:nth-child(1) {
    margin-bottom: 15px;
  }

  .G-zr .feed-card .feed-body span h5 {
    line-height: 25px;
  }

  .G-zr .feed-card .feed-body span {
    flex-flow: column;
    align-items: start !important;
  }

  .G-zr .main-dash.seller-D {
    padding-left: 0rem !important;
    padding-right: 0rem !important;
  }

  .G-zr .place-order h6 {
    padding: 0 10%;
  }

  .G-zr .search-form .input-group input.a-in::placeholder {
    font-size: 12px;
  }
}

.loader {
  border: 16px solid #f3f3f3;
  /* Light grey */
  border-top: 16px solid #358539;
  /* Blue */
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
  margin: 20px auto;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.cp-spinner {
  width: 48px;
  height: 48px;
  display: inline-block;
  box-sizing: border-box;
  position: relative;
}

.cp-balls {
  animation: cp-balls-animate 1s linear infinite;
}

.cp-balls:before {
  border-radius: 50%;
  content: " ";
  width: 24px;
  height: 24px;
  display: inline-block;
  box-sizing: border-box;
  background-color: #0fd6ff;
  position: absolute;
  top: 0;
  left: 0;
  animation: cp-balls-animate-before 1s ease-in-out infinite;
}

.cp-balls:after {
  border-radius: 50%;
  content: " ";
  width: 24px;
  height: 24px;
  display: inline-block;
  box-sizing: border-box;
  background-color: #eb68a1;
  position: absolute;
  bottom: 0;
  right: 0;
  animation: cp-balls-animate-after 1s ease-in-out infinite;
}

.validation-error {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  margin: 0;
  z-index: 999999999 !important;
}

@keyframes cp-balls-animate {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes cp-balls-animate-before {
  0% {
    transform: translate(-5px, -5px);
  }

  50% {
    transform: translate(0, 0);
  }

  100% {
    transform: translate(-5px, -5px);
  }
}

@keyframes cp-balls-animate-after {
  0% {
    transform: translate(5px, 5px);
  }

  50% {
    transform: translate(0, 0);
  }

  100% {
    transform: translate(5px, 5px);
  }
}

.cp-spinner {
  width: 32px;
  height: 32px;
  display: inline-block;
  box-sizing: border-box;
  position: relative;
}

.cp-bubble {
  border-radius: 50%;
  width: 13px;
  height: 13px;
  display: inline-block;
  box-sizing: border-box;
  margin: 0 30px;
  background: #58bd55;
  animation: cp-bubble-animate 1s linear infinite;
}

.cp-bubble:before {
  border-radius: 50%;
  content: " ";
  width: 12px;
  height: 12px;
  display: inline-block;
  box-sizing: border-box;
  background-color: #58bd55;
  position: absolute;
  left: -30px;
  animation: cp-bubble-animate-before 1s ease-in-out infinite;
}

.cp-bubble:after {
  border-radius: 50%;
  content: " ";
  width: 12px;
  height: 12px;
  display: inline-block;
  box-sizing: border-box;
  background-color: #58bd55;
  position: absolute;
  right: -30px;
  animation: cp-bubble-animate-after 1s ease-in-out infinite;
}

@keyframes cp-bubble-animate {
  0% {
    opacity: 0.5;
    transform: scale(1) translateX(0);
  }

  25% {
    opacity: 1;
    transform: scale(1) translateX(0px);
  }

  50% {
    opacity: 1;
    transform: scale(1) translateX(0px);
  }

  100% {
    opacity: 0.5;
    transform: scale(1) translateX(0);
  }
}

@keyframes cp-bubble-animate-before {
  0% {
    opacity: 0.5;
    transform: scale(1);
  }

  25% {
    transform: scale(1.1);
  }

  100%,
  50% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes cp-bubble-animate-after {
  0%,
  50% {
    opacity: 0.5;
    transform: scale(1);
  }

  50% {
    transform: scale(1.1);
  }

  100%,
  75% {
    opacity: 1;
    transform: scale(1);
  }
}

.modal .modal-header {
  margin-bottom: 0;
}

.G-zr .G-modal .modal-body {
  max-height: calc(90vh - 61px);
  overflow: auto;
}

@media (max-width: 1199px) {
  .G-zr.top-h .px-5.pb-5 {
    padding: 0 15px !important;
  }

  .G-zr .breadcrumb-item.active {
    padding-left: 0;
  }

  .G-zr .dash-hd .breadcrumb-item a {
    padding: 5px 5px 5px 0;
  }

  .G-zr .Dash-right .nav-tabs {
    border-bottom: 1px solid #358539;
    display: flex;
    white-space: nowrap;
    flex-wrap: nowrap;
    overflow: auto;
  }

  .G-zr .Dash-right .nav-tabs .nav-item {
    width: auto !important;
  }
}

@media (max-width: 850px) {
  .G-zr .search-form .scd-fx .btn {
    font-size: 14px;
    font-weight: 500;
    margin: 20px 10px;
  }
  .search-form .sech-pag {
    width: 50%;
    max-width: 50%;
    flex: 0 0 45%;
  }
}

@media (max-width: 768px) and (max-width: 850px) {
  .G-zr .search-form .scd-fx .btn {
    font-size: 14px;
    font-weight: 500;
    margin: 20px 10px;
  }
  .search-form .sech-pag {
    width: 50%;
    max-width: 50%;
    flex: 0 0 45%;
  }
}

@media (max-width: 767px) {
  .search-form .sech-pag {
    width: 100%;
    max-width: 100%;
    flex: 0 0 100%;
  }
  /* .sn-msg {
        padding-left: 120px; 
    } */

  .mrtp-11 {
    margin-top: -11px;
  }

  .pxs-0 {
    padding: 0 !important;
  }

  .G-zr .dash-banner-h {
    height: 180px;
    min-height: 180px;
  }
  .mtp-82 {
    margin-top: 62px;
  }
  .G-zr .main-dash.seller-D .da-sh {
    width: 48%;
    margin: 0 auto 20px;
    height: 185px;
  }

  /* .G-zr .main-dash.seller-D .da-sh:last-child {
        width:100%;
    } */

  .G-zr .bg-dash {
    padding: 0 !important;
  }

  .G-zr .Dash-right .Farms-card {
    width: 48%;
    max-width: 48%;
    margin: 5px auto 20px;
  }
  .G-zr.top-h {
    margin-top: 75px;
  }
  .G-zr .form-right {
    margin: 0 auto 20px;
  }

  .pt-xs-70 {
    padding-top: 75px;
  }

  .pt-xs-30 {
    padding-top: 30px;
  }
  .lead {
    font-size: 15px;
    font-weight: 300;
    margin: 20px 0;
  }
}

@media (min-width: 768px) {
  .G-zr .hiw-sec .list-works .card-list.pcrd-list {
    width: 33.3%;
  }

  .G-zr .hiw-sec .list-works .card-list.pcrd-list img {
    object-fit: contain;
  }
}

@media (min-width: 768px) and (max-width: 1199px) {
  .G-zr .hiw-sec .list-works {
    background: none;
    padding: 0;
  }

  .G-zr .hiw-sec .list-works .card-list {
    padding: 10px;
  }
}

@media (min-width: 600px) {
  .G-zr .search-form .scd-fx .btn {
    margin: 0px 10px;
    width: 100%;
  }
}

@media (max-width: 600px) {
  .cstmPopTable #no-more-tables thead tr {
    position: relative;
    top: unset;
    left: unset;
  }

  .cstmPopTable #no-more-tables table,
  .cstmPopTable #no-more-tables thead,
  .cstmPopTable #no-more-tables tbody,
  .cstmPopTable #no-more-tables tr {
    display: table;
  }

  .cstmPopTable #no-more-tables th,
  .cstmPopTable #no-more-tables td {
    display: table-cell;
    padding-left: 8px;
    padding-right: 8px;
  }
}

@media (max-width: 425px) {
  .G-zr .F-select figure h5 {
    font-size: 15px !important;
  }
  .G-zr .search-form .scd-fx .btn {
    width: 100%;
    margin: 3px 10px;
  }
  .G-zr .quick-search.quick-search-filter {
    padding: 10px 0 !important;
  }
}

@media (max-width: 375px) {
  .G-zr .F-select span {
    width: 120px;
    margin: 6px auto;
    height: 45px;
  }
}


.App-logo {
  height: 40vmin;
} 
/* .nav-tabs {
	z-index: 110;
    position: relative;
    background: #358539;
    height: 60px;
    display: flex;
    justify-content: space-between;
    padding: 2px 15px;
} */

/* .nav-tabs .nav-link {
    border: 1px solid transparent;
    border-top-left-radius: .25rem;
    border-top-right-radius: .25rem;
    width: 16%;
    padding:14px;
    color: #fff;
    text-align:center;
} */

.nav-tabs .nav-link:hover,
.nav-tabs .nav-link:focus {
	color: #358539;
    background: #fff;
} 

.rtngPopBody .rtngForm{
    max-width: 500px;
    width: 100%;
    margin: 15px auto;
}

.rtngPopBody .rtngForm .rtngComments{
    width: 100%;
    height: 160px;
}

.rtngPopBody .dv-star-rating-star i{
    font-size: 24px;
    margin: 0 5px;
}

.trck-lfpop.model-height.rtngModal{
    height: 450px !important;
    min-height: unset !important;
}

.btn-green.sbmtBtn{
    margin-left: auto;
}

.trck-lfpop.model-height.rtngModal .fa-close-cls{
    margin-left: 0;
}

@media(max-width:600px){
    .rtngPopBody .rtngForm{
      padding: 0 20px;
    }
}
@media screen and (min-width: 1025px){
    #mobile-restriction{
      display:none !important;
    }
  }
  @media screen and (max-width: 1024px){
    html{
      width: 100vw;
      height: 100vh;
      overflow: hidden !important;
    }
    #mobile-restriction{
      padding: 20px;
      position: fixed;
      width: 100vw;
      height: 100vh;
      top: 0;
      left: 0;
      z-index: 10000000000000000;
      background-color: #000000;
      pointer-events: none;
      overflow: hidden;
      display: flex !important;
      flex-wrap: wrap;
      justify-content: center;
      align-content: center;
      align-items: center;
      flex-direction: column;
      grid-gap:50px;
      gap:50px;
    }
    #mobile-restriction img{
      width:100%;
      max-width:250px;
      height:auto;
    }
    #mobile-restriction h1{
      font-size:40px;
      text-align:center;
      color: #ffffff;
    }
  }
.G-zr .site-top-hight {
    margin-top: 22%;
}

.G-zr .fw-r {
    font-weight: 400;
}
.G-zr .fs-18 {
    font-size: 18px !important;
}
.fot-sec figure {
    padding: 0 20px;
}
.fot-sec .contact span.af:after {
    position: absolute;
    content: '';
    background: #DEDEDE;
    width: 1px;
    height: 25px;
    top: 0;
    right: 0;
}
.fot-sec .contact span {
    font-size: 15px;
    font-weight: 500;
    position: relative;
}
.fot-sec span {
    padding: 0 20px;
}
.fot-sec span a{
    font-size: 15px;
    font-weight: 500;
    color: #358539;
    cursor: pointer;
}
/* .p-5 {
    padding: 3rem!important;
} */
footer h6{
    font-size: 14px;
}
footer .fot-sec2{
    background: #262626;
    padding: 12px;
        color: #fff;
    text-align: center;
}
.navbar-brand{
  background: black;
  padding: 0 3rem;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: center;
}

.header-dropdown{
  position: absolute;
  right: 0;
  margin-top: 2.5rem;
  width: 100px;
  background: white;
  border: 1px solid #E3E8EE;
  padding: 1rem;
  box-shadow: 0px 1px 2px 0px #37415114;
  cursor: pointer;
}
.custom-select {
  border: 1px solid #EFEFEF; /* Added border */
  border-radius: 10px; /* Added border radius */
  color: #000000; /* Change this to your desired color */
  background-color: transparent; /* Optional: make the background transparent */
  font-size: 14px; /* Optional: adjust the font size */
  padding: 5px; /* Optional: adjust the padding */
  -webkit-appearance: none;
          appearance: none; /* Remove default arrow in some browsers */
  width: 11rem;
  height: 2.5rem;
}

.custom-select:focus {
  outline: none; /* Remove the focus outline */
}


.header-dropdown:hover{
  color: #E3E8EE;
}

.header-datetime{
  border: 1px solid #EFEFEF;
  padding: 0.5rem 2rem;
  border-radius: 10px;
}

.header-datetime span{
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.25px;
}

body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  background: #fff;
  font-family: "Raleway", sans-serif;
}

.nav-item.cartNavItem p {
  margin: 0;
}

.nav-item.cartNavItem .cartPrnts {
  width: 25px;
  height: 25px;
  position: relative;
}
.nav-item.cartNavItem .cartPrnts .crtCountCircle {
  width: 20px;
  height: 20px;
  background-color: #f35825;
  color: white;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 30px;
  top: -8px;
  right: -5px;
}
.bell-icon-container {
  position: relative;
  display: inline-block;
}

.notification-dot {
  position: absolute;
  top: 0px;  /* Adjust this to move the dot vertically */
  right: 12px; /* Adjust this to move the dot horizontally */
  height: 10px;
  width: 10px;
  background-color: red;
  border-radius: 50%;
  border: 2px solid white; /* Optional white border */
}
.profiledrop {
  padding: 0;
  line-height: 25px;
  border: none;
  box-shadow: 0px 0px 9px 0px #00000012;
  position: absolute;
  width: 140px;
  top: 76px;
  transform: translate(4px, 2px);
  transition: cubic-bezier(0.075, 0.82, 0.165, 1);
}
.profiledrop ul {
  padding: 0.25rem;
  list-style-type: none;
  font-size: 12px;
  font-family: "Montserrat", sans-serif;
  background-color: #ffffff;
}
.profiledrop ul li a {
  color: #000000 !important;
  text-decoration: none;
}
.profiledrop ul li:hover a {
  color: #ffffff;
}
.profiledrop ul li:hover {
  background-color: #358539;
}
.profiledrop ul li {
  /* background: #f0f7ffcc; */
  padding: 10px 5px;
  margin: 1px auto;
}
.Af-log {
  border-bottom: 3px solid #358539;
}
.G-zr .navbar-brand img {
  width: 170px;
  height: 50px;
  object-fit: cover;
}
.G-zr .navbar-expand-lg .navbar-nav .nav-item.active .nav-link {
  border: 1px solid #fff;
  border-radius: 4px;
}

#snackbar {
  background-color: #d4edda;
    padding: 21px 42px 21px 17px;
    border-radius: 4px;
    color: #155724;
    position: fixed;
    z-index: 1301;
    /* padding-right: 185px; */
    width: 256px;
    right: 0;
    top: 6em;
    float: none;
    margin: 0 2px;
    box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3), 0 2px 1px rgba(0, 0, 0, 0.22);
    color: #000;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    min-height: 58px;
    line-height: normal;
    height: auto;
}
#snackbar.show {
  visibility: visible; /* Show the snackbar */
  /* Add animation: Take 0.5 seconds to fade in and out the snackbar. 
  However, delay the fade out process for 2.5 seconds */
  animation: fadein 0.5s, fadeout 0.5s 2.5s;
}
.snackbarClose {
  right: 20px;
  float: right;
  position: absolute;
  top: 15px;
  font-size: 20px;;
  padding: 0.2rem 0.3rem;
  border-radius: 50%;
  font-size: 12px;
  color: white;
  cursor: pointer;
}

.fa-times:before {
  content: "\f00d";
}
.fa,
.fas {
  font-weight: 900;
}

.img-avatar {
  width: 40px;
  height: 40px;
  border-radius: 100px;
}
.G-zr .Af-log .logo2,
.G-zr .Af-log.normal .logo1 {
  display: none;
}
.G-zr .Af-log.normal .logo2,
.G-zr .Af-log .logo1 {
  display: block;
}
.G-zr .Af-log .icon-bar {
  display: block;
  width: 26px;
  height: 2px;
  margin: 7px auto;
  border-radius: 1px;
}
.G-zr .Af-log .icon-bar {
  background-color: #fff;
}
/* .G-zr .Af-log .navbar-toggler {
  padding: 0rem;
}
.G-zr .Af-log .navbar-toggler:focus {
  outline: 0;
} */
.G-zr .Af-log #navbarSupportedContent ul {
  align-items: center;
}
.G-zr .Af-log #navbarSupportedContent {
  display: block;
}
.G-zr .Af-log.inner-Header {
  background: transparent;
  background-color: transparent;
}
.G-zr .Af-log {
  background: #ffffff;
  background-color: #ffffff;
  color: #000;
  border-bottom: 1px solid #EFEFEF;
}
.G-zr .Af-log.normal.inner-Header {
  background: #262626;
}
.G-zr .Af-log.inner-Header .navbar-expand-lg .navbar-nav .nav-link {
  color: #ffffff;
}
.G-zr .Af-log .navbar-expand-lg .navbar-nav .nav-link:hover {
  color: #4caf50;
}
.G-zr .Af-log .navbar-expand-lg .navbar-nav .nav-link {
  color: #262626;
}

.G-zr .dash-hd .breadcrumb-item a {
  color: hsl(18, 10%, 19%);
  font-weight: 700;
}

.profiledrop ul a {
  color: #358539;
}

.profiledrop ul a:hover {
  color: #fff;
}
.mobile-drodn-view {
  display: none;
}
.mobile-drodn-view li {
  list-style-type: none;
  padding: 5px 0;
}
.mobile-drodn-view li a {
  color: #fff;
}

.installPWA {
  position: fixed;
  top: 80px;
  left: 0;
  right: 0;
  width: 100%;
  max-width: 90vw;
  margin-left: auto;
  margin-right: auto;
  text-align: left;
  padding: 10px;
  background: #ebebeb;
  z-index: 99999999999999999999999999999;
  border-radius: 10px;
  border: 1px solid #e8e8e8;
}

.installPWA .inrFlx {
  display: flex;
  align-items: center;
  justify-content: center;
}

.installPWA .addIconImg {
  width: 30px;
  height: 30px;
  margin-right: 20px;
}

.installPWA p {
  margin: 0;
  font-size: 13px;
}

.installPWA .mnuIconImg {
  width: 25px;
  height: 25px;
}

.installPWA button {
  width: 100%;
  background: gray;
  color: white;
  margin-top: 10px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #333;
  color: #fff;
  padding: 10px 20px;
  position: relative;
  z-index: 99999;
}

.menu-icon {
  font-size: 24px;
  cursor: pointer;
}

.logo {
  font-size: 20px;
  font-weight: bold;
}

.mobile-menu {
  position: absolute;
  top: 50px; /* Adjust as needed */
  left: 0;
  background: white;
  width: 100%;
  padding: 10px 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border: 1px solid #e3e5e5 !important;
  height: 100vh;
}

.mobile-menu a {
  text-decoration: none;
  color: #fff;
  padding: 5px 0;
}

.logo-mobile{
  height: 30px;
}

.centered-logo {
  font-size: 20px;
  font-weight: bold;
  flex-grow: 1; /* This allows the logo to take up remaining space */
  text-align: center;
}

@media (max-width: 600px) {
  .mobile-drodn-view {
    display: block;
  }
  .mobile-drodn-none {
    display: none;
  }
  #snackbar {
    background-color: #d4edda;
    color: #155724;
    position: fixed;
    z-index: 1;
    padding: 15px;
    padding-right: 40px;
    right: 0;
    left: 0;
    top: 6em;
    width: 90vw;
    float: none;
    margin-left: auto;
    margin-right: auto;
    box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22);
  }

  .snackbarClose {
    right: 7px;
    float: right;
    position: absolute;
    top: 17px;
    font-size: 20px;
  }
}

@media (max-width: 991px) {
  .G-zr .Af-log .navbar-expand-lg .navbar-nav .nav-link {
    color: #fff;
  }
  .G-zr .Af-log #navbarSupportedContent ul.onres li.mobile-drodn-none.mbnonep {
    display: none;
  }
}

/* @import '~bootstrap/dist/css/bootstrap.css';        */
body.print > *:not(print) {
  display: none !important;
}

body.print .no-print {
  display: none !important;
}

body .only-print {
  display: none;
}

body.print .only-print {
  display: block;
}

body strong,
.react-form-builder .react-form-builder-preview .bold {
  font-weight: 700;
  color: #000000;
}

@media print {
  div {
    page-break-inside: avoid;
    break-inside: avoid;
  }
}

.MuiPaper-elevation8 {
  box-shadow: none !important;
  border: 1px solid black;
}

.MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]
  .MuiAutocomplete-input {
  padding: 2.5px 4px !important;
}

.btn-center {
  margin-left: 160px;
  margin-top: 5px;
}

.btn-size {
  max-width: 235px;
}

.btm-text {
  margin-top: 20px;
  margin-left: 120px;
}

.fa-close-cls {
  cursor: pointer;
  margin-left: 560px;
}

.model-height {
  height: auto;
}

.calender-margin {
  margin-top: 40px;
  margin-left: 120px;
}

/* .react-calendar__navigation__label
{
  background-color: aquamarine !important;
} */

.active {
  background-color: #4f4f4f;
  color: white;
}

.nav-link {
  padding: 0.5rem;
}

.radWrp {
  grid-gap: 10px;
  gap: 10px;
}

.radWrp input {
  accent-color: #3c923f;
}

.radIpt input {
  margin-right: 5px !important;
  display: inline-block;
}

.cursor-pointer {
  cursor: pointer !important;
}

body .icnButton {
  border: none;
  padding: 0;
  margin: 0 10px 0 0 !important;
  background: none;
}

body .btn-primary {
  background: #3c923f;
  border-radius: 8px;
  display: flex;
  width: 180px;
  height: 44px;
  padding: 14px 20px;
  justify-content: center;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
  flex-shrink: 0;
  border: none;
  cursor: pointer;
  font-size: 14px !important;
  color: var(--grey-light, #f5f5f5) !important;
  font-family: Roboto;
  font-size: 14px !important;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

body .btn-primary:hover,
body .btn-primary:active,
body .btn-primary:focus {
  background: #3c923f !important;
  opacity: 0.8;
}

body .btn-primary.disabled,
body .btn-primary:disabled,
body .btn-primary.disabled:hover,
body .btn-primary:disabled:hover {
  background: #3c923f !important;
  opacity: 0.8;
}

body .btn-primary:not(:disabled):not(.disabled).active,
body .btn-primary:not(:disabled):not(.disabled):active,
body .show > .btn-primary.dropdown-toggle {
  background: #2d6a2f !important;
}

body .btn-secondary {
  background: var(--dark-medium, #4f4f4f);
  border-radius: 8px;
  display: flex;
  width: 180px;
  height: 44px;
  padding: 14px 20px;
  justify-content: center;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
  flex-shrink: 0;
  border: none;
  cursor: pointer;
  font-size: 14px !important;
  color: var(--grey-light, #f5f5f5) !important;
  font-family: Roboto;
  font-size: 14px !important;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

body .btn-secondary:hover,
body .btn-secondary:active,
body .btn-secondary:focus {
  background: var(--dark-medium, #4f4f4f);
  opacity: 0.8;
}

body .btn-secondary.disabled,
body .btn-secondary:disabled,
body .btn-secondary.disabled:hover,
body .btn-secondary:disabled:hover {
  background: #6c757d !important;
  opacity: 0.8;
}

body .btn-small {
  border: 1px solid #5e6366;
  border-radius: 4px;
  font-size: 11px;
  padding: 1px 5px;
}

body :not(.dynamic-options-actions-buttons) > .btn-danger {
  background: #f08181;
  border-radius: 8px;
  display: flex;
  width: 180px;
  height: 44px;
  padding: 14px 20px;
  justify-content: center;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
  flex-shrink: 0;
  border: none;
  cursor: pointer;
  font-size: 14px !important;
  color: var(--grey-light, #f5f5f5) !important;
  font-family: Roboto;
  font-size: 14px !important;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

body :not(.dynamic-options-actions-buttons) > .btn-danger:hover,
body :not(.dynamic-options-actions-buttons) > .btn-danger:active,
body :not(.dynamic-options-actions-buttons) > .btn-danger:focus {
  background: #f08181 !important;
  opacity: 0.8;
}

body .btn-group-toggle .btn {
  width: auto !important;
}

body .btn-group-toggle .btn.active {
  background-color: #3c923f !important;
}

body .form-label {
  color: var(--gray-500, #697386);
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  /* 133.333% */
  padding-bottom: 4px;
}

.checkbox-label {
  color: var(--gray-500, #697386);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

body .form-group .badge {
  margin-left: 10px;
}

body .form-group label p {
  margin-bottom: 0;
}

body input[type="checkbox"].form-control {
  width: auto;
  height: auto;
  display: inline-block;
  margin-right: 10px;
  accent-color: rgb(60, 146, 63);
}

body input[type="text"].form-control,
body input[type="time"].form-control,
body input[type="date"].form-control,
body input[type="number"].form-control,
body input[type="email"].form-control,
body input[type="tel"].form-control,
body input[type="password"].form-control,
body select.form-control,
body textarea.form-control {
  border-radius: 4px;
  border: 1px solid var(--gray-100, #e3e8ee);
  background: var(--base-white, #fff);

  /* Light / Elevation / 200 */
  box-shadow: 0px 1px 2px 0px rgba(55, 65, 81, 0.08);
  min-height: 36px;

  color: var(--gray-500, #697386);

  /* Body / 500 */
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  /* 142.857% */
}

body .form-control-autocomplete .MuiInputBase-input {
  color: var(--gray-500, #697386);

  /* Body / 500 */
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  /* 142.857% */
}

body .form-control-autocomplete .MuiInputBase-formControl {
  padding: 7px 9px !important;
}

body .form-control-autocomplete .MuiOutlinedInput-notchedOutline {
  border-radius: 4px;
  border: 1px solid var(--gray-100, #e3e8ee) !important;
  /* background: var(--base-white, #FFF); */

  /* Light / Elevation / 200 */
  box-shadow: 0px 1px 2px 0px rgba(55, 65, 81, 0.08);
  min-height: 36px;
}

body .btn-mini:hover {
  background: linear-gradient(90deg, #d1fdff 0%, #ffecbc 100%);
  padding: 0.3rem 1rem;
  border-radius: 5px;
  color: #5e6366;
}

body .btn-mini {
  background: #f7f9fa;
  padding: 0.3rem 1rem;
  border-radius: 5px;
  color: #5e6366;
}

.left-menu-profile {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  align-content: start;
  width: 100%;
  padding: 1rem 1.2rem;
}

.left-menu-profile h1 {
  font-size: 14px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.4399999976158142px;
  text-align: left;
  color: black;
  margin-bottom: 0;
}

.left-menu-item-icon-img {
  border-radius: 100px;
  width: 40px !important;
  height: 40px !important;
  margin-right: 1rem;
}

.label-checkbox {
  color: #000;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0;
}

.btn-transparent {
  border-radius: 6px;
  /* border: 1px solid #BEBEBE; */
  /* background: #FFF; */
  /* border-radius: 8px; */
  /* display: flex; */
  width: 152px;
  height: 44px;
  padding: 14px 20px;
  justify-content: center;
  align-items: center;
  grid-gap: 10px;
  grid-gap: 10px;
  gap: 10px;
  /* flex-shrink: 0; */
  /* cursor: pointer; */
  margin-bottom: 10px;
  margin-right: 10px;
  /* font-family: Roboto; */
  /* font-size: 14px; */
  /* font-style: normal; */
  /* font-weight: 500; */
  /* line-height: normal; */
  /* color: #000; */
  /* width: 110px; */
  /* text-align: center; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  border-radius: 4px !important;
  border: 1px solid #e0e0e0 !important;
  background: #fff !important;
  box-shadow: 0px 11px 11px 0px rgba(0, 0, 0, 0.08) !important;
  color: #5e6366 !important;
  text-align: center;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.4px;
  /* height: 90px;*/
}

.btn-tab {
  border: 1px solid #bebebe;
  border-radius: 10px;
  color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  padding: 0.5rem;
  cursor: pointer;
  font-size: 18px;
  border-radius: 6px;
  background: #fff;
  font-size: 18px;
}

.btn-tab:hover {
  background-color: #e0e0e0;
}

.tab-active {
  border-radius: 10px;
  background: #4f4f4f !important;
  color: white !important;
}

.sub-title {
  width: 200px;
  border-bottom: 1px solid #f1f3f4;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-content: center;
  padding: 0.5rem;
  margin: 1rem 0;
}

.sub-title span {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.25px;
  text-align: left;
}

.tab-container {
  border: 1px solid #e3e8ee;
  border-radius: 10px;
  padding: 1rem;
  width: 100%;
}

.popup-title {
  color: var(--secondary-dark-color, #54595e);
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 8px;
}

.page-title {
  color: #000;
  font-family: Roboto;
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
  /* 43.75% */
  letter-spacing: -0.64px;
}

.popup-title-warning {
  color: black;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 25px;
  padding-top: 6px;
}

.popup-subtitle-warning {
  color: #000;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.popup-btn-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-end;
  align-content: flex-end;
}

.popup-warning-container {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: start;
  align-content: start;
  width: 100%;
  padding: 37px 39px 18px;
}

.popup-container {
  display: flex;
  flex-direction: column;
}

.main-wrap {
  padding-bottom: 80px;
  padding-left: 15px;
}
body table.cstmGrzrTable {
  border-collapse: collapse !important;
}

body table.cstmGrzrTable th {
  color: var(--gray-60, #919699) !important;
  text-align: left;
  font-family: Roboto;
  font-size: 14px !important;
  font-style: normal;
  font-weight: 500 !important;
  line-height: 22px;
  letter-spacing: 0.1px;
  border-top: none !important;
  border-bottom: 2px solid #e3e5e5 !important;
  padding: 4.25px 10px 4.25px 0 !important;
  min-height: 40px;
  height: 40px !important;
  vertical-align: middle !important;
}

body table.cstmGrzrTable td {
  color: var(--gray-80, #5e6366);
  font-family: Roboto;
  font-size: 16px !important;
  font-style: normal;
  font-weight: 400 !important;
  line-height: 24px;
  letter-spacing: 0.44px;
  border-top: none !important;
  border-bottom: 1px solid #e3e5e5 !important;
  padding: 3px 10px 3px 0 !important;
  min-height: 40px !important;
  height: 40px !important;
  text-align: left !important;
  vertical-align: middle !important;
}

body table.cstmGrzrTable tr.hold {
  background: hsl(41, 100%, 94%);
}

body table.cstmGrzrTable thead {
  background: white;
}

body .react-form-builder-form table.cstmGrzrTable th {
  background: #eeeeee;
  color: #000000 !important;
  font-weight: bold;
}

.MuiPaper-elevation8 {
  border: 1px solid #e3e8ee !important;
}

.MuiMenuItem-root {
  color: #697386 !important;
  font-size: 12px !important;
}

.barcode-wrap.carcass-barcode > svg {
  max-width: 340px;
}

.barcode-wrap > svg {
  max-width: 250px;
  height: auto;
}

.MuiPaper-elevation8 {
  box-shadow: none !important;
  border: 1px solid black;
}

.MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]
  .MuiAutocomplete-input {
  padding: 2.5px 4px !important;
}

.trimmings-item {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: nowrap;
  align-items: flex-start;
  margin-bottom: 10px;
  max-width: 200px;
}

.table-header-search-input {
  position: relative;
  color: #aaa;
  font-size: 16px;
}

.btn-center {
  margin-left: 160px;
  margin-top: 5px;
}

.btn-size {
  max-width: 235px;
}

.btm-text {
  margin-top: 20px;
  margin-left: 120px;
}

.fa-close-cls {
  cursor: pointer;
  margin-left: 560px;
}

.model-height {
  height: auto;
}

.calender-margin {
  margin-top: 40px;
  margin-left: 120px;
}

/* .react-calendar__navigation__label
  {
    background-color: aquamarine !important;
  } */

.order-detail {
  padding-right: 20px;
}

.order-detail-x {
  cursor: pointer;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: end;
  align-items: center;
  align-content: center;
}

.order-detail-x:hover {
  cursor: pointer;
  font-weight: 700;
}

.order-detail-x-item {
  width: 20px;
  height: 20px;
  font-size: 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  border-radius: 50px;
  background: #e5e5e5;
}

.order-detail-container {
  border: 1px solid #e3e8ee;
  padding: 1rem;
  border-radius: 8px;
  width: 100%;
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
}

.order-detail-container-item {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}

.order-detail-wrap {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  grid-gap: 0;
  grid-gap: 0;
  grid-gap: 0;
  gap: 0;
  grid-column-gap: 18px;
  grid-column-gap: 18px;
  column-gap: 18px;
}

.order-detail-field-column {
  display: flex;
  flex-direction: column;
  justify-content: start;
  width: 220px;
}

.order-detail-field-column-wide {
  display: flex;
  flex-direction: column;
  justify-content: start;
  width: 100%;
  max-width: 500px;
}

.order-detail-container-input {
  display: flex;
  flex-direction: column;
}

.inline-form-group {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  align-content: start;
  margin: 0.5rem 1rem 0.5rem 0;
  width: 22%;
}

.inline-form-group-wide {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  align-content: start;
  margin: 0.5rem 0;
  grid-gap: 10px;
  gap: 10px;
}

.inline-form-group-wide > * {
  width: 16% !important;
  margin-right: 0 !important;
}

.inline-form-group-wide > span {
  display: block;
  padding: 8px;
}

.order-detail-input-line {
  box-shadow: 0px 1px 2px 0px #37415114;
  padding: 8px;
  border-radius: 4px;
  border: 1px;
  grid-gap: 10px;
  gap: 10px;
  border: 1px solid #e3e8ee;
  width: 116px;
}

.order-detail-label-line {
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: left;
  color: #697386;
  margin-bottom: 0;
  margin-right: 0.5rem;
}

.order-detail-input-line-wide {
  box-shadow: 0px 1px 2px 0px #37415114;
  padding: 8px;
  border-radius: 4px;
  border: 1px;
  grid-gap: 10px;
  gap: 10px;
  border: 1px solid #e3e8ee;
  width: 80px;
  margin-right: 0.5rem;
}

.order-detail-label-line-wide {
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: left;
  color: #697386;
  margin-bottom: 0;
  margin-right: 0.5rem;
  width: 80px;
}

.detail-heading-wrap {
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;
  grid-gap: 20px;
  gap: 20px;
  align-content: center;
  flex-direction: row;
  justify-content: flex-start;
  color: #000;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  /* 142.857% */
  letter-spacing: 0.25px;
}

.order-detail-input {
  width: 170px;
  box-shadow: 0px 1px 2px 0px #37415114;
  padding: 8px;
  border-radius: 4px;
  border: 1px;
  grid-gap: 10px;
  gap: 10px;
  border: 1px solid #e3e8ee;
  margin-bottom: 1rem;
}

.order-detail-label {
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: left;
  color: #697386;
  margin-bottom: 0;
}

.order-detail-trimming {
  width: 250px;
}

.note-box {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 10px;
  font-size: 14px;
}

.dialog-action-wrap {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  margin-top: 20px;
}

.order-detail-title-border {
  padding: 0.5rem 1rem;
  border: 1px solid #e3e8ee;
  font-size: 14px;
  border-radius: 8px;
  width: 150px;
  text-align: center;
}

.field-view-carcass-text {
  font-family: Roboto;
  font-size: 30px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.25px;
  text-align: left;
  margin: 2.5rem 0;
}

.order-detail-tab {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  margin-top: 20px;
}

.field-view-item {
  padding: 0.5rem 1rem;
  border: 1px solid #e3e8ee;
  font-size: 14px;
  border-radius: 8px;
  width: 100px;
  text-align: center;
  cursor: pointer;
}

.field-view-item:hover {
  background-color: #e0e0e0;
  color: black;
}

.active {
  background-color: #4f4f4f;
  color: white;
}

.order-detail-container-2-side {
  display: flex;
  flex-direction: row;
}

.order-detail-container-2-side-item {
  padding: 1rem;
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  width: 50%;
  justify-content: start;
  align-items: start;
  align-content: start;
}

.order-detail-container-2-side-title {
  font-size: 32px;
  font-weight: 400;
  text-align: center;
  margin: 1rem 0 60px 0;
}

.popup-modal-item-title-block {
  padding: 1rem 2rem;
  border-radius: 8px;
  background: var(--dark-medium, #4f4f4f);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  margin-bottom: 1rem;
  cursor: pointer;
  color: white;
  width: 200px;
}

.field-view-content {
  padding-top: 30px;
}

.order-detail-content-two-column {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.order-detail-container-mini {
  border: 1px solid #e3e8ee;
  padding: 1rem;
  border-radius: 8px;
  width: 100%;
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: start;
  position: relative;
}

.order-detail-container-mini .remove-box {
  font-size: 8px;
  position: absolute;
  right: 5px;
  top: 5px;
}

.order-detail-container-mini.activated {
  border-color: #000000 !important;
}

.order-detail-packaging-right {
  width: 230px;
  margin-left: 1rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-content: center;
}

.popup-table-mini-item {
  padding: 0 5px;
  margin: 0 5px 0 0;
  border: 1px solid black;
  font-size: 14px;
}

.popup-table-mini-item-white {
  padding: 0;
  margin: 0;
  color: grey;
  font-size: 14px;
}

.recipes-add-btn {
  padding: 1rem;
  border-radius: 8px;
  background: var(--dark-medium, #4f4f4f);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  margin-bottom: 1rem;
  cursor: pointer;
  color: white !important;
  width: 200px;
}

.recipes-add-btn:hover {
  opacity: 0.8;
}

.table-header-search-input {
  display: inline-block;
}

.table-header-search-input input {
  box-shadow: 0px 1px 2px 0px #37415114;
  padding: 6px;
  border-radius: 4px;
  border: 1px;
  grid-gap: 10px;
  gap: 10px;
  border: 1px solid #e3e8ee;
}

.table-header-search-input input {
  text-indent: 5px;
}

.table-header-search-input .fa-search {
  position: absolute;
  top: 10px;
  left: 10px;
}

.table-header-search-input .fa-search {
  left: auto;
  right: 25px;
  background-color: white;
}

.table-header {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  align-content: start;
}

.table-header-filter {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: start;
  align-content: start;
}

.table-header-search {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  align-content: center;
}

.table-header-item {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  align-content: start;
  margin-right: 1rem;
}

.table-header-item input {
  width: 200px;
}

.table-header-item select {
  width: 200px;
}

.table-header-item-label {
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: left;
  color: #697386;
  margin-bottom: 0;
}

.table-header-item-input {
  box-shadow: 0px 1px 2px 0px #37415114;
  padding: 8px;
  border-radius: 4px;
  border: 1px;
  grid-gap: 10px;
  gap: 10px;
  border: 1px solid #e3e8ee;
  margin-bottom: 1rem;
}

.file-upload {
  /* border: 1px solid black; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* padding: 1rem; */
}

.file-upload-title {
  font-size: 19px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left;
  margin-bottom: 30px;
}

.file-upload-btn {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  width: 100%;
}

.file-upload-btn-item {
  color: white !important;
  background: #4f4f4f;
  padding: 0.5rem 2rem;
  border-radius: 5px;
  cursor: pointer;
}

.file-upload-table {
  width: 100%;
  border: 1px solid #e3e8ee;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0.5rem;
  margin-top: 1rem;
  border-radius: 5px;
}

.file-upload-table-item {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem;
  /* border-bottom: 1px solid black; */
}

.download-button {
  cursor: pointer;
}

.download-button:hover {
  opacity: 0.8;
}

.button-separator {
  margin: 0 0.5rem;
}

.left-menu-item-create-new {
  display: flex;
  flex-direction: row;
  justify-content: star;
  align-items: center;
  align-content: center;
  padding: 1.5rem 2rem;
  border-radius: 99px;
  background: var(--white-base, #fff);
  box-shadow: 0px 6px 12px 0px rgba(54, 123, 245, 0.16),
    0px 16px 24px 0px rgba(54, 123, 245, 0.16);
  width: 250px;
  cursor: pointer;
}

.left-menu-item-create-new:hover {
  background: rgba(218, 218, 218, 0.16) !important;
}

.left-menu-item-create-new h1 {
  color: var(--blue-base, #367bf5);
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0.15px;
  margin-bottom: 0;
}

.calendar-popup-form-config-item {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  align-content: start;
  width: 100%;
  margin-bottom: 1rem;
}

.calendar-popup-form-config-item-label {
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: left;
  color: #697386;
  margin-bottom: 0;
}

.calendar-popup-form-config-item-input {
  box-shadow: 0px 1px 2px 0px #37415114;
  padding: 8px;
  border-radius: 4px;
  border: 1px;
  grid-gap: 10px;
  gap: 10px;
  border: 1px solid #e3e8ee;
  width: 300px;
  cursor: pointer;
}

.calendar-popup-form-config-item-input-dropdown {
  box-shadow: 0px 1px 2px 0px #37415114;
  border-radius: 4px;
  border: 1px;
  grid-gap: 10px;
  gap: 10px;
  border: 1px solid #e3e8ee;
  width: 300px;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  align-content: center;
  padding: 0.5rem;
  accent-color: #3c923f;
}

.calendar-popup-form-config-item-input-dropdown label {
  margin-bottom: 0;
}

.calendar-popup-form-container {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: start;
  align-content: start;
  padding: 2rem;
}

.calendar-popup-form-right {
  display: flex;
  flex-direction: column;
  justify-content: start;
  width: 100%;
}

.nav-link {
  padding: 0.5rem;
}

.radWrp input {
  accent-color: #3c923f;
}

.modal-header {
  border: none;
}

.proc-dash-info {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  margin-bottom: 5rem;
  grid-gap: 20px;
  gap: 20px;
}

.proc-dash-info-item:hover {
  background-color: var(--gray-10, #cacaca);
}

.proc-dash-info-item {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  align-content: start;
  border-radius: 16px;
  background: var(--gray-10, #f7f9fa);
  padding: 1rem;
  min-width: 200px;
  cursor: pointer;
}

.proc-dash-info-item span {
  color: var(--h-5-color, rgba(0, 0, 0, 0.47));
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0.15px;
  margin-bottom: 1rem;
}

.proc-dash-info-item h1 {
  font-size: 48px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.customer-popup-btn-no {
  width: 150px;
  border: 1px solid #4f4f4f !important;
  background: white;
  font-size: 15px;
  color: black;
  transition: all 0.3s ease-in-out;
  border-radius: 5px;
  margin-right: 1rem !important;
  text-transform: none !important;
}

.customer-popup-btn-x {
  color: white !important;
  background: #4f4f4f !important;
  padding: 0.5rem 1rem;
  border-radius: 10px;
  text-transform: unset !important;
  padding: 0.5rem 2rem !important;
}

.customer-popup-btn {
  color: white !important;
  background: #4f4f4f !important;
  padding: 0.5rem 1rem;
  border-radius: 10px;
  text-transform: unset !important;
  padding: 0.5rem 2rem !important;
}

.customer-popup-btn:hover {
  opacity: 0.8;
}

.customer-popup-form label {
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: left;
  color: #697386;
}

.customer-popup-form input {
  box-shadow: 0px 1px 2px 0px #37415114;
  padding: 8px;
  border-radius: 4px;
  border: 1px;
  grid-gap: 10px;
  gap: 10px;
  border: 1px solid #e3e8ee;
  margin-bottom: 1rem;
}

.customer-popup-header {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  align-content: center;
}

.customer-popup-info {
  width: 200px;
  border-bottom: 1px solid #f1f3f4;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-content: center;
  padding: 0.5rem;
  margin: 1rem 0;
}

.customer-popup-info span {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.25px;
  text-align: left;
}

.users-and-roles-form-x-item {
  width: 20px;
  height: 20px;
  font-size: 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  border-radius: 50px;
  background: #e5e5e5;
}

.organic-form-x-item {
  width: 20px;
  height: 20px;
  font-size: 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  border-radius: 50px;
  background: #e5e5e5;
}

.calendar-popup-form-x-item {
  width: 20px;
  height: 20px;
  font-size: 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  border-radius: 50px;
  background: #e5e5e5;
}

.calendar-popup-form-x {
  cursor: pointer;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: end;
  align-items: center;
  align-content: center;
  padding: 1rem;
  margin-top: 5rem;
}

.calendar-popup-form-x:hover {
  cursor: pointer;
  font-weight: 700;
}

.calendar-popup-form-slot-item-container {
  padding-top: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  align-content: start;
  width: 100%;
}

.calendar-popup-form-slot {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  align-content: start;
  padding: 0 2rem 2rem 2rem;
}

.calendar-popup-form-slot-header {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: start;
  align-content: start;
  padding: 0 2rem;
}

.calendar-popup-form-slot-header-icon {
  padding: 1rem;
  border-radius: 8px;
  background: var(--grey-light, #f5f5f5);
  margin-right: 1.5rem;
}

.calendar-popup-form-slot-header-data {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  align-content: start;
}

.calendar-popup-form-slot-header-data h1 {
  color: var(--secondary-dark-color, #54595e);
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.calendar-popup-form-slot-header-data span {
  color: rgba(84, 89, 94, 0.6);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
}

.calendar-popup-form-slot-item {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  align-content: start;
}

.calendar-popup-form-slot-item-input {
  box-shadow: 0px 1px 2px 0px #37415114;
  padding: 8px;
  border-radius: 4px;
  border: 1px;
  grid-gap: 10px;
  gap: 10px;
  border: 1px solid #e3e8ee;
  margin-bottom: 1rem;
  width: 400px;
}

.calendar-popup-form-slot-item-label {
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: left;
  color: #697386;
  margin-bottom: 0;
}

.calendar-popup-form-slot-item-btn {
  color: white !important;
  background: #4f4f4f !important;
  padding: 0.5rem 1rem;
  border-radius: 8px;
  text-transform: unset !important;
  padding: 0.5rem 2rem !important;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.calendar-popup-form-slot-item-btn-container {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  align-content: start;
  width: 400px;
  margin-bottom: 1rem;
}

.calendar-popup-form-slot-btn-container {
  display: flex;
  flex-direction: row;
  justify-content: end;
  align-items: center;
  align-content: center;
  width: 100%;
}

.calendar-popup-form-slot-btn-container-item {
  cursor: pointer;
  width: 100%;
}

.calendar-popup-form-slot-btn-container-item:hover {
  opacity: 0.8;
}

.calendar-popup-form-slot-btn {
  color: white !important;
  background: #4f4f4f !important;
  padding: 0.5rem 1rem;
  border-radius: 8px;
  text-transform: unset !important;
  padding: 0.5rem 2rem !important;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.calendar-popup-form-config {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  align-content: start;
  padding: 0 2rem 2rem 2rem;
}

.calendar-popup-form-config-header {
  margin-bottom: 2rem;
}

.calendar-popup-form-config-header h1 {
  color: #000;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  /* 100% */
  margin-bottom: 0;
}

.calendar-popup-form-config-header span {
  color: #989b9e;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  /* 142.857% */
}

.proc-profile-detail-item-input {
  /* box-shadow: 0px 1px 2px 0px #37415114; */
  padding: 8px;
  border-radius: 4px;
  border: none !important;
  grid-gap: 10px;
  gap: 10px;
  border: 1px solid #e3e8ee;
  margin-bottom: 1rem;
  width: 90%;
}

.proc-profile-btn {
  color: white !important;
  background: #4f4f4f;
  padding: 0.5rem 1rem;
  border-radius: 5px;
}

.proc-profile-btn-popup {
  width: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  cursor: pointer;
}

.proc-profile-btn-popup:hover {
  opacity: 0.8;
}

.change-password {
  width: 100%;
  padding: 0 2rem 2rem 2rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.popup-no-x {
  width: 100%;
  padding: 2rem 2.5rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.notif-title-item {
  color: white !important;
  background: #4f4f4f;
  padding: 0.5rem 1rem;
  border-radius: 5px;
}

.notif-table-btn {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: end;
  margin-bottom: 1rem;
}

.notif-title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: start;
  align-content: start;
}

.notif-container {
}

.notif-header {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.notif-header h1 {
  /* font-size: 34px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0.25px;
    text-align: left; */
}

.users-and-roles-form-menu {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  flex-wrap: wrap;
  width: 100%;
}

.popup-form-group-menu {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  align-content: start;
}

.popup-form-control {
  box-shadow: 0px 1px 2px 0px #37415114;
  padding: 8px;
  border-radius: 4px;
  border: 1px;
  grid-gap: 10px;
  gap: 10px;
  border: 1px solid #e3e8ee;
}

.popup-form-label {
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: left;
  color: #697386;
  margin-bottom: 0;
}

.users-and-roles-form-x {
  cursor: pointer;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: end;
  align-items: center;
  align-content: center;
}

.users-and-roles-form-x:hover {
  cursor: pointer;
  font-weight: 700;
}

.users-and-roles-btn {
  color: white !important;
  background: #4f4f4f;
  padding: 0.5rem 1rem;
  border-radius: 5px;
}

.users-and-roles-btn:hover {
  color: white;
  opacity: 0.8;
  cursor: pointer;
}

.popup-form-btn-user {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: end;
  align-items: center;
  align-content: center;
  padding: 1rem;
}

.popup-form-btn-user-item {
  color: white !important;
  background: #4f4f4f;
  padding: 0.5rem 1rem;
  border-radius: 5px;
  cursor: pointer;
}

.popup-form-btn-user-item:hover {
  opacity: 0.8;
}

.popup-form-btn {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-content: center;
}
/* 
.rrulegenerator .col-sm-1.offset-sm-2 {
  margin-left: 0;
  margin-right: 37px;
}

.rrulegenerator .offset-sm-2 {
  margin-left: 15px;
  margin-right: 10px;
}

#rrule-1-repeat-monthly-on-day {
  width: 80px;
}

#rrule-1-end {
  width: 100px;
}

#rrule-1-end-onDate-datetime {
  width: 150px;
} */

.users-and-roles-form {
  padding: 0.5rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: start;
  align-content: start;
}

.MuiPaper-rounded {
  border-radius: 8px !important;
}

.modal-dialog {
  margin-top: 100px !important;
}

.modal-content {
  border-radius: 8px !important;
}

.popup-form-group {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  align-content: start;
  /* padding: 1rem 0; */
}

.popup-form-group input {
  /* width: 250px; */
}

.popup-form-group select {
  /* width: 250px; */
}

.organic-form {
  padding: 0.5rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: start;
  align-content: start;
}

.organic-form-item {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  align-content: start;
  padding: 1rem;
}

.organic-form-item input {
  width: 250px;
}

.organic-form-item select {
  width: 250px;
}

.organic-form-x {
  cursor: pointer;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: end;
  align-items: center;
  align-content: center;
}

.organic-form-x:hover {
  cursor: pointer;
  font-weight: 700;
}

body .organic-form-btn {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-content: center;
  padding: 1rem 0.5rem;
  flex-wrap: nowrap;
}

.organic-form-btn-item {
  width: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  cursor: pointer;
  color: white !important;
  background: #4f4f4f;
  padding: 0.5rem 1rem;
  border-radius: 5px;
}

.new-order-header {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  align-content: center;
  padding: 0 2rem;
}
.remove-customer {
  position: absolute;
  top: -10px;
  right: -10px;
  z-index: 1;
  font-size: 10px;
  border-radius: 100px;
}
.remove-customer:hover {
  color: #000000 !important;
}

.popup-header-icon {
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-wrap: nowrap;
  width: 100%;
  max-width: 56px;
  height: 56px;
  border-radius: 8px;
  background: var(--grey-light, #f5f5f5);
  margin-right: 1.5rem;
}

.popup-header-wrap {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  align-content: start;
}

.popup-header-wrap h1 {
  color: var(--secondary-dark-color, #54595e);
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.popup-header-wrap span {
  color: rgba(84, 89, 94, 0.6);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  margin-bottom: 18px;
}

.list-customer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  grid-gap: 80px;
  gap: 80px;
}

.list-customer-item {
  display: flex;
  flex-direction: column;
  justify-content: start;
  width: 100%;
}
.event-detail-wrap {
  margin-bottom: 3rem;
}
.list-customer-item-map {
  margin-bottom: 3rem;
  display: flex;
  position: relative;
}

.new-order-form {
  /* padding: 1rem; */
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: start;
  align-content: start;
}

.new-order-form-item {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  align-content: start;
  width: 100%;
}

.new-order-form-item input {
  /* max-width: 350px; */
}

.new-order-form-item select {
  /* width: 350px; */
}

.new-order-form-label {
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: left;
  color: #697386;
  margin-bottom: 0;
}

.new-order-form-input {
  box-shadow: 0px 1px 2px 0px #37415114;
  padding: 8px;
  border-radius: 4px;
  border: 1px;
  grid-gap: 10px;
  gap: 10px;
  border: 1px solid #e3e8ee;
  margin-bottom: 1rem;
}

.popup-modal {
  padding: 1rem;
  display: flex;
  flex-direction: column;
}

.popup-modal-form-x-item {
  width: 30px;
  height: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  border-radius: 50px;
  background: #e5e5e5;
}

.popup-modal-x {
  cursor: pointer;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: end;
  align-items: center;
  align-content: center;
  padding: 1rem;
  margin-top: 5rem;
}

.popup-modal-x:hover {
  cursor: pointer;
  font-weight: 700;
}

.popup-modal-label {
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: left;
  color: #697386;
}

.popup-modal-btn-container {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  grid-gap: 15px;
  gap: 15px;
  margin-top: 1rem;
}

.popup-modal-btn {
  padding: 1rem 2rem;
  border-radius: 8px;
  background: var(--dark-medium, #4f4f4f);
  color: white !important;
  margin-top: 1rem;
  margin-left: 1rem;
  cursor: pointer;
}

.popup-modal-btn:hover {
  opacity: 0.8;
}

.schedule-modal {
  padding: 0;
}

.schedule-modal-item {
  display: block !important;
  margin-top: 2rem;
}

.new-order-list-btn {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  align-content: start;
}

.new-order-list-btn-item-cut-sheet {
  display: flex;
  flex-direction: row;
}

.new-order-list-btn-item-cut-sheet input {
  accent-color: #3c923f;
}

.new-order-list-btn-item-disabled {
  padding: 1rem 2rem;
  border-radius: 8px;
  background: var(--dark-medium, #808080);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  width: 100%;
  margin-bottom: 1rem;
  color: white !important;
}

.new-order-list-btn-item-disabled span {
  color: var(--grey-light, #f5f5f5);
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.new-order-list-btn-item {
  padding: 1rem 2rem;
  border-radius: 8px;
  background: var(--dark-medium, #4f4f4f);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  width: 100%;
  margin-bottom: 1rem;
  cursor: pointer;
  line-height: 1;
}

.new-order-list-btn-item:hover {
  opacity: 0.8;
}

.new-order-list-btn-item span {
  color: var(--grey-light, #f5f5f5);
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.new-order-list-btn-item-organs-label {
  margin-left: 0.5rem;
}

.new-order-list-btn-item-organs {
  display: flex;
  flex-direction: row;
  color: white;
}

.new-order-list-btn-item-organs-item {
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
}

.new-order-list-btn-item-organs-item-2 {
  padding: 0.5rem;
  display: flex;
  flex-direction: row;
}

.new-order-list-btn-item-organs-item-2 label {
  margin: 0.5rem 0.5rem 0.5rem 0;
}

.new-order-list-info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 6px;
  border: 1px solid #000;
  background: #fffbfb;
  padding: 1rem;
  height: 7rem;
}

.new-order-list-info-item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.new-order-list-info-item h1 {
  color: #000;
  font-size: 23px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  /* 86.957% */
  letter-spacing: 0.25px;
}

.new-order-list-info-item span {
  color: #fff;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  /* 142.857% */
}

.new-order-list-info-item-value {
  width: 30px;
  height: 30px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-content: center;
  background: #4f4f4f;
}

.new-order-list-btn-submit {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.new-order-list-btn-submit-item {
  padding: 10px 2rem;
  border-radius: 8px;
  background: var(--dark-medium, #4f4f4f);
  color: white !important;
  margin-top: 1rem;
  cursor: pointer;
  border: none;
  outline: none !important;
}

.new-order-list-btn-submit-item:hover {
  opacity: 0.8;
}

.users-and-roles-header {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  align-content: center;
}

.users-and-roles-info-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  align-content: flex-start;
}

.users-and-roles-info-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  align-content: start;
}

.users-and-roles-info-title {
  width: 200px;
  border-bottom: 1px solid #f1f3f4;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-content: center;
  padding: 0.5rem;
  margin: 1rem 0;
}

.users-and-roles-info-value {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.25px;
  text-align: left;
  margin: 0.2rem 0;
}

.cut-sheet-new-header {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  align-content: center;
}

.cut-sheet-new-info {
  width: 200px;
  border-bottom: 1px solid #f1f3f4;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-content: center;
  padding: 0.5rem;
  margin: 1rem 0;
}

.left-menu-item {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  align-content: start;
  width: 100%;
  margin: 0 1rem;
}

.left-menu-item-text {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: start;
  align-content: start;
  width: 15rem;
}

.left-menu-item-text h1 {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.4399999976158142px;
  text-align: left;
  color: black;
  margin-bottom: 0;
}

.left-menu-item-text span {
  font-size: 11px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.4000000059604645px;
  text-align: left;
  color: black;
}

.left-menu-item-icon {
  margin: 0.5rem 1rem;
}

.left-menu-items-active {
  background-color: #e2ffe3;
}

.left-menu-items {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  align-content: start;
  width: 100%;
  padding: 1rem 2rem;
  cursor: pointer;
}

.left-menu-items:hover {
  opacity: 0.8;
}

.left-menu-items h1 {
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.4399999976158142px;
  text-align: left;
  color: black;
  margin-bottom: 0;
}

.left-menu-items-button {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  align-content: center;
  margin: 1rem 2rem;
}

.G-zr.dashLayoutWrapper .main-dash h4 {
  font-family: Roboto;
  font-size: 23px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.25px;
  text-align: left;
  color: white;
  border-radius: 7px;
  padding: 0.7rem;
  background: #525252;
}

.item-detail {
  display: flex;
  flex-direction: column;
  justify-content: start;
  flex-wrap: nowrap;
  width: 100%;
}

.MuiDialog-paper {
  width: 100%;
}

.item-detail-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 700px;
}

.item-detail-btn {
  border: 1px solid #4e4e4e;
  background: #4e4e4e;
  padding: 10px 25px;
  font-size: 15px;
  color: #fff;
  transition: all 0.3s ease-in-out;
  border-radius: 8px;
}

.item-detail-label {
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: left;
  color: #697386;
  margin-bottom: 0;
}

.item-detail-input {
  box-shadow: 0px 1px 2px 0px #37415114;
  padding: 8px;
  border-radius: 4px;
  border: 1px;
  grid-gap: 10px;
  grid-gap: 10px;
  gap: 10px;
  border: 1px solid #e3e8ee;
  margin-bottom: 1rem;
  width: 200px;
}

.item-detail-x-item {
  width: 24px;
  height: 24px;
  font-size: 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  border-radius: 50px;
  background: #e5e5e5;
}

.item-detail-x {
  cursor: pointer;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: end;
  align-items: center;
  align-content: center;
  padding: 10px;
}

.item-detail-x:hover {
  cursor: pointer;
  font-weight: 700;
}

.dashLayoutWrapper {
  padding: initial !important;
}

.fc-toolbar h2 {
  font-size: 32px;
}

table.cstmGrzrTable td,
table.cstmGrzrTable th {
  color: #919699;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0.10000000149011612px;
  text-align: left;
}

table.cstmGrzrTable td {
  font-size: 16px;
}

#print-labels {
  display: flex;
  grid-gap: 10px;
  grid-gap: 10px;
  gap: 10px;
  grid-column-gap: 10px;
  column-gap: 10px;
  flex-wrap: wrap;
}

#print-labels > img {
  border: 1px solid #000000;
  width: 45%;
}

.cstmGrzrTable thead {
  background: white;
}

body .actvOrdrsWrpr .tableTitle {
  color: #b4b4b4;
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
}

.G-zr .childrenValues .Dash-right .navSetup .nav-item {
  border: 1px solid #bebebe;
  border-radius: 10px;
  color: black;
  margin-right: 100px;
}

.G-zr .childrenValues .Dash-right .navSetup .activepnav {
  border-radius: 10px;
  background: #4f4f4f !important;
}

.G-zr .mnDashLayoutWrpr {
  background: none !important;
}

.G-zr .tabVwWrapper .cutGridLayout {
  display: flex !important;
  flex-wrap: wrap;
}

.G-zr .tabVwWrapper .cutGridLayout .cutSheets {
  border: 1.5px solid #4FD1C5;
  width: 23%;
  padding: 10px 30px;
}

.G-zr .tabVwWrapper .cutGridLayout .cutSheets:hover {
  /* background: #4F4F4F; */
}

.G-zr .cstmrLkpPpup .modal-title span {
  color: red;
}

.G-zr .Dash-right .tabVwWrapper .table tbody td {
  font-size: 16px;
}

.modal-body.dialog-popup-body {
  padding: 0 40px 38px;
}

.Dash-container {
  margin-top: 30px;
  /* margin-right:2rem; */
}
.calendar-sidebar .otherSlots h3 {
  background: none !important;
  font-size: 16px !important;
  padding: 0 !important;
  color: #b4b4b4 !important;
  font-family: "Roboto" !important;
}
.G-zr.dashLayoutWrapper .calendar-sidebar > .otherSlots > ul > li > h4 {
  background: none !important;
  font-size: 14px !important;
  padding: 0 !important;
  color: #212529 !important;
  font-family: "Roboto" !important;
}
.calendar-sidebar .otherSlots ul {
  list-style-type: none;
  padding-left: 0;
}

.calendar-sidebar .otherSlots li {
  font-size: 12px;
}
.calendar-sidebar .otherSlots h4::after {
  display: none !important;
}

@media (max-width: 768px) {
  .d-flex .align-items-start .flex-mb-wrap .mnDashLayoutWrpr {
    width: none;
  }

  .left-menu-item {
    margin: 0;
  }

  .container {
    overflow-y: scroll;
    height: 100vh;
    padding-bottom: 5rem;
    margin-top: 1rem;
  }

  .proc-dash-info {
    flex-direction: column;
    margin-bottom: 3rem;
  }

  .proc-dash-info-item span {
    font-size: 16px;
    margin: 0;
  }

  .proc-dash-info-item h1 {
    font-size: 20px;
    margin: 0;
  }

  .proc-dash-info-item {
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
  }

  .calendar-search {
    flex-direction: column !important;
    grid-gap: 1rem;
    gap: 1rem;
  }

  .tableTitle {
    margin-top: 3rem !important;
  }

  .calendar-header-left {
    margin-bottom: 1rem;
  }

  .agendaCnt {
    width: 100%;
  }

  .Dash-container {
    margin-right: 0;
    margin-top: 3rem;
  }

  .actvOrdrsWrpr {
    margin-top: 1rem;
  }

  .left-menu-item-create-new {
    padding: 1.5rem 2rem !important;
  }

  .list-customer {
    flex-direction: column;
  }

  .new-order-list-btn-item-disabled {
    text-align: center;
  }

  .new-order-list-btn-item {
    text-align: center;
  }

  .new-order-list-btn-submit {
    flex-direction: column;
  }

  .new-order-list-btn-submit-item {
    text-align: center;
  }

  .modal-body {
    overflow-x: auto;
  }

  /* .fc-direction-ltr {
    width: 220px;
  } */

  .fc-toolbar h2 {
    font-size: 28px !important;
    text-align: start;
    margin-bottom: 1rem !important;
  }

  .organic-form-btn {
    flex-direction: column !important;
    grid-gap: 1rem;
    gap: 1rem;
  }

  .btn-secondary {
    margin: 0 !important;
  }

  .item-detail {
    overflow-x: hidden;
  }

  .table {
    width: 100vw;
  }

  .modal-body.dialog-popup-body {
    padding: 1.5rem;
  }

  .list-customer-item-map {
    margin-bottom: 0;
  }

  .new-order-list-btn-item-organs-item-2 {
    flex-direction: column;
  }

  .table-header-item {
    margin: 0;
  }

  .table-header-item select {
    width: 100px;
  }

  .table-header-filter {
    justify-content: center;
    grid-gap: 1rem;
    gap: 1rem;
  }

  .Dash-right {
    padding: 1.5rem;
  }

  .profile-container {
    padding: 1.5rem;
    height: 100vh;
  }

  .proc-profile-menu-btn {
    flex-direction: column !important;
    grid-gap: 1rem;
    gap: 1rem;
    align-items: center !important;
  }

  .btn-tab {
    width: 200px;
  }

  .page-title {
    line-height: 30px;
  }

  .user-and-roles-container {
    padding: 1.5rem;
    margin: 0 !important;
    height: 100vh;
  }

  .navSetup {
    background: white !important;
  }

  .cutSheets {
    padding: 1rem;
    border: 1px solid #358539 !important;
    margin-top: 1rem;
    border-radius: 10px;
  }

  .cutSheetsNew {
    border: 1px dashed #358539 !important;
  }

  .cut-sheet-new-header-container {
    padding: 1.5rem;
    height: 100vh;
    overflow-y: auto;
  }

  .cut-sheet-new-button {
    flex-direction: column !important;
    grid-gap: 1rem;
    gap: 1rem;
    padding-bottom: 1rem;
  }

  .btn-danger {
    margin: 0 !important;
  }

  .calendar-popup-form-container {
    flex-direction: column;
    padding: 0;
    overflow-x: hidden;
  }

  .calendar-popup-form-slot {
    padding: 0;
    width: 100vw;
  }

  .calendar-popup-form-slot-data h1 {
    font-size: 20px;
  }

  .calendar-popup-form-slot-item-input {
    width: 200px !important;
  }

  .calendar-popup-form-slot-item-btn {
    width: 200px !important;
    text-align: center;
  }

  .calendar-popup-form-slot-data {
    width: 200px !important;
  }

  .calendar-popup-form-slot-data-container {
    width: 200px !important;
  }

  .calendar-popup-form-right {
    align-items: center;
    justify-content: center;
  }

  .calendar-popup-form-slot-btn-container-item {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding-bottom: 1rem;
  }

  #root {
    height: 100vh;
  }

  .order-detail {
    padding: 1rem;
  }

  .order-detail-container-input {
    width: 100%;
  }

  .order-detail-container-item {
    grid-gap: 1rem;
    gap: 1rem;
  }

  .order-detail-wrap {
    flex-direction: column;
    margin-bottom: 1rem;
  }

  .inline-form-group {
    width: 100%;
  }

  .order-detail-input-line {
    width: 200px;
  }

  .justify-content-end {
    align-content: center;
    flex-wrap: wrap;
    grid-gap: 1rem;
    gap: 1rem;
  }

  .order-detail-container-2-side-title {
    font-size: 20px;
  }

  .order-detail-tab {
    flex-wrap: wrap;
    grid-gap: 0.5rem;
    gap: 0.5rem;
  }

  .order-detail-content-two-column {
    flex-wrap: wrap !important;
    justify-content: center;
  }

  .table-responsive {
    margin: 0 !important;
  }

  .order-detail-packaging-right {
    margin-top: 1rem;
    margin-left: 0;
  }

  .order-detail-trimming {
    width: 100%;
    margin-top: 1rem;
  }

  .popup-page-container-item {
    flex-wrap: wrap;
    grid-gap: 1rem;
    gap: 1rem;
  }

  .popup-page-container-input {
    margin: 0;
  }

  .popup-page-input-line-wide {
    width: 30px !important;
  }

  .align-items-center {
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;
    display: flex;
    align-content: flex-start;
    flex-wrap: wrap;
  }

  .customer-popup-btn {
    border-radius: 8px !important;
    margin-bottom: 3rem !important;
  }
}

.right-2{
    right:2em;
}
.top-2{
    top:1em;
}
.top-5{
    top:5em;
}
.ml-11{
    margin-left: 11em;    
}
.Addmodal{
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1050;
    /* display: none; */
    overflow: hidden;
    outline: 0;
}
.marginSubmitButton{
    margin: 1em 0em 1em 10em;
}
.zindex{
    z-index: 9999999999;
}
#Add-specf-form{
    margin:1em;
    border-bottom: 1px solid #dee2e6;
}
#Add-specf-form .inp p{
    position: absolute;
    top: -8px;
    /* z-index: 9999999; */
    left: 15px;
    background: #fff;
    font-size: 14px;
    margin: 0;
    padding: 0px 5px;
}
#Add-specf-form .row1{
    width: 100%;
    margin: 0;
    padding: 0;
}

#Showevents .row{display: flex;
border: 0;
flex-direction: row;
justify-content: space-between;
border-bottom: 1px solid #2f7d33;
margin: 1em;
box-shadow: 0px 1px 4px 1px lightgrey;
height: 3em;
padding: 0.5em;
}
#Showevents .acrbutton{
    border: 1px solid #358539;
    padding: .2em;
    border-radius: 6px;
    box-shadow: 0px 0px 1px 0px #358539;
}
#Showevents .selectOptiom{
    display: flex;
    border: 0;
    flex-direction: row;
    justify-content: space-between;
    margin: 1em;
    height: 2.5em;
}
#Showevents .Header{
    background: #2f7d33;
    color: white;
    box-shadow: 0px 0px 2px 2px #2f7d33;
    border-bottom: #2f7d33;    
}
#Showevents .headid{
    margin-top: 0px;
}

#Showevents .row i{
color:#358539
}

.select-head{
border: 0;
box-shadow: 2px 1px 6px 1px lightgrey;
}

#Showevents .tooltip {
    position: relative;
    display: inline-block;
    border-bottom: 1px dotted black;
  }
  
  #Showevents .tooltip .tooltiptext {
    visibility: hidden;
    width: 120px;
    background-color: #555;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    bottom: 125%;
    left: 50%;
    margin-left: -60px;
    opacity: 0;
    transition: opacity 0.3s;
  }
  
  #Showevents .tooltip .tooltiptext::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #555 transparent transparent transparent;
  }
  
  #Showevents .tooltip:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
  }
.inneraccordian{
    /* background: #F7F7DE; */
    margin-top: -1em;
    width: 56em;
    padding: 1em;
     margin-left: 1em;
}
#Showevents .inneraccordianrow{display: flex;
    border: 0;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: 1px solid #2f7d33;
    margin: 1em;
    box-shadow: 0px 1px 4px 1px lightgrey;
    height: 3em;
    padding: 0.5em;
    background:rgba(196, 255, 171, 0.5)
    }
/* @import '~@fullcalendar/core/main.css'; */
/* @import '~@fullcalendar/daygrid/main.css'; */
.slotNo {
  box-shadow: 0px 0px 3px 0px grey;
  margin: 10px;
  cursor: pointer;
}
.yellow {
  background: yellow;
}

.blue {
  background: #167982;
}

.green {
  background: green;
}

.text-white {
  color: white;
}

.btnred {
  background: red;
  background-color: red;
  color: red;
}
.calenderPop {
  border: none;
  padding: 2em;
  background: white;
  z-index: 9999999;
  left: 0;
  position: absolute;
  margin: -5em 0em 0em -8em;
  top: 0;
  width: 150px;
  box-shadow: 1px 1px 7px 3px lightgrey;
  height: 150px;
  border-radius: 1em;
  transform: translate(-22px, 62px);
}
.cursor-pointer {
  cursor: pointer;
}

.agendaCnt {
  display: grid;
  /* grid-template-columns: 4fr 1fr; */
  grid-gap: 30px;
  gap: 30px;
}

.agendaCnt .otherSlots {
  background: #f6f6f6;
  padding: 15px;
  height: -webkit-max-content;
  height: max-content;
}

.agendaCnt .otherSlots h3 {
  font-size: 16px;
  margin-bottom: 15px;
  font-weight: 700;
  color: #358539;
  text-transform: uppercase;
}

.agendaCnt .otherSlots ul {
  padding: 0;
  margin: 0;
}

.agendaCnt .otherSlots ul li {
  list-style: none;
  color: #333;
  padding: 8px 0;
  margin: 0;
}

.agendaCnt .otherSlots ul li h4 {
  font-size: 12px;
  font-weight: 600;
  margin: 0;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 1;
}

.agendaCnt .otherSlots ul li p {
  margin: 5px 0 0;
  font-size: 12px;
  font-weight: 400;
}

.agendaCnt .otherSlots ul hr {
  margin: 5px 0;
}

.fc-content{
  border: none;
  background: rgba(0, 0, 0, 0);
}

.fc-event-title{
  color: #000 !important;
}

.dropoff-calendar .fc-event-title{
  color: #ffffff !important;
  cursor: pointer;
}

.fc-event, .fc-event-dot{
  background-color: rgba(0, 0, 0, 0);
}

.fc-event{
  border: none;
  cursor: pointer;
}
.fc-event:hover{
  background: rgb(60, 146, 63);
  color: #ffffff;
}

.fc-event:hover .fc-event-title{
  color: #ffffff !important;
}
.fc-day.selected-dropoff{
  background: rgba(60, 146, 63, 0.3);
}

@media (max-width: 1200px) {
  .agendaCnt {
    display: block;
  }

  .agendaCnt .otherSlots {
    margin-top: 30px;
  }
}




.btn-center{
    margin-left: 160px;
    margin-top:5px;
}

.btn-size{
    max-width: 235px;
}

.btm-text
{
    margin-top:20px;
    margin-left: 120px;
}

.fa-close-cls
{ 
    cursor: pointer;
    margin-left: 560px;    
}

.model-height
{
    height: auto;
}
.calender-margin
{
    margin-top:40px;
    margin-left: 120px;
}
/* .react-calendar__navigation__label
{
    background-color: aquamarine !important;
} */

.popup-page{
    padding: 0.5rem;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
    align-content: center;
    background-color: white;
    border: 1px solid #E3E8EE;
}

.popup-page-x{
    cursor: pointer;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: end;
    align-items: center;
    align-content: center;
}

.popup-page-x:hover{
    cursor: pointer;
    font-weight: 700;
}

.popup-page-x-item{
    width: 20px;
    height: 20px;
    font-size: 12px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
    border-radius: 50px;
    background: #E5E5E5;
}

.popup-page-container-no-boder{
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    border: none;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
}

.popup-page-container-item{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 1rem;
}

.popup-page-container-item-column{
    display: flex;
    flex-direction: column;
    justify-content: start;
    width: 200px;
}

.popup-page-container-item-column-wide{
    display: flex;
    flex-direction: column;
    justify-content: start;
    width: 500px;
}

.popup-page-container-input{
    display: flex;
    flex-direction: column;
}

.popup-page-container-input-line{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    align-content: start;
    margin: 0.5rem 0;
}

.popup-page-container-input-line-wide{
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    align-content: start;
    margin: 0.5rem 0;
}

.popup-page-input-line{
    box-shadow: 0px 1px 2px 0px #37415114;
    padding: 8px;
    border-radius: 4px;
    border: 1px;
    grid-gap: 10px;
    gap: 10px;
    border: 1px solid #E3E8EE;
    width: 50px;
}
  
.popup-page-label-line{
    font-size: 12px;
    font-weight: 600;
    line-height: 16px;
    letter-spacing: 0px;
    text-align: left;
    color: #697386;
    margin-bottom: 0;
    margin-right: 0.5rem;
}

.popup-page-input-line-wide{
    box-shadow: 0px 1px 2px 0px #37415114;
    padding: 8px;
    border-radius: 4px;
    border: 1px;
    grid-gap: 10px;
    gap: 10px;
    border: 1px solid #E3E8EE;
    width: 80px;
    margin-right: 0.5rem;
}
  
.popup-page-label-line-wide{
    font-size: 12px;
    font-weight: 600;
    line-height: 16px;
    letter-spacing: 0px;
    text-align: left;
    color: #697386;
    margin-bottom: 0;
    margin-right: 0.5rem;
    width: 80px;
}

.switch-wrap{
    width:80px;
    height:36px;
    padding:0 8px !important;
}

.popup-page-input{
    box-shadow: 0px 1px 2px 0px #37415114;
    padding: 8px;
    border-radius: 4px;
    border: 1px;
    grid-gap: 10px;
    gap: 10px;
    border: 1px solid #E3E8EE;
}

.popup-page-input-checkbox{
    box-shadow: 0px 1px 2px 0px #37415114;
    padding: 8px;
    border-radius: 4px;
    border: 1px;
    grid-gap: 10px;
    gap: 10px;
    border: 1px solid #E3E8EE;
    margin-bottom: 1rem;
    width: 70px;
}
  
.popup-page-label{
    font-size: 12px;
    font-weight: 600;
    line-height: 16px;
    letter-spacing: 0px;
    text-align: left;
    color: #697386;
    margin-bottom: 0;
}

.popup-page-title-border{
    padding: 0.5rem 1rem;
    border: 1px solid #E3E8EE;
    font-size: 14px;
    border-radius: 8px;
    width: 150px;
    text-align: center;
}

.popup-page-title-fill{
    padding: 0.5rem 1rem;
    font-size: 14px;
    border-radius: 8px;
    width: 150px;
    text-align: center;
    background-color: #525252;
    color: white;
}

.popup-page-title{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: start;
    padding: 1rem;
}

.popup-multiple-checkbox{
    width: 100%;
    max-width:300px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: start;
    align-items: flex-start;
    align-content: center;
    border: 1px solid #E3E8EE;
    padding: 1rem;
    border-radius: 8px;
    background: white;
    position: absolute;
    margin-top: 35px;
    z-index: 999;
}

.popup-multiple-checkbox label{
    width: 100px;
    font-size:14px;
    font-family: Roboto;
    color: var(--gray-500, #697386);
}

.popup-multiple-checkbox input[type=checkbox]{
    margin-right:5px;
}


.G-zr .msg-head {
    background: #362F2C;
    padding: 10px 20px;
}
.G-zr .Dash-right .msg-head .w-92p {
    width: 88%;
}
.G-zr .msg-head .w-92p {
    width: 92%;
}
.G-zr .msg-head img {
    min-width: 44px;
    width: 44px;
    object-fit: cover;
    object-position: center;
}
.G-zr .fw-m {
    font-weight: 500;
}

.G-zr .fs-16 {
    font-size: 16px !important;
}
.G-zr .fs-15 {
    font-size: 12px
     /* !important; */
}
.G-zr .Dash-right .msg-body {
    background: #eee;
    border-top: 10px solid #fff;
    background-image: url(/images/msg-bg.png);
    border-bottom: 10px solid #fff;
}

.G-zr .msg-body {
    min-height: 250px;
    height: 423px;
    background: #fff;
    border-top: 10px solid #f1f1f1;
    border-bottom: 10px solid #f1f1f1;
    padding: 30px 20px 20px 20px;
    overflow: auto;
    background-image: url(/images/msg-bg.png);
    background-size: cover;
    background-repeat: no-repeat;

}

.G-zr .Dash-right .msg-body .reciver-msg h6, .G-zr .Dash-right .msg-body .sender-msg h6 {
    background: #ffffff;
}

.G-zr .Dash-right .msg-body .sender-msg h6 {
    background: #57A15A;
}
.G-zr .msg-fot .input-group {
    background: #fff;
    border: 1px solid #358539;
    height: 50px;
    overflow: hidden;
    border-radius: 2px;
}
.G-zr .msg-fot input {
    height: 50px;
    border: none;
    position: relative;
    font-style: italic;
    font-size: 14px;
}
.G-zr .Dash-right .msg-list-head .comunity-sch {
    box-shadow: 0px 2px 6px 0px #0000002e;
    height: 62px;
    margin-bottom: 10px;
}
.G-zr .Dash-right .msg-list-head .comunity-sch .input-group input {
    border: none;
    height: 62px;
}
.G-zr .Dash-right .msg-list-head .comunity-sch .input-group-prepend span.input-group-text {
    background: none !important;
}

.G-zr .msg-list {
    background: #EEEEEE;
    padding: 10px;
    min-height: 423px;
    overflow: auto;
    height: 423px;
}

.G-zr .msg-list .B-L {
    cursor: pointer;
    background: white;
    padding: 10px 5px;
    border-radius: 5px;
    margin-bottom: 10px;
    height: 70px;
    min-height: 70px;
}
.G-zr .msg-list figure {
    width: 20%;
    position: relative;
}
.G-zr .msg-list img {
    width: 44px;
    min-width: 44px;
    height: 44px;
    border-radius: 100px;
}
.G-zr .msg-list .list-bdy {
    width: 80%;
}

.G-zr .breadcrumb-item+.breadcrumb-item::before{
    content: ">"!important;
    font-weight: 600;
    color: #358539
}
.G-zr .side-Dash ul{
    list-style-type: none;
    background: #fff;
    box-shadow: 0px 7px 15px 0px #0000001f;
}

.G-zr .side-Dash ul li:hover,.G-zr .side-Dash ul li:focus{cursor:pointer;background: #358539;color: #fff;transition: all 0.8s cubic-bezier(0.22, 0.61, 0.36, 1);}


.G-zr .Dash-right .pro-file img {
    width: 120px;
    height: 120px;
    object-fit: cover;
    margin: auto;
    border-radius: 100px;
}

.G-zr .side-Dash ul li.active{
    background: #358539;
    position: relative;color: #fff;
}

.G-zr .Dash-right .table tbody td {
    background: #fff;
}
.G-zr .Dash-right .table thead th {
    vertical-align: top;
    /* text-align: center; */
    border-bottom: 2px solid #E3E5E5;
    border-top:none;
    font-size: 14px;
    font-weight: 500;
    padding: 0;
    background: #fff;
    padding-bottom: 5px;
    padding-top: 5px;
    color: #919699;
}
.G-zr .Dash-right .table .tbody tr{
    background: #F3F3F3;
}
.G-zr .Dash-right .table tbody {background: #F3F3F3;}
.G-zr .Dash-right .table tbody td b{
    width:115px;
    overflow:hidden;
    white-space:nowrap;
    text-overflow: ellipsis;
    display: block;
    font-weight:400;
}.G-zr .Dash-right .table tbody td {
    vertical-align: middle;
    border-top:none;
    height: 45px;
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    padding-top: .5em;
    padding-bottom: .5em;
    border-bottom:2px solid #dbdbdb;
}

.G-zr .Dash-right .nav-tabs {
    border-bottom: 1px solid #358539;
}
.react-tabs__tab--selected {
    font-size: 16px;
    font-weight: 500;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    color: #ffffff;
    background-color: #358539;
    border: 1px solid #358539;
}
.G-zr .Dash-right .nav-tabs .nav-item {
    cursor: pointer;
    margin-bottom: 0px;

}
/* .G-zr .Dash-right .nav-tabs .nav-link {
    border: 1px solid #F3F3F3;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    background: #F3F3F3;
    font-size: 16px;
    font-weight: 500;
    color: #358539;
} */

.G-zr .progress-form .sub-pro img{
    width: 115px;
    height: 52px;
    object-position: center;
    object-fit: cover;
    border-radius: 6px;
}
.G-zr .progress-form.form-col p.t-t{left: 30px;}
.G-zr .progress-form p.t-t{
    top: -8px;
    background: #fff;
    left: 15px;
    padding: 0 12px;
    font-size: 12px;
    font-weight: 500;
}
.G-zr .D-input {
    border: 1px solid #DEDEDE;
    font-size: 15px;
    font-weight: 400;
    height: 45px;
    color: #929292;
    border-radius: 2px;
    padding: 0 25px;
}
.G-zr .txt-area {
    height: 100px!important;
    padding: 20px 25px!important;
}
.up-load{position: relative;}
.up-load input[type=file] {
    left: 0;
    top: 0;
    bottom: 0;
    opacity: 0;
    padding: 0px;
    position: absolute;
    cursor: pointer;
    width: 100%;
    line-height: 33px;
}

.numberfield::-webkit-inner-spin-button, 
.numberfield::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}

@media (max-width: 600px) {
    .progress-form .PrfDts .col{
        max-width: -webkit-fit-content;
        max-width: -moz-fit-content;
        max-width: fit-content;
        width: auto;
        min-width: auto;
    }

    .gfrWrpr{
        flex-wrap: wrap;
    }
}

.Sidebar_Sidebar__3C2mM {}
.montserrat {
  font-family: "Montserrat", sans-serif;
}

.G-zr .fs-14 {
  font-size: 14px;
}

.G-zr .fs-15 {
  font-size: 15px;
}

.G-zr .fs-16 {
  font-size: 16px !important;
}

.G-zr .fs-18 {
  font-size: 18px !important;
}

.G-zr .fs-20 {
  font-size: 20px !important;
}

.G-zr .fw-m {
  font-weight: 500;
}

.G-zr .fw-sm {
  font-weight: 600;
}

.G-zr .main-dash .da-sh img {
  /* width: 58px;
    height: 58px; */
  object-fit: contain;
}

.G-zr .main-dash a:hover {
  text-decoration: none;
}

.G-zr.dashLayoutWrapper .main-dash h4 {
  letter-spacing: 1px;
  color: gray;
  font-size: 20px !important;
  font-weight: bold;
  font-family: "Raleway", sans-serif;
  margin-top: 15px;
}

.cntNumber {
  font-weight: 600;
  font-size: 45px;
  color: black;
}

.G-zr.dashLayoutWrapper .main-dash .da-sh {
  background: #fff;
  display: flex;
  flex-flow: column;
  align-items: center;
  width: 320px;
  height: -webkit-max-content;
  height: max-content;
  justify-content: stretch;
  border-radius: 10px;
  box-shadow: 0px 0px 10px 0px #00000042;
  border: none;
  margin-bottom: 20px;
  padding: 30px 30px;
}

.G-zr.dashLayoutWrapper .main-dash h4::after {
  content: "";
  position: absolute;
  background: #358539;
  left: 0;
  right: unset;
  bottom: -5px;
  width: 34px;
  border-radius: 100px;
  height: 2px;
}

.G-zr .main-dash .da-sh.bg-none {
  background: transparent;
  box-shadow: none;
}

.G-zr .btn-gradien {
  background: linear-gradient(45deg, #41933b, #1ab97a);
  border-radius: 100px;
  padding: 8px 24px;
  color: #fff;
  font-size: 12px;
  font-weight: 500;
}

.act-cen .ac-scrollbar {
  overflow-y: scroll;
  padding-right: 10px;
  width: 100%;
}

.dashLayoutWrapper {
  padding: 20px 60px;
}

.dashLayoutWrapper .topMnContainer {
  min-height: 166px;
}

.dashLayoutWrapper .topMnContainer .lftContainer p {
  color: #7f8fa4;
  font-size: 13px;
  margin-top: 10px;
}

table.cstmGrzrTable td,
table.cstmGrzrTable th {
  padding: 15px;
  font-size: 15px;
}

table.cstmGrzrTable td {
  font-family: "Montserrat";
}

.cstmGrzrTable thead {
  background: #ececec;
}

.actvOrdrsWrpr {
  /* padding-left: 15px;
  padding-right: 15px; */
}

.actvOrdrsWrpr .tableTitle {
  color: #358539;
  font-weight: bold;
  font-size: 16px;
  margin-top: 34px;
  margin-bottom: 15px;
}

.actvOrdrsWrpr .icnButton {
  background: transparent;
  padding: 0;
}

.actvOrdrsWrpr .icnButton.downloadBtn {
  color: #3b6dd1;
}

.actvOrdrsWrpr .icnButton.cancelButton {
  color: #dc6c6c;
}

.actvOrdrsWrpr .icnButton.chckButton {
  color: #358539;
}

.actvOrdrsWrpr .icnButton.editButton {
  color: #ffffff;
  background: #5e6366;
  border-radius: 100px;
  padding:3px 3px;
  line-height: 0;
  margin-bottom:7px !important;
  display:block;
}
.actvOrdrsWrpr .icnButton.editButton .material-icons{
  font-size:14px;
}


.swtchTgleDiv {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 200px;
  margin-bottom: 25px;
}

.swtchTgleDiv .nvButton {
  background: #e3f5e4;
  color: #358539;
  height: 35px;
  padding: 10px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
}

.swtchTgleDiv .nvButton.txtContnr {
  margin: 0 10px;
}

.tableButton {
  padding: 10px;
  width: -webkit-max-content;
  width: max-content;
}

.noRecordsFound {
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: whitesmoke;
  color: gray;
  font-weight: 500;
}

.sdNavWrpr a img {
  height: 50px;
}

.G-zr .main-dash.sdNavWrpr a.active {
  background: #e2ffe3;
}

.G-zr .main-dash.sdNavWrpr a.active h4 {
  color: green;
}

.itmDetailsWrpr .pdtDetails .imgWrapper img {
  width: 90px;
  height: 90px;
  border-radius: 5px;
  object-fit: cover;
}

.itmDetailsWrpr .pdtDetails {
  display: flex;
  align-items: flex-start;
}

.itmDetailsWrpr .pdtDetails .pdtInfoDtls {
  margin-left: 25px;
}

.itmDetailsWrpr .pdtDetails .pdtInfoDtls h3 {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 15px;
}

.itmDetailsWrpr .pdtDetails .pdtInfoDtls p {
  font-size: 16px;
  margin-bottom: 10px;
}

.itmDetailsWrpr .pdtDetails .pdtInfoDtls span {
  font-weight: 500;
}

.acrdnWrapper .acrdnTitle {
  background: whitesmoke;
  padding: 10px 15px;
  margin-top: 25px;
}

.acrdnWrapper .acrdnInr {
  padding: 10px 15px;
}

.acrdnWrapper .acrdnInr .col-md-6 {
  margin-bottom: 10px;
}

.acrdnWrapper .acrdnInr .col-md-6.text-right {
  font-weight: 500;
}

.trck-lfpop.itmDtlPopup {
  min-height: -webkit-max-content;
  min-height: max-content;
  height: -webkit-max-content;
  height: max-content;
  max-height: 600px;
  overflow: hidden;
}

.trck-lfpop.itmDtlPopup .modal-body {
  overflow-y: auto;
}

.G-zr .childrenValues .Dash-right .navSetup {
  background: white;
  border-bottom: none;
}

.G-zr .childrenValues .Dash-right .navSetup .nav-item {
  border: 1px solid #358539;
  border-radius: 4px;
  color: #358539;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  margin-right: 10px;
}

.G-zr .childrenValues .Dash-right .navSetup .activepnav {
  border-radius: 4px;
  background: #358539 !important;
  color: white !important;
}

.srchBox {
  display: flex;
  align-items: center;
}

.srchBox input {
  height: 40px;
  border-radius: 4px;
  border: 1px solid #9b9a9a;
  padding: 10px;
  margin-right: 10px;
}

.plnButton {
  color: #358539;
  display: flex;
  align-items: center;
  margin-left: auto;
  margin-top: 25px;
}

.btn-green.btn-outline {
  border: 1px solid #358539;
  background: transparent;
  color: #358539;
}

.G-zr .mnDashLayoutWrpr {
  background: url(/images/icon/Dash-bg.svg) repeat;
  background-size: contain;
  background-position: center;
  padding: 20px;
}

.G-zr .tabVwWrapper {
  padding: 30px 0;
}

.G-zr .tabVwWrapper .cutGridLayout {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
  grid-gap: 20px;
  gap: 20px;
}

.G-zr .tabVwWrapper .cutGridLayout .cutSheets {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  background: white;
  border: 1.5px solid #4FD1C5;
  border-radius: 6px;
  cursor: pointer;
  transition: ease-in-out 0.3s;
}

.G-zr .tabVwWrapper .cutGridLayout .cutSheetsNew {
  border-style: dotted !important;
}

.G-zr .tabVwWrapper .cutGridLayout .cutSheets:hover {
  background: "#4FD1C5";
  color: white;
}

.G-zr .tabVwWrapper .hdrCntr {
  padding: 15px;
  background: #e2ffe3;
  color: #358539;
  font-size: 20px;
  text-align: center;
  border-radius: 10px;
  margin-bottom: 30px;
}

.G-zr .table .stsChip {
  padding: 7px 10px;
  background: whitesmoke;
  font-size: 14px;
  border-radius: 10px;
  text-transform: capitalize;
  font-weight: 500;
}

.G-zr .table .stsChip.compltd {
  background: #e1ffe3;
  color: #358539;
}

.G-zr .table .stsChip.opn {
  background: #fff5d8;
  color: #a78608;
}

.G-zr .table .rsndBtn {
  border-color: #358539;
  color: #358539;
}

.G-zr .table .rsndBtn .MuiButton-label {
  text-transform: initial;
}

.G-zr .Dash-right .table thead tr th:first-child,
.G-zr .Dash-right .table tbody tr td:first-child {
  text-align: left;
  padding-left: 15px;
}

.G-zr .Dash-right .ctFrmWrpr .form-control {
  height: 46px;
}

.G-zr .Dash-right .ctFrmWrpr .form-control:focus {
  box-shadow: none;
  border-color: #358539;
}

.G-zr .lokSrchBtn {
  background: #358539;
  color: white;
  margin-top: 20px;
  height: 45px;
  width: 120px;
}

.G-zr .lokSrchBtn .MuiButton-label {
  text-transform: initial;
}

.G-zr .cstmrLkpPpup .modal-title {
  font-size: 19px;
  font-weight: 500;
}

.G-zr .cstmrLkpPpup .modal-title span {
  font-weight: 300;
  padding-right: 6px;
}

.G-zr .cstLkIpt {
  margin-bottom: 20px;
}

.G-zr .cstLkIpt input,
.G-zr .cstLkIpt select {
  height: 46px;
}

.G-zr .cstLkIpt .form-control:focus {
  box-shadow: none;
  border-color: #358539;
}

.G-zr .lkFmrTitle {
  border-bottom: 1px solid #bbbbbb;
  padding-bottom: 13px;
  margin-bottom: 29px;
  font-size: 18px;
  font-weight: 600;
}

.G-zr .Dash-right .tabVwWrapper .table tbody td {
  font-size: 13px;
}

.G-zr .radIpt {
  display: flex;
  align-items: center;
  margin-right: 20px;
}

.G-zr .radIpt label {
  margin: 0;
  margin-left: 5px;
}

.G-zr .cstmrLkpPpup .lokSrchBtn {
  width: 150px;
  margin-top: 20px;
}

@media (max-width: 1200px) {
  .G-zr .mnDashLayoutWrpr {
    background: url(/images/icon/Dash-bg.svg) no-repeat;
    background-size: cover;
  }
}

@media (max-width: 900px) {
  .dashLayoutWrapper {
    padding: 20px 30px;
  }

  .G-zr.dashLayoutWrapper .main-dash .da-sh {
    padding: 20px 20px;
    width: auto;
    max-width: 200px;
    margin-bottom: auto;
  }

  .G-zr.dashLayoutWrapper .main-dash h4 {
    font-size: 14px !important;
  }

  .G-zr .main-dash .da-sh figure {
    margin-bottom: 10px !important;
  }

  .G-zr .main-dash .da-sh img {
    object-fit: contain;
    width: 50px;
    height: 40px;
  }
}

@media (max-width: 600px) {
  .srchBox input {
    width: 65%;
  }

  .px-xs-0 {
    padding: 0 !important;
  }

  .dashLayoutWrapper {
    padding: 20px;
  }

  .G-zr .main-dash.seller-D {
    width: 100%;
  }

  .G-zr.dashLayoutWrapper .main-dash .da-sh {
    width: auto;
    max-width: -webkit-fit-content;
    max-width: -moz-fit-content;
    max-width: fit-content;
    min-width: 100%;
    flex-direction: column !important;
    margin: 15px auto;
  }

  .dashFlexWrpper {
    flex-wrap: wrap;
  }

  .flex-mb-wrap {
    flex-wrap: wrap;
    flex-direction: column-reverse;
  }

  .dashLayoutWrapper .flex-mb-wrap {
    flex-direction: column;
  }

  .G-zr.dashLayoutWrapper .main-dash.sdNavWrpr .da-sh {
    flex-direction: row !important;
  }
}
.SortableItem {
   padding: 3px !important;
}

.form-label p {
   margin-bottom: 0;
}




.btn-center{
    margin-left: 160px;
    margin-top:5px;
}

.btn-size{
    max-width: 235px;
}

.btm-text
{
    margin-top:20px;
    margin-left: 120px;
}

#calenderComponent .fa-close-cls
{ 
    cursor: pointer;
    margin:1em
    /* margin-left: 560px;     */
}
#calenderComponent{
    height:auto;
    width:1000px;
    margin: auto;
}
#calenderComponent .model-height
{
    /*height: auto;*/
    width:1000px;
}
.calender-margin
{
    margin-top:40px;
    margin-left: 120px;
}
/* .react-calendar__navigation__label
{
    background-color: aquamarine !important;
} */


@media (max-width: 600px){
    #calenderComponent .model-height {
        width: 100%;
        height: auto;
    }
    .fc-toolbar {
        display: flex;
        justify-content: space-between;
        align-items: baseline;
        flex-flow: column;
    }
}
.G-zr .procnavp1{
    /* position: absolute; */
    top: 0;
    margin-top: 0;
    width: inherit;
    height: inherit;
    left: 0;
    color: white;
}

.G-zr .procnavp2{
    /* position: absolute; */
    right: 0;
    color: white;
}
.G-zr .procnavp2,.procnavp1:hover{
    border-radius: 0;
}

.navSetup{
    /* background: #358539; */
    /* height: 3em; */
    display: flex;
    justify-content: flex-start;
    background: #358539;
}
.activepnav{
    background-color:white!important;
    border: 1px solid#BEBEBE!important;
    color:#358539!important;
}


.login-page {
  /* Scope all styles to the login page */
  /* General Styles */
  body, html {
    margin: 0;
    padding: 0;
    height: 100%;
    font-family: Arial, sans-serif;
    background-color: #ffffff; /* Set background color to white */
  }

  .G-zr {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #ffffff; /* Set background color to white */
  }

  .row {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin: auto;
  }

  .col-lg-7, .col-md-6, .col-sm-12, .col-lg-5 {
    padding: 0;
  }

  .form-right {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    padding: 10rem;
    background-color: white;
    border-radius: 8px;
    align-items: baseline;
  }

  .form-left1 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-size: cover; /* Ensure the image covers the entire area */
    background-position: center; /* Center the image */
    height: 90%;
    padding: 7rem 5rem;
    color: white;
    width: 122%;
    border-bottom-left-radius: 15px; /* Adjust the value as needed */
  }

  .dont-have-account {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    font-weight: 500;
    font-size: 16px;
    line-height: 18.75px;
    color: #94969A;
  }

  .login-title {
    font-weight: 700;
    color: #3C923F80;
    font-size: 32px;
  }

  .login-button {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: start;
    align-content: start;
    width: 100%;
  }

  .password-eye-icon {
    cursor: pointer;
    position: absolute;
    right: 30px;
    top: 50%;
    transform: translateY(-50%);
  }

  .btn-google, .btn-fb, .btn-login {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 10px 20px;
    border-radius: 30px;
    text-decoration: none;
    padding: 1rem;
    width: 100%;
    max-width: 22rem;
    box-shadow: 0px 15px 50px 0px #DE5F8F1F;
    font-size: 16px;
    font-weight: 500;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
  }

  .btn-google {
    background-image: linear-gradient(to right, #1688D6, #02EC25);
    color: white;
  }

  .btn-fb {
    background-color: white;
    color: black;
    font-weight: bold;
  }

  .btn-login1 {
    background-color: #3C923F80; /* 50% opacity */
    color: white;
    border: none;
    padding: 10px 20px;
    font-size: 12px;
    cursor: pointer;
    border-radius: 12px;
    width: 100%;
    max-width: 22rem;
    transition: background-color 0.3s ease;
  }

  .btn-login1:hover {
    background-color: #3C923F; /* Full opacity on hover */
  }

  .google-icon {
    border: none;
    content: "\f078";
    font-family: "FontAwesome";
    font-weight: 400;
    display: inline-block;
    font-style: normal;
    font-feature-settings: normal;
    font-variant: normal;
    margin-left: 15px;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
  }

  .msg-or, .msg-copyright {
    color: #94969A;
    margin: 2rem 0;
  }

  .footer1 {
    text-align: center;
    padding: 10px;
    position: absolute;
    bottom: 0;
    width: 100%;
  }

  .msg-copyright {
    margin: 10rem 0;
  }

  .form-input {
    flex-direction: column;
    justify-content: start;
    align-items: center;
    align-content: start;
    width: 100%;
  }

  .G-form {
    width: 100%;
  }

  .input-login {
    position: relative;
    width: 100%;
    max-width: 22rem;
  }

  .input-login-icon {
    height: 1.5rem;
    width: 1.5rem;
    padding: 1px;
    position: absolute;
    box-sizing: border-box;
    top: 50%;
    left: 20px;
    transform: translateY(-50%);
    padding: 0.2rem;
  }

  .input-login-form {
    height: 50px;
    box-sizing: border-box;
    padding-left: 3.5rem;
    border-radius: 8px;
    border: 1px solid #EAEBF6;
    width: 100%;
    max-width: 22rem;
  }

  /* Responsive Styles */
  @media (max-width: 768px) {
    .form-left {
      display: none;
    }

    .form-right {
      padding: 1rem;
      box-shadow: none;
    }

    .btn-google, .btn-fb, .btn-login {
      min-width: 100%;
    }

    .input-login-form {
      max-width: 100%;
    }
  }

  @media (max-width: 576px) {
    .login-title {
      font-size: 1.5rem;
    }

    .dont-have-account {
      font-size: 14px;
    }
  }
}

.register-page {
  .register-container {
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: start;
    align-content: start;
  }
  
  .btn-login {
    margin-top: 2rem;
  }
  
  .form-right {
    margin-top: 10%;
  }
       
  
  /* Create a custom radio button */
  .G-zr .G-radio .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #fff;
    border: 2px solid #000;
    border-radius: 50%;
  }
  
  /* On mouse-over, add a grey background color */
  .G-zr .G-radio .conta:hover input~.checkmark {
    background-color: #ccc;
  }
  
  /* When the radio button is checked, add a blue background */
  .G-zr .G-radio .conta input:checked~.checkmark {
    background-color: #358539;
    border: 2px solid #358539;
  }
  
  /* Create the indicator (the dot/circle - hidden when not checked) */
  .G-zr .G-radio .checkmark:after {
    content: '';
    position: absolute;
    display: none;
  }
  
  /* Show the indicator (dot/circle) when checked */
  .G-zr .G-radio .conta input:checked~.checkmark:after {
    display: block;
  }
  
  .alert {
    padding: 15px;
    margin-top: 20px;
    border: 1px solid transparent;
    border-radius: 4px;
  }
  
  .checkbox-new {}
  
  .checkbox-new input[type='checkbox'] {
    accent-color: #3C923F;
    transform: scale(1.5);
  }
  
  .checkbox-new label {
    margin: 0 1rem 0 0.4rem;
  }
  
  .G-zr .G-radio .conta .checkmark:hover {
    background: white;
  }
  
  /* Style the indicator (dot/circle) */
  .G-zr .G-radio .conta .checkmark:after {
    top: 4.5px;
    left: 4.5px;
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background: white;
  }
  
  .G-zr .ptype input[type='checkbox']+label {
    display: block;
    margin: 0.2em;
    cursor: pointer;
    padding: 0.2em;
  }
  
  .G-zr .ptype input[type='checkbox'] {
    display: none;
  }
  
     
  
  /* RegistrationPage.css */
  .registration-page {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .header-banner { 
    padding: 50px 0;
    text-align: center;
    width: 97%;
    background-size: cover; /* Ensure the image covers the entire area */
    background-position: center; 
    height: 19rem;
    margin-top: 18px;
    border-radius: 12px;
  }
  
  .banner-content h1 {
    font-size: 2.5em;
    color: white;
  }
  
  .banner-content p {
    font-size: 1.2em;
    color: white;
  }
  
  .registration-form {
    background-color: white;
    padding: 40px;
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.04);
    width: 400px;
    margin-top: -142px;
  }
  
  .registration-form h2 {
    margin-bottom: 20px;
    text-align: center;
    font-weight: 600;
    font-size: 18px;
    color: #2D3748;
  }
  
  .form-group {
    margin-bottom: 14px;
  }
  
  .form-group input,
  .form-group select {
    width: 100%;
    padding: 10px;
    font-size: 1em;
    border: 1px solid #A0AEC0;
    border-radius: 8px;
    outline: none;
    color: #A0AEC0;
  }
  
  .signup-btn {
    background-color: #3C923F80; /* 50% opacity */
    color: white;
    border: none;
    padding: 10px 20px;
    font-size: 12px;
    cursor: pointer;
    border-radius: 12px;
    width: 100%;
    max-width: 22rem;
    transition: background-color 0.3s ease;
  }
  
  .signup-btn:hover {
    background-color: #3C923F;
  }
  
  
   
  
  .signin-link {
    text-align: center;
    margin-top: 20px;
  }
  
  .signin-link a {
    color: #4CAF50;
    text-decoration: none;
  }
  
  .signin-link a:hover {
    text-decoration: underline;
  }
  .footer1 {
    text-align: left;
    padding: 10px;
    bottom: 0;
    width: 100%;
    margin-left: 10%;
  }
}
  
