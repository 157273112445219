
.App-logo {
  height: 40vmin;
} 
/* .nav-tabs {
	z-index: 110;
    position: relative;
    background: #358539;
    height: 60px;
    display: flex;
    justify-content: space-between;
    padding: 2px 15px;
} */

/* .nav-tabs .nav-link {
    border: 1px solid transparent;
    border-top-left-radius: .25rem;
    border-top-right-radius: .25rem;
    width: 16%;
    padding:14px;
    color: #fff;
    text-align:center;
} */

.nav-tabs .nav-link:hover,
.nav-tabs .nav-link:focus {
	color: #358539;
    background: #fff;
} 

.rtngPopBody .rtngForm{
    max-width: 500px;
    width: 100%;
    margin: 15px auto;
}

.rtngPopBody .rtngForm .rtngComments{
    width: 100%;
    height: 160px;
}

.rtngPopBody .dv-star-rating-star i{
    font-size: 24px;
    margin: 0 5px;
}

.trck-lfpop.model-height.rtngModal{
    height: 450px !important;
    min-height: unset !important;
}

.btn-green.sbmtBtn{
    margin-left: auto;
}

.trck-lfpop.model-height.rtngModal .fa-close-cls{
    margin-left: 0;
}

@media(max-width:600px){
    .rtngPopBody .rtngForm{
      padding: 0 20px;
    }
}
@media screen and (min-width: 1025px){
    #mobile-restriction{
      display:none !important;
    }
  }
  @media screen and (max-width: 1024px){
    html{
      width: 100vw;
      height: 100vh;
      overflow: hidden !important;
    }
    #mobile-restriction{
      padding: 20px;
      position: fixed;
      width: 100vw;
      height: 100vh;
      top: 0;
      left: 0;
      z-index: 10000000000000000;
      background-color: #000000;
      pointer-events: none;
      overflow: hidden;
      display: flex !important;
      flex-wrap: wrap;
      justify-content: center;
      align-content: center;
      align-items: center;
      flex-direction: column;
      gap:50px;
    }
    #mobile-restriction img{
      width:100%;
      max-width:250px;
      height:auto;
    }
    #mobile-restriction h1{
      font-size:40px;
      text-align:center;
      color: #ffffff;
    }
  }