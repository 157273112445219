.right-2{
    right:2em;
}
.top-2{
    top:1em;
}
.top-5{
    top:5em;
}
.ml-11{
    margin-left: 11em;    
}
.Addmodal{
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1050;
    /* display: none; */
    overflow: hidden;
    outline: 0;
}
.marginSubmitButton{
    margin: 1em 0em 1em 10em;
}
.zindex{
    z-index: 9999999999;
}
#Add-specf-form{
    margin:1em;
    border-bottom: 1px solid #dee2e6;
}
#Add-specf-form .inp p{
    position: absolute;
    top: -8px;
    /* z-index: 9999999; */
    left: 15px;
    background: #fff;
    font-size: 14px;
    margin: 0;
    padding: 0px 5px;
}
#Add-specf-form .row1{
    width: 100%;
    margin: 0;
    padding: 0;
}

#Showevents .row{display: flex;
border: 0;
flex-direction: row;
justify-content: space-between;
border-bottom: 1px solid #2f7d33;
margin: 1em;
box-shadow: 0px 1px 4px 1px lightgrey;
height: 3em;
padding: 0.5em;
}
#Showevents .acrbutton{
    border: 1px solid #358539;
    padding: .2em;
    border-radius: 6px;
    box-shadow: 0px 0px 1px 0px #358539;
}
#Showevents .selectOptiom{
    display: flex;
    border: 0;
    flex-direction: row;
    justify-content: space-between;
    margin: 1em;
    height: 2.5em;
}
#Showevents .Header{
    background: #2f7d33;
    color: white;
    box-shadow: 0px 0px 2px 2px #2f7d33;
    border-bottom: #2f7d33;    
}
#Showevents .headid{
    margin-top: 0px;
}

#Showevents .row i{
color:#358539
}

.select-head{
border: 0;
box-shadow: 2px 1px 6px 1px lightgrey;
}

#Showevents .tooltip {
    position: relative;
    display: inline-block;
    border-bottom: 1px dotted black;
  }
  
  #Showevents .tooltip .tooltiptext {
    visibility: hidden;
    width: 120px;
    background-color: #555;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    bottom: 125%;
    left: 50%;
    margin-left: -60px;
    opacity: 0;
    transition: opacity 0.3s;
  }
  
  #Showevents .tooltip .tooltiptext::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #555 transparent transparent transparent;
  }
  
  #Showevents .tooltip:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
  }
.inneraccordian{
    /* background: #F7F7DE; */
    margin-top: -1em;
    width: 56em;
    padding: 1em;
     margin-left: 1em;
}
#Showevents .inneraccordianrow{display: flex;
    border: 0;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: 1px solid #2f7d33;
    margin: 1em;
    box-shadow: 0px 1px 4px 1px lightgrey;
    height: 3em;
    padding: 0.5em;
    background:rgba(196, 255, 171, 0.5)
    }