@import url('https://fonts.googleapis.com/css?family=Raleway:300,400,500,600,700&display=swap');

.fot-sec figure {
    padding: 0 20px;
}
.fot-sec .contact span.af:after {
    position: absolute;
    content: '';
    background: #DEDEDE;
    width: 1px;
    height: 25px;
    top: 0;
    right: 0;
}
.fot-sec .contact span {
    font-size: 15px;
    font-weight: 500;
    position: relative;
}
.fot-sec span {
    padding: 0 20px;
}
.fot-sec span a{
    font-size: 15px;
    font-weight: 500;
    color: #358539;
    cursor: pointer;
}
/* .p-5 {
    padding: 3rem!important;
} */
footer h6{
    font-size: 14px;
}
footer .fot-sec2{
    background: #262626;
    padding: 12px;
        color: #fff;
    text-align: center;
}