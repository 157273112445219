@import url("https://fonts.googleapis.com/css?family=Raleway:300,400,500,600,700&display=swap");
@import url("https://fonts.googleapis.com/css?family=Montserrat:300,400,500,600,700&display=swap");

.montserrat {
  font-family: "Montserrat", sans-serif;
}

.G-zr .fs-14 {
  font-size: 14px;
}

.G-zr .fs-15 {
  font-size: 15px;
}

.G-zr .fs-16 {
  font-size: 16px !important;
}

.G-zr .fs-18 {
  font-size: 18px !important;
}

.G-zr .fs-20 {
  font-size: 20px !important;
}

.G-zr .fw-m {
  font-weight: 500;
}

.G-zr .fw-sm {
  font-weight: 600;
}

.G-zr .main-dash .da-sh img {
  /* width: 58px;
    height: 58px; */
  object-fit: contain;
}

.G-zr .main-dash a:hover {
  text-decoration: none;
}

.G-zr.dashLayoutWrapper .main-dash h4 {
  letter-spacing: 1px;
  color: gray;
  font-size: 20px !important;
  font-weight: bold;
  font-family: "Raleway", sans-serif;
  margin-top: 15px;
}

.cntNumber {
  font-weight: 600;
  font-size: 45px;
  color: black;
}

.G-zr.dashLayoutWrapper .main-dash .da-sh {
  background: #fff;
  display: flex;
  flex-flow: column;
  align-items: center;
  width: 320px;
  height: max-content;
  justify-content: stretch;
  border-radius: 10px;
  box-shadow: 0px 0px 10px 0px #00000042;
  border: none;
  margin-bottom: 20px;
  padding: 30px 30px;
}

.G-zr.dashLayoutWrapper .main-dash h4::after {
  content: "";
  position: absolute;
  background: #358539;
  left: 0;
  right: unset;
  bottom: -5px;
  width: 34px;
  border-radius: 100px;
  height: 2px;
}

.G-zr .main-dash .da-sh.bg-none {
  background: transparent;
  box-shadow: none;
}

.G-zr .btn-gradien {
  background: linear-gradient(45deg, #41933b, #1ab97a);
  border-radius: 100px;
  padding: 8px 24px;
  color: #fff;
  font-size: 12px;
  font-weight: 500;
}

.act-cen .ac-scrollbar {
  overflow-y: scroll;
  padding-right: 10px;
  width: 100%;
}

.dashLayoutWrapper {
  padding: 20px 60px;
}

.dashLayoutWrapper .topMnContainer {
  min-height: 166px;
}

.dashLayoutWrapper .topMnContainer .lftContainer p {
  color: #7f8fa4;
  font-size: 13px;
  margin-top: 10px;
}

table.cstmGrzrTable td,
table.cstmGrzrTable th {
  padding: 15px;
  font-size: 15px;
}

table.cstmGrzrTable td {
  font-family: "Montserrat";
}

.cstmGrzrTable thead {
  background: #ececec;
}

.actvOrdrsWrpr {
  /* padding-left: 15px;
  padding-right: 15px; */
}

.actvOrdrsWrpr .tableTitle {
  color: #358539;
  font-weight: bold;
  font-size: 16px;
  margin-top: 34px;
  margin-bottom: 15px;
}

.actvOrdrsWrpr .icnButton {
  background: transparent;
  padding: 0;
}

.actvOrdrsWrpr .icnButton.downloadBtn {
  color: #3b6dd1;
}

.actvOrdrsWrpr .icnButton.cancelButton {
  color: #dc6c6c;
}

.actvOrdrsWrpr .icnButton.chckButton {
  color: #358539;
}

.actvOrdrsWrpr .icnButton.editButton {
  color: #ffffff;
  background: #5e6366;
  border-radius: 100px;
  padding:3px 3px;
  line-height: 0;
  margin-bottom:7px !important;
  display:block;
}
.actvOrdrsWrpr .icnButton.editButton .material-icons{
  font-size:14px;
}


.swtchTgleDiv {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 200px;
  margin-bottom: 25px;
}

.swtchTgleDiv .nvButton {
  background: #e3f5e4;
  color: #358539;
  height: 35px;
  padding: 10px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
}

.swtchTgleDiv .nvButton.txtContnr {
  margin: 0 10px;
}

.tableButton {
  padding: 10px;
  width: max-content;
}

.noRecordsFound {
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: whitesmoke;
  color: gray;
  font-weight: 500;
}

.sdNavWrpr a img {
  height: 50px;
}

.G-zr .main-dash.sdNavWrpr a.active {
  background: #e2ffe3;
}

.G-zr .main-dash.sdNavWrpr a.active h4 {
  color: green;
}

.itmDetailsWrpr .pdtDetails .imgWrapper img {
  width: 90px;
  height: 90px;
  border-radius: 5px;
  object-fit: cover;
}

.itmDetailsWrpr .pdtDetails {
  display: flex;
  align-items: flex-start;
}

.itmDetailsWrpr .pdtDetails .pdtInfoDtls {
  margin-left: 25px;
}

.itmDetailsWrpr .pdtDetails .pdtInfoDtls h3 {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 15px;
}

.itmDetailsWrpr .pdtDetails .pdtInfoDtls p {
  font-size: 16px;
  margin-bottom: 10px;
}

.itmDetailsWrpr .pdtDetails .pdtInfoDtls span {
  font-weight: 500;
}

.acrdnWrapper .acrdnTitle {
  background: whitesmoke;
  padding: 10px 15px;
  margin-top: 25px;
}

.acrdnWrapper .acrdnInr {
  padding: 10px 15px;
}

.acrdnWrapper .acrdnInr .col-md-6 {
  margin-bottom: 10px;
}

.acrdnWrapper .acrdnInr .col-md-6.text-right {
  font-weight: 500;
}

.trck-lfpop.itmDtlPopup {
  min-height: max-content;
  height: max-content;
  max-height: 600px;
  overflow: hidden;
}

.trck-lfpop.itmDtlPopup .modal-body {
  overflow-y: auto;
}

.G-zr .childrenValues .Dash-right .navSetup {
  background: white;
  border-bottom: none;
}

.G-zr .childrenValues .Dash-right .navSetup .nav-item {
  border: 1px solid #358539;
  border-radius: 4px;
  color: #358539;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  margin-right: 10px;
}

.G-zr .childrenValues .Dash-right .navSetup .activepnav {
  border-radius: 4px;
  background: #358539 !important;
  color: white !important;
}

.srchBox {
  display: flex;
  align-items: center;
}

.srchBox input {
  height: 40px;
  border-radius: 4px;
  border: 1px solid #9b9a9a;
  padding: 10px;
  margin-right: 10px;
}

.plnButton {
  color: #358539;
  display: flex;
  align-items: center;
  margin-left: auto;
  margin-top: 25px;
}

.btn-green.btn-outline {
  border: 1px solid #358539;
  background: transparent;
  color: #358539;
}

.G-zr .mnDashLayoutWrpr {
  background: url(/images/icon/Dash-bg.svg) repeat;
  background-size: contain;
  background-position: center;
  padding: 20px;
}

.G-zr .tabVwWrapper {
  padding: 30px 0;
}

.G-zr .tabVwWrapper .cutGridLayout {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
  gap: 20px;
}

.G-zr .tabVwWrapper .cutGridLayout .cutSheets {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  background: white;
  border: 1.5px solid #4FD1C5;
  border-radius: 6px;
  cursor: pointer;
  transition: ease-in-out 0.3s;
}

.G-zr .tabVwWrapper .cutGridLayout .cutSheetsNew {
  border-style: dotted !important;
}

.G-zr .tabVwWrapper .cutGridLayout .cutSheets:hover {
  background: "#4FD1C5";
  color: white;
}

.G-zr .tabVwWrapper .hdrCntr {
  padding: 15px;
  background: #e2ffe3;
  color: #358539;
  font-size: 20px;
  text-align: center;
  border-radius: 10px;
  margin-bottom: 30px;
}

.G-zr .table .stsChip {
  padding: 7px 10px;
  background: whitesmoke;
  font-size: 14px;
  border-radius: 10px;
  text-transform: capitalize;
  font-weight: 500;
}

.G-zr .table .stsChip.compltd {
  background: #e1ffe3;
  color: #358539;
}

.G-zr .table .stsChip.opn {
  background: #fff5d8;
  color: #a78608;
}

.G-zr .table .rsndBtn {
  border-color: #358539;
  color: #358539;
}

.G-zr .table .rsndBtn .MuiButton-label {
  text-transform: initial;
}

.G-zr .Dash-right .table thead tr th:first-child,
.G-zr .Dash-right .table tbody tr td:first-child {
  text-align: left;
  padding-left: 15px;
}

.G-zr .Dash-right .ctFrmWrpr .form-control {
  height: 46px;
}

.G-zr .Dash-right .ctFrmWrpr .form-control:focus {
  box-shadow: none;
  border-color: #358539;
}

.G-zr .lokSrchBtn {
  background: #358539;
  color: white;
  margin-top: 20px;
  height: 45px;
  width: 120px;
}

.G-zr .lokSrchBtn .MuiButton-label {
  text-transform: initial;
}

.G-zr .cstmrLkpPpup .modal-title {
  font-size: 19px;
  font-weight: 500;
}

.G-zr .cstmrLkpPpup .modal-title span {
  font-weight: 300;
  padding-right: 6px;
}

.G-zr .cstLkIpt {
  margin-bottom: 20px;
}

.G-zr .cstLkIpt input,
.G-zr .cstLkIpt select {
  height: 46px;
}

.G-zr .cstLkIpt .form-control:focus {
  box-shadow: none;
  border-color: #358539;
}

.G-zr .lkFmrTitle {
  border-bottom: 1px solid #bbbbbb;
  padding-bottom: 13px;
  margin-bottom: 29px;
  font-size: 18px;
  font-weight: 600;
}

.G-zr .Dash-right .tabVwWrapper .table tbody td {
  font-size: 13px;
}

.G-zr .radIpt {
  display: flex;
  align-items: center;
  margin-right: 20px;
}

.G-zr .radIpt label {
  margin: 0;
  margin-left: 5px;
}

.G-zr .cstmrLkpPpup .lokSrchBtn {
  width: 150px;
  margin-top: 20px;
}

@media (max-width: 1200px) {
  .G-zr .mnDashLayoutWrpr {
    background: url(/images/icon/Dash-bg.svg) no-repeat;
    background-size: cover;
  }
}

@media (max-width: 900px) {
  .dashLayoutWrapper {
    padding: 20px 30px;
  }

  .G-zr.dashLayoutWrapper .main-dash .da-sh {
    padding: 20px 20px;
    width: auto;
    max-width: 200px;
    margin-bottom: auto;
  }

  .G-zr.dashLayoutWrapper .main-dash h4 {
    font-size: 14px !important;
  }

  .G-zr .main-dash .da-sh figure {
    margin-bottom: 10px !important;
  }

  .G-zr .main-dash .da-sh img {
    object-fit: contain;
    width: 50px;
    height: 40px;
  }
}

@media (max-width: 600px) {
  .srchBox input {
    width: 65%;
  }

  .px-xs-0 {
    padding: 0 !important;
  }

  .dashLayoutWrapper {
    padding: 20px;
  }

  .G-zr .main-dash.seller-D {
    width: 100%;
  }

  .G-zr.dashLayoutWrapper .main-dash .da-sh {
    width: auto;
    max-width: fit-content;
    min-width: 100%;
    flex-direction: column !important;
    margin: 15px auto;
  }

  .dashFlexWrpper {
    flex-wrap: wrap;
  }

  .flex-mb-wrap {
    flex-wrap: wrap;
    flex-direction: column-reverse;
  }

  .dashLayoutWrapper .flex-mb-wrap {
    flex-direction: column;
  }

  .G-zr.dashLayoutWrapper .main-dash.sdNavWrpr .da-sh {
    flex-direction: row !important;
  }
}