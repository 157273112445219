@import url("https://fonts.googleapis.com/css?family=Raleway:300,400,500,600,700&display=swap");

.navbar-brand{
  background: black;
  padding: 0 3rem;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: center;
}

.header-dropdown{
  position: absolute;
  right: 0;
  margin-top: 2.5rem;
  width: 100px;
  background: white;
  border: 1px solid #E3E8EE;
  padding: 1rem;
  box-shadow: 0px 1px 2px 0px #37415114;
  cursor: pointer;
}
.custom-select {
  border: 1px solid #EFEFEF; /* Added border */
  border-radius: 10px; /* Added border radius */
  color: #000000; /* Change this to your desired color */
  background-color: transparent; /* Optional: make the background transparent */
  font-size: 14px; /* Optional: adjust the font size */
  padding: 5px; /* Optional: adjust the padding */
  appearance: none; /* Remove default arrow in some browsers */
  width: 11rem;
  height: 2.5rem;
}

.custom-select:focus {
  outline: none; /* Remove the focus outline */
}


.header-dropdown:hover{
  color: #E3E8EE;
}

.header-datetime{
  border: 1px solid #EFEFEF;
  padding: 0.5rem 2rem;
  border-radius: 10px;
}

.header-datetime span{
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.25px;
}

body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  background: #fff;
  font-family: "Raleway", sans-serif;
}

.nav-item.cartNavItem p {
  margin: 0;
}

.nav-item.cartNavItem .cartPrnts {
  width: 25px;
  height: 25px;
  position: relative;
}
.nav-item.cartNavItem .cartPrnts .crtCountCircle {
  width: 20px;
  height: 20px;
  background-color: #f35825;
  color: white;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 30px;
  top: -8px;
  right: -5px;
}
.bell-icon-container {
  position: relative;
  display: inline-block;
}

.notification-dot {
  position: absolute;
  top: 0px;  /* Adjust this to move the dot vertically */
  right: 12px; /* Adjust this to move the dot horizontally */
  height: 10px;
  width: 10px;
  background-color: red;
  border-radius: 50%;
  border: 2px solid white; /* Optional white border */
}
.profiledrop {
  padding: 0;
  line-height: 25px;
  border: none;
  box-shadow: 0px 0px 9px 0px #00000012;
  position: absolute;
  width: 140px;
  top: 76px;
  transform: translate(4px, 2px);
  transition: cubic-bezier(0.075, 0.82, 0.165, 1);
}
.profiledrop ul {
  padding: 0.25rem;
  list-style-type: none;
  font-size: 12px;
  font-family: "Montserrat", sans-serif;
  background-color: #ffffff;
}
.profiledrop ul li a {
  color: #000000 !important;
  text-decoration: none;
}
.profiledrop ul li:hover a {
  color: #ffffff;
}
.profiledrop ul li:hover {
  background-color: #358539;
}
.profiledrop ul li {
  /* background: #f0f7ffcc; */
  padding: 10px 5px;
  margin: 1px auto;
}
.Af-log {
  border-bottom: 3px solid #358539;
}
.G-zr .navbar-brand img {
  width: 170px;
  height: 50px;
  object-fit: cover;
}
.G-zr .navbar-expand-lg .navbar-nav .nav-item.active .nav-link {
  border: 1px solid #fff;
  border-radius: 4px;
}

#snackbar {
  background-color: #d4edda;
    padding: 21px 42px 21px 17px;
    border-radius: 4px;
    color: #155724;
    position: fixed;
    z-index: 1301;
    /* padding-right: 185px; */
    width: 256px;
    right: 0;
    top: 6em;
    float: none;
    margin: 0 2px;
    box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3), 0 2px 1px rgba(0, 0, 0, 0.22);
    color: #000;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    min-height: 58px;
    line-height: normal;
    height: auto;
}
#snackbar.show {
  visibility: visible; /* Show the snackbar */
  /* Add animation: Take 0.5 seconds to fade in and out the snackbar. 
  However, delay the fade out process for 2.5 seconds */
  -webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
  animation: fadein 0.5s, fadeout 0.5s 2.5s;
}
.snackbarClose {
  right: 20px;
  float: right;
  position: absolute;
  top: 15px;
  font-size: 20px;;
  padding: 0.2rem 0.3rem;
  border-radius: 50%;
  font-size: 12px;
  color: white;
  cursor: pointer;
}

.fa-times:before {
  content: "\f00d";
}
.fa,
.fas {
  font-weight: 900;
}

.img-avatar {
  width: 40px;
  height: 40px;
  border-radius: 100px;
}
.G-zr .Af-log .logo2,
.G-zr .Af-log.normal .logo1 {
  display: none;
}
.G-zr .Af-log.normal .logo2,
.G-zr .Af-log .logo1 {
  display: block;
}
.G-zr .Af-log .icon-bar {
  display: block;
  width: 26px;
  height: 2px;
  margin: 7px auto;
  border-radius: 1px;
}
.G-zr .Af-log .icon-bar {
  background-color: #fff;
}
/* .G-zr .Af-log .navbar-toggler {
  padding: 0rem;
}
.G-zr .Af-log .navbar-toggler:focus {
  outline: 0;
} */
.G-zr .Af-log #navbarSupportedContent ul {
  align-items: center;
}
.G-zr .Af-log #navbarSupportedContent {
  display: block;
}
.G-zr .Af-log.inner-Header {
  background: transparent;
  background-color: transparent;
}
.G-zr .Af-log {
  background: #ffffff;
  background-color: #ffffff;
  color: #000;
  border-bottom: 1px solid #EFEFEF;
}
.G-zr .Af-log.normal.inner-Header {
  background: #262626;
}
.G-zr .Af-log.inner-Header .navbar-expand-lg .navbar-nav .nav-link {
  color: #ffffff;
}
.G-zr .Af-log .navbar-expand-lg .navbar-nav .nav-link:hover {
  color: #4caf50;
}
.G-zr .Af-log .navbar-expand-lg .navbar-nav .nav-link {
  color: #262626;
}

.G-zr .dash-hd .breadcrumb-item a {
  color: hsl(18, 10%, 19%);
  font-weight: 700;
}

.profiledrop ul a {
  color: #358539;
}

.profiledrop ul a:hover {
  color: #fff;
}
.mobile-drodn-view {
  display: none;
}
.mobile-drodn-view li {
  list-style-type: none;
  padding: 5px 0;
}
.mobile-drodn-view li a {
  color: #fff;
}

.installPWA {
  position: fixed;
  top: 80px;
  left: 0;
  right: 0;
  width: 100%;
  max-width: 90vw;
  margin-left: auto;
  margin-right: auto;
  text-align: left;
  padding: 10px;
  background: #ebebeb;
  z-index: 99999999999999999999999999999;
  border-radius: 10px;
  border: 1px solid #e8e8e8;
}

.installPWA .inrFlx {
  display: flex;
  align-items: center;
  justify-content: center;
}

.installPWA .addIconImg {
  width: 30px;
  height: 30px;
  margin-right: 20px;
}

.installPWA p {
  margin: 0;
  font-size: 13px;
}

.installPWA .mnuIconImg {
  width: 25px;
  height: 25px;
}

.installPWA button {
  width: 100%;
  background: gray;
  color: white;
  margin-top: 10px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #333;
  color: #fff;
  padding: 10px 20px;
  position: relative;
  z-index: 99999;
}

.menu-icon {
  font-size: 24px;
  cursor: pointer;
}

.logo {
  font-size: 20px;
  font-weight: bold;
}

.mobile-menu {
  position: absolute;
  top: 50px; /* Adjust as needed */
  left: 0;
  background: white;
  width: 100%;
  padding: 10px 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border: 1px solid #e3e5e5 !important;
  height: 100vh;
}

.mobile-menu a {
  text-decoration: none;
  color: #fff;
  padding: 5px 0;
}

.logo-mobile{
  height: 30px;
}

.centered-logo {
  font-size: 20px;
  font-weight: bold;
  flex-grow: 1; /* This allows the logo to take up remaining space */
  text-align: center;
}

@media (max-width: 600px) {
  .mobile-drodn-view {
    display: block;
  }
  .mobile-drodn-none {
    display: none;
  }
  #snackbar {
    background-color: #d4edda;
    color: #155724;
    position: fixed;
    z-index: 1;
    padding: 15px;
    padding-right: 40px;
    right: 0;
    left: 0;
    top: 6em;
    width: 90vw;
    float: none;
    margin-left: auto;
    margin-right: auto;
    box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22);
  }

  .snackbarClose {
    right: 7px;
    float: right;
    position: absolute;
    top: 17px;
    font-size: 20px;
  }
}

@media (max-width: 991px) {
  .G-zr .Af-log .navbar-expand-lg .navbar-nav .nav-link {
    color: #fff;
  }
  .G-zr .Af-log #navbarSupportedContent ul.onres li.mobile-drodn-none.mbnonep {
    display: none;
  }
}
