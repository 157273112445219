/* @import '~bootstrap/dist/css/bootstrap.css';        */
@import "~react-rrule-generator/build/styles.css";
body.print > *:not(print) {
  display: none !important;
}

body.print .no-print {
  display: none !important;
}

body .only-print {
  display: none;
}

body.print .only-print {
  display: block;
}

body strong,
.react-form-builder .react-form-builder-preview .bold {
  font-weight: 700;
  color: #000000;
}

@media print {
  div {
    break-inside: avoid;
  }
}

.MuiPaper-elevation8 {
  box-shadow: none !important;
  border: 1px solid black;
}

.MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]
  .MuiAutocomplete-input {
  padding: 2.5px 4px !important;
}

.btn-center {
  margin-left: 160px;
  margin-top: 5px;
}

.btn-size {
  max-width: 235px;
}

.btm-text {
  margin-top: 20px;
  margin-left: 120px;
}

.fa-close-cls {
  cursor: pointer;
  margin-left: 560px;
}

.model-height {
  height: auto;
}

.calender-margin {
  margin-top: 40px;
  margin-left: 120px;
}

/* .react-calendar__navigation__label
{
  background-color: aquamarine !important;
} */

.active {
  background-color: #4f4f4f;
  color: white;
}

.nav-link {
  padding: 0.5rem;
}

.radWrp {
  gap: 10px;
}

.radWrp input {
  accent-color: #3c923f;
}

.radIpt input {
  margin-right: 5px !important;
  display: inline-block;
}

.cursor-pointer {
  cursor: pointer !important;
}

body .icnButton {
  border: none;
  padding: 0;
  margin: 0 10px 0 0 !important;
  background: none;
}

body .btn-primary {
  background: #3c923f;
  border-radius: 8px;
  display: flex;
  width: 180px;
  height: 44px;
  padding: 14px 20px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  border: none;
  cursor: pointer;
  font-size: 14px !important;
  color: var(--grey-light, #f5f5f5) !important;
  font-family: Roboto;
  font-size: 14px !important;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

body .btn-primary:hover,
body .btn-primary:active,
body .btn-primary:focus {
  background: #3c923f !important;
  opacity: 0.8;
}

body .btn-primary.disabled,
body .btn-primary:disabled,
body .btn-primary.disabled:hover,
body .btn-primary:disabled:hover {
  background: #3c923f !important;
  opacity: 0.8;
}

body .btn-primary:not(:disabled):not(.disabled).active,
body .btn-primary:not(:disabled):not(.disabled):active,
body .show > .btn-primary.dropdown-toggle {
  background: #2d6a2f !important;
}

body .btn-secondary {
  background: var(--dark-medium, #4f4f4f);
  border-radius: 8px;
  display: flex;
  width: 180px;
  height: 44px;
  padding: 14px 20px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  border: none;
  cursor: pointer;
  font-size: 14px !important;
  color: var(--grey-light, #f5f5f5) !important;
  font-family: Roboto;
  font-size: 14px !important;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

body .btn-secondary:hover,
body .btn-secondary:active,
body .btn-secondary:focus {
  background: var(--dark-medium, #4f4f4f);
  opacity: 0.8;
}

body .btn-secondary.disabled,
body .btn-secondary:disabled,
body .btn-secondary.disabled:hover,
body .btn-secondary:disabled:hover {
  background: #6c757d !important;
  opacity: 0.8;
}

body .btn-small {
  border: 1px solid #5e6366;
  border-radius: 4px;
  font-size: 11px;
  padding: 1px 5px;
}

body :not(.dynamic-options-actions-buttons) > .btn-danger {
  background: #f08181;
  border-radius: 8px;
  display: flex;
  width: 180px;
  height: 44px;
  padding: 14px 20px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  border: none;
  cursor: pointer;
  font-size: 14px !important;
  color: var(--grey-light, #f5f5f5) !important;
  font-family: Roboto;
  font-size: 14px !important;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

body :not(.dynamic-options-actions-buttons) > .btn-danger:hover,
body :not(.dynamic-options-actions-buttons) > .btn-danger:active,
body :not(.dynamic-options-actions-buttons) > .btn-danger:focus {
  background: #f08181 !important;
  opacity: 0.8;
}

body .btn-group-toggle .btn {
  width: auto !important;
}

body .btn-group-toggle .btn.active {
  background-color: #3c923f !important;
}

body .form-label {
  color: var(--gray-500, #697386);
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  /* 133.333% */
  padding-bottom: 4px;
}

.checkbox-label {
  color: var(--gray-500, #697386);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

body .form-group .badge {
  margin-left: 10px;
}

body .form-group label p {
  margin-bottom: 0;
}

body input[type="checkbox"].form-control {
  width: auto;
  height: auto;
  display: inline-block;
  margin-right: 10px;
  accent-color: rgb(60, 146, 63);
}

body input[type="text"].form-control,
body input[type="time"].form-control,
body input[type="date"].form-control,
body input[type="number"].form-control,
body input[type="email"].form-control,
body input[type="tel"].form-control,
body input[type="password"].form-control,
body select.form-control,
body textarea.form-control {
  border-radius: 4px;
  border: 1px solid var(--gray-100, #e3e8ee);
  background: var(--base-white, #fff);

  /* Light / Elevation / 200 */
  box-shadow: 0px 1px 2px 0px rgba(55, 65, 81, 0.08);
  min-height: 36px;

  color: var(--gray-500, #697386);

  /* Body / 500 */
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  /* 142.857% */
}

body .form-control-autocomplete .MuiInputBase-input {
  color: var(--gray-500, #697386);

  /* Body / 500 */
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  /* 142.857% */
}

body .form-control-autocomplete .MuiInputBase-formControl {
  padding: 7px 9px !important;
}

body .form-control-autocomplete .MuiOutlinedInput-notchedOutline {
  border-radius: 4px;
  border: 1px solid var(--gray-100, #e3e8ee) !important;
  /* background: var(--base-white, #FFF); */

  /* Light / Elevation / 200 */
  box-shadow: 0px 1px 2px 0px rgba(55, 65, 81, 0.08);
  min-height: 36px;
}

body .btn-mini:hover {
  background: linear-gradient(90deg, #d1fdff 0%, #ffecbc 100%);
  padding: 0.3rem 1rem;
  border-radius: 5px;
  color: #5e6366;
}

body .btn-mini {
  background: #f7f9fa;
  padding: 0.3rem 1rem;
  border-radius: 5px;
  color: #5e6366;
}

.left-menu-profile {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  align-content: start;
  width: 100%;
  padding: 1rem 1.2rem;
}

.left-menu-profile h1 {
  font-size: 14px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.4399999976158142px;
  text-align: left;
  color: black;
  margin-bottom: 0;
}

.left-menu-item-icon-img {
  border-radius: 100px;
  width: 40px !important;
  height: 40px !important;
  margin-right: 1rem;
}

.label-checkbox {
  color: #000;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0;
}

.btn-transparent {
  border-radius: 6px;
  /* border: 1px solid #BEBEBE; */
  /* background: #FFF; */
  /* border-radius: 8px; */
  /* display: flex; */
  width: 152px;
  height: 44px;
  padding: 14px 20px;
  justify-content: center;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
  /* flex-shrink: 0; */
  /* cursor: pointer; */
  margin-bottom: 10px;
  margin-right: 10px;
  /* font-family: Roboto; */
  /* font-size: 14px; */
  /* font-style: normal; */
  /* font-weight: 500; */
  /* line-height: normal; */
  /* color: #000; */
  /* width: 110px; */
  /* text-align: center; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  border-radius: 4px !important;
  border: 1px solid #e0e0e0 !important;
  background: #fff !important;
  box-shadow: 0px 11px 11px 0px rgba(0, 0, 0, 0.08) !important;
  color: #5e6366 !important;
  text-align: center;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.4px;
  /* height: 90px;*/
}

.btn-tab {
  border: 1px solid #bebebe;
  border-radius: 10px;
  color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  padding: 0.5rem;
  cursor: pointer;
  font-size: 18px;
  border-radius: 6px;
  background: #fff;
  font-size: 18px;
}

.btn-tab:hover {
  background-color: #e0e0e0;
}

.tab-active {
  border-radius: 10px;
  background: #4f4f4f !important;
  color: white !important;
}

.sub-title {
  width: 200px;
  border-bottom: 1px solid #f1f3f4;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-content: center;
  padding: 0.5rem;
  margin: 1rem 0;
}

.sub-title span {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.25px;
  text-align: left;
}

.tab-container {
  border: 1px solid #e3e8ee;
  border-radius: 10px;
  padding: 1rem;
  width: 100%;
}

.popup-title {
  color: var(--secondary-dark-color, #54595e);
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 8px;
}

.page-title {
  color: #000;
  font-family: Roboto;
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
  /* 43.75% */
  letter-spacing: -0.64px;
}

.popup-title-warning {
  color: black;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 25px;
  padding-top: 6px;
}

.popup-subtitle-warning {
  color: #000;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.popup-btn-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-end;
  align-content: flex-end;
}

.popup-warning-container {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: start;
  align-content: start;
  width: 100%;
  padding: 37px 39px 18px;
}

.popup-container {
  display: flex;
  flex-direction: column;
}

.main-wrap {
  padding-bottom: 80px;
  padding-left: 15px;
}
body table.cstmGrzrTable {
  border-collapse: collapse !important;
}

body table.cstmGrzrTable th {
  color: var(--gray-60, #919699) !important;
  text-align: left;
  font-family: Roboto;
  font-size: 14px !important;
  font-style: normal;
  font-weight: 500 !important;
  line-height: 22px;
  letter-spacing: 0.1px;
  border-top: none !important;
  border-bottom: 2px solid #e3e5e5 !important;
  padding: 4.25px 10px 4.25px 0 !important;
  min-height: 40px;
  height: 40px !important;
  vertical-align: middle !important;
}

body table.cstmGrzrTable td {
  color: var(--gray-80, #5e6366);
  font-family: Roboto;
  font-size: 16px !important;
  font-style: normal;
  font-weight: 400 !important;
  line-height: 24px;
  letter-spacing: 0.44px;
  border-top: none !important;
  border-bottom: 1px solid #e3e5e5 !important;
  padding: 3px 10px 3px 0 !important;
  min-height: 40px !important;
  height: 40px !important;
  text-align: left !important;
  vertical-align: middle !important;
}

body table.cstmGrzrTable tr.hold {
  background: hsl(41, 100%, 94%);
}

body table.cstmGrzrTable thead {
  background: white;
}

body .react-form-builder-form table.cstmGrzrTable th {
  background: #eeeeee;
  color: #000000 !important;
  font-weight: bold;
}

.MuiPaper-elevation8 {
  border: 1px solid #e3e8ee !important;
}

.MuiMenuItem-root {
  color: #697386 !important;
  font-size: 12px !important;
}

.barcode-wrap.carcass-barcode > svg {
  max-width: 340px;
}

.barcode-wrap > svg {
  max-width: 250px;
  height: auto;
}

.MuiPaper-elevation8 {
  box-shadow: none !important;
  border: 1px solid black;
}

.MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]
  .MuiAutocomplete-input {
  padding: 2.5px 4px !important;
}

.trimmings-item {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: nowrap;
  align-items: flex-start;
  margin-bottom: 10px;
  max-width: 200px;
}

.table-header-search-input {
  position: relative;
  color: #aaa;
  font-size: 16px;
}

.btn-center {
  margin-left: 160px;
  margin-top: 5px;
}

.btn-size {
  max-width: 235px;
}

.btm-text {
  margin-top: 20px;
  margin-left: 120px;
}

.fa-close-cls {
  cursor: pointer;
  margin-left: 560px;
}

.model-height {
  height: auto;
}

.calender-margin {
  margin-top: 40px;
  margin-left: 120px;
}

/* .react-calendar__navigation__label
  {
    background-color: aquamarine !important;
  } */

.order-detail {
  padding-right: 20px;
}

.order-detail-x {
  cursor: pointer;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: end;
  align-items: center;
  align-content: center;
}

.order-detail-x:hover {
  cursor: pointer;
  font-weight: 700;
}

.order-detail-x-item {
  width: 20px;
  height: 20px;
  font-size: 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  border-radius: 50px;
  background: #e5e5e5;
}

.order-detail-container {
  border: 1px solid #e3e8ee;
  padding: 1rem;
  border-radius: 8px;
  width: 100%;
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
}

.order-detail-container-item {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}

.order-detail-wrap {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  grid-gap: 0;
  grid-gap: 0;
  gap: 0;
  grid-column-gap: 18px;
  column-gap: 18px;
}

.order-detail-field-column {
  display: flex;
  flex-direction: column;
  justify-content: start;
  width: 220px;
}

.order-detail-field-column-wide {
  display: flex;
  flex-direction: column;
  justify-content: start;
  width: 100%;
  max-width: 500px;
}

.order-detail-container-input {
  display: flex;
  flex-direction: column;
}

.inline-form-group {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  align-content: start;
  margin: 0.5rem 1rem 0.5rem 0;
  width: 22%;
}

.inline-form-group-wide {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  align-content: start;
  margin: 0.5rem 0;
  gap: 10px;
}

.inline-form-group-wide > * {
  width: 16% !important;
  margin-right: 0 !important;
}

.inline-form-group-wide > span {
  display: block;
  padding: 8px;
}

.order-detail-input-line {
  box-shadow: 0px 1px 2px 0px #37415114;
  padding: 8px;
  border-radius: 4px;
  border: 1px;
  gap: 10px;
  border: 1px solid #e3e8ee;
  width: 116px;
}

.order-detail-label-line {
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: left;
  color: #697386;
  margin-bottom: 0;
  margin-right: 0.5rem;
}

.order-detail-input-line-wide {
  box-shadow: 0px 1px 2px 0px #37415114;
  padding: 8px;
  border-radius: 4px;
  border: 1px;
  gap: 10px;
  border: 1px solid #e3e8ee;
  width: 80px;
  margin-right: 0.5rem;
}

.order-detail-label-line-wide {
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: left;
  color: #697386;
  margin-bottom: 0;
  margin-right: 0.5rem;
  width: 80px;
}

.detail-heading-wrap {
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;
  gap: 20px;
  align-content: center;
  flex-direction: row;
  justify-content: flex-start;
  color: #000;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  /* 142.857% */
  letter-spacing: 0.25px;
}

.order-detail-input {
  width: 170px;
  box-shadow: 0px 1px 2px 0px #37415114;
  padding: 8px;
  border-radius: 4px;
  border: 1px;
  gap: 10px;
  border: 1px solid #e3e8ee;
  margin-bottom: 1rem;
}

.order-detail-label {
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: left;
  color: #697386;
  margin-bottom: 0;
}

.order-detail-trimming {
  width: 250px;
}

.note-box {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 10px;
  font-size: 14px;
}

.dialog-action-wrap {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  margin-top: 20px;
}

.order-detail-title-border {
  padding: 0.5rem 1rem;
  border: 1px solid #e3e8ee;
  font-size: 14px;
  border-radius: 8px;
  width: 150px;
  text-align: center;
}

.field-view-carcass-text {
  font-family: Roboto;
  font-size: 30px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.25px;
  text-align: left;
  margin: 2.5rem 0;
}

.order-detail-tab {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  margin-top: 20px;
}

.field-view-item {
  padding: 0.5rem 1rem;
  border: 1px solid #e3e8ee;
  font-size: 14px;
  border-radius: 8px;
  width: 100px;
  text-align: center;
  cursor: pointer;
}

.field-view-item:hover {
  background-color: #e0e0e0;
  color: black;
}

.active {
  background-color: #4f4f4f;
  color: white;
}

.order-detail-container-2-side {
  display: flex;
  flex-direction: row;
}

.order-detail-container-2-side-item {
  padding: 1rem;
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  width: 50%;
  justify-content: start;
  align-items: start;
  align-content: start;
}

.order-detail-container-2-side-title {
  font-size: 32px;
  font-weight: 400;
  text-align: center;
  margin: 1rem 0 60px 0;
}

.popup-modal-item-title-block {
  padding: 1rem 2rem;
  border-radius: 8px;
  background: var(--dark-medium, #4f4f4f);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  margin-bottom: 1rem;
  cursor: pointer;
  color: white;
  width: 200px;
}

.field-view-content {
  padding-top: 30px;
}

.order-detail-content-two-column {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.order-detail-container-mini {
  border: 1px solid #e3e8ee;
  padding: 1rem;
  border-radius: 8px;
  width: 100%;
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: start;
  position: relative;
}

.order-detail-container-mini .remove-box {
  font-size: 8px;
  position: absolute;
  right: 5px;
  top: 5px;
}

.order-detail-container-mini.activated {
  border-color: #000000 !important;
}

.order-detail-packaging-right {
  width: 230px;
  margin-left: 1rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-content: center;
}

.popup-table-mini-item {
  padding: 0 5px;
  margin: 0 5px 0 0;
  border: 1px solid black;
  font-size: 14px;
}

.popup-table-mini-item-white {
  padding: 0;
  margin: 0;
  color: grey;
  font-size: 14px;
}

.recipes-add-btn {
  padding: 1rem;
  border-radius: 8px;
  background: var(--dark-medium, #4f4f4f);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  margin-bottom: 1rem;
  cursor: pointer;
  color: white !important;
  width: 200px;
}

.recipes-add-btn:hover {
  opacity: 0.8;
}

.table-header-search-input {
  display: inline-block;
}

.table-header-search-input input {
  box-shadow: 0px 1px 2px 0px #37415114;
  padding: 6px;
  border-radius: 4px;
  border: 1px;
  gap: 10px;
  border: 1px solid #e3e8ee;
}

.table-header-search-input input {
  text-indent: 5px;
}

.table-header-search-input .fa-search {
  position: absolute;
  top: 10px;
  left: 10px;
}

.table-header-search-input .fa-search {
  left: auto;
  right: 25px;
  background-color: white;
}

.table-header {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  align-content: start;
}

.table-header-filter {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: start;
  align-content: start;
}

.table-header-search {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  align-content: center;
}

.table-header-item {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  align-content: start;
  margin-right: 1rem;
}

.table-header-item input {
  width: 200px;
}

.table-header-item select {
  width: 200px;
}

.table-header-item-label {
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: left;
  color: #697386;
  margin-bottom: 0;
}

.table-header-item-input {
  box-shadow: 0px 1px 2px 0px #37415114;
  padding: 8px;
  border-radius: 4px;
  border: 1px;
  gap: 10px;
  border: 1px solid #e3e8ee;
  margin-bottom: 1rem;
}

.file-upload {
  /* border: 1px solid black; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* padding: 1rem; */
}

.file-upload-title {
  font-size: 19px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left;
  margin-bottom: 30px;
}

.file-upload-btn {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  width: 100%;
}

.file-upload-btn-item {
  color: white !important;
  background: #4f4f4f;
  padding: 0.5rem 2rem;
  border-radius: 5px;
  cursor: pointer;
}

.file-upload-table {
  width: 100%;
  border: 1px solid #e3e8ee;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0.5rem;
  margin-top: 1rem;
  border-radius: 5px;
}

.file-upload-table-item {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem;
  /* border-bottom: 1px solid black; */
}

.download-button {
  cursor: pointer;
}

.download-button:hover {
  opacity: 0.8;
}

.button-separator {
  margin: 0 0.5rem;
}

.left-menu-item-create-new {
  display: flex;
  flex-direction: row;
  justify-content: star;
  align-items: center;
  align-content: center;
  padding: 1.5rem 2rem;
  border-radius: 99px;
  background: var(--white-base, #fff);
  box-shadow: 0px 6px 12px 0px rgba(54, 123, 245, 0.16),
    0px 16px 24px 0px rgba(54, 123, 245, 0.16);
  width: 250px;
  cursor: pointer;
}

.left-menu-item-create-new:hover {
  background: rgba(218, 218, 218, 0.16) !important;
}

.left-menu-item-create-new h1 {
  color: var(--blue-base, #367bf5);
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0.15px;
  margin-bottom: 0;
}

.calendar-popup-form-config-item {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  align-content: start;
  width: 100%;
  margin-bottom: 1rem;
}

.calendar-popup-form-config-item-label {
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: left;
  color: #697386;
  margin-bottom: 0;
}

.calendar-popup-form-config-item-input {
  box-shadow: 0px 1px 2px 0px #37415114;
  padding: 8px;
  border-radius: 4px;
  border: 1px;
  gap: 10px;
  border: 1px solid #e3e8ee;
  width: 300px;
  cursor: pointer;
}

.calendar-popup-form-config-item-input-dropdown {
  box-shadow: 0px 1px 2px 0px #37415114;
  border-radius: 4px;
  border: 1px;
  gap: 10px;
  border: 1px solid #e3e8ee;
  width: 300px;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  align-content: center;
  padding: 0.5rem;
  accent-color: #3c923f;
}

.calendar-popup-form-config-item-input-dropdown label {
  margin-bottom: 0;
}

.calendar-popup-form-container {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: start;
  align-content: start;
  padding: 2rem;
}

.calendar-popup-form-right {
  display: flex;
  flex-direction: column;
  justify-content: start;
  width: 100%;
}

.nav-link {
  padding: 0.5rem;
}

.radWrp input {
  accent-color: #3c923f;
}

.modal-header {
  border: none;
}

.proc-dash-info {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  margin-bottom: 5rem;
  gap: 20px;
}

.proc-dash-info-item:hover {
  background-color: var(--gray-10, #cacaca);
}

.proc-dash-info-item {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  align-content: start;
  border-radius: 16px;
  background: var(--gray-10, #f7f9fa);
  padding: 1rem;
  min-width: 200px;
  cursor: pointer;
}

.proc-dash-info-item span {
  color: var(--h-5-color, rgba(0, 0, 0, 0.47));
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0.15px;
  margin-bottom: 1rem;
}

.proc-dash-info-item h1 {
  font-size: 48px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.customer-popup-btn-no {
  width: 150px;
  border: 1px solid #4f4f4f !important;
  background: white;
  font-size: 15px;
  color: black;
  transition: all 0.3s ease-in-out;
  border-radius: 5px;
  margin-right: 1rem !important;
  text-transform: none !important;
}

.customer-popup-btn-x {
  color: white !important;
  background: #4f4f4f !important;
  padding: 0.5rem 1rem;
  border-radius: 10px;
  text-transform: unset !important;
  padding: 0.5rem 2rem !important;
}

.customer-popup-btn {
  color: white !important;
  background: #4f4f4f !important;
  padding: 0.5rem 1rem;
  border-radius: 10px;
  text-transform: unset !important;
  padding: 0.5rem 2rem !important;
}

.customer-popup-btn:hover {
  opacity: 0.8;
}

.customer-popup-form label {
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: left;
  color: #697386;
}

.customer-popup-form input {
  box-shadow: 0px 1px 2px 0px #37415114;
  padding: 8px;
  border-radius: 4px;
  border: 1px;
  gap: 10px;
  border: 1px solid #e3e8ee;
  margin-bottom: 1rem;
}

.customer-popup-header {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  align-content: center;
}

.customer-popup-info {
  width: 200px;
  border-bottom: 1px solid #f1f3f4;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-content: center;
  padding: 0.5rem;
  margin: 1rem 0;
}

.customer-popup-info span {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.25px;
  text-align: left;
}

.users-and-roles-form-x-item {
  width: 20px;
  height: 20px;
  font-size: 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  border-radius: 50px;
  background: #e5e5e5;
}

.organic-form-x-item {
  width: 20px;
  height: 20px;
  font-size: 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  border-radius: 50px;
  background: #e5e5e5;
}

.calendar-popup-form-x-item {
  width: 20px;
  height: 20px;
  font-size: 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  border-radius: 50px;
  background: #e5e5e5;
}

.calendar-popup-form-x {
  cursor: pointer;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: end;
  align-items: center;
  align-content: center;
  padding: 1rem;
  margin-top: 5rem;
}

.calendar-popup-form-x:hover {
  cursor: pointer;
  font-weight: 700;
}

.calendar-popup-form-slot-item-container {
  padding-top: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  align-content: start;
  width: 100%;
}

.calendar-popup-form-slot {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  align-content: start;
  padding: 0 2rem 2rem 2rem;
}

.calendar-popup-form-slot-header {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: start;
  align-content: start;
  padding: 0 2rem;
}

.calendar-popup-form-slot-header-icon {
  padding: 1rem;
  border-radius: 8px;
  background: var(--grey-light, #f5f5f5);
  margin-right: 1.5rem;
}

.calendar-popup-form-slot-header-data {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  align-content: start;
}

.calendar-popup-form-slot-header-data h1 {
  color: var(--secondary-dark-color, #54595e);
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.calendar-popup-form-slot-header-data span {
  color: rgba(84, 89, 94, 0.6);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
}

.calendar-popup-form-slot-item {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  align-content: start;
}

.calendar-popup-form-slot-item-input {
  box-shadow: 0px 1px 2px 0px #37415114;
  padding: 8px;
  border-radius: 4px;
  border: 1px;
  gap: 10px;
  border: 1px solid #e3e8ee;
  margin-bottom: 1rem;
  width: 400px;
}

.calendar-popup-form-slot-item-label {
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: left;
  color: #697386;
  margin-bottom: 0;
}

.calendar-popup-form-slot-item-btn {
  color: white !important;
  background: #4f4f4f !important;
  padding: 0.5rem 1rem;
  border-radius: 8px;
  text-transform: unset !important;
  padding: 0.5rem 2rem !important;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.calendar-popup-form-slot-item-btn-container {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  align-content: start;
  width: 400px;
  margin-bottom: 1rem;
}

.calendar-popup-form-slot-btn-container {
  display: flex;
  flex-direction: row;
  justify-content: end;
  align-items: center;
  align-content: center;
  width: 100%;
}

.calendar-popup-form-slot-btn-container-item {
  cursor: pointer;
  width: 100%;
}

.calendar-popup-form-slot-btn-container-item:hover {
  opacity: 0.8;
}

.calendar-popup-form-slot-btn {
  color: white !important;
  background: #4f4f4f !important;
  padding: 0.5rem 1rem;
  border-radius: 8px;
  text-transform: unset !important;
  padding: 0.5rem 2rem !important;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.calendar-popup-form-config {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  align-content: start;
  padding: 0 2rem 2rem 2rem;
}

.calendar-popup-form-config-header {
  margin-bottom: 2rem;
}

.calendar-popup-form-config-header h1 {
  color: #000;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  /* 100% */
  margin-bottom: 0;
}

.calendar-popup-form-config-header span {
  color: #989b9e;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  /* 142.857% */
}

.proc-profile-detail-item-input {
  /* box-shadow: 0px 1px 2px 0px #37415114; */
  padding: 8px;
  border-radius: 4px;
  border: none !important;
  gap: 10px;
  border: 1px solid #e3e8ee;
  margin-bottom: 1rem;
  width: 90%;
}

.proc-profile-btn {
  color: white !important;
  background: #4f4f4f;
  padding: 0.5rem 1rem;
  border-radius: 5px;
}

.proc-profile-btn-popup {
  width: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  cursor: pointer;
}

.proc-profile-btn-popup:hover {
  opacity: 0.8;
}

.change-password {
  width: 100%;
  padding: 0 2rem 2rem 2rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.popup-no-x {
  width: 100%;
  padding: 2rem 2.5rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.notif-title-item {
  color: white !important;
  background: #4f4f4f;
  padding: 0.5rem 1rem;
  border-radius: 5px;
}

.notif-table-btn {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: end;
  margin-bottom: 1rem;
}

.notif-title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: start;
  align-content: start;
}

.notif-container {
}

.notif-header {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.notif-header h1 {
  /* font-size: 34px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0.25px;
    text-align: left; */
}

.users-and-roles-form-menu {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  flex-wrap: wrap;
  width: 100%;
}

.popup-form-group-menu {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  align-content: start;
}

.popup-form-control {
  box-shadow: 0px 1px 2px 0px #37415114;
  padding: 8px;
  border-radius: 4px;
  border: 1px;
  gap: 10px;
  border: 1px solid #e3e8ee;
}

.popup-form-label {
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: left;
  color: #697386;
  margin-bottom: 0;
}

.users-and-roles-form-x {
  cursor: pointer;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: end;
  align-items: center;
  align-content: center;
}

.users-and-roles-form-x:hover {
  cursor: pointer;
  font-weight: 700;
}

.users-and-roles-btn {
  color: white !important;
  background: #4f4f4f;
  padding: 0.5rem 1rem;
  border-radius: 5px;
}

.users-and-roles-btn:hover {
  color: white;
  opacity: 0.8;
  cursor: pointer;
}

.popup-form-btn-user {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: end;
  align-items: center;
  align-content: center;
  padding: 1rem;
}

.popup-form-btn-user-item {
  color: white !important;
  background: #4f4f4f;
  padding: 0.5rem 1rem;
  border-radius: 5px;
  cursor: pointer;
}

.popup-form-btn-user-item:hover {
  opacity: 0.8;
}

.popup-form-btn {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-content: center;
}
/* 
.rrulegenerator .col-sm-1.offset-sm-2 {
  margin-left: 0;
  margin-right: 37px;
}

.rrulegenerator .offset-sm-2 {
  margin-left: 15px;
  margin-right: 10px;
}

#rrule-1-repeat-monthly-on-day {
  width: 80px;
}

#rrule-1-end {
  width: 100px;
}

#rrule-1-end-onDate-datetime {
  width: 150px;
} */

.users-and-roles-form {
  padding: 0.5rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: start;
  align-content: start;
}

.MuiPaper-rounded {
  border-radius: 8px !important;
}

.modal-dialog {
  margin-top: 100px !important;
}

.modal-content {
  border-radius: 8px !important;
}

.popup-form-group {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  align-content: start;
  /* padding: 1rem 0; */
}

.popup-form-group input {
  /* width: 250px; */
}

.popup-form-group select {
  /* width: 250px; */
}

.organic-form {
  padding: 0.5rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: start;
  align-content: start;
}

.organic-form-item {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  align-content: start;
  padding: 1rem;
}

.organic-form-item input {
  width: 250px;
}

.organic-form-item select {
  width: 250px;
}

.organic-form-x {
  cursor: pointer;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: end;
  align-items: center;
  align-content: center;
}

.organic-form-x:hover {
  cursor: pointer;
  font-weight: 700;
}

body .organic-form-btn {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-content: center;
  padding: 1rem 0.5rem;
  flex-wrap: nowrap;
}

.organic-form-btn-item {
  width: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  cursor: pointer;
  color: white !important;
  background: #4f4f4f;
  padding: 0.5rem 1rem;
  border-radius: 5px;
}

.new-order-header {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  align-content: center;
  padding: 0 2rem;
}
.remove-customer {
  position: absolute;
  top: -10px;
  right: -10px;
  z-index: 1;
  font-size: 10px;
  border-radius: 100px;
}
.remove-customer:hover {
  color: #000000 !important;
}

.popup-header-icon {
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-wrap: nowrap;
  width: 100%;
  max-width: 56px;
  height: 56px;
  border-radius: 8px;
  background: var(--grey-light, #f5f5f5);
  margin-right: 1.5rem;
}

.popup-header-wrap {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  align-content: start;
}

.popup-header-wrap h1 {
  color: var(--secondary-dark-color, #54595e);
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.popup-header-wrap span {
  color: rgba(84, 89, 94, 0.6);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  margin-bottom: 18px;
}

.list-customer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  gap: 80px;
}

.list-customer-item {
  display: flex;
  flex-direction: column;
  justify-content: start;
  width: 100%;
}
.event-detail-wrap {
  margin-bottom: 3rem;
}
.list-customer-item-map {
  margin-bottom: 3rem;
  display: flex;
  position: relative;
}

.new-order-form {
  /* padding: 1rem; */
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: start;
  align-content: start;
}

.new-order-form-item {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  align-content: start;
  width: 100%;
}

.new-order-form-item input {
  /* max-width: 350px; */
}

.new-order-form-item select {
  /* width: 350px; */
}

.new-order-form-label {
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: left;
  color: #697386;
  margin-bottom: 0;
}

.new-order-form-input {
  box-shadow: 0px 1px 2px 0px #37415114;
  padding: 8px;
  border-radius: 4px;
  border: 1px;
  gap: 10px;
  border: 1px solid #e3e8ee;
  margin-bottom: 1rem;
}

.popup-modal {
  padding: 1rem;
  display: flex;
  flex-direction: column;
}

.popup-modal-form-x-item {
  width: 30px;
  height: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  border-radius: 50px;
  background: #e5e5e5;
}

.popup-modal-x {
  cursor: pointer;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: end;
  align-items: center;
  align-content: center;
  padding: 1rem;
  margin-top: 5rem;
}

.popup-modal-x:hover {
  cursor: pointer;
  font-weight: 700;
}

.popup-modal-label {
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: left;
  color: #697386;
}

.popup-modal-btn-container {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  gap: 15px;
  margin-top: 1rem;
}

.popup-modal-btn {
  padding: 1rem 2rem;
  border-radius: 8px;
  background: var(--dark-medium, #4f4f4f);
  color: white !important;
  margin-top: 1rem;
  margin-left: 1rem;
  cursor: pointer;
}

.popup-modal-btn:hover {
  opacity: 0.8;
}

.schedule-modal {
  padding: 0;
}

.schedule-modal-item {
  display: block !important;
  margin-top: 2rem;
}

.new-order-list-btn {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  align-content: start;
}

.new-order-list-btn-item-cut-sheet {
  display: flex;
  flex-direction: row;
}

.new-order-list-btn-item-cut-sheet input {
  accent-color: #3c923f;
}

.new-order-list-btn-item-disabled {
  padding: 1rem 2rem;
  border-radius: 8px;
  background: var(--dark-medium, #808080);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  width: 100%;
  margin-bottom: 1rem;
  color: white !important;
}

.new-order-list-btn-item-disabled span {
  color: var(--grey-light, #f5f5f5);
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.new-order-list-btn-item {
  padding: 1rem 2rem;
  border-radius: 8px;
  background: var(--dark-medium, #4f4f4f);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  width: 100%;
  margin-bottom: 1rem;
  cursor: pointer;
  line-height: 1;
}

.new-order-list-btn-item:hover {
  opacity: 0.8;
}

.new-order-list-btn-item span {
  color: var(--grey-light, #f5f5f5);
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.new-order-list-btn-item-organs-label {
  margin-left: 0.5rem;
}

.new-order-list-btn-item-organs {
  display: flex;
  flex-direction: row;
  color: white;
}

.new-order-list-btn-item-organs-item {
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
}

.new-order-list-btn-item-organs-item-2 {
  padding: 0.5rem;
  display: flex;
  flex-direction: row;
}

.new-order-list-btn-item-organs-item-2 label {
  margin: 0.5rem 0.5rem 0.5rem 0;
}

.new-order-list-info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 6px;
  border: 1px solid #000;
  background: #fffbfb;
  padding: 1rem;
  height: 7rem;
}

.new-order-list-info-item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.new-order-list-info-item h1 {
  color: #000;
  font-size: 23px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  /* 86.957% */
  letter-spacing: 0.25px;
}

.new-order-list-info-item span {
  color: #fff;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  /* 142.857% */
}

.new-order-list-info-item-value {
  width: 30px;
  height: 30px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-content: center;
  background: #4f4f4f;
}

.new-order-list-btn-submit {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.new-order-list-btn-submit-item {
  padding: 10px 2rem;
  border-radius: 8px;
  background: var(--dark-medium, #4f4f4f);
  color: white !important;
  margin-top: 1rem;
  cursor: pointer;
  border: none;
  outline: none !important;
}

.new-order-list-btn-submit-item:hover {
  opacity: 0.8;
}

.users-and-roles-header {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  align-content: center;
}

.users-and-roles-info-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  align-content: flex-start;
}

.users-and-roles-info-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  align-content: start;
}

.users-and-roles-info-title {
  width: 200px;
  border-bottom: 1px solid #f1f3f4;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-content: center;
  padding: 0.5rem;
  margin: 1rem 0;
}

.users-and-roles-info-value {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.25px;
  text-align: left;
  margin: 0.2rem 0;
}

.cut-sheet-new-header {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  align-content: center;
}

.cut-sheet-new-info {
  width: 200px;
  border-bottom: 1px solid #f1f3f4;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-content: center;
  padding: 0.5rem;
  margin: 1rem 0;
}

.left-menu-item {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  align-content: start;
  width: 100%;
  margin: 0 1rem;
}

.left-menu-item-text {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: start;
  align-content: start;
  width: 15rem;
}

.left-menu-item-text h1 {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.4399999976158142px;
  text-align: left;
  color: black;
  margin-bottom: 0;
}

.left-menu-item-text span {
  font-size: 11px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.4000000059604645px;
  text-align: left;
  color: black;
}

.left-menu-item-icon {
  margin: 0.5rem 1rem;
}

.left-menu-items-active {
  background-color: #e2ffe3;
}

.left-menu-items {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  align-content: start;
  width: 100%;
  padding: 1rem 2rem;
  cursor: pointer;
}

.left-menu-items:hover {
  opacity: 0.8;
}

.left-menu-items h1 {
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.4399999976158142px;
  text-align: left;
  color: black;
  margin-bottom: 0;
}

.left-menu-items-button {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  align-content: center;
  margin: 1rem 2rem;
}

.G-zr.dashLayoutWrapper .main-dash h4 {
  font-family: Roboto;
  font-size: 23px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.25px;
  text-align: left;
  color: white;
  border-radius: 7px;
  padding: 0.7rem;
  background: #525252;
}

.item-detail {
  display: flex;
  flex-direction: column;
  justify-content: start;
  flex-wrap: nowrap;
  width: 100%;
}

.MuiDialog-paper {
  width: 100%;
}

.item-detail-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 700px;
}

.item-detail-btn {
  border: 1px solid #4e4e4e;
  background: #4e4e4e;
  padding: 10px 25px;
  font-size: 15px;
  color: #fff;
  transition: all 0.3s ease-in-out;
  border-radius: 8px;
}

.item-detail-label {
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: left;
  color: #697386;
  margin-bottom: 0;
}

.item-detail-input {
  box-shadow: 0px 1px 2px 0px #37415114;
  padding: 8px;
  border-radius: 4px;
  border: 1px;
  grid-gap: 10px;
  gap: 10px;
  border: 1px solid #e3e8ee;
  margin-bottom: 1rem;
  width: 200px;
}

.item-detail-x-item {
  width: 24px;
  height: 24px;
  font-size: 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  border-radius: 50px;
  background: #e5e5e5;
}

.item-detail-x {
  cursor: pointer;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: end;
  align-items: center;
  align-content: center;
  padding: 10px;
}

.item-detail-x:hover {
  cursor: pointer;
  font-weight: 700;
}

.dashLayoutWrapper {
  padding: initial !important;
}

.fc-toolbar h2 {
  font-size: 32px;
}

table.cstmGrzrTable td,
table.cstmGrzrTable th {
  color: #919699;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0.10000000149011612px;
  text-align: left;
}

table.cstmGrzrTable td {
  font-size: 16px;
}

#print-labels {
  display: flex;
  grid-gap: 10px;
  gap: 10px;
  column-gap: 10px;
  flex-wrap: wrap;
}

#print-labels > img {
  border: 1px solid #000000;
  width: 45%;
}

.cstmGrzrTable thead {
  background: white;
}

body .actvOrdrsWrpr .tableTitle {
  color: #b4b4b4;
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
}

.G-zr .childrenValues .Dash-right .navSetup .nav-item {
  border: 1px solid #bebebe;
  border-radius: 10px;
  color: black;
  margin-right: 100px;
}

.G-zr .childrenValues .Dash-right .navSetup .activepnav {
  border-radius: 10px;
  background: #4f4f4f !important;
}

.G-zr .mnDashLayoutWrpr {
  background: none !important;
}

.G-zr .tabVwWrapper .cutGridLayout {
  display: flex !important;
  flex-wrap: wrap;
}

.G-zr .tabVwWrapper .cutGridLayout .cutSheets {
  border: 1.5px solid #4FD1C5;
  width: 23%;
  padding: 10px 30px;
}

.G-zr .tabVwWrapper .cutGridLayout .cutSheets:hover {
  /* background: #4F4F4F; */
}

.G-zr .cstmrLkpPpup .modal-title span {
  color: red;
}

.G-zr .Dash-right .tabVwWrapper .table tbody td {
  font-size: 16px;
}

.modal-body.dialog-popup-body {
  padding: 0 40px 38px;
}

.Dash-container {
  margin-top: 30px;
  /* margin-right:2rem; */
}
.calendar-sidebar .otherSlots h3 {
  background: none !important;
  font-size: 16px !important;
  padding: 0 !important;
  color: #b4b4b4 !important;
  font-family: "Roboto" !important;
}
.G-zr.dashLayoutWrapper .calendar-sidebar > .otherSlots > ul > li > h4 {
  background: none !important;
  font-size: 14px !important;
  padding: 0 !important;
  color: #212529 !important;
  font-family: "Roboto" !important;
}
.calendar-sidebar .otherSlots ul {
  list-style-type: none;
  padding-left: 0;
}

.calendar-sidebar .otherSlots li {
  font-size: 12px;
}
.calendar-sidebar .otherSlots h4::after {
  display: none !important;
}

@media (max-width: 768px) {
  .d-flex .align-items-start .flex-mb-wrap .mnDashLayoutWrpr {
    width: none;
  }

  .left-menu-item {
    margin: 0;
  }

  .container {
    overflow-y: scroll;
    height: 100vh;
    padding-bottom: 5rem;
    margin-top: 1rem;
  }

  .proc-dash-info {
    flex-direction: column;
    margin-bottom: 3rem;
  }

  .proc-dash-info-item span {
    font-size: 16px;
    margin: 0;
  }

  .proc-dash-info-item h1 {
    font-size: 20px;
    margin: 0;
  }

  .proc-dash-info-item {
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
  }

  .calendar-search {
    flex-direction: column !important;
    gap: 1rem;
  }

  .tableTitle {
    margin-top: 3rem !important;
  }

  .calendar-header-left {
    margin-bottom: 1rem;
  }

  .agendaCnt {
    width: 100%;
  }

  .Dash-container {
    margin-right: 0;
    margin-top: 3rem;
  }

  .actvOrdrsWrpr {
    margin-top: 1rem;
  }

  .left-menu-item-create-new {
    padding: 1.5rem 2rem !important;
  }

  .list-customer {
    flex-direction: column;
  }

  .new-order-list-btn-item-disabled {
    text-align: center;
  }

  .new-order-list-btn-item {
    text-align: center;
  }

  .new-order-list-btn-submit {
    flex-direction: column;
  }

  .new-order-list-btn-submit-item {
    text-align: center;
  }

  .modal-body {
    overflow-x: auto;
  }

  /* .fc-direction-ltr {
    width: 220px;
  } */

  .fc-toolbar h2 {
    font-size: 28px !important;
    text-align: start;
    margin-bottom: 1rem !important;
  }

  .organic-form-btn {
    flex-direction: column !important;
    gap: 1rem;
  }

  .btn-secondary {
    margin: 0 !important;
  }

  .item-detail {
    overflow-x: hidden;
  }

  .table {
    width: 100vw;
  }

  .modal-body.dialog-popup-body {
    padding: 1.5rem;
  }

  .list-customer-item-map {
    margin-bottom: 0;
  }

  .new-order-list-btn-item-organs-item-2 {
    flex-direction: column;
  }

  .table-header-item {
    margin: 0;
  }

  .table-header-item select {
    width: 100px;
  }

  .table-header-filter {
    justify-content: center;
    gap: 1rem;
  }

  .Dash-right {
    padding: 1.5rem;
  }

  .profile-container {
    padding: 1.5rem;
    height: 100vh;
  }

  .proc-profile-menu-btn {
    flex-direction: column !important;
    gap: 1rem;
    align-items: center !important;
  }

  .btn-tab {
    width: 200px;
  }

  .page-title {
    line-height: 30px;
  }

  .user-and-roles-container {
    padding: 1.5rem;
    margin: 0 !important;
    height: 100vh;
  }

  .navSetup {
    background: white !important;
  }

  .cutSheets {
    padding: 1rem;
    border: 1px solid #358539 !important;
    margin-top: 1rem;
    border-radius: 10px;
  }

  .cutSheetsNew {
    border: 1px dashed #358539 !important;
  }

  .cut-sheet-new-header-container {
    padding: 1.5rem;
    height: 100vh;
    overflow-y: auto;
  }

  .cut-sheet-new-button {
    flex-direction: column !important;
    gap: 1rem;
    padding-bottom: 1rem;
  }

  .btn-danger {
    margin: 0 !important;
  }

  .calendar-popup-form-container {
    flex-direction: column;
    padding: 0;
    overflow-x: hidden;
  }

  .calendar-popup-form-slot {
    padding: 0;
    width: 100vw;
  }

  .calendar-popup-form-slot-data h1 {
    font-size: 20px;
  }

  .calendar-popup-form-slot-item-input {
    width: 200px !important;
  }

  .calendar-popup-form-slot-item-btn {
    width: 200px !important;
    text-align: center;
  }

  .calendar-popup-form-slot-data {
    width: 200px !important;
  }

  .calendar-popup-form-slot-data-container {
    width: 200px !important;
  }

  .calendar-popup-form-right {
    align-items: center;
    justify-content: center;
  }

  .calendar-popup-form-slot-btn-container-item {
    width: fit-content;
    padding-bottom: 1rem;
  }

  #root {
    height: 100vh;
  }

  .order-detail {
    padding: 1rem;
  }

  .order-detail-container-input {
    width: 100%;
  }

  .order-detail-container-item {
    gap: 1rem;
  }

  .order-detail-wrap {
    flex-direction: column;
    margin-bottom: 1rem;
  }

  .inline-form-group {
    width: 100%;
  }

  .order-detail-input-line {
    width: 200px;
  }

  .justify-content-end {
    align-content: center;
    flex-wrap: wrap;
    gap: 1rem;
  }

  .order-detail-container-2-side-title {
    font-size: 20px;
  }

  .order-detail-tab {
    flex-wrap: wrap;
    gap: 0.5rem;
  }

  .order-detail-content-two-column {
    flex-wrap: wrap !important;
    justify-content: center;
  }

  .table-responsive {
    margin: 0 !important;
  }

  .order-detail-packaging-right {
    margin-top: 1rem;
    margin-left: 0;
  }

  .order-detail-trimming {
    width: 100%;
    margin-top: 1rem;
  }

  .popup-page-container-item {
    flex-wrap: wrap;
    gap: 1rem;
  }

  .popup-page-container-input {
    margin: 0;
  }

  .popup-page-input-line-wide {
    width: 30px !important;
  }

  .align-items-center {
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;
    display: flex;
    align-content: flex-start;
    flex-wrap: wrap;
  }

  .customer-popup-btn {
    border-radius: 8px !important;
    margin-bottom: 3rem !important;
  }
}
