
.G-zr .breadcrumb-item+.breadcrumb-item::before{
    content: ">"!important;
    font-weight: 600;
    color: #358539
}
.G-zr .side-Dash ul{
    list-style-type: none;
    background: #fff;
    box-shadow: 0px 7px 15px 0px #0000001f;
}

.G-zr .side-Dash ul li:hover,.G-zr .side-Dash ul li:focus{cursor:pointer;background: #358539;color: #fff;transition: all 0.8s cubic-bezier(0.22, 0.61, 0.36, 1);}


.G-zr .Dash-right .pro-file img {
    width: 120px;
    height: 120px;
    object-fit: cover;
    margin: auto;
    border-radius: 100px;
}

.G-zr .side-Dash ul li.active{
    background: #358539;
    position: relative;color: #fff;
}

.G-zr .Dash-right .table tbody td {
    background: #fff;
}
.G-zr .Dash-right .table thead th {
    vertical-align: top;
    /* text-align: center; */
    border-bottom: 2px solid #E3E5E5;
    border-top:none;
    font-size: 14px;
    font-weight: 500;
    padding: 0;
    background: #fff;
    padding-bottom: 5px;
    padding-top: 5px;
    color: #919699;
}
.G-zr .Dash-right .table .tbody tr{
    background: #F3F3F3;
}
.G-zr .Dash-right .table tbody {background: #F3F3F3;}
.G-zr .Dash-right .table tbody td b{
    width:115px;
    overflow:hidden;
    white-space:nowrap;
    text-overflow: ellipsis;
    display: block;
    font-weight:400;
}.G-zr .Dash-right .table tbody td {
    vertical-align: middle;
    border-top:none;
    height: 45px;
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    padding-top: .5em;
    padding-bottom: .5em;
    border-bottom:2px solid #dbdbdb;
}

.G-zr .Dash-right .nav-tabs {
    border-bottom: 1px solid #358539;
}
.react-tabs__tab--selected {
    font-size: 16px;
    font-weight: 500;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    color: #ffffff;
    background-color: #358539;
    border: 1px solid #358539;
}
.G-zr .Dash-right .nav-tabs .nav-item {
    cursor: pointer;
    margin-bottom: 0px;

}
/* .G-zr .Dash-right .nav-tabs .nav-link {
    border: 1px solid #F3F3F3;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    background: #F3F3F3;
    font-size: 16px;
    font-weight: 500;
    color: #358539;
} */

.G-zr .progress-form .sub-pro img{
    width: 115px;
    height: 52px;
    object-position: center;
    object-fit: cover;
    border-radius: 6px;
}
.G-zr .progress-form.form-col p.t-t{left: 30px;}
.G-zr .progress-form p.t-t{
    top: -8px;
    background: #fff;
    left: 15px;
    padding: 0 12px;
    font-size: 12px;
    font-weight: 500;
}
.G-zr .D-input {
    border: 1px solid #DEDEDE;
    font-size: 15px;
    font-weight: 400;
    height: 45px;
    color: #929292;
    border-radius: 2px;
    padding: 0 25px;
}
.G-zr .txt-area {
    height: 100px!important;
    padding: 20px 25px!important;
}
.up-load{position: relative;}
.up-load input[type=file] {
    left: 0;
    top: 0;
    bottom: 0;
    opacity: 0;
    padding: 0px;
    position: absolute;
    cursor: pointer;
    width: 100%;
    line-height: 33px;
}

.numberfield::-webkit-inner-spin-button, 
.numberfield::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}

@media (max-width: 600px) {
    .progress-form .PrfDts .col{
        max-width: fit-content;
        width: auto;
        min-width: auto;
    }

    .gfrWrpr{
        flex-wrap: wrap;
    }
}