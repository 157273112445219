.SortableItem {
   padding: 3px !important;
}

.form-label p {
   margin-bottom: 0;
}



