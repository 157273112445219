.agendaCnt {
  display: grid;
  /* grid-template-columns: 4fr 1fr; */
  gap: 30px;
}

.agendaCnt .otherSlots {
  background: #f6f6f6;
  padding: 15px;
  height: max-content;
}

.agendaCnt .otherSlots h3 {
  font-size: 16px;
  margin-bottom: 15px;
  font-weight: 700;
  color: #358539;
  text-transform: uppercase;
}

.agendaCnt .otherSlots ul {
  padding: 0;
  margin: 0;
}

.agendaCnt .otherSlots ul li {
  list-style: none;
  color: #333;
  padding: 8px 0;
  margin: 0;
}

.agendaCnt .otherSlots ul li h4 {
  font-size: 12px;
  font-weight: 600;
  margin: 0;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 1;
}

.agendaCnt .otherSlots ul li p {
  margin: 5px 0 0;
  font-size: 12px;
  font-weight: 400;
}

.agendaCnt .otherSlots ul hr {
  margin: 5px 0;
}

.fc-content{
  border: none;
  background: rgba(0, 0, 0, 0);
}

.fc-event-title{
  color: #000 !important;
}

.dropoff-calendar .fc-event-title{
  color: #ffffff !important;
  cursor: pointer;
}

.fc-event, .fc-event-dot{
  background-color: rgba(0, 0, 0, 0);
}

.fc-event{
  border: none;
  cursor: pointer;
}
.fc-event:hover{
  background: rgb(60, 146, 63);
  color: #ffffff;
}

.fc-event:hover .fc-event-title{
  color: #ffffff !important;
}
.fc-day.selected-dropoff{
  background: rgba(60, 146, 63, 0.3);
}

@media (max-width: 1200px) {
  .agendaCnt {
    display: block;
  }

  .agendaCnt .otherSlots {
    margin-top: 30px;
  }
}


