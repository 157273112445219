.G-zr .procnavp1{
    /* position: absolute; */
    top: 0;
    margin-top: 0;
    width: inherit;
    height: inherit;
    left: 0;
    color: white;
}

.G-zr .procnavp2{
    /* position: absolute; */
    right: 0;
    color: white;
}
.G-zr .procnavp2,.procnavp1:hover{
    border-radius: 0;
}

.navSetup{
    /* background: #358539; */
    /* height: 3em; */
    display: flex;
    justify-content: flex-start;
    background: #358539;
}
.activepnav{
    background-color:white!important;
    border: 1px solid#BEBEBE!important;
    color:#358539!important;
}

