.login-page {
  /* Scope all styles to the login page */
  /* General Styles */
  body, html {
    margin: 0;
    padding: 0;
    height: 100%;
    font-family: Arial, sans-serif;
    background-color: #ffffff; /* Set background color to white */
  }

  .G-zr {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #ffffff; /* Set background color to white */
  }

  .row {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin: auto;
  }

  .col-lg-7, .col-md-6, .col-sm-12, .col-lg-5 {
    padding: 0;
  }

  .form-right {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    padding: 10rem;
    background-color: white;
    border-radius: 8px;
    align-items: baseline;
  }

  .form-left1 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-size: cover; /* Ensure the image covers the entire area */
    background-position: center; /* Center the image */
    height: 90%;
    padding: 7rem 5rem;
    color: white;
    width: 122%;
    border-bottom-left-radius: 15px; /* Adjust the value as needed */
  }

  .dont-have-account {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    font-weight: 500;
    font-size: 16px;
    line-height: 18.75px;
    color: #94969A;
  }

  .login-title {
    font-weight: 700;
    color: #3C923F80;
    font-size: 32px;
  }

  .login-button {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: start;
    align-content: start;
    width: 100%;
  }

  .password-eye-icon {
    cursor: pointer;
    position: absolute;
    right: 30px;
    top: 50%;
    transform: translateY(-50%);
  }

  .btn-google, .btn-fb, .btn-login {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 10px 20px;
    border-radius: 30px;
    text-decoration: none;
    padding: 1rem;
    width: 100%;
    max-width: 22rem;
    box-shadow: 0px 15px 50px 0px #DE5F8F1F;
    font-size: 16px;
    font-weight: 500;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
  }

  .btn-google {
    background-image: linear-gradient(to right, #1688D6, #02EC25);
    color: white;
  }

  .btn-fb {
    background-color: white;
    color: black;
    font-weight: bold;
  }

  .btn-login1 {
    background-color: #3C923F80; /* 50% opacity */
    color: white;
    border: none;
    padding: 10px 20px;
    font-size: 12px;
    cursor: pointer;
    border-radius: 12px;
    width: 100%;
    max-width: 22rem;
    transition: background-color 0.3s ease;
  }

  .btn-login1:hover {
    background-color: #3C923F; /* Full opacity on hover */
  }

  .google-icon {
    border: none;
    content: "\f078";
    font-family: "FontAwesome";
    font-weight: 400;
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    margin-left: 15px;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
  }

  .msg-or, .msg-copyright {
    color: #94969A;
    margin: 2rem 0;
  }

  .footer1 {
    text-align: center;
    padding: 10px;
    position: absolute;
    bottom: 0;
    width: 100%;
  }

  .msg-copyright {
    margin: 10rem 0;
  }

  .form-input {
    flex-direction: column;
    justify-content: start;
    align-items: center;
    align-content: start;
    width: 100%;
  }

  .G-form {
    width: 100%;
  }

  .input-login {
    position: relative;
    width: 100%;
    max-width: 22rem;
  }

  .input-login-icon {
    height: 1.5rem;
    width: 1.5rem;
    padding: 1px;
    position: absolute;
    box-sizing: border-box;
    top: 50%;
    left: 20px;
    transform: translateY(-50%);
    padding: 0.2rem;
  }

  .input-login-form {
    height: 50px;
    box-sizing: border-box;
    padding-left: 3.5rem;
    border-radius: 8px;
    border: 1px solid #EAEBF6;
    width: 100%;
    max-width: 22rem;
  }

  /* Responsive Styles */
  @media (max-width: 768px) {
    .form-left {
      display: none;
    }

    .form-right {
      padding: 1rem;
      box-shadow: none;
    }

    .btn-google, .btn-fb, .btn-login {
      min-width: 100%;
    }

    .input-login-form {
      max-width: 100%;
    }
  }

  @media (max-width: 576px) {
    .login-title {
      font-size: 1.5rem;
    }

    .dont-have-account {
      font-size: 14px;
    }
  }
}
